import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import TableLoader from '../../../../../core/ui/utility/TableLoader';
import { printUser } from '../../../../../core/utils/text';
import { Cst } from '../cst';
import { sortCsts } from '../services/cstService';
import CSTMenu from './cstMenu/CSTMenu';
import EvaluationMethods from './EvaluationMethods';

type CstListProps = {
  csts: Cst[];
  fetchCsts: () => Promise<void>;
  loading: boolean;
};

const CSTList = ({ csts, fetchCsts, loading }: CstListProps) => {
  return (
    <>
      <Box sx={{ mt: 4 }}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 500, maxWidth: 1200 }}
        >
          <Table aria-label="custom pagination table" size="medium">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Teacher</TableCell>
                <TableCell>Weekly Count</TableCell>
                <TableCell>Year</TableCell>
                <TableCell>Evaluation Methods</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableLoader loading={loading} span={6}>
                {sortCsts(csts).map((cst, i) => (
                  <TableRow key={cst.id}>
                    <TableCell size="small">{i + 1}</TableCell>
                    <TableCell>{cst.subject.subject}</TableCell>
                    <TableCell>{printUser(cst.teacher.user)}</TableCell>
                    <TableCell>{cst.count}</TableCell>
                    <TableCell>{cst.academicYear.year}</TableCell>
                    <TableCell sx={{ py: 0 }}>
                      <EvaluationMethods
                        cstId={cst.id}
                        evaluationMethods={cst.evaluationMethods}
                      />
                    </TableCell>
                    <TableCell align="right">
                      {/* <Grid display="flex" flexDirection="row"> */}
                      <CSTMenu
                        cst={cst}
                        fetchCsts={fetchCsts}
                        insertedQuarters={cst.evaluationMethods.map(
                          (em) => em.quarter.id
                        )}
                      />
                      {/* </Grid> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableLoader>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CSTList;
