import { useEffect, useMemo, useState } from 'react';
import { TextMessage } from '../../text-message';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';

export const useLoadMessages = (resourceId: string) => {
  const [msgs, setMsgs] = useState<TextMessage[]>([]);
  const [loading, setLoading] = useState(false);

  const { fetchData } = useFetchApiData();

  const stat = useMemo(
    () => [msgs.filter((i) => i.msg_sent).length, msgs.length],
    [msgs]
  );

  const loadMessages = async () => {
    setLoading(true);
    await fetchData(`text-messages?parent_id=${resourceId}`, {
      onError: () => {},
      onSuccess: (data) => {
        setMsgs(data);
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    loadMessages();
  }, [resourceId]);

  return { loading, sent: stat[0], total: stat[1], loadMessages };
};
