import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { AttachmentPayment } from '../lib/types/attachment-payment.type';
import {
  StudentFixedReport,
  StudentRecurrentReport,
} from '../lib/types/student-payment-report';

const AttachmentReportTable = ({
  payments,
}: {
  payments: AttachmentPayment[];
}) => {
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 1000, maxWidth: 1600 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Attachment</TableCell>
                <TableCell>Payment</TableCell>
                <TableCell>Student</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Penalty</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Fs</TableCell>
                <TableCell>Handled By</TableCell>
                <TableCell>Paid On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((payment, i) => (
                <TableRow
                  key={i}
                  sx={{
                    py: 0,
                    bgcolor:
                      payment.type === 'empty' ? '#f1628160' : 'transparent',
                  }}
                >
                  <TableCell size="small">
                    <Typography
                      variant="body2"
                      color={payment.type === 'empty' ? 'red' : 'black'}
                    >
                      {i + 1}
                    </Typography>
                  </TableCell>
                  <TableCell size="small">
                    <Typography
                      variant="body2"
                      color={payment.type === 'empty' ? 'red' : 'black'}
                    >
                      {payment.type}
                    </Typography>
                  </TableCell>
                  {payment.type === 'fixed' && (
                    <FixedPaymentRow payment={payment.payment} />
                  )}
                  {payment.type === 'recurrent' && (
                    <RecurrentPaymentRow payment={payment.payment} />
                  )}
                  {payment.type === 'empty' && (
                    <EmptyRow attachment={payment.attachment} />
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default AttachmentReportTable;

const FixedPaymentRow = ({ payment }: { payment: StudentFixedReport }) => (
  <>
    <TableCell size="small">{payment.attachment}</TableCell>
    <TableCell size="small">{payment.fixedPayment.description}</TableCell>
    <TableCell size="small">
      {payment.student.first_name} {payment.student.father_name} (
      {payment.grade?.name})
    </TableCell>
    <TableCell size="small">{payment.amount}</TableCell>
    <TableCell size="small">{payment.penalty}</TableCell>
    <TableCell size="small">{payment.total}</TableCell>
    <TableCell size="small">{payment.fs}</TableCell>
    <TableCell size="small">
      {payment.user.first_name} {payment.user.father_name}
    </TableCell>
    <TableCell size="small">
      {moment(payment.created_at).format('MMM D YYYY')}
    </TableCell>
  </>
);

const RecurrentPaymentRow = ({
  payment,
}: {
  payment: StudentRecurrentReport;
}) => (
  <>
    <TableCell size="small">{payment.attachment}</TableCell>
    <TableCell size="small">
      {payment.recurrentPayment.description} -{' '}
      {payment.recurrentPaymentChild.description}
    </TableCell>
    <TableCell size="small">
      {payment.student.first_name} {payment.student.father_name} (
      {payment.grade?.name})
    </TableCell>
    <TableCell size="small">{payment.amount}</TableCell>
    <TableCell size="small">{payment.penalty}</TableCell>
    <TableCell size="small">{payment.total}</TableCell>
    <TableCell size="small">{payment.fs}</TableCell>
    <TableCell size="small">
      {payment.user.first_name} {payment.user.father_name}
    </TableCell>
    <TableCell size="small">
      {moment(payment.created_at).format('MMM D YYYY')}
    </TableCell>
  </>
);

const EmptyRow = ({ attachment }: { attachment: number }) => (
  <>
    <TableCell colSpan={9} size="small">
      <Typography color="red" variant="body2">
        {attachment}
      </Typography>
    </TableCell>
  </>
);
