/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { User } from '../../../user/user';

const useFetchNonHrts = () => {
  const {
    fetchData,
    data: users,
    loading: usersLoading,
  } = useFetchApiData<User>();

  useEffect(() => {
    fetchData(`academic/home-room/hrts/non-hrts`);
  }, []);

  return { users, usersLoading };
};

export default useFetchNonHrts;
