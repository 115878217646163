import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import { useState } from 'react';
import { RecurrentChildCreate, RecurrentCreate } from '../../recurrent';

type RecurrentChildProps = {
  formik: {
    errors: FormikErrors<RecurrentCreate>;
    touched: FormikTouched<RecurrentCreate>;
  };
  index: number;
  childInit: RecurrentChildCreate;
  onChange: (index: number, item: RecurrentChildCreate) => void;
};

const CreateRecurrentChildForm = ({
  formik,
  index,
  childInit,
  onChange,
}: RecurrentChildProps) => {
  const [payment, setPayment] = useState<RecurrentChildCreate>(childInit);
  const hasError = (field: string) =>
    !!formik.errors.payments && !!formik.errors.payments[index];

  const getError = (field: string) => {
    if (formik.errors.payments && formik.errors.payments![index]) {
      return (formik.errors.payments![index] as any)[field];
    }

    return '';
  };

  const handleChange = (field: keyof RecurrentChildCreate, value: any) => {
    setPayment((prevState) => {
      // console.log('update');
      const newState = { ...prevState, [field]: value };
      onChange(index, newState);

      return newState;
    });
  };

  return (
    <>
      <Paper sx={{ p: 4, mb: 2, maxWidth: 600, position: 'relative' }}>
        <Grid
          sx={{
            position: 'absolute',
            padding: '2px 26px',
            bgcolor: '#1565C0',
            borderBottomLeftRadius: '15px',
            top: '0',
            right: '0',
            color: 'white',
          }}
        >
          <Typography textAlign="center">{index + 1}</Typography>
        </Grid>
        <Typography variant="h5" textAlign="center" sx={{ mx: 'auto', mb: 4 }}>
          Recurrent Sub-Payment - {index + 1}
        </Typography>

        <Grid container sx={{ mt: 1 }} spacing={1}>
          <Grid item flexGrow={1}>
            <TextField
              fullWidth
              size="small"
              type="date"
              variant="outlined"
              label="Start Date"
              placeholder=""
              value={payment.start_date}
              onChange={(e) => handleChange('start_date', e.target.value)}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <TextField
              fullWidth
              size="small"
              type="date"
              variant="outlined"
              label="End Date"
              placeholder=""
              value={payment.end_date}
              onChange={(e) => handleChange('end_date', e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item flexGrow={1}>
            <TextField
              fullWidth
              name={`payments[${index}].amount`}
              size="small"
              type="number"
              variant="outlined"
              label="Amount"
              placeholder=""
              value={payment.amount}
              onChange={(e) => handleChange('amount', e.target.value)}
            />
          </Grid>
          <Grid item flexGrow={1}>
            <TextField
              fullWidth
              name={`payments[${index}].description`}
              size="small"
              required
              type="text"
              variant="outlined"
              label="Description"
              placeholder=""
              value={payment.description}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography variant="subtitle2">{payment.order}</Typography>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleChange('description', e.target.value)}
              error={hasError('description')}
              helperText={getError('description')}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item>
            <FormControl error>
              <FormControlLabel
                control={
                  <Checkbox
                    name={`payments[${index}].has_penalty`}
                    checked={payment.has_penalty}
                    onChange={(e) =>
                      handleChange('has_penalty', e.target.checked)
                    }
                  />
                }
                label="Has penalty"
              />
            </FormControl>
          </Grid>
        </Grid>

        {payment.has_penalty && (
          <Grid
            container
            sx={{ mt: 1, pt: 2, borderTop: '1px solid lightgrey' }}
          >
            <Grid container>
              <Grid item display="flex" flexDirection="column">
                <TextField
                  fullWidth
                  name={`payments[${index}].no_penalty_days`}
                  size="small"
                  type="number"
                  variant="outlined"
                  label="Apply penalty after"
                  value={payment.no_penalty_days}
                  onChange={(e) =>
                    handleChange('no_penalty_days', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography variant="subtitle2">Days</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography fontSize={12} color="green" sx={{ mt: '2px' }}>
                  *Penalty will apply {payment.no_penalty_days} days after
                  effective date
                </Typography>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }} spacing={1} display="flex">
              <Grid item flexGrow={1}>
                <FormControl fullWidth sx={{ maxWidth: '270px' }}>
                  <InputLabel>Penalty Type</InputLabel>
                  <Select
                    value={payment.penalty_type}
                    size="small"
                    label="PenaltyType"
                    name={`payments[${index}].penalty_type`}
                    onChange={(e) =>
                      handleChange('penalty_type', e.target.value)
                    }
                    fullWidth
                  >
                    <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item flexGrow={1}>
                <TextField
                  fullWidth
                  name={`payments[${index}].penalty_amount`}
                  size="small"
                  type="number"
                  variant="outlined"
                  label={
                    payment.penalty_type === 'fixed'
                      ? 'Penalty Fee'
                      : 'Penalty Percentage'
                  }
                  placeholder=""
                  value={payment.penalty_amount}
                  onChange={(e) =>
                    handleChange('penalty_amount', e.target.value)
                  }
                />
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }} spacing={1} display="flex">
              <Grid item flexGrow={1}>
                <FormControl fullWidth sx={{ maxWidth: '270px' }}>
                  <InputLabel>Penalty Frequency</InputLabel>
                  <Select
                    value={payment.penalty_frequency}
                    size="small"
                    label="Penalty Frequency"
                    name={`payments[${index}].penalty_frequency`}
                    onChange={(e) =>
                      handleChange('penalty_frequency', e.target.value)
                    }
                    fullWidth
                  >
                    <MenuItem value="onetime">One Time</MenuItem>
                    <MenuItem value="recurrent">Recurrent</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item flexGrow={1}>
                {payment.penalty_frequency === 'recurrent' ? (
                  <TextField
                    fullWidth
                    name={`payments[${index}].penalty_reapply_days`}
                    size="small"
                    type="number"
                    variant="outlined"
                    label="Re apply penalty every"
                    placeholder=""
                    value={payment.penalty_reapply_days}
                    onChange={(e) =>
                      handleChange('penalty_reapply_days', e.target.value)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="subtitle2">Days</Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Grid></Grid>
                )}
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }} spacing={1}>
              <Grid item flexGrow={1}>
                <TextField
                  fullWidth
                  name={`payments[${index}].max_penalty`}
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Max penalty amount"
                  placeholder=""
                  value={payment.max_penalty}
                  onChange={(e) => handleChange('max_penalty', e.target.value)}
                />
              </Grid>
              <Grid item flexGrow={1}>
                <TextField
                  fullWidth
                  name={`payments[${index}].max_penalty_apply_days`}
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Max penalty days(penalty will stop applying after)"
                  placeholder=""
                  value={payment.max_penalty_apply_days}
                  onChange={(e) =>
                    handleChange('max_penalty_apply_days', e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="subtitle2">Days</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
};

export default CreateRecurrentChildForm;
