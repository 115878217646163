/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from '@mui/icons-material';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Layout from '../../../../../core/ui/layout/Layout';
import ShowIfHas from '../../../../../core/ui/permission/ShowIfHas';
import SummaryBlock from '../../../../../core/ui/table/components/SummaryBlock';
import { hasPermission } from '../../../../../core/utils/data';
import { toastError } from '../../../../../core/utils/ui/alert';
import AuthContext from '../../../../auth/service/authContext';
import { EvaluationType } from '../evaluationType';

const EvaluationTypeList = () => {
  const [data, setData] = useState<EvaluationType[]>([]);
  const { user } = useContext(AuthContext);

  const { fetchData } = useFetchApiData();

  useEffect(() => {
    fetchData(`academic/marklist/evaluation-types`, {
      onSuccess: ({ data: apiData }) => {
        setData(apiData);
      },
      onError: toastError,
    });
  }, []);

  return (
    <Layout>
      <>
        <Box sx={{ p: 2 }}>
          <SummaryBlock
            buttonLabel="New Evaluation Type"
            modelLabel="Evaluation Types"
            itemCount={data.length}
            addRoute="/academic/evaluation-type/create"
            showCreate={hasPermission(user!.permissions, [
              'add-evaluation-type',
            ])}
          />

          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 500, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Evaluation Type</TableCell>
                  <TableCell>Weight</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((evaluationType) => (
                  <TableRow key={evaluationType.id}>
                    <TableCell size="small">{evaluationType.name}</TableCell>
                    <TableCell size="small">{evaluationType.weight}</TableCell>
                    <TableCell component="th" scope="row">
                      {DateTime.fromISO(
                        evaluationType.created_at
                      ).toLocaleString(DateTime.DATE_MED)}
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <ShowIfHas permissions={['edit-evaluation-type']}>
                        <Link
                          to={`/academic/evaluation-type/edit/${evaluationType.id}`}
                        >
                          <Edit
                            fontSize="small"
                            sx={{ mr: 1, fontSize: '15px' }}
                          />
                        </Link>
                      </ShowIfHas>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Layout>
  );
};

export default EvaluationTypeList;
