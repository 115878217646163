import { logError } from '../utils/logger';
import { toastError, toastMessage } from '../utils/ui/alert';
import { useSendApiData } from './useSendApiData';

type HandleDeleteArgs = {
  endpoint: string;
  onSuccess?: () => void | Promise<void>;
  successMsg?: string;
  errorMsg?: string;
  showMessage?: boolean;
};

type HandleMultiDeleteArgs = {
  checklist: Record<string, boolean | undefined>;
  resetChecklist: () => void;
  baseEndpoint: string;
  onSuccess?: () => void | Promise<void>;
};

export const useDeleteData = () => {
  const { callApi } = useSendApiData();

  const handleDelete = async ({
    endpoint,
    onSuccess,
    successMsg = 'Item removed',
    errorMsg = 'Something went wrong while deleting. Try again',
    showMessage = true,
  }: HandleDeleteArgs) => {
    let success = false;

    await callApi({
      endpoint: endpoint,
      data: {},
      method: 'delete',
      onSuccess: async () => {
        if (showMessage) toastMessage(successMsg);
        success = true;
      },
      onError: () => {
        if (showMessage) toastError(errorMsg);
      },
    });

    if (success && onSuccess) await onSuccess();

    return success;
  };

  const handleMultiDelete = async ({
    checklist,
    resetChecklist,
    baseEndpoint,
    onSuccess,
  }: HandleMultiDeleteArgs) => {
    let success = false;

    try {
      const promises: any[] = [];

      Object.keys(checklist).forEach((key) => {
        promises.push(
          handleDelete({
            endpoint: `${baseEndpoint}/${key}`,
          }).then((deleted) => {
            success = success || deleted;
          })
        );
      });

      await Promise.all(promises);

      resetChecklist();

      toastMessage('Items removed');
    } catch (_err) {
      logError('delete err', _err);
      toastError('Something wen wrong try again');
    }

    if (success && onSuccess) await onSuccess();
  };

  return { handleDelete, handleMultiDelete };
};
