/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from '@mui/icons-material';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Button,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../core/service/globalContext';
import Layout from '../../../../core/ui/layout/Layout';
import ShowIfHas from '../../../../core/ui/permission/ShowIfHas';
import SummaryBlock from '../../../../core/ui/table/components/SummaryBlock';
import { hasPermission } from '../../../../core/utils/data';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import AuthContext from '../../../auth/service/authContext';
import { AcademicYear } from '../academicYear';

const EvaluationTypeList = () => {
  const [data, setData] = useState<AcademicYear[]>([]);
  const { user } = useContext(AuthContext);
  const { fetchGlobalData } = useContext(GlobalContext);

  const { fetchData } = useFetchApiData();
  const { callApi, loading } = useSendApiData();

  const fetchYears = () =>
    fetchData(`academic/academic-years`, {
      onSuccess: ({ data: apiData }) => {
        setData(apiData);
      },
    });

  useEffect(() => {
    fetchYears();
  }, []);

  const setYearActive = async (yearId: string) => {
    let success = false;

    await callApi({
      endpoint: `academic/academic-years/set-active/${yearId}`,
      data: {},
      onSuccess: () => {
        toastMessage('Active year changed');
        success = true;
      },
      onError: toastError,
    });

    if (success) fetchGlobalData();
  };

  return (
    <Layout>
      <>
        <Box sx={{ p: 2 }}>
          <SummaryBlock
            buttonLabel="New Academic Year"
            modelLabel="Academic Years"
            itemCount={data.length}
            addRoute="/academic/academic-year/create"
            showCreate={hasPermission(user!.permissions, ['add-academic-year'])}
          />

          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 350, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell align="right" sx={{ width: 180 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((year) => (
                  <TableRow key={year.id}>
                    <TableCell size="small">{year.year}</TableCell>
                    <TableCell size="small">
                      {year.active ? 'Active' : ''}
                    </TableCell>
                    <TableCell align="right" sx={{ width: 180 }}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {year.active &&
                        hasPermission(user!.permissions, [
                          'edit-academic-year',
                        ]) ? (
                          ''
                        ) : (
                          <Button
                            disabled={loading}
                            onClick={() => setYearActive(year.id)}
                            variant="outlined"
                            size="small"
                            sx={{ mr: 2 }}
                          >
                            Set Active
                          </Button>
                        )}
                        <ShowIfHas permissions={['edit-academic-year']}>
                          <Link to={`/academic/academic-year/edit/${year.id}`}>
                            <Edit
                              fontSize="small"
                              sx={{ mr: 1, fontSize: '15px' }}
                            />
                          </Link>
                        </ShowIfHas>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Layout>
  );
};

export default EvaluationTypeList;
