import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { Grade } from '../../../academic/grade/grade';
import GlobalContext from '../../../../core/service/globalContext';

type RegisterFilterProps = {
  onClear: () => void;
  onApply: (filter: any) => void;
};

const RegisterFilter = ({ onClear, onApply }: RegisterFilterProps) => {
  const { grades } = useContext(GlobalContext);
  const gradeMap: Record<string, Grade> = {};
  grades.forEach((curGrade) => (gradeMap[curGrade.id] = curGrade));

  const [grade, setGrade] = useState<Grade | null>(null);

  const handleSearch = () => {
    const body: Record<string, any> = {
      whereHas: {},
    };
    if (grade) {
      body.whereHas = {
        student: {
          gradeStudents: {
            filters: [
              {
                field: 'grade_id',
                op: '=',
                value: grade.id,
              },
            ],
          },
        },
      };
    }

    // console.log(body);
    onApply(body);
  };

  const handleClear = () => {
    setGrade(null);
    onClear();
  };

  return (
    <>
      <Box
        sx={{
          py: 1,
          px: 1,
          pb: 3,
          mt: 1,
          border: '1px solid',
          borderColor: 'grey.300',
          borderRadius: '5px',
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="body1">Registration - Filters</Typography>
          </Grid>
          <Grid item display="flex">
            <Button
              variant="outlined"
              sx={{
                mr: 1,
                height: '25px',
                fontSize: '13px',
                textTransform: 'none',
                borderRadius: '3px',
              }}
              color="primary"
              onClick={handleClear}
            >
              Clear all
            </Button>
            <Button
              variant="outlined"
              sx={{
                mr: 1,
                height: '25px',
                fontSize: '13px',
                textTransform: 'none',
                borderRadius: '3px',
                px: 5,
              }}
              color="primary"
              onClick={handleSearch}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ mt: 1 }}>
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
            sx={{ mt: 2 }}
          >
            <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
              <FormControl fullWidth sx={{ maxWidth: 300 }}>
                <InputLabel>Grade</InputLabel>
                <Select
                  value={grade?.id || ''}
                  size="small"
                  label="Grade"
                  onChange={(e) => {
                    const curGrade = gradeMap[
                      e.target.value! as string
                    ] as Grade;
                    setGrade(curGrade);
                  }}
                  fullWidth
                >
                  {Object.keys(gradeMap).map((item) => (
                    <MenuItem dense key={item} value={item}>
                      {gradeMap[item].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default RegisterFilter;
