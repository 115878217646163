import { Box } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { modalStyle } from '../../../../core/ui/utility/ModalContainer';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { dataParserFactory } from '../../../../core/utils/utility';
import { parseValidationErrors } from '../../../../core/utils/validation';
import AddStudentFixedPaymentForm from '../components/forms/AddStudentFixedPaymentForm';
import { FixedPending, StudentFixedPaymentCreate } from '../fixedPayment';

const AddStudentFixedPaymentContainer = ({
  onSuccess,
  onCancel,
  fixedPayment,
  fixedPaymentId,
  fixedPaymentPending,
}: {
  onSuccess: (id: string) => void;
  onCancel: () => void;
  fixedPayment: string;
  fixedPaymentId: string;
  fixedPaymentPending: FixedPending;
}) => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: StudentFixedPaymentCreate,
    { setFieldError }: FormikHelpers<StudentFixedPaymentCreate>
  ) => {
    let success = false;

    const parsedData = dataParserFactory({ ...values })
      .clearEmptyFields()
      .parseDate(['slip_date']).data;

    await callApi({
      endpoint: 'payment-latest/fixed/student-payment',
      data: parsedData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (data) => {
        toastMessage('Fixed Payment Created');
        success = true;
      },
    });

    if (success) {
      onSuccess(fixedPaymentPending.id);
    }

    return success;
  };

  return (
    <Box
      sx={{
        ...modalStyle,
        p: 2,
        bgcolor: 'white',
        minWidth: 500,
        overflow: 'auto',
      }}
    >
      <AddStudentFixedPaymentForm
        onSubmit={handleSubmit}
        submitting={submitting}
        fixedPayment={fixedPayment}
        fixedPaymentId={fixedPaymentId}
        fixedPaymentPending={fixedPaymentPending}
        onCancel={onCancel}
      />
    </Box>
  );
};

export default AddStudentFixedPaymentContainer;
