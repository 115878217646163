import { useState } from 'react';
import axios, { AxiosError } from 'axios';
import constants from '../utils/constants';
import { ErrorHandler, PaginationMeta, SuccessHandler } from '../utils/types';
import authService from '../../features/auth/service/authService';
import { logError } from '../utils/logger';
import useTablePagination from '../ui/table/hooks/useTablePagination';

type CallApiArg = {
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
};

const useGetPaginated = <T = any>(modelToken: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T[]>([]);
  const pagination = useTablePagination(modelToken);

  const fetchData = async (
    endpointUrl: string,
    query: string = '',
    { onSuccess, onError }: CallApiArg = {}
  ) => {
    const token = `Bearer ${authService.getToken()}`;

    setLoading(true);
    try {
      const res = await axios.get(
        `${constants.apiUrl}/${endpointUrl}?page=${
          pagination.data.page + 1
        }&pageSize=${pagination.data.rowsPerPage}${query ? `&${query}` : ''}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: token,
          },
        }
      );

      const resData = res.data as { meta: PaginationMeta; data: T[] };

      setData(resData.data);
      pagination.handlers.setTotal(resData.meta.total);
      if (onSuccess) onSuccess(res.data, res.status);
    } catch (e) {
      const apiErr = e as AxiosError;
      logError('Api Error', apiErr);
      logError('Res Data', apiErr?.response?.data);

      if (onError) {
        onError(
          apiErr.response?.data?.message ||
            apiErr.message ||
            'Unknown Error occurred'
        );
      }
    }
    setLoading(false);
  };

  return { fetchData, loading, data, setData, pagination };
};

export default useGetPaginated;
