// import { useRef } from 'react';
// import { useReactToPrint } from 'react-to-print';
// import Layout from '../../../core/ui/layout/Layout';
// import constants from '../../../core/utils/constants';

// const IdCardPage = () => {
//   const componentRef = useRef<HTMLDivElement | null>(null);

//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current!,
//     documentTitle: 'Id card',
//     onAfterPrint: () => console.log('Print success'),
//   });

//   const UnderLineItem = ({
//     label,
//     value,
//     borderColor = 'black',
//   }: {
//     label: string;
//     value: string;
//     borderColor?: string;
//   }) => (
//     <div
//       style={{
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'flex-end',
//       }}
//     >
//       <div style={{ marginRight: '5px' }}>{label}:</div>
//       <div
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'flex-end',
//           flex: 1,
//         }}
//       >
//         <div
//           style={{
//             width: '100%',
//             borderBottom: `1px solid ${borderColor}`,
//           }}
//         ></div>
//         <div style={{ height: '5px' }}></div>
//       </div>
//     </div>
//   );

//   const grad1 =
//     'linear-gradient(0deg, rgba(195,92,34,1) 0%, rgba(242,255,0,1) 100%)';
//   const back1 = '#873020';

//   const IdComponent = () => (
//     <div
//       style={{
//         width: '50%',
//         height: '100%',
//         border: '1px solid black',
//         margin: '5px',
//         padding: '5px',
//         display: 'flex',
//         flexDirection: 'column',
//         backgroundColor: 'white',
//       }}
//     >
//       <div
//         style={{
//           height: '23%',
//           width: '100%',
//           display: 'flex',
//           flexDirection: 'row',
//         }}
//       >
//         <div
//           style={{
//             width: '20%',
//             height: '100%',
//             display: 'flex',
//             margin: '0 5px',
//             justifyContent: 'flex-end',
//           }}
//         >
//           <img
//             style={{ height: '100%' }}
//             src={`${constants.baseurl}/img?img_path=/imgs/logo-blue.jpg`}
//             alt="logo"
//           />
//         </div>

//         <div
//           style={{
//             width: '80%',
//             height: '100%',
//             border: '2px solid rgba(61,100,245,1)',
//             padding: '10px',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'space-around',
//             background:
//               'linear-gradient(0deg, rgba(61,100,245,1) 0%, rgba(0,255,221,1) 93%)',
//           }}
//         >
//           <div
//             style={{
//               width: '100%',
//               margin: '0 auto',
//               textAlign: 'center',
//               fontSize: '16px',
//               fontWeight: 'bold',
//               textTransform: 'uppercase',
//             }}
//           >
//             Strivers Academy
//           </div>

//           <div style={{ display: 'flex' }}>
//             <div style={{ flex: 1, fontWeight: '600', fontSize: '12px' }}>
//               Tel: 011-8829390
//             </div>
//             <div style={{ flex: 1, fontWeight: '600', fontSize: '12px' }}>
//               Mob: 0911685076
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         style={{
//           height: '58%',
//           width: '100%',
//           border: '2px solid rgba(61,100,245,1)',
//           padding: '5px',
//           margin: '5px 0',
//           display: 'flex',
//           flexDirection: 'row',
//           justifyContent: 'space-between',
//           background:
//             'linear-gradient(0deg, rgba(61,100,245,1) 0%, rgba(0,255,221,1) 93%)',
//         }}
//       >
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'space-between',
//             width: '65%',
//           }}
//         >
//           <UnderLineItem label="Name" value="" />
//           <div style={{ display: 'flex' }}>
//             <div style={{ width: '100%' }}>
//               <UnderLineItem label="Age" value="" />
//             </div>
//             <div style={{ width: '100%', marginLeft: '5px' }}>
//               <UnderLineItem label="Sex" value="" />
//             </div>
//           </div>
//           <UnderLineItem label="Grade" value="" />
//           <UnderLineItem label="Date of Issue" value="" />
//           <UnderLineItem label="Date of Expiry" value="" />
//           <UnderLineItem label="Phone" value="" />
//         </div>
//         <div
//           style={{
//             width: '35%',
//             border: '3px solid black',
//             height: '100%',
//             marginLeft: '25px',
//             background: 'white',
//           }}
//         ></div>
//       </div>

//       <div
//         style={{
//           height: '15%',
//           width: '100%',
//           border: '1px solid grey',
//           padding: '5px 10px',
//           background: 'rgba(61,100,245,1)',
//         }}
//       >
//         <div style={{ width: '80%', color: 'white' }}>
//           <UnderLineItem label="Student ID: " value="" borderColor="white" />
//         </div>
//       </div>
//     </div>
//   );

//   return (
//     <>
//       <Layout>
//         <>
//           <button onClick={handlePrint}>Print this out</button>
//           <div ref={componentRef}>
//             <div
//               style={{
//                 width: '100%',
//                 height: '100vh',
//                 padding: '20px 30px',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//               }}
//             >
//               {Array(4)
//                 .fill(null)
//                 .map((_, i) => (
//                   <div
//                     style={{
//                       width: '100%',
//                       height: '25%',
//                       margin: '5px auto',
//                       display: 'flex',
//                     }}
//                     key={i}
//                   >
//                     <IdComponent />
//                     <IdComponent />
//                   </div>
//                 ))}
//             </div>
//             <div
//               style={{
//                 width: '100%',
//                 height: '100vh',
//                 padding: '20px 30px',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//               }}
//             >
//               {Array(4)
//                 .fill(null)
//                 .map((_, i) => (
//                   <div
//                     style={{
//                       width: '100%',
//                       height: '25%',
//                       margin: '5px auto',
//                       display: 'flex',
//                     }}
//                     key={i}
//                   >
//                     <IdComponent />
//                     <IdComponent />
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </>
//       </Layout>
//     </>
//   );
// };

// export default IdCardPage;

import { Box, Paper } from '@mui/material';
import Layout from '../../../core/ui/layout/Layout';
import StudentGradeSelector from './components/StudentGradeSelector';

const IdCardPage = () => {
  return (
    <>
      <Layout>
        <>
          <Box sx={{ p: 2, maxWidth: 900 }}>
            <Paper
              sx={{ p: 2, mb: 2, display: 'flex', flexDirection: 'column' }}
            >
              <StudentGradeSelector />
            </Paper>
          </Box>
        </>
      </Layout>
    </>
  );
};

export default IdCardPage;
