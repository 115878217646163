import { useContext } from 'react';
import { Navigate } from 'react-router';
import NotFoundPage from '../../../core/ui/pages/NotFoundPage';
import Loading from '../../../core/ui/utility/Loading';
import { permissionChecker } from '../../../core/utils/data';
import { ReactChildren } from '../../../core/utils/types';
import AuthContext from '../service/authContext';

type PrivateRouteProps = {
  permissions?: string[];
  mode?: 'any' | 'all';
} & ReactChildren;

const PrivateRoute = ({
  children,
  permissions,
  mode = 'all',
}: PrivateRouteProps) => {
  const { user, authLoading } = useContext(AuthContext);

  if (authLoading) return <Loading />;

  if (!user) return <Navigate to="/login" />;

  if (permissions === undefined || !permissions.length) return <>{children}</>;

  return (
    <>
      {permissionChecker[mode](user!.permissions, permissions) ? (
        children
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default PrivateRoute;
