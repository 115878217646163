import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { StudentCreate } from '../student';
import CreateStudentForm from '../components/CreateStudentForm';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../../core/utils/utility';

const CreateStudentContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: StudentCreate,
    { setFieldError }: FormikHelpers<StudentCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: 'academic/students',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Student Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/student" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Student</Typography>
        </Grid>
        <CreateStudentForm onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
    </Layout>
  );
};

export default CreateStudentContainer;
