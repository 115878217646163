/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../../../core/hooks/useFetchApiData';
// import { ReportRoster } from '../../models/report';
import Loader from '../../../../../../core/ui/utility/Loader';
import GlobalContext from '../../../../../../core/service/globalContext';
import { Grade } from '../../../../grade/grade';
import { Cst } from '../../../cst/cst';
import GradeStat from './GradeStat';
import { ReportPeriod, ReportRoster } from '../../models/report';

type ReportGrade = Grade & {
  csts: Cst[];
};

const RosterReport = () => {
  const { quarters, semesters, activeYear, years } = useContext(GlobalContext);
  const periodValues: Record<string, ReportPeriod> = {};

  quarters.forEach((quarter) => {
    periodValues[quarter.id] = {
      id: quarter.id,
      label: `Quarter ${quarter.quarter}`,
      type: 'quarter',
    };
  });
  semesters.forEach((semester) => {
    periodValues[semester.id] = {
      id: semester.id,
      label: `Semester ${semester.semester}`,
      type: 'semester',
    };
  });
  periodValues[activeYear!.id] = {
    id: activeYear!.id,
    label: 'Year',
    type: 'year',
  };

  const { fetchData, loading: gradesLoading } = useFetchApiData();
  const { fetchData: fetchReport, loading: reportLoading } = useFetchApiData();

  const [grades, setGrades] = useState<ReportGrade[]>([]);
  const [report, setReport] = useState<ReportRoster | null>(null);
  const [subjects, setSubjects] = useState<{ id: string; subject: string }[]>(
    []
  );

  const [year, setYear] = useState('');
  const [grade, setGrade] = useState('');
  const [subject, setSubject] = useState('');
  const [period, setPeriod] = useState<string>('');
  const [minRange, setMinRange] = useState(0);
  const [maxRange, setMaxRange] = useState(100);

  const [selectedPeriod, setSelectedPeriod] = useState<ReportPeriod | null>(
    null
  );
  const [selectedGrade, setSelectedGrade] = useState<string | null>(null);
  const [selectedSubjects, setSelectedSubjects] = useState<
    { id: string; subject: string }[] | null
  >(null);

  useEffect(() => {
    if (year) {
      fetchData(`academic/marklist/report-card/fetch-report-grades/${year}`, {
        onSuccess: (data) => {
          setGrades(data.grades);
        },
      });
    }
  }, [year]);

  useEffect(() => {
    if (grade) {
      const subjects = grades
        .find((i) => i.id === grade)!
        .csts.map(({ subject }) => ({
          id: subject.id,
          subject: subject.subject,
        }));

      setSubject('');
      setSubjects(subjects);
      setSelectedSubjects(null);

      fetchReport(
        `academic/marklist/report-card/fetch-report/${grade}/${year}`,
        {
          onSuccess: (data) => {
            setReport(data.report);
          },
        }
      );
    } else {
      setReport(null);
    }
  }, [year, grade]);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Loader loading={gradesLoading}>
          <>
            <Grid display="flex" flexDirection="row" sx={{ mb: 4 }}>
              <Grid
                sx={{
                  p: 2,
                  bgcolor: 'white',
                  borderLeft: '3px solid',
                  borderLeftColor: 'primary.main',
                  filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                  flexShrink: 1,
                }}
              >
                <Typography sx={{ mb: 2 }}>Filters</Typography>
                <Grid display="flex" flexDirection="row">
                  <FormControl sx={{ mr: 2 }}>
                    <InputLabel>Year</InputLabel>
                    <Select
                      value={year}
                      onChange={(e: SelectChangeEvent<string>) => {
                        setYear(e.target.value);
                      }}
                      label="Year"
                      placeholder="Select Year"
                      sx={{ minWidth: 200 }}
                      size="small"
                    >
                      <MenuItem value=""></MenuItem>
                      {years.map((year) => (
                        <MenuItem key={`year-${year.id}`} value={year.id}>
                          {year.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ mr: 2 }}>
                    <InputLabel>Grade</InputLabel>
                    <Select
                      value={grade}
                      onChange={(e) => {
                        if (e.target.value) {
                          setGrade(e.target.value);
                          const curGrade = grades.find(
                            (i) => i.id === e.target.value
                          )!;
                          setSelectedGrade(curGrade.name);
                        } else {
                          setGrade('');
                          setSelectedGrade(null);
                        }
                      }}
                      label="Grade"
                      placeholder="Select Grade"
                      sx={{ minWidth: 200 }}
                      size="small"
                    >
                      <MenuItem value=""></MenuItem>
                      {grades.map((grade) => (
                        <MenuItem key={`grade-${grade.id}`} value={grade.id}>
                          {grade.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ mr: 2 }}>
                    <InputLabel>Subject</InputLabel>
                    <Select
                      value={subject}
                      onChange={(e) => {
                        if (e.target.value) {
                          setSubject(e.target.value);
                          if (e.target.value === 'all') {
                            setSelectedSubjects(subjects);
                          } else {
                            setSelectedSubjects(
                              subjects.filter((i) => i.id === e.target.value)
                            );
                          }
                        } else {
                          setSubject('');
                          setSelectedSubjects(null);
                        }
                      }}
                      label="Subject"
                      placeholder="Select Subject"
                      sx={{ minWidth: 200 }}
                      size="small"
                    >
                      <MenuItem value=""></MenuItem>
                      {subjects
                        .sort((a, b) => a.subject.localeCompare(b.subject))
                        .map((subject) => (
                          <MenuItem
                            key={`subject-${subject.id}`}
                            value={subject.id}
                          >
                            {subject.subject}
                          </MenuItem>
                        ))}
                      <MenuItem value="all">All</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{ mr: 2 }}>
                    <InputLabel>Period</InputLabel>
                    <Select
                      value={period || ''}
                      onChange={(e) => {
                        if (e.target.value) {
                          setPeriod(e.target.value);
                          setSelectedPeriod(periodValues[e.target.value]);
                        } else {
                          setPeriod('');
                          setSelectedPeriod(null);
                        }
                      }}
                      label="Period"
                      placeholder="Select Period"
                      sx={{ minWidth: 200 }}
                      size="small"
                    >
                      <MenuItem value=""></MenuItem>
                      {Object.values(periodValues).map((period) => (
                        <MenuItem key={`period-${period.id}`} value={period.id}>
                          {period.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Grid
                    display="flex"
                    flexDirection={'row'}
                    alignItems="center"
                  >
                    <Typography sx={{ mr: 2 }}>Range</Typography>

                    <TextField
                      type="number"
                      size="small"
                      label="From"
                      value={minRange}
                      onChange={(e) =>
                        setMinRange(
                          e.target.value ? parseFloat(e.target.value) : 0
                        )
                      }
                      sx={{ width: 80, mr: 2 }}
                    />
                    <TextField
                      type="number"
                      size="small"
                      label="To"
                      value={maxRange}
                      onChange={(e) =>
                        setMaxRange(
                          e.target.value ? parseFloat(e.target.value) : 0
                        )
                      }
                      sx={{ width: 80 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <pre>
                {JSON.stringify(
                  {
                    selectedGrade,
                    selectedSubjects,
                    selectedPeriod,
                    range: { min: minRange, max: maxRange },
                  },
                  null,
                  2
                )}
              </pre> */}

            <Loader loading={reportLoading}>
              {report !== null &&
                !_.isEmpty(selectedGrade) &&
                !_.isEmpty(selectedPeriod) &&
                !_.isNull(selectedSubjects) && (
                  <GradeStat
                    gradeReport={report!}
                    grade={selectedGrade!}
                    subjects={selectedSubjects!}
                    period={selectedPeriod!}
                    range={{
                      min: minRange,
                      max: maxRange,
                    }}
                    showReportMeta={subject === 'all'}
                  />
                )}
            </Loader>
          </>
        </Loader>
      </Box>
    </>
  );
};

export default RosterReport;
