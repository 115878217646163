/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { toastError } from '../../../../core/utils/ui/alert';
import { FixedPayment } from '../../fixed/fixedPayment';
import { Recurrent, RecurrentChild } from '../../recurrent/recurrent';
import { ReportType } from '../report';

type FixedSubType = { fixed: FixedPayment } & ReportType;

type RecurrentChildSubType = {
  payment: RecurrentChild;
} & ReportType;

type RecurrentSubType = {
  recurrent: Recurrent;
  recurrentChildren: RecurrentChildSubType[];
} & ReportType;

type ReportResponse = {
  recurrent: ReportType & {
    payments: RecurrentSubType[];
  };
  fixed: ReportType & {
    payments: FixedSubType[];
  };
};

const usePaymentReportController = () => {
  const [mode, setMode] = useState<'today' | 'range' | ''>('');
  const [report, setReport] = useState<ReportResponse | null>(null);

  const { fetchData, loading } = useFetchApiData();

  useEffect(() => {
    setReport(null);
    if (mode !== '' && mode === 'today') {
      const date = new Date().toISOString().substring(0, 10);
      getReport(date, date);
    }
  }, [mode]);

  const getReport = (startDate: string, endDate: string) => {
    fetchData(`payment-latest/reports/${startDate}/${endDate}`, {
      onSuccess: (data) => {
        setReport(data);
      },
      onError: toastError,
    });
  };

  return {
    mode,
    setMode,
    report,
    loading,
    getReport,
    resetReport: () => setReport(null),
  };
};

export default usePaymentReportController;
