import { Box } from '@mui/material';
import Layout from '../../../../core/ui/layout/Layout';
import PaymentReport from '../components/PaymentReport';

const ReportPage = () => {
  return (
    <>
      <Layout>
        <Box sx={{ p: 2, mt: 2 }}>
          <PaymentReport />
        </Box>
      </Layout>
    </>
  );
};

export default ReportPage;
