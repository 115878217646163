import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import { User } from '../user';

const useFetchUser = () => {
  const { id } = useParams();
  const { fetchData, loading } = useFetchApiData();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    fetchData(`users/${id}`, {
      onSuccess: (data: User) => {
        setUser(data);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { user, loading };
};

export default useFetchUser;
