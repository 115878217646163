import { Delete, Refresh } from '@mui/icons-material';
import { Grid, TextField, Tooltip } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useState } from 'react';
import { useDeleteData } from '../../../../../core/hooks/useDeleteData';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import Loader from '../../../../../core/ui/utility/Loader';
import { LoadingFit } from '../../../../../core/ui/utility/Loading';
import { toastError } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';

type MarkInlineProps = {
  gradeStudentId: string;
  evaluationMethodId: string;
  score: number | undefined;
  smlId?: string | undefined;
  updateMark: (s: number) => void;
};

type MarkInput = {
  grade_student_id: string;
  evaluation_method_id: string;
  score: string | undefined;
};

const MarkInlineForm = ({
  gradeStudentId,
  evaluationMethodId,
  score,
  smlId,
  updateMark,
}: MarkInlineProps) => {
  const { callApi, loading } = useSendApiData();
  const [showDelete, setShowDelete] = useState(false);
  const { handleDelete } = useDeleteData();

  const initialValues: MarkInput = {
    grade_student_id: gradeStudentId,
    evaluation_method_id: evaluationMethodId,
    score: score === undefined ? '' : `${score}`,
  };

  const handleSubmit = async (
    value: MarkInput,
    { setFieldError }: FormikHelpers<MarkInput>
  ) => {
    let success = false;

    if (
      value.score !== undefined &&
      value.score !== '' &&
      value.score !== initialValues.score
    ) {
      await callApi({
        endpoint: 'academic/marklist/smls',
        data: {
          ...value,
          finalized: false,
        },
        headers: {
          'Content-Type': 'application/json',
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
        onSuccess: async (_) => {
          success = true;
        },
      });
    }

    if (success) updateMark(parseFloat(value.score as string));
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const renderForm = () => (
    <TextField
      size="small"
      sx={{
        width: '60px',
        input: {
          px: 1,
          textAlign: 'center',
        },
      }}
      name="score"
      placeholder={score === undefined ? 'N/A' : ''}
      onChange={formik.handleChange}
      InputProps={{}}
      onBlur={(e) => {
        // console.log('blur');
        formik.submitForm();
        // setShowDelete(false);
      }}
      value={formik.values.score}
      error={hasError()}
    />
  );
  // console.log(score);

  const hasError = () => {
    return Object.values(formik.errors).filter((i) => i !== '').length > 0;
  };

  const getErrors = () => {
    return Object.entries(formik.errors).map(([key, value], i) => (
      <p key={`err-${i}`}>
        {key} - {value}
      </p>
    ));
  };

  return (
    <>
      <Grid
        display="flex"
        onMouseOver={() => {
          setShowDelete(true);
        }}
        onMouseLeave={() => {
          setShowDelete(false);
        }}
        sx={{ width: '90px' }}
      >
        <Loader loaderComponent={<LoadingFit />} loading={loading}>
          <>
            {hasError() ? (
              <Tooltip title={<>{getErrors()}</>} arrow>
                {renderForm()}
              </Tooltip>
            ) : (
              renderForm()
            )}
            {showDelete && smlId && (
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ ml: 1 }}
              >
                <Delete
                  color="warning"
                  onClick={(e) => {
                    handleDelete({
                      endpoint: `academic/marklist/smls/${smlId}`,
                      onSuccess: () => {
                        formik.setFieldValue('score', '');
                        updateMark('' as any);
                      },
                      showMessage: false,
                    });
                  }}
                />
              </Grid>
            )}

            {hasError() && (
              <Grid display="flex" alignItems="center" justifyContent="center">
                <Refresh />
              </Grid>
            )}
          </>
        </Loader>
      </Grid>
    </>
  );
};

export default MarkInlineForm;
