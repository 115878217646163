import CSTContainer from '../containers/CSTContainer';

const CstPage = () => {
  return (
    <>
      <CSTContainer />
    </>
  );
};

export default CstPage;
