import QuarterReportCardContainer from '../containers/QuarterReportCardContainer';

const QuarterReportCardPage = () => {
  return (
    <>
      <QuarterReportCardContainer />
    </>
  );
};

export default QuarterReportCardPage;
