import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import authService from '../../features/auth/service/authService';
import constants from '../utils/constants';
import { logError } from '../utils/logger';
import { ErrorHandler, SuccessHandler } from '../utils/types';

type CallApiArg = {
  endpoint: string;
  headers?: Record<string, any>;
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
};

const useDeleteApiData = () => {
  const [loading, setLoading] = useState(false);

  const callApi = async ({
    endpoint,
    headers = {},
    onError,
    onSuccess,
  }: CallApiArg) => {
    setLoading(true);
    try {
      const headerList = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${authService.getToken()}`,
        ...headers,
      };

      const response = await axios.delete(`${constants.apiUrl}/${endpoint}`, {
        headers: headerList,
      });

      if (onSuccess) onSuccess(response.data, response.status);
    } catch (err) {
      const apiErr = err as AxiosError;
      // logError('Api Error', apiErr);
      logError('Res Data', apiErr.response?.data);

      if (onError) {
        onError(
          apiErr.response?.data?.message ||
            apiErr.message ||
            'Unknown Error occurred'
        );
      }
    }
    setLoading(false);
  };

  return { callApi, loading };
};

export default useDeleteApiData;
