import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Loader from '../../../../core/ui/utility/Loader';
import { Grade } from '../../grade/grade';
import { Student } from '../../student/student';
import useAttendanceController from '../hooks/useAttendanceController';
import { CreateStudentAttendance } from '../models/attendance';

type StudentAttendanceProps = {
  students: Student[];
  grade: Grade;
};

// TODO: Optimize
const StudentAttendanceForm = ({ students, grade }: StudentAttendanceProps) => {
  const { formik, loading, attendanceLoading, attData, setAttData } =
    useAttendanceController(students, grade.id);

  const getError = (index: number, field: string) => {
    if (formik.errors.data && formik.errors.data?.length) {
      const item = formik.errors.data![index] as any;
      return item === null ? null : item[field];
    }

    return null;
  };

  const handleSubmit = async () => {
    const studentAttendances: CreateStudentAttendance[] = attData.map((i) => ({
      student_id: i.student.id,
      absent_reason: i.absentReason,
      status: i.status,
    }));
    await formik.setFieldValue('data', studentAttendances);
    formik.submitForm();
  };

  return (
    <>
      <Box sx={{}}>
        <Grid>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Attendance for {grade.name}
          </Typography>

          <Grid container>
            <Paper sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item>
                  <TextField
                    fullWidth
                    name="date"
                    size="small"
                    type="date"
                    variant="outlined"
                    label="Date"
                    placeholder=""
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.date)}
                    helperText={formik.errors.date}
                  />
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(formik.errors, null, 2)}</pre> */}

              <Loader loading={attendanceLoading}>
                <Grid container sx={{ mt: 2 }}>
                  <TableContainer
                    component={Paper}
                    sx={{ overflowX: 'auto', maxWidth: 1300, mt: 2 }}
                  >
                    <Table aria-label="custom pagination table" size="small">
                      <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell sx={{ minWidth: 350 }}>Student</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Permission Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {attData.map((data, i) => (
                          <TableRow key={data.student.id}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell size="small">
                              <Grid display="column" flexDirection="column">
                                {data.student.first_name}{' '}
                                {data.student.father_name}
                                {getError(i, 'student_id') && (
                                  <FormHelperText error>
                                    {getError(i, 'student_id')}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </TableCell>
                            <TableCell size="small">
                              <Grid
                                container
                                display="flex"
                                flexDirection="column"
                              >
                                <Select
                                  value={data.status}
                                  size="small"
                                  label=""
                                  onChange={(e) => {
                                    const curData = [...attData];
                                    curData[i].status = e.target.value;
                                    setAttData(curData);
                                  }}
                                  fullWidth
                                >
                                  <MenuItem value="Present">Present</MenuItem>
                                  <MenuItem value="Absent">Absent</MenuItem>
                                  <MenuItem value="Late">Late</MenuItem>
                                  <MenuItem value="Permission">
                                    Permission
                                  </MenuItem>
                                </Select>
                                {getError(i, 'status') && (
                                  <FormHelperText error>
                                    {getError(i, 'status')}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </TableCell>
                            <TableCell size="small">
                              {data.status === 'Permission' && (
                                <Grid
                                  container
                                  display="flex"
                                  flexDirection="column"
                                >
                                  <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    variant="outlined"
                                    label=""
                                    placeholder=""
                                    value={data.absentReason}
                                    onChange={(e) => {
                                      const curData = [...attData];
                                      curData[i].absentReason = e.target.value;
                                      setAttData(curData);
                                    }}
                                    error={Boolean(
                                      getError(i, 'absent_reason')
                                    )}
                                    helperText={getError(i, 'absent_reason')}
                                  />
                                </Grid>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Loader>

              <Grid container sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={loading || attendanceLoading}
                >
                  Submit
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default StudentAttendanceForm;
