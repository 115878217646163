import { Student } from '../../../student/student';
import { Rcq } from '../models/rcq';
import { Rcs } from '../models/rcs';
import { Rcy } from '../models/rcy';
import { StudentForRcq, StudentForRcs, StudentForRcy } from '../reportCard';
import { DEFAULT_DISPLAY_RULE, DisplayRule } from '../types/report';

export const parseStudentForRcq = (
  students: Student[],
  mark: any,
  rcqs: Rcq[]
): StudentForRcq[] => {
  // console.log({ cstMap });

  const studentList: StudentForRcq[] = students.map((student) => ({
    ...student,
    gradeStudentId: student.gradeStudents[0].id,
    cstScore: mark[student.gradeStudents[0].id] || {},
    rcq: rcqs.find(
      (item) => item.grade_student_id === student.gradeStudents[0].id
    ),
  }));

  return studentList;
};

export const parseStudentForRcs = (
  students: Student[],
  mark: any,
  rcss: Rcs[]
): StudentForRcs[] => {
  // console.log({ cstMap });

  const studentList: StudentForRcs[] = students.map((student) => ({
    ...student,
    gradeStudentId: student.gradeStudents[0].id,
    cstScore: mark[student.gradeStudents[0].id] || {},
    rcs: rcss.find(
      (item) => item.grade_student_id === student.gradeStudents[0].id
    ),
  }));

  return studentList;
};

export const parseStudentForRcy = (
  students: Student[],
  mark: any,
  rcys: Rcy[]
): StudentForRcy[] => {
  // console.log({ cstMap });

  const studentList: StudentForRcy[] = students.map((student) => ({
    ...student,
    gradeStudentId: student.gradeStudents[0].id,
    cstScore: mark[student.gradeStudents[0].id] || {},
    rcy: rcys.find(
      (item) => item.grade_student_id === student.gradeStudents[0].id
    ),
  }));

  return studentList;
};

export const getQuarterMark = (
  quarterMarkMap: any,
  gsId: string,
  cstId: string,
  quarterId: string
) => {
  if (
    quarterMarkMap[gsId] &&
    quarterMarkMap[gsId][cstId] &&
    quarterMarkMap[gsId][cstId][quarterId]
  ) {
    return quarterMarkMap[gsId][cstId][quarterId];
  }

  return 'N/A';
};

export const getSemesterMark = (
  semesterMarkMap: any,
  gsId: string,
  cstId: string,
  semesterId: string
) => {
  if (
    semesterMarkMap[gsId] &&
    semesterMarkMap[gsId][cstId] &&
    semesterMarkMap[gsId][cstId][semesterId]
  ) {
    return semesterMarkMap[gsId][cstId][semesterId];
  }

  return 'N/A';
};

export const getRcq = (rcqMap: any, gsId: string, quarterId: string) => {
  if (rcqMap[gsId] && rcqMap[gsId][quarterId]) {
    const { total_score, rank, average } = rcqMap[gsId][quarterId];
    return [total_score || 'N/A', average || 'N/A', rank || 'N/A'];
  }

  return ['N/A', 'N/A', 'N/A'];
};

export const getRcs = (rcsMap: any, gsId: string, semesterId: string) => {
  if (rcsMap[gsId] && rcsMap[gsId][semesterId]) {
    const { total_score, rank, average } = rcsMap[gsId][semesterId];
    return [total_score || 'N/A', average || 'N/A', rank || 'N/A'];
  }

  return ['N/A', 'N/A', 'N/A'];
};

export const printMark = (mark: number | undefined | string | null) => {
  if (mark === undefined || mark === null) return 'N/A';

  if (typeof mark === 'string') return mark;

  return parseInt(`${mark}`) === mark ? mark : (mark as number).toFixed(1);
};

export const parseNonRanked = (mark: number, displayRules: DisplayRule) => {
  // console.log({ mark });
  if (mark >= parseFloat(`${displayRules.A}`)) {
    return 'A';
  } else if (mark >= parseFloat(`${displayRules.B}`)) {
    return 'B';
  } else if (mark >= parseFloat(`${displayRules.C}`)) {
    return 'C';
  } else if (mark >= parseFloat(`${displayRules.D}`)) {
    return 'D';
  } else if (mark > 0) {
    return 'D';
  }
};

export const formatMark = (
  mark: number | undefined,
  displayMode: string,
  display_rules: DisplayRule = DEFAULT_DISPLAY_RULE
) => {
  // console.log(subject, subjectRankMap[subject]);
  if (mark === undefined) {
    return '-';
  }

  return displayMode === 'number'
    ? printMark(mark)
    : parseNonRanked(mark, display_rules);
};

export const formatNursery = (mark?: number) => {
  if (mark === undefined) {
    return '-';
  }

  if (mark >= 85) {
    return 'A';
  } else if (mark >= 70) {
    return 'B';
  } else if (mark >= 55) {
    return 'C';
  }

  return 'P';
};
