import ReportCardContainer from '../containers/ReportCardContainer';

const ReportCardPage = () => {
  return (
    <>
      <ReportCardContainer />
    </>
  );
};

export default ReportCardPage;
