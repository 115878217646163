import { Class as ClassIcon, Group, PersonAddAlt } from '@mui/icons-material';
import { Divider, Grid, List, Toolbar } from '@mui/material';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../../../../features/auth/service/authContext';
import SidebarLink from './SidebarLink';

const TeacherSidebar = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              {user!.hrt !== null ? (
                <SidebarLink
                  to="/home-room-attendance"
                  label="Home Room attendance"
                  active={location.pathname === '/home-room-attendance'}
                  icon={<Group />}
                  topLink={true}
                />
              ) : (
                <></>
              )}
            </Grid>

            <Grid item>
              {user!.hrt !== null ? (
                <SidebarLink
                  to="/home-room-students"
                  label="Students"
                  active={location.pathname === '/home-room-students'}
                  icon={<PersonAddAlt />}
                  topLink={true}
                />
              ) : (
                <></>
              )}
            </Grid>

            <Grid item>
              {user!.hrt !== null ? (
                <SidebarLink
                  to={`/academic/marklist/cst/${user!.hrt!.grade.id}`}
                  label="Marklist"
                  active={location.pathname.startsWith(
                    '/academic/marklist/cst'
                  )}
                  icon={<ClassIcon />}
                  topLink={true}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </List>
      </div>
    </>
  );
};

export default TeacherSidebar;
