import { User } from '../user';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';
import useEditUserController from '../hooks/useEditUserController';

type UserProps = {
  user: User;
};

const EditUserForm = ({ user }: UserProps) => {
  const { formik, submitting } = useEditUserController(user);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Paper sx={{ p: 2, pb: 3, maxWidth: '350px' }}>
            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="first_name"
                size="small"
                type="text"
                variant="outlined"
                label="First Name"
                placeholder=""
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.first_name)}
                helperText={formik.errors.first_name}
              />
            </Grid>

            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="father_name"
                size="small"
                type="text"
                variant="outlined"
                label="Father Name"
                placeholder=""
                value={formik.values.father_name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.father_name)}
                helperText={formik.errors.father_name}
              />
            </Grid>
            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="email"
                size="small"
                type="text"
                variant="outlined"
                label="Email"
                placeholder=""
                disabled
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
              />
            </Grid>

            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="password"
                size="small"
                type="text"
                variant="outlined"
                label="Password"
                placeholder=""
                value={formik.values.password}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
              />
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                size="small"
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Paper>
        </form>
      </Box>
    </>
  );
};

export default EditUserForm;
