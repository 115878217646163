import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { FixedPayment } from '../../fixedPayment';

type RestoreProps = {
  payment: FixedPayment | null;
  cancel: () => void;
  onSuccess: () => void;
};

const RestoreFixedDialog = ({ payment, cancel, onSuccess }: RestoreProps) => {
  const { callApi, loading } = useSendApiData();

  const handleSubmit = async () => {
    let success = false;
    await callApi({
      endpoint: `payment-latest/fixed/${payment!.id}`,
      data: {
        archived: false,
      },
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => toastError('Invalid data, try again'),
      onError: toastError,
      onSuccess: async (data) => {
        toastMessage('Fixed Payment restored');
        success = true;
      },
    });

    if (success) {
      onSuccess();
    }
  };

  return (
    <>
      <Dialog
        open={payment !== null}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Do you want to restore payment?'}
        </DialogTitle>
        <DialogActions>
          <Button disabled={loading} onClick={handleSubmit} autoFocus>
            Restore
          </Button>
          <Button disabled={loading} onClick={cancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RestoreFixedDialog;
