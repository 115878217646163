import {
  BadgeOutlined,
  Group,
  Home,
  Person,
  PersonOutline,
} from '@mui/icons-material';
import { Box, Divider, Grid, List, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ShowIfHas from '../../permission/ShowIfHas';
import Dropper from './Dropper';
import SidebarLink from './SidebarLink';

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <Toolbar variant="dense" />
        <Divider />
        <List sx={{ p: 1 }}>
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            sx={{}}
          >
            <Grid item>
              <Typography variant="subtitle1" fontSize="12px" fontWeight="bold">
                COLLECTION TYPES
              </Typography>
              <SidebarLink
                to="/"
                label="Home"
                active={location.pathname === '/'}
                icon={<Home />}
                topLink={true}
              />
              <ShowIfHas mode="all" permissions={['view-user']}>
                <SidebarLink
                  to="/user"
                  label="Users"
                  active={location.pathname.startsWith('/user')}
                  icon={<Person />}
                  topLink={true}
                />
              </ShowIfHas>
              <Dropper
                label="Payment"
                active={location.pathname.startsWith('/payment')}
                // active={true}
              >
                <>
                  <Typography
                    variant="subtitle1"
                    fontSize="12px"
                    fontWeight="bold"
                    sx={{ mt: 1 }}
                  >
                    FIXED
                  </Typography>
                  <Box sx={{ ml: 1 }}>
                    <SidebarLink
                      to="/payment/fixed/create"
                      label="Create Fixed"
                      active={location.pathname.startsWith(
                        '/payment/fixed/create'
                      )}
                    />
                    <SidebarLink
                      to="/payment/fixed/add"
                      label="Add Fixed"
                      active={location.pathname.startsWith(
                        '/payment/fixed/add'
                      )}
                    />
                    <Divider sx={{ my: 1 }} />

                    <Typography
                      variant="subtitle1"
                      fontSize="12px"
                      fontWeight="bold"
                      sx={{ mt: 1 }}
                    >
                      RECURRENT
                    </Typography>
                    <Box sx={{ ml: 1 }}>
                      <SidebarLink
                        to="/payment/recurrent/create"
                        label="Create Recurrent"
                        active={location.pathname.startsWith(
                          '/payment/recurrent/create'
                        )}
                      />
                      <SidebarLink
                        to="/payment/recurrent/add"
                        label="Add Recurrent"
                        active={location.pathname.startsWith(
                          '/payment/recurrent/add'
                        )}
                      />
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    <Typography
                      variant="subtitle1"
                      fontSize="12px"
                      fontWeight="bold"
                      sx={{ mt: 1 }}
                    >
                      VIEW PAYMENTS
                    </Typography>

                    <Box sx={{ ml: 1 }}>
                      <SidebarLink
                        to="/payment/student"
                        label="Student"
                        active={location.pathname.startsWith(
                          '/payment/student'
                        )}
                      />
                      <SidebarLink
                        to="/payment/attachment"
                        label="Attachment"
                        active={location.pathname.startsWith(
                          '/payment/attachment'
                        )}
                      />
                    </Box>
                  </Box>

                  <SidebarLink
                    to="/payment/report"
                    label="Report"
                    active={location.pathname.startsWith('/payment/report')}
                  />
                </>
              </Dropper>
              <Dropper
                label="Academic"
                active={location.pathname.startsWith('/academic')}
              >
                <>
                  <ShowIfHas permissions={['view-academic-year']}>
                    <SidebarLink
                      to="/academic/academic-year"
                      label="Academic Year"
                      active={location.pathname.startsWith(
                        '/academic/academic-year'
                      )}
                    />
                  </ShowIfHas>
                  <Divider sx={{ my: '6px' }} />
                  <ShowIfHas permissions={['view-grade']}>
                    <SidebarLink
                      to="/academic/grade"
                      label="Grade"
                      active={
                        location.pathname.startsWith('/academic/grade') &&
                        !location.pathname.startsWith(
                          '/academic/grade-students'
                        )
                      }
                    />
                  </ShowIfHas>
                  <ShowIfHas permissions={['view-grade']}>
                    <SidebarLink
                      to="/academic/change-grade"
                      label="Change Grade"
                      active={location.pathname.startsWith(
                        '/academic/change-grade'
                      )}
                    />
                  </ShowIfHas>
                  <SidebarLink
                    to="/academic/grade-students"
                    label="Grade Students"
                    active={location.pathname.startsWith(
                      '/academic/grade-students'
                    )}
                  />
                  <Divider sx={{ my: '6px' }} />
                  <SidebarLink
                    to="/academic/student"
                    label="Student"
                    icon={<PersonOutline />}
                    active={location.pathname.startsWith('/academic/student')}
                  />
                  <SidebarLink
                    to="/academic/attendance-student"
                    label="Student attendance"
                    icon={<Group />}
                    active={location.pathname.startsWith(
                      '/academic/attendance-student'
                    )}
                  />
                  <SidebarLink
                    to="/academic/id-card"
                    label="ID Card"
                    active={location.pathname.startsWith('/academic/id-card')}
                    icon={<BadgeOutlined />}
                  />
                  <Divider sx={{ my: '6px' }} />
                  <ShowIfHas mode="all" permissions={['view-teacher']}>
                    <SidebarLink
                      to="/academic/teacher"
                      label="Teacher"
                      active={location.pathname.startsWith('/academic/teacher')}
                      icon={<Person />}
                    />
                  </ShowIfHas>

                  <Typography
                    variant="subtitle1"
                    fontSize="12px"
                    fontWeight="bold"
                    sx={{ mt: 1 }}
                  >
                    MARKLIST
                  </Typography>
                  <Box sx={{ ml: 1 }}>
                    <ShowIfHas permissions={['view-subject']}>
                      <SidebarLink
                        to="/academic/subject"
                        label="Subject"
                        active={location.pathname.startsWith(
                          '/academic/subject'
                        )}
                      />
                    </ShowIfHas>
                    <Divider sx={{ my: 1 }} />
                    <ShowIfHas permissions={['view-cst', 'add-cst']} mode="any">
                      <SidebarLink
                        to="/academic/marklist/cst"
                        label="Class Subject"
                        active={location.pathname.startsWith(
                          '/academic/marklist'
                        )}
                      />
                    </ShowIfHas>

                    <SidebarLink
                      to="/academic/evaluation-type"
                      label="Evaluation Type"
                      active={location.pathname.startsWith(
                        '/academic/evaluation-type'
                      )}
                    />
                    <SidebarLink
                      to="/academic/roster"
                      label="Roster"
                      active={location.pathname.startsWith('/academic/roster')}
                    />
                    <SidebarLink
                      to="/academic/report-roster"
                      label="Report"
                      active={location.pathname.startsWith(
                        '/academic/report-roster'
                      )}
                    />
                    <Divider sx={{ my: 1 }} />
                    <ShowIfHas permissions={['view-quarter']}>
                      <SidebarLink
                        to="/academic/quarter"
                        label="Quarter"
                        active={location.pathname.startsWith(
                          '/academic/quarter'
                        )}
                      />
                    </ShowIfHas>
                    <ShowIfHas permissions={['view-semester']}>
                      <SidebarLink
                        to="/academic/semester"
                        label="Semester"
                        active={location.pathname.startsWith(
                          '/academic/semester'
                        )}
                      />
                    </ShowIfHas>
                  </Box>
                </>
              </Dropper>
            </Grid>

            {/* <Grid item>
              <Typography
                variant="subtitle1"
                fontSize="12px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                SINGLE TYPES
              </Typography>
            </Grid> */}
          </Grid>
        </List>
      </div>
    </>
  );
};

export default Sidebar;
