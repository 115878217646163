import { useState } from 'react';
import { AttachmentPayment } from '../types/attachment-payment.type';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import { toastError } from '../../../../../core/utils/ui/alert';

const useLoadAttachmentPayments = () => {
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [payments, setPayments] = useState<AttachmentPayment[]>([]);

  const { fetchData, loading } = useFetchApiData();

  const getPayments = () => {
    fetchData(`payment-latest/reports/attachment-payments/${start}/${end}`, {
      onSuccess: (data: AttachmentPayment[]) => {
        setPayments(data);
      },
      onError: toastError,
    });
  };

  return { payments, getPayments, loading, start, setStart, end, setEnd };
};

export default useLoadAttachmentPayments;
