import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import { SubjectCreate } from '../subject';
import CreateSubjectForm from '../components/CreateSubjectForm';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';

const CreateSubjectContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: SubjectCreate,
    { setFieldError }: FormikHelpers<SubjectCreate>
  ) => {
    let success = false;

    await callApi({
      endpoint: 'academic/marklist/subjects',
      data: {
        ...values,
        display_rules: JSON.stringify({
          A: values.A,
          B: values.B,
          C: values.C,
          D: values.D,
        }),
      },
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Subject Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/subject" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Subject</Typography>
        </Grid>
        <CreateSubjectForm onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
    </Layout>
  );
};

export default CreateSubjectContainer;
