/* eslint-disable react-hooks/exhaustive-deps */
import { Restore } from '@mui/icons-material';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import DateModified from '../../../../../core/ui/utility/DateModified';
import Loader from '../../../../../core/ui/utility/Loader';
import { Recurrent } from '../../recurrent';
import RestoreRecurrentDialog from '../dialogs/RestoreRecurrentDialog';

const RecurrentArchivedList = () => {
  const [restorePayment, setRestorePayment] = useState<Recurrent | null>(null);
  const { data, fetchData, loading } = useFetchApiData<Recurrent>();

  useEffect(() => {
    fetchData('payment-latest/recurrent/archived', {});
  }, []);

  return (
    <>
      <Box sx={{ p: 2, maxWidth: 1000 }}>
        <Grid display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" sx={{ mb: 2 }}>
            Archived Recurrent Payment List
          </Typography>
        </Grid>

        <Loader loading={loading}>
          <TableContainer component={Paper} sx={{ overflowX: 'auto', mt: 2 }}>
            <Table aria-label="custom pagination table" size="small">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Effective Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Modified</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell size="small">{payment.description}</TableCell>
                    <TableCell size="small">{payment.effective_date}</TableCell>
                    <TableCell size="small">{payment.end_date}</TableCell>
                    <TableCell component="th" scope="row" sx={{ py: '4px' }}>
                      <DateModified
                        createdAt={payment.created_at}
                        updatedAt={payment.updated_at}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <Grid
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Tooltip title="Restore Recurrent Payment">
                          <Restore
                            color="primary"
                            fontSize="small"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => setRestorePayment(payment)}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </Box>

      <RestoreRecurrentDialog
        payment={restorePayment}
        onSuccess={() => {
          fetchData('payment-latest/recurrent/archived', {});
          setRestorePayment(null);
        }}
        cancel={() => setRestorePayment(null)}
      />
    </>
  );
};

export default RecurrentArchivedList;
