/* eslint-disable react-hooks/exhaustive-deps */
import { Clear } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Grade } from '../../academic/grade/grade';
import { Student } from '../../academic/student/student';

type GradeWithStudents = Grade & { students: Student[] };

type StudentWithGrade = Student & { grade_id: string };

type PendingProps = {
  onSubmit: () => Promise<any>;
  submitting: boolean;
  gradeStudents: GradeWithStudents[];
  onSetStudentData: (d: { student_id: string; grade_id: string }[]) => void;
};

const StudentSelectionForm = ({
  submitting,
  gradeStudents,
  onSubmit,
  onSetStudentData,
}: PendingProps) => {
  const [gradesPending, setGradesPending] =
    useState<GradeWithStudents[]>(gradeStudents);
  const [activeGradeSelections, setActiveGradeSelections] = useState<string[]>(
    []
  );
  const [gradesSelected, setGradesSelected] = useState<GradeWithStudents[]>([]);
  const [removeGradeSelections, setRemoveGradeSelections] = useState<string[]>(
    []
  );
  const [pendingGradesFilter, setPendingGradesFilter] = useState('');
  const [selectedGradesFilter, setSelectedGradesFilter] = useState('');

  const [studentsPending, setStudentsPending] = useState<StudentWithGrade[]>(
    []
  );
  const [activeStudentSelections, setActiveStudentSelections] = useState<
    string[]
  >([]);
  const [studentsSelected, setStudentsSelected] = useState<StudentWithGrade[]>(
    []
  );
  const [removeStudentSelections, setRemoveStudentSelections] = useState<
    string[]
  >([]);
  const [pendingStudentsFilter, setPendingStudentsFilter] = useState('');
  const [selectedStudentsFilter, setSelectedStudentsFilter] = useState('');

  const handleGradePendingChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setActiveGradeSelections(value);
  };

  const handleGradeChangeSelected = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setRemoveGradeSelections(value);
  };

  const selectGrades = () => {
    const curSelections = gradesPending.filter((grade) =>
      activeGradeSelections.includes(grade.id)
    );
    const nonSelectedGrades = gradesPending.filter(
      (grade) => !activeGradeSelections.includes(grade.id)
    );
    setGradesPending(nonSelectedGrades);
    setGradesSelected([...gradesSelected, ...curSelections]);
    setActiveGradeSelections([]);
  };

  const selectAllGrades = () => {
    const curSelections = getPendingGrades();
    const curSelectionIds = curSelections.map((i) => i.id);
    const nonSelectedGrades = gradesPending.filter(
      (grade) => !curSelectionIds.includes(grade.id)
    );
    setGradesPending(nonSelectedGrades);
    setGradesSelected([...gradesSelected, ...curSelections]);
  };

  const deselectGrades = () => {
    const curSelections = gradesSelected.filter((grade) =>
      removeGradeSelections.includes(grade.id)
    );
    const nonSelected = gradesSelected.filter(
      (grade) => !removeGradeSelections.includes(grade.id)
    );

    setGradesSelected(nonSelected);
    setGradesPending([...gradesPending, ...curSelections]);
    setRemoveGradeSelections([]);
  };

  const deselectAllGrades = () => {
    const curSelections = getSelectedGrades();
    const curSelectionIds = curSelections.map((i) => i.id);
    const nonSelected = gradesSelected.filter(
      (grade) => !curSelectionIds.includes(grade.id)
    );

    setGradesSelected(nonSelected);
    setGradesPending([...gradesPending, ...curSelections]);
  };

  const getPendingGrades = () =>
    gradesPending
      .sort((a, b) => a.order - b.order)
      .filter(
        (grade) =>
          pendingGradesFilter === '' ||
          grade.name.match(new RegExp(`.*${pendingGradesFilter}.*`, 'i'))
      );

  const getSelectedGrades = () =>
    gradesSelected
      .sort((a, b) => a.order - b.order)
      .filter(
        (grade) =>
          selectedGradesFilter === '' ||
          grade.name.match(new RegExp(`.*${selectedGradesFilter}.*`, 'i'))
      );

  const handleStudentPendingChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setActiveStudentSelections(value);
  };

  const handleStudentSelectedChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target;
    const value: string[] = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setRemoveStudentSelections(value);
  };
  const selectStudents = () => {
    const curSelections = studentsPending.filter((student) =>
      activeStudentSelections.includes(student.id)
    );
    const nonSelectedStudents = studentsPending.filter(
      (student) => !activeStudentSelections.includes(student.id)
    );
    setStudentsPending(nonSelectedStudents);
    setStudentsSelected([...studentsSelected, ...curSelections]);
    setActiveStudentSelections([]);
  };

  const selectAllStudents = () => {
    const curSelections = getPendingStudents();
    const curSelectionIds = curSelections.map((i) => i.id);
    const nonSelectedStudents = studentsPending.filter(
      (student) => !curSelectionIds.includes(student.id)
    );
    setStudentsPending(nonSelectedStudents);
    setStudentsSelected([...studentsSelected, ...curSelections]);
  };

  const deselectStudents = () => {
    const curSelections = studentsSelected.filter((student) =>
      removeStudentSelections.includes(student.id)
    );
    const nonSelected = studentsSelected.filter(
      (student) => !removeStudentSelections.includes(student.id)
    );

    setStudentsSelected(nonSelected);
    setStudentsPending([...studentsPending, ...curSelections]);
    setRemoveStudentSelections([]);
  };

  const deselectAllStudents = () => {
    const curSelections = getSelectedStudents();
    const curSelectionIds = curSelections.map((i) => i.id);
    const nonSelected = studentsSelected.filter(
      (student) => !curSelectionIds.includes(student.id)
    );

    setStudentsSelected(nonSelected);
    setStudentsPending([...studentsPending, ...curSelections]);
  };

  const getPendingStudents = () =>
    studentsPending
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .filter(
        (student) =>
          pendingStudentsFilter === '' ||
          (student.first_name + ' ' + student.father_name).match(
            new RegExp(`.*${pendingStudentsFilter}.*`, 'i')
          )
      );

  const getSelectedStudents = () =>
    studentsSelected
      .sort((a, b) => a.first_name.localeCompare(b.first_name))
      .filter(
        (student) =>
          selectedStudentsFilter === '' ||
          (student.first_name + ' ' + student.father_name).match(
            new RegExp(`.*${selectedStudentsFilter}.*`, 'i')
          )
      );

  useEffect(() => {
    const students: StudentWithGrade[] = [];
    gradesPending.forEach((grade) => {
      const curStudents: StudentWithGrade[] = grade.students.map((student) => ({
        ...student,
        grade_id: grade.id,
      }));
      students.push(...curStudents);
    });
    setStudentsPending(students);
  }, [gradesPending]);

  useEffect(() => {
    const students: StudentWithGrade[] = [];
    gradesSelected.forEach((grade) => {
      const curStudents: StudentWithGrade[] = grade.students.map((student) => ({
        ...student,
        grade_id: grade.id,
      }));
      students.push(...curStudents);
    });
    setStudentsSelected(students);
  }, [gradesSelected]);

  useEffect(() => {
    const parsedStudents: { grade_id: string; student_id: string }[] =
      studentsSelected.map((student) => ({
        student_id: student.id,
        grade_id: student.grade_id,
      }));

    onSetStudentData(parsedStudents);
  }, [studentsSelected]);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        {/* Grades  */}
        <Grid container display="flex">
          <Grid item sm={5}>
            <Typography sx={{ mb: 1 }} variant="h6" fontWeight={600}>
              Available classes ({gradesPending.length}){' '}
            </Typography>
            <TextField
              fullWidth
              placeholder="Filter pending"
              value={pendingGradesFilter}
              onChange={(e) => setPendingGradesFilter(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear filter"
                      onClick={() => setPendingGradesFilter('')}
                      edge="end"
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Select
              sx={{
                mt: 2,
                width: '100%',
                select: {
                  minHeight: '300px',
                },
              }}
              value={activeGradeSelections}
              size="small"
              multiple
              native
              // @ts-ignore Typings are not considering `native`
              onChange={handleGradePendingChange}
              inputProps={{
                id: 'select-multiple-native',
              }}
            >
              {getPendingGrades().map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.name} ({grade.students.length} students)
                </option>
              ))}
            </Select>
          </Grid>

          <Grid
            item
            sm={2}
            justifyContent="center"
            display="flex"
            flexDirection="column"
            sx={{ px: 2 }}
          >
            <Button
              disabled={gradesPending.length === 0}
              onClick={selectAllGrades}
            >{`>>`}</Button>
            <Button
              disabled={activeGradeSelections.length === 0}
              onClick={selectGrades}
            >{`>`}</Button>
            <Button
              disabled={removeGradeSelections.length === 0}
              onClick={deselectGrades}
            >{`<`}</Button>
            <Button
              disabled={gradesSelected.length === 0}
              onClick={deselectAllGrades}
            >{`<<`}</Button>
          </Grid>

          <Grid item sm={5}>
            <Typography sx={{ mb: 1 }} variant="h6" fontWeight={600}>
              Selected classes ({gradesSelected.length}){' '}
            </Typography>
            <TextField
              fullWidth
              placeholder="Filter selection"
              size="small"
              value={selectedGradesFilter}
              onChange={(e) => setSelectedGradesFilter(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear filter"
                      onClick={() => setSelectedGradesFilter('')}
                      edge="end"
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Select
              sx={{
                mt: 2,
                width: '100%',
                select: {
                  minHeight: '300px',
                },
              }}
              value={removeGradeSelections}
              size="small"
              multiple
              native
              // @ts-ignore Typings are not considering `native`
              onChange={handleGradeChangeSelected}
              inputProps={{
                id: 'select-multiple-grade-native',
              }}
            >
              {getSelectedGrades().map((grade) => (
                <option key={grade.id} value={grade.id}>
                  {grade.name} ({grade.students.length} students)
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>

        {/* Students  */}
        <Grid container display="flex" sx={{ mt: 3 }}>
          <Grid item sm={5}>
            <Typography sx={{ mb: 1 }} variant="h6" fontWeight={600}>
              Available students ({studentsPending.length}){' '}
            </Typography>
            <TextField
              fullWidth
              placeholder="Filter pending students"
              value={pendingStudentsFilter}
              onChange={(e) => setPendingStudentsFilter(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear filter"
                      onClick={() => setPendingStudentsFilter('')}
                      edge="end"
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Select
              sx={{
                mt: 2,
                width: '100%',
                select: {
                  minHeight: '300px',
                },
              }}
              value={activeStudentSelections}
              size="small"
              multiple
              native
              // @ts-ignore Typings are not considering `native`
              onChange={handleStudentPendingChange}
              inputProps={{
                id: 'select-multiple-student-native',
              }}
            >
              {getPendingStudents().map((student) => (
                <option key={student.id} value={student.id}>
                  {`${student.first_name} ${student.father_name}`}
                </option>
              ))}
            </Select>
          </Grid>

          <Grid
            item
            sm={2}
            justifyContent="center"
            display="flex"
            flexDirection="column"
            sx={{ px: 2 }}
          >
            <Button
              disabled={studentsPending.length === 0}
              onClick={selectAllStudents}
            >{`>>`}</Button>
            <Button
              disabled={activeStudentSelections.length === 0}
              onClick={selectStudents}
            >{`>`}</Button>
            <Button
              disabled={removeStudentSelections.length === 0}
              onClick={deselectStudents}
            >{`<`}</Button>
            <Button
              disabled={studentsSelected.length === 0}
              onClick={deselectAllStudents}
            >{`<<`}</Button>
          </Grid>

          <Grid item sm={5}>
            <Typography sx={{ mb: 1 }} variant="h6" fontWeight={600}>
              Selected students ({studentsSelected.length}){' '}
            </Typography>
            <TextField
              fullWidth
              placeholder="Filter selection"
              size="small"
              value={selectedStudentsFilter}
              onChange={(e) => setSelectedStudentsFilter(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="clear filter"
                      onClick={() => setSelectedStudentsFilter('')}
                      edge="end"
                      size="small"
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Select
              sx={{
                mt: 2,
                width: '100%',
                select: {
                  minHeight: '300px',
                },
              }}
              value={removeStudentSelections}
              size="small"
              multiple
              native
              // @ts-ignore Typings are not considering `native`
              onChange={handleStudentSelectedChange}
              inputProps={{
                id: 'select-multiple-student-selected-native',
              }}
            >
              {getSelectedStudents().map((student) => (
                <option key={student.id} value={student.id}>
                  {`${student.first_name} ${student.father_name}`}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 3 }} justifyContent="center">
          <Button
            type="submit"
            variant="outlined"
            disabled={submitting}
            onClick={() => onSubmit()}
            size="small"
          >
            {submitting ? 'Saving' : 'Save'}
          </Button>
        </Grid>
      </Box>
    </>
  );
};

export default StudentSelectionForm;
