import { Grade } from '../grade';
import {
  Grid,
  TextField,
  Paper,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import useEditGradeController from '../hooks/useEditGradeController';

type EditGradeProps = {
  grade: Grade;
};

const EditGradeForm = ({ grade }: EditGradeProps) => {
  const { formik, submitting } = useEditGradeController(grade);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 400 }}>
        <form onSubmit={formik.handleSubmit}>
          <Paper sx={{ p: 2, pb: 3 }}>
            <Grid item sx={{ mt: 2 }}>
              <TextField
                fullWidth
                name="name"
                size="small"
                type="text"
                variant="outlined"
                label="Name"
                placeholder=""
                value={formik.values.name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.name)}
                helperText={formik.errors.name}
              />
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <TextField
                fullWidth
                name="order"
                size="small"
                type="number"
                variant="outlined"
                label="Order"
                placeholder=""
                value={formik.values.order}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.order)}
                helperText={formik.errors.order}
              />
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <TextField
                fullWidth
                name="monthly_fee"
                size="small"
                type="number"
                variant="outlined"
                label="Monthly Fee"
                placeholder=""
                value={formik.values.monthly_fee}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.monthly_fee)}
                helperText={formik.errors.monthly_fee}
              />
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <TextField
                fullWidth
                name="registration_fee"
                size="small"
                type="number"
                variant="outlined"
                label="Registration Fee"
                placeholder=""
                value={formik.values.registration_fee}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.registration_fee)}
                helperText={formik.errors.registration_fee}
              />
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <TextField
                fullWidth
                name="tutorial_fee"
                size="small"
                type="number"
                variant="outlined"
                label="Tutorial Fee"
                placeholder=""
                value={formik.values.tutorial_fee}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.tutorial_fee)}
                helperText={formik.errors.tutorial_fee}
              />
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <TextField
                fullWidth
                name="summer_fee"
                size="small"
                type="number"
                variant="outlined"
                label="Summer Fee"
                placeholder=""
                value={formik.values.summer_fee}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.summer_fee)}
                helperText={formik.errors.summer_fee}
              />
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Report card template</InputLabel>
                <Select
                  value={formik.values.report_card_template}
                  size="small"
                  label="Report card template"
                  name="report_card_template"
                  onChange={formik.handleChange}
                  fullWidth
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="main">Main</MenuItem>
                  <MenuItem value="nursery">Nursery</MenuItem>
                </Select>
                {formik.touched.report_card_template &&
                  formik.errors.report_card_template && (
                    <FormHelperText error>
                      {formik.errors.report_card_template}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Skill template</InputLabel>
                <Select
                  value={formik.values.skill_template}
                  size="small"
                  label="Skill template"
                  name="skill_template"
                  onChange={formik.handleChange}
                  fullWidth
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="main">Main</MenuItem>
                  <MenuItem value="nursery">Nursery</MenuItem>
                </Select>
                {formik.touched.skill_template &&
                  formik.errors.skill_template && (
                    <FormHelperText error>
                      {formik.errors.skill_template}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                size="small"
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Paper>
        </form>
      </Box>
    </>
  );
};

export default EditGradeForm;
