import { sortByText } from '../../../../core/utils/data';
import { Rcq, RcqCst } from '../../marklist/reportCard/models/rcq';
import { Rcs, RcsCst } from '../../marklist/reportCard/models/rcs';
import { RcyCst } from '../../marklist/reportCard/models/rcy';

export const sortRcqs = (rcqs: Rcq[]) =>
  (rcqs || []).sort((a, b) => a.quarter.quarter - b.quarter.quarter);

export const sortRcqCsts = (rcqCsts: RcqCst[]) =>
  sortByText(rcqCsts || [], (i: RcqCst) => i.cst.subject.subject, 'asc');

export const sortRcss = (rcss: Rcs[]) =>
  (rcss || []).sort((a, b) => a.semester.semester - b.semester.semester);

export const sortRcsCsts = (rcsCsts: RcsCst[]) =>
  sortByText(rcsCsts || [], (i: RcsCst) => i.cst.subject.subject, 'asc');

export const sortRcyCsts = (rcyCsts: RcyCst[]) =>
  sortByText(rcyCsts || [], (i: RcyCst) => i.cst.subject.subject, 'asc');
