import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../../../../core/service/globalContext';
import Layout from '../../../../../core/ui/layout/Layout';

const ReportCardContainer = () => {
  const { grades, quarters, semesters, activeYear } = useContext(GlobalContext);

  return (
    <>
      <Layout>
        <>
          <Box sx={{ p: 2 }}>
            <Typography variant="h4">Roster</Typography>
            <TableContainer
              component={Paper}
              sx={{ overflowX: 'auto', maxWidth: 700, mt: 2 }}
            >
              <Table aria-label="custom pagination table" size="small">
                <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                  <TableRow>
                    <TableCell size="small" sx={{ width: '20px' }}>
                      #
                    </TableCell>
                    <TableCell>Grade</TableCell>
                    <TableCell align="left">Quarters</TableCell>
                    <TableCell align="left">Semesters</TableCell>
                    <TableCell align="left">Year</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {grades.map((grade, i) => (
                    <TableRow key={grade.id}>
                      <TableCell size="small">{i + 1}</TableCell>
                      <TableCell>{grade.name}</TableCell>
                      <TableCell>
                        <Table size="small">
                          <TableBody>
                            {quarters.map((quarter) => (
                              <TableRow key={`quarter-${quarter.id}`}>
                                <TableCell sx={{ display: 'flex' }}>
                                  <Link
                                    to={`/academic/roster/grade/${grade.id}/quarter/${quarter.id}`}
                                  >
                                    <Box display="flex" alignItems="center">
                                      <Typography variant="body1">
                                        {quarter.quarter}
                                      </Typography>
                                      <OpenInNew
                                        sx={{ ml: 1, fontSize: '14px' }}
                                        fontSize="small"
                                      />
                                    </Box>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                      <TableCell>
                        <Table size="small">
                          <TableBody>
                            {semesters.map((semester) => (
                              <TableRow key={semester.id}>
                                <TableCell>
                                  <Link
                                    to={`/academic/roster/grade/${grade.id}/semester/${semester.id}`}
                                  >
                                    <Box display="flex" alignItems="center">
                                      <Typography variant="body1">
                                        {semester.semester}
                                      </Typography>
                                      <OpenInNew
                                        sx={{ ml: 1, fontSize: '14px' }}
                                        fontSize="small"
                                      />
                                    </Box>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                      <TableCell>
                        <Link to={`/academic/roster/grade/${grade.id}/year`}>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body1">
                              {activeYear!.year}
                            </Typography>
                            <OpenInNew
                              sx={{ ml: 1, fontSize: '14px' }}
                              fontSize="small"
                            />
                          </Box>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      </Layout>
    </>
  );
};

export default ReportCardContainer;
