import _ from 'lodash';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { dataParserFactory } from '../../../../core/utils/utility';
import CreateRecurrentChildForm from '../components/forms/CreateRecurrentChildForm';
import {
  PenaltyCreate,
  RecurrentChildCreate,
  RecurrentCreate,
} from '../recurrent';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import CreateRecurrentForm from '../components/forms/CreateRecurrentForm';

type RecurrentContainerProps = {
  onSuccess: (id: string) => void;
};

const CreateRecurrentContainer = ({ onSuccess }: RecurrentContainerProps) => {
  const [childrenGenerated, setChildrenGenerated] = useState(false);
  const [penalty, setPenalty] = useState<PenaltyCreate>({
    has_penalty: false,
    no_penalty_days: 0,
    penalty_type: 'fixed',
    penalty_amount: 0,
    penalty_frequency: 'onetime',
    penalty_reapply_days: 0,
    max_penalty: '',
    max_penalty_apply_days: '',
  });

  const initialValues: RecurrentCreate = {
    effective_date: new Date(),
    end_date: new Date(),
    description: '',
    amount: 0,
    payments: [],
  };
  const [payments, setPayments] = useState<RecurrentChildCreate[]>([]);
  const { loading: submitting, callApi } = useSendApiData();

  const formik = useFormik<RecurrentCreate>({
    initialValues,
    onSubmit: async (values, { setFieldError }) => {
      let success = false;
      let id = '';
      formik.setFieldValue('payments', payments);
      const curValues: any = _.cloneDeep(values);
      curValues.payments = payments.map(
        (payment) =>
          dataParserFactory(payment).parseDate(['start_date', 'end_date']).data
      ) as any;

      const parsedData = dataParserFactory(curValues).parseDate([
        'effective_date',
        'end_date',
      ]).data;

      await callApi({
        endpoint: 'payment-latest/recurrent',
        data: parsedData,
        onSuccess: (data) => {
          toastMessage('Recurrent payment created');
          id = data.recurrentId;
          success = true;
        },
        onError: toastError,
        onValidationError: (err) => {
          toastError('Invalid data, check your input');
          parseValidationErrors(err, setFieldError);
        },
      });

      if (success) {
        onSuccess(id);
      }
    },
  });

  const generateRecurrentChildren = (limit: number) => {
    const diff = moment(formik.values.end_date).diff(
      moment(formik.values.effective_date),
      'days'
    );

    if (diff > 0) {
      const curPayments: RecurrentChildCreate[] = [];
      const dateAdd = Math.floor(diff / limit);

      for (let i = 0; i < limit; i++) {
        curPayments.push({
          start_date: moment(formik.values.effective_date || new Date())
            .add(i * dateAdd + (i === 0 ? 0 : 1), 'days')
            .format('YYYY-MM-DD'),
          end_date: moment(formik.values.effective_date || new Date())
            .add(i * dateAdd + dateAdd + 1, 'days')
            .format('YYYY-MM-DD'),
          amount: formik.values.amount,
          order: i + 1,
          description: '',
          ...penalty,
        });
      }

      setPayments(curPayments);
    }
  };

  return (
    <>
      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
        }}
      >
        <CreateRecurrentForm
          formik={formik}
          onGeneratePayment={(limit) => {
            setChildrenGenerated(true);
            generateRecurrentChildren(limit);
          }}
          submitting={submitting}
          onFinalize={() => formik.submitForm()}
          penalty={penalty}
          setPenalty={setPenalty}
          childrenGenerated={childrenGenerated}
          cancelPayment={() => {
            setChildrenGenerated(false);
            setPayments([]);
            setPenalty({
              has_penalty: false,
              no_penalty_days: 0,
              penalty_type: 'fixed',
              penalty_amount: 0,
              penalty_frequency: 'onetime',
              penalty_reapply_days: 0,
              max_penalty: '',
              max_penalty_apply_days: '',
            });
          }}
        />

        <Grid
          container
          display="flex"
          flexWrap="wrap"
          spacing={2}
          justifyContent="center"
        >
          {payments.map((_, i) => (
            <Grid item key={`child-pay-${i}`}>
              <CreateRecurrentChildForm
                formik={formik}
                index={i}
                childInit={payments[i]}
                onChange={(index, item) => {
                  payments[index] = item;
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default CreateRecurrentContainer;
