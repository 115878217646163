import Layout from '../../../../core/ui/layout/Layout';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import FixedArchivedPaymentList from '../components/lists/FixedArchivedPaymentList';

const ListArchiveFixedPaymentPage = () => {
  return (
    <>
      <Layout
        renderLeftToolbar={() => <ReturnButton to="/payment/fixed/add" />}
      >
        <FixedArchivedPaymentList />
      </Layout>
    </>
  );
};

export default ListArchiveFixedPaymentPage;
