import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { StudentFixedPending } from '../lib/types/student-payment-report';

const StudentFixedPendingTable = ({
  pendings,
}: {
  pendings: StudentFixedPending[];
}) => {
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Pending Fixed Payments
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 1000, maxWidth: 1600 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell sx={{}}>Payment</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Due</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendings.map((payment, i) => (
                <TableRow key={payment.id} sx={{ py: 0 }}>
                  <TableCell size="small">{i + 1}</TableCell>
                  <TableCell size="small">
                    {payment?.fixedPayment.description}
                  </TableCell>
                  <TableCell size="small">
                    {payment?.fixedPayment.amount}
                  </TableCell>
                  <TableCell size="small">
                    {moment(payment.fixedPayment.end_date).format('MMM D YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default StudentFixedPendingTable;
