import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Loader from '../../../../core/ui/utility/Loader';
import useStudentSelector from '../hooks/useStudentSelector';
import IDListing from './IdListing';

const StudentGradeSelector = () => {
  const {
    grade,
    setGrade,
    year,
    setYear,
    grades,
    years,
    students,
    setStudents,
    loading,
    printFormat,
    setPrintFormat,
    getSelectedGrade,
    getSelectedYear,
  } = useStudentSelector();

  return (
    <>
      <Grid container sx={{}} spacing={1}>
        <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Grade</InputLabel>
            <Select
              value={grade}
              size="small"
              label="Grade"
              onChange={(e) => {
                setGrade(e.target.value);
              }}
              fullWidth
            >
              {Object.values(grades).map((grade) => (
                <MenuItem key={grade.id} value={grade.id}>
                  {grade.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item alignSelf="flex-start" sx={{ flexGrow: 1, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Year</InputLabel>
            <Select
              value={year}
              size="small"
              label="Year"
              onChange={(e) => {
                setYear(e.target.value);
              }}
              fullWidth
            >
              {Object.values(years).map((year) => (
                <MenuItem key={year.id} value={year.id}>
                  {year.year} {year.active ? '(Active)' : ''}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item alignSelf="flex-start" sx={{ flexGrow: 1, mr: 1 }}>
          <FormControl fullWidth>
            <InputLabel>Print Format</InputLabel>
            <Select
              value={printFormat}
              size="small"
              label="Print Format"
              onChange={(e) => {
                setPrintFormat(e.target.value as any);
              }}
              fullWidth
            >
              <MenuItem value="main">Main</MenuItem>
              <MenuItem value="nursery">Nursery</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Loader loading={loading}>
        <Grid
          container
          sx={{ pl: 2, mt: 2, display: 'flex', flexDirection: 'column' }}
        >
          {students
            .sort((a, b) =>
              a.student.first_name.localeCompare(b.student.first_name)
            )
            .map((student, i) => (
              <Grid
                item
                alignSelf="flex-start"
                sx={{ flexGrow: 1 }}
                key={student.student.id}
              >
                <FormControl error>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={students[i].selected}
                        onChange={(e) => {
                          const curStudents = [...students];
                          curStudents[i].selected = e.target.checked;
                          setStudents(curStudents);
                        }}
                      />
                    }
                    label={`${student.student.first_name} ${student.student.father_name}`}
                  />
                </FormControl>
              </Grid>
            ))}
        </Grid>
      </Loader>

      {students.length > 0 && (
        <>
          <IDListing
            grade={getSelectedGrade().name}
            year={getSelectedYear().year}
            students={students
              .filter((i) => i.selected)
              .map((i) => i.student)
              .sort((a, b) => a.first_name.localeCompare(b.first_name))}
            idType={printFormat}
          />
        </>
      )}
    </>
  );
};

export default StudentGradeSelector;
