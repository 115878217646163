/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../../core/service/globalContext';
import { toastError } from '../../../../../core/utils/ui/alert';
import { Skill } from '../../models/skill';
import { parseSkills } from '../../services/skillServices';
import SkillInlineForm from './SkillInlineForm';

type SkillProps = {
  studentId: string;
  gsId: string;
};

const SkillComponent = ({ studentId, gsId }: SkillProps) => {
  const { quarters } = useContext(GlobalContext);
  const { fetchData } = useFetchApiData();
  const [skillMap, setSkillMap] = useState<Record<string, Skill>>({});
  const { callApi, loading } = useSendApiData();

  const fetchSkills = () =>
    fetchData(`academic/students/skills/student/${studentId}`, {
      onSuccess: (data) => {
        setSkillMap(parseSkills(data?.skills || []));
      },
      onError: toastError,
    });

  useEffect(() => {
    fetchSkills();
  }, []);

  const markAllE = async () => {
    for (let i = 0; i < quarters.length; i++) {
      await callApi({
        endpoint: 'academic/students/skills',
        data: {
          grade_student_id: gsId,
          quarter_id: quarters[i].id,
          punctuality: 'E',
          anthem_participation: 'E',
          attendance: 'E',
          completing_work: 'E',
          follow_rules: 'E',
          english_use: 'E',
          listening: 'E',
          class_participation: 'E',
          handwriting: 'E',
          communication_book_use: 'E',
          material_handling: 'E',
          cooperation: 'E',
          school_uniform: 'E',
        },
        onSuccess: () => {
          fetchSkills();
        },
        onError: toastError,
      });
    }
  };

  return (
    <>
      <Grid display="flex" alignItems="baseline" sx={{ mb: 1 }}>
        <Typography variant="h5" sx={{ mr: 8 }}>
          Skill
        </Typography>

        <Button
          disabled={loading}
          onClick={markAllE}
          variant="contained"
          size="small"
        >
          All E
        </Button>
      </Grid>
      <Box sx={{ mb: 4 }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 600, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell size="small">Evaluation Areas</TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`eval-${quarter.id}`} size="small">
                      <Grid
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography sx={{ fontSize: 14 }}>Quarter</Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {quarter.quarter}
                        </Typography>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Punctuality</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`p-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="punctuality"
                        value={skillMap[quarter.id]?.punctuality || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Anthem participation</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`an-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="anthem_participation"
                        value={skillMap[quarter.id]?.anthem_participation || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Attendance</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`at-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="attendance"
                        value={skillMap[quarter.id]?.attendance || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Completing work on time</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`cw-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="completing_work"
                        value={skillMap[quarter.id]?.completing_work || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Follow Rules</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`fr-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="follow_rules"
                        value={skillMap[quarter.id]?.follow_rules || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>English use and practice</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`eu-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="english_use"
                        value={skillMap[quarter.id]?.english_use || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Listening skill</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`lg-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="listening"
                        value={skillMap[quarter.id]?.listening || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Participation in class</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`cp-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="class_participation"
                        value={skillMap[quarter.id]?.class_participation || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Hand writing</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`hw-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="handwriting"
                        value={skillMap[quarter.id]?.handwriting || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Use of communication book</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`cby-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="communication_book_use"
                        value={
                          skillMap[quarter.id]?.communication_book_use || ''
                        }
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Material handling</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`mh-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="material_handling"
                        value={skillMap[quarter.id]?.material_handling || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Cooperation with others</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`coop-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="cooperation"
                        value={skillMap[quarter.id]?.cooperation || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>School uniform</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`su-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="school_uniform"
                        value={skillMap[quarter.id]?.school_uniform || ''}
                        fetchSkill={fetchSkills}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            sx={{
              px: 6,
              py: 4,
              ml: 4,
              border: '1px solid lightblue',
              borderColor: 'grey.200',
              borderTop: '5px solid',
              borderTopColor: '#1565C0',
              bgcolor: 'white',
              boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.06)',
            }}
          >
            <Typography
              variant="h6"
              textAlign="center"
              sx={{ mb: 2 }}
              fontWeight={800}
            >
              Evaluation Code
            </Typography>

            <Grid display="flex">
              <Grid display="flex" flexDirection="column" sx={{ mr: 4 }}>
                <Typography variant="subtitle2" fontWeight="700">
                  E = Excellent
                </Typography>
                <Typography variant="subtitle2" fontWeight="700">
                  G = Good
                </Typography>
                <Typography variant="subtitle2" fontWeight="700">
                  N = Needs Improvement
                </Typography>
              </Grid>

              <Grid display="flex" flexDirection="column">
                <Typography variant="subtitle2" fontWeight="700">
                  P = Poor
                </Typography>
                <Typography variant="subtitle2" fontWeight="700">
                  R = At Risk
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SkillComponent;
