import { useState } from 'react';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import { Subject } from '../subject';

const useSubjectHook = () => {
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const { fetchData, loading: subjectsLoading } = useFetchApiData();

  const fetchSubjects = async () => {
    await fetchData('academic/marklist/subjects', {
      onSuccess: ({ data }) => {
        setSubjects(data);
      },
    });
  };

  return { subjects, fetchSubjects, subjectsLoading };
};

export default useSubjectHook;
