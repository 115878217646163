import { FormikHelpers, useFormik } from 'formik';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { TeacherCreate } from '../teacher';

const useCreateTeacherController = (onSuccess: () => void) => {
  const { callApi, loading: submitting } = useSendApiData();

  const initialValues: TeacherCreate = { user_id: '' };

  const formik = useFormik({
    initialValues,
    onSubmit: async (
      values: TeacherCreate,
      { setFieldError }: FormikHelpers<TeacherCreate>
    ) => {
      await callApi({
        endpoint: 'academic/teachers',
        data: values,
        headers: {
          'Content-Type': 'application/json',
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
        onSuccess: async (_) => {
          toastMessage('Teacher Assigned');
          onSuccess();
        },
      });
    },
  });

  return { formik, submitting };
};

export default useCreateTeacherController;
