import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { GradeSmsResponse } from '../models/grade-sms-response';
import { toastError } from '../../../../core/utils/ui/alert';

export const useLoadGradesSms = () => {
  const [grades, setGrades] = useState<GradeSmsResponse[]>([]);
  const { fetchData, loading } = useFetchApiData();

  useEffect(() => {
    fetchData(`academic/grades/with-students`, {
      onSuccess: ({ data: apiData }) => {
        const mapped = (apiData as GradeSmsResponse[]).map((grade) => ({
          ...grade,
          gradeStudents: grade.gradeStudents.filter(
            (i) => i.student && i.student.primary_phone
          ),
        }));

        setGrades(mapped);
      },
      onError: toastError,
    });
  }, []);

  return { grades, loading };
};
