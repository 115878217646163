import CreateSmlContainer from '../containers/CreateSmlContainer';

const CreateSmlPage = () => {
  return (
    <>
      <CreateSmlContainer />
    </>
  );
};

export default CreateSmlPage;
