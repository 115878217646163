import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import Layout from "../../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../../core/utils/validation";
import { AcademicYearCreate } from "../academicYear";
import CreateAcademicYearForm from "../components/CreateAcademicYearForm";
import ReturnButton from "../../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../../core/utils/utility";

const CreateAcademicYearContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  

  const handleSubmit = async (
    values: AcademicYearCreate,
    { setFieldError }: FormikHelpers<AcademicYearCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: 'academic/academic-years',
      data: formData,
      headers: {
        'Content-Type': 'application/json'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('AcademicYear Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/academic-year" />}>
      
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add AcademicYear</Typography>
        </Grid>
        <CreateAcademicYearForm  onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
      
    </Layout>
  );
};

export default CreateAcademicYearContainer;
