import { FormikHelpers, useFormik } from 'formik';
import { StudentEdit, Student } from '../student';
import {
  Grid,
  TextField,
  Paper,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import constants from '../../../../core/utils/constants';

type StudentProps = {
  onSubmit: (
    value: StudentEdit,
    helpers: FormikHelpers<StudentEdit>
  ) => Promise<void>;
  submitting: boolean;
  student: Student;
};

const EditStudentForm = ({ onSubmit, submitting, student }: StudentProps) => {
  const handleSubmit = async (
    value: StudentEdit,
    helpers: FormikHelpers<StudentEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: StudentEdit = {
    first_name: student.first_name,
    father_name: student.father_name,
    grand_father_name: student.grand_father_name,
    gender: student.gender,
    status: student.status || '',
    primary_phone: student.primary_phone || '',
    img: null,
    date_of_birth: student.date_of_birth || '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, pb: 3 }}>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item>
                  <TextField
                    fullWidth
                    name="first_name"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="First Name"
                    placeholder=""
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.first_name)}
                    helperText={formik.errors.first_name}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    name="father_name"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Father Name"
                    placeholder=""
                    value={formik.values.father_name}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.father_name)}
                    helperText={formik.errors.father_name}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item>
                  <TextField
                    fullWidth
                    name="grand_father_name"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="G/Father Name"
                    placeholder=""
                    value={formik.values.grand_father_name}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.grand_father_name)}
                    helperText={formik.errors.grand_father_name}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    name="id_number"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="ID Number"
                    placeholder=""
                    value={formik.values.id_number}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.id_number)}
                    helperText={formik.errors.id_number}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item flexGrow={1}>
                  <TextField
                    fullWidth
                    name="date_of_birth"
                    size="small"
                    type="date"
                    variant="outlined"
                    label="Date"
                    placeholder=""
                    value={formik.values.date_of_birth}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.date_of_birth)}
                    helperText={formik.errors.date_of_birth}
                  />
                </Grid>
                <Grid item flexGrow={1}>
                  <TextField
                    fullWidth
                    name="primary_phone"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Phone"
                    placeholder=""
                    value={formik.values.primary_phone}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.primary_phone)}
                    helperText={formik.errors.primary_phone}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel>Gender</InputLabel>
                    <Select
                      value={formik.values.gender}
                      size="small"
                      label="Gender"
                      name="gender"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                    </Select>
                    {formik.touched.gender && formik.errors.gender && (
                      <FormHelperText error>
                        {formik.errors.gender}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={formik.values.status}
                      size="small"
                      label="Status"
                      name="status"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                    {formik.touched.status && formik.errors.status && (
                      <FormHelperText error>
                        {formik.errors.status}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid
                  item
                  sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}
                >
                  <InputLabel sx={{ mr: 1 }}>Image</InputLabel>
                  <Grid item>
                    {student.img && (
                      <img
                        src={`${constants.baseurl}/img?img_path=/images/${student.img}`}
                        alt=" "
                        style={{
                          width: '250px',
                          height: 'fit-content',
                          objectFit: 'cover',
                          objectPosition: 'center',
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    <input
                      id="btn-upload"
                      name="btn-upload"
                      type="file"
                      itemType="image/*"
                      onChange={(e) =>
                        formik.setFieldValue(
                          'img',
                          e.target.files ? e.target.files[0] : null
                        )
                      }
                    />
                    {formik.touched.img && formik.errors.img && (
                      <FormHelperText error>{formik.errors.img}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                  fullWidth
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditStudentForm;
