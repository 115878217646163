/* eslint-disable no-loop-func */
import axios, { AxiosError } from 'axios';
import { createContext, ReactNode, useEffect, useState } from 'react';
import authService from '../../features/auth/service/authService';
import { StudentFixedPaymentCreate } from '../../features/paymentLatest/fixed/fixedPayment';
import { StudentRecurrentPaymentCreate } from '../../features/paymentLatest/recurrent/recurrent';
import { StageStorage } from '../service/storage.service';
import constants from '../utils/constants';
import { logger } from '../utils/logger';
import { toastError, toastMessage } from '../utils/ui/alert';
import { StagePaymentInstance } from './stage.types';

type PaymentRemovable = { index: number; successId: string };

type StagePaymentType = {
  payments: StagePaymentInstance[];
  successfulPayments: string[];
  formSubmitting: boolean;

  clearStage: () => void;
  addPayment: (p: StagePaymentInstance) => void;
  removePayment: (id: string) => void;
  submitForm: () => void;
};

export const StagePaymentContext = createContext<StagePaymentType>({
  payments: [],
  successfulPayments: [],
  formSubmitting: false,
  clearStage: () => {},
  addPayment: (_) => {},
  removePayment: (_) => {},
  submitForm: () => {},
});

export const StagePaymentProvider = ({ children }: { children: ReactNode }) => {
  const { payments: paymentsInit, successfulPayments: successfulInit } =
    StageStorage.load();
  const [payments, setPayments] =
    useState<StagePaymentInstance[]>(paymentsInit);
  const [successfulPayments, setSuccessfulPayments] = useState(successfulInit);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const clearStage = () => {
    setPayments([]);
    StageStorage.remove();
  };

  const removePayment = (id: string) => {
    logger('remove', id);
    const filtered = payments.filter((i) =>
      i.type === 'fixed'
        ? (i.payment as StudentFixedPaymentCreate).fixed_payment_pending_id !==
          id
        : (i.payment as StudentRecurrentPaymentCreate)
            .recurrent_payment_pending_id !== id
    );

    setPayments(filtered);
    StageStorage.save(filtered, successfulPayments);
  };

  const addPayment = (payment: StagePaymentInstance) => {
    const newPayment = { ...payment, error: '', submitting: false };
    setPayments([...payments, newPayment]);
    StageStorage.save([...payments, newPayment], successfulPayments);
  };

  const _setPaymentError = (index: number, err: string) => {
    const curPayments = [...payments];
    curPayments[index].error = err;
    setPayments(curPayments);
  };

  const _setPaymentSubmitting = (index: number, submitting: boolean) => {
    const curPayments = [...payments];
    curPayments[index].submitting = submitting;
    setPayments(curPayments);
  };

  const _getPaymentRemove = (index: number): PaymentRemovable => {
    const curPayments = [...payments];
    curPayments.splice(index, 1);

    let successId = '';
    if (payments[index].type === 'fixed') {
      successId = (payments[index].payment as StudentFixedPaymentCreate)
        .fixed_payment_pending_id;
    } else {
      successId = (payments[index].payment as StudentRecurrentPaymentCreate)
        .recurrent_payment_pending_id;
    }

    return { index, successId };
  };

  const _removePayments = (removableItems: PaymentRemovable[]) => {
    const removableIds = removableItems.map((i) => i.index);
    const curPayments = payments.filter((_, i) => !removableIds.includes(i));

    setPayments(curPayments);
    setSuccessfulPayments([
      ...successfulPayments,
      ...removableItems.map((i) => i.successId),
    ]);
  };

  const submitForm = async () => {
    const curPayments = [...payments];
    const resetPayments = curPayments.map((payment) => ({
      ...payment,
      error: '',
    }));
    setPayments(resetPayments);
    setFormSubmitting(true);
    const removeItems: PaymentRemovable[] = [];
    let hasError = false;

    for (let i = 0; i < payments.length; i++) {
      _setPaymentSubmitting(i, true);

      try {
        const endpoint =
          payments[i].type === 'fixed'
            ? 'payment-latest/fixed/student-payment'
            : 'payment-latest/recurrent/student-payment';

        await axios.post(
          `${constants.apiUrl}/${endpoint}`,
          payments[i].payment,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${authService.getToken()}`,
            },
          }
        );

        removeItems.push(_getPaymentRemove(i));
      } catch (e) {
        hasError = true;
        let errMessage = '';
        const apiErr = e as AxiosError;
        if (apiErr.response && apiErr.response?.status === 422) {
          errMessage = Object.entries(apiErr.response!.data.errors)
            .map(([key, value]) => `${key}: ${value}`)
            .join(', ');
        } else {
          errMessage = apiErr.message || 'Unknown error occurred';
        }
        _setPaymentError(i, errMessage);
      }

      _setPaymentSubmitting(i, false);
    }

    _removePayments(removeItems);

    setFormSubmitting(false);

    if (hasError) {
      toastError('Some payments were not submitted');
    } else {
      toastMessage('All Payments submitted successfully');
    }
  };

  useEffect(() => {
    if (payments.length > 0 || successfulPayments.length > 0) {
      StageStorage.save(payments, successfulPayments);
    }
  }, [payments, successfulPayments]);

  return (
    <StagePaymentContext.Provider
      value={{
        payments,
        successfulPayments,
        formSubmitting,
        clearStage,
        removePayment,
        addPayment,
        submitForm,
      }}
    >
      {children}
    </StagePaymentContext.Provider>
  );
};
