import { Grid, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Student } from '../../../features/academic/student/student';
import { getImageUrl } from '../../utils/image';

const StudentPreview = ({ student }: { student: Student }) => {
  const [showPreview, setShowPreview] = useState(false);

  let color = '#4d4d4d';
  let status = 'Unknown';
  if (student.gradeStudents?.length) {
    color = student.gradeStudents[0].active ? '#176592' : '#c75258';
    status = student.gradeStudents[0].active ? 'Active' : 'Inactive';
  }

  return (
    <>
      <Link to={`/academic/student/edit/${student.id}`} target="_blank">
        <Tooltip title={`Status ${status}`}>
          <Grid
            display="flex"
            alignItems="center"
            style={{ cursor: 'pointer' }}
          >
            <Grid sx={{ position: 'relative', height: '25px' }}>
              <img
                src={getImageUrl(student.img)}
                height="25px"
                width="25px"
                alt=""
                style={{
                  objectFit: 'cover',
                  objectPosition: 'center',
                  borderRadius: '12px',
                }}
                onMouseEnter={() => setShowPreview(true)}
                onMouseLeave={() => setShowPreview(false)}
              />

              <Grid
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: -10,
                  width: 200,
                  transform: 'translate(100%, -50%)',
                  opacity: showPreview ? 1 : 0.5,
                  visibility: showPreview ? 'visible' : 'hidden',
                  transition: 'all 0.2s ease',
                }}
              >
                <img src={getImageUrl(student.img)} width="250px" alt="" />
              </Grid>
            </Grid>
            <Typography color={color} sx={{ ml: 1 }}>
              {student.first_name} {student.father_name}
            </Typography>
          </Grid>
        </Tooltip>
      </Link>
    </>
  );
};

export default StudentPreview;
