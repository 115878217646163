import { Grid, Typography } from '@mui/material';
import { timeElapsed } from '../../utils/utility';

type DateModifiedProps = {
  createdAt: string;
  updatedAt: string;
};
const DateModified = ({ createdAt, updatedAt }: DateModifiedProps) => {
  return (
    <>
      <Grid display="flex" flexDirection="column" justifyContent="space-evenly">
        <Grid display="flex">
          <Typography
            variant="body1"
            fontSize={12}
            fontWeight="bold"
            sx={{ mr: 1, width: '52px' }}
          >
            Created:
          </Typography>
          <Typography variant="body1" fontSize={12}>
            {timeElapsed(createdAt)}
          </Typography>
        </Grid>
        <Grid display="flex">
          <Typography
            variant="body1"
            fontSize={12}
            fontWeight="bold"
            sx={{ mr: 1, width: '52px' }}
          >
            Updated:
          </Typography>
          <Typography variant="body1" fontSize={12}>
            {timeElapsed(updatedAt)}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default DateModified;
