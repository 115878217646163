/* eslint-disable react-hooks/exhaustive-deps */
import { PictureAsPdf } from '@mui/icons-material';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../../core/service/globalContext';
import Layout from '../../../../../core/ui/layout/Layout';
import ShowIfHas from '../../../../../core/ui/permission/ShowIfHas';
import Loader from '../../../../../core/ui/utility/Loader';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';
import constants from '../../../../../core/utils/constants';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { AcademicYear } from '../../../academicYear/academicYear';
import { Grade } from '../../../grade/grade';
import { Cst } from '../../cst/cst';
import { sortCsts } from '../../cst/services/cstService';
import { Semester } from '../../semester/semester';
import YearReportCardTable from '../components/YearReportCardTable';
import { StudentForRcy } from '../reportCard';
import {
  formatMark,
  formatNursery,
  parseStudentForRcy,
} from '../services/reportCardService';
import { useLoadMessages } from '../../../../textMessages/lib/hooks/useLoadMessages';

const YearReportCardContainer = () => {
  const { gradeId } = useParams();
  const { activeYear } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [grade, setGrade] = useState<Grade | null>(null);
  const [year, setYear] = useState<AcademicYear | null>(null);
  const [csts, setCsts] = useState<Cst[]>([]);
  const [students, setStudents] = useState<StudentForRcy[]>([]);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [semesterMarkMap, setSemesterMarMap] = useState({});
  const [quarterMarkMap, setQuarterMarkMap] = useState({});
  const [rcsMap, setRcsMap] = useState({});
  const [rcqMap, setRcqMap] = useState({});

  const { fetchData } = useFetchApiData();
  const { callApi, loading: generating } = useSendApiData();
  const { callApi: callSmsApi, loading: smsGenerating } = useSendApiData();
  const resourceId = `year-report-${activeYear?.id}-${grade?.id}`;

  const { sent, total, loadMessages } = useLoadMessages(resourceId);

  const fetchReportData = async () => {
    setLoading(true);
    await fetchData(
      `academic/marklist/report-card/rcys/grade/${gradeId}/year`,
      {
        onError: () => toastError('Something went wrong, refresh to try again'),
        onSuccess: (data) => {
          setGrade(data.grade);
          setYear(data.year);
          setCsts(sortCsts(data.csts));
          setSemesters(data.semesters);
          setSemesterMarMap(data.semesterMarkMap);
          setQuarterMarkMap(data.marklistMap);
          setRcsMap(data.rcsMap);
          setRcqMap(data.rcqMap);

          setStudents(parseStudentForRcy(data.students, data.mark, data.rcys));
        },
      }
    );
    setLoading(false);
  };

  const generateReport = async () => {
    await callApi({
      endpoint: `academic/marklist/report-card/rcys/generate/class/${gradeId}/${
        activeYear!.id
      }`,
      data: {},
      method: 'post',
      onSuccess: () => {
        toastMessage('Report Generated');
        fetchReportData();
      },
      onError: toastError,
    });
  };

  const _getStudentMessage = (student: StudentForRcy) => {
    const cstMap: Record<string, Cst> = csts.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur }),
      {}
    );
    const marks = csts
      .map(
        (cst) =>
          `${cst.subject.subject}: ${formatMark(
            student.cstScore[cst.id],
            cstMap[cst.id].subject.display_mode,
            cstMap[cst.id].subject.display_rules
          )}`
      )
      .join('\n');

    const summary =
      grade?.report_card_template === 'nursery'
        ? `Average: ${formatNursery(student.rcy?.average) || 'N\\A'}`
        : `Total Mark: ${student.rcy?.total_score || 'N\\A'}
Average: ${student.rcy?.average || 'N\\A'}
Rank: ${student.rcy?.rank || 'N\\A'}`;

    const template = `Dear Parent,
Your child ${student.first_name} ${student.father_name || ''} ${
      grade?.name || ''
    } Report for Year ${activeYear?.year} is

${marks}

${summary}

Strivers Academy
0118 829390/91
0911 685076`;

    return {
      message: template,
      entity_id: student.rcy?.id,
      phone: student.primary_phone,
      parent_resource_id: resourceId,
      msg_type: 'Report',
      msg_tags: ['Year', 'Report'],
    };
  };

  const generateSms = async () => {
    console.log('generate', resourceId);

    const data = students
      .filter((i) => i.primary_phone && i.rcy?.id)
      .map(_getStudentMessage);

    if (!data.length) {
      toastError(
        'No data found (report may not be generated, or students may not have valid phone)'
      );
      return;
    }
    await callSmsApi({
      endpoint: 'text-messages',
      data: {
        data,
      },
      method: 'post',
      onSuccess: () => {
        toastMessage('Text messages generated');
      },
      onError: toastError,
    });
    loadMessages();
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  return (
    <>
      <Layout renderLeftToolbar={() => <ReturnButton to="/academic/roster" />}>
        <Box sx={{ p: 2 }}>
          <Loader loading={loading}>
            <Box
              sx={{
                border: '1px solid lightblue',
                borderColor: 'grey.400',
                borderLeft: '5px solid',
                borderLeftColor: '#1565C0',
                cursor: 'pointer',
                p: 2,
                maxWidth: 300,
                mb: 2,
              }}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Grid item display="flex" justifyContent="space-between">
                <Typography>Roster for </Typography>
                <Typography variant="body2" fontWeight={700}>
                  {grade?.name}
                </Typography>
              </Grid>

              <Grid item display="flex" justifyContent="space-between">
                <Typography>Year</Typography>
                <Typography variant="body2" fontWeight={700}>
                  {year?.year}
                </Typography>
              </Grid>

              <Grid
                item
                display="flex"
                justifyContent="space-between"
                sx={{ mt: 2 }}
              >
                <ShowIfHas permissions={['add-rcy']}>
                  <Button
                    variant="outlined"
                    onClick={() => generateReport()}
                    disabled={generating}
                  >
                    Generate
                  </Button>
                </ShowIfHas>
              </Grid>
              <Grid item sx={{ mt: 1 }}>
                <a
                  href={`${constants.apiUrl}/academic/marklist/report-card/generate-pdf/${gradeId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Grid display="flex" alignItems="flex-end">
                    <PictureAsPdf sx={{ mr: 1 }} color="primary" />
                    <Typography color="primary">Print Roster</Typography>
                  </Grid>
                </a>
              </Grid>

              <Grid
                item
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 3 }}
              >
                <Typography variant="h6">Msgs Sent</Typography>

                <Typography variant="body2" fontWeight={700}>
                  ({sent} / {total})
                </Typography>
              </Grid>

              <Grid
                item
                display="flex"
                justifyContent="space-between"
                sx={{ mt: 1 }}
              >
                <Button
                  variant="outlined"
                  onClick={() => generateSms()}
                  disabled={smsGenerating}
                >
                  Generate Sms
                </Button>
              </Grid>
            </Box>
            <Loader loading={generating}>
              <YearReportCardTable
                students={students}
                csts={csts}
                semesters={semesters}
                semesterMarkMap={semesterMarkMap}
                quarterMarkMap={quarterMarkMap}
                rcsMap={rcsMap}
                rcqMap={rcqMap}
              />
            </Loader>
          </Loader>
        </Box>
      </Layout>
    </>
  );
};

export default YearReportCardContainer;
