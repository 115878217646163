import { KeyboardArrowLeft } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type ReturnButtonProps = {
  to: string;
  text?: string;
};

const ReturnButton = ({ to, text }: ReturnButtonProps) => {
  return (
    <>
      <IconButton
        aria-label="return back"
        component={Link}
        to={to}
        replace={true}
        sx={{ borderRadius: 2 }}
      >
        <KeyboardArrowLeft fontSize="large" sx={{ height: '100%' }} />

        {text && <Typography fontSize={12}>{text}</Typography>}
      </IconButton>
    </>
  );
};

export default ReturnButton;
