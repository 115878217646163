import { StagePaymentInstance } from '../state/stage.types';

const StageKey = 'stagePayment';

export const StageStorage = {
  save: (payments: StagePaymentInstance[], successfulPayments: string[]) => {
    localStorage.setItem(
      StageKey,
      JSON.stringify({
        payments: payments.map((payment) => ({
          ...payment,
          error: '',
          submitting: false,
        })),
        successfulPayments,
      })
    );
  },
  load: (): {
    payments: StagePaymentInstance[];
    successfulPayments: string[];
  } => {
    const saved = localStorage.getItem(StageKey);
    if (saved) {
      return JSON.parse(saved) as {
        payments: StagePaymentInstance[];
        successfulPayments: string[];
      };
    }

    return { payments: [], successfulPayments: [] };
  },
  remove: () => {
    // TODO: Fix remove bug
    localStorage.removeItem(StageKey);
  },
};
