import React from 'react';
import { ReactChildren } from '../../utils/types';
import Loading from './Loading';

type LoaderProps = {
  loading: boolean;
  loaderComponent?: React.ReactElement;
} & ReactChildren;

const Loader = ({
  children,
  loading,
  loaderComponent = <Loading />,
}: LoaderProps) => {
  return <>{loading ? loaderComponent : children}</>;
};

export default Loader;
