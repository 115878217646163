import { Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import GlobalContext from '../../../../../core/service/globalContext';
import Layout from '../../../../../core/ui/layout/Layout';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';
import { toastError } from '../../../../../core/utils/ui/alert';
import CSTHeader from '../components/CSTHeader';
import CSTList from '../components/CSTList';
import { Cst } from '../cst';

const CSTContainer = () => {
  const [csts, setCsts] = useState<Cst[]>([]);
  const { gradeId } = useParams();
  const { grades } = useContext(GlobalContext);
  const grade = grades.find((item) => item.id === gradeId)!;

  const { fetchData, loading } = useFetchApiData();

  const fetchCsts = () =>
    fetchData(`academic/marklist/csts/grade/${grade.id}`, {
      onSuccess: (data) => {
        setCsts(data);
      },
      onError: () => {
        toastError('Something went wrong, refresh page to try again');
      },
    });

  useEffect(() => {
    fetchCsts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout
        renderLeftToolbar={() => (
          <ReturnButton to="/academic/marklist/cst" text="Back to grade list" />
        )}
      >
        <Box sx={{ p: 2 }}>
          <CSTHeader grade={grade} onSuccess={fetchCsts} />

          <CSTList csts={csts} fetchCsts={fetchCsts} loading={loading} />
        </Box>
      </Layout>
    </>
  );
};

export default CSTContainer;
