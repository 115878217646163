import Layout from '../../../../../core/ui/layout/Layout';
import RosterReport from '../components/report/RosterReport';

const RosterReportPage = () => {
  return (
    <>
      <Layout>
        <RosterReport />
      </Layout>
    </>
  );
};

export default RosterReportPage;
