import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Loader from '../../../../../core/ui/utility/Loader';
import { SubjectEdit, Subject } from '../subject';
import EditSubjectForm from '../components/EditSubjectForm';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';

const EditSubjectContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [subject, setSubject] = useState<Subject | null>(null);

  const fetchSubject = () =>
    fetchData(`academic/marklist/subjects/${id}`, {
      onSuccess: (data: Subject) => {
        setSubject(data);
      },
    });

  useEffect(() => {
    fetchSubject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: SubjectEdit,
    { setFieldError }: FormikHelpers<SubjectEdit>
  ) => {
    let success = false;

    await callApi({
      endpoint: `academic/marklist/subjects/${id}`,
      method: 'patch',
      data: {
        ...values,
        display_rules: JSON.stringify({
          A: values.A,
          B: values.B,
          C: values.C,
          D: values.D,
        }),
      },
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        success = true;
        toastMessage('Subject Edited');
      },
    });

    if (success) {
      navigate('/academic/subject', { replace: true });
    }
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/subject" />}>
      <Loader loading={loading || !subject}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Subject</Typography>
          </Grid>
          <EditSubjectForm
            subject={subject!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditSubjectContainer;
