import { FormikHelpers, useFormik } from 'formik';
import { RegistrationEdit, Registration } from '../registration';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
  Paper,
  Box,
  Button,
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';

type RegistrationProps = {
  onSubmit: (
    value: RegistrationEdit,
    helpers: FormikHelpers<RegistrationEdit>
  ) => Promise<void>;
  submitting: boolean;
  registration: Registration;
};

const EditRegistrationForm = ({
  onSubmit,
  submitting,
  registration,
}: RegistrationProps) => {
  const handleSubmit = async (
    value: RegistrationEdit,
    helpers: FormikHelpers<RegistrationEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: RegistrationEdit = {
    fee: registration.fee,
    attachment: registration.attachment,
    fs: registration.fs,
    slip_date: new Date(registration.slip_date),
    remark: registration.remark,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="fee"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Fee"
                      placeholder="Fee"
                      value={formik.values.fee}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.fee)}
                      helperText={formik.errors.fee}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="attachment"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Attachment"
                      placeholder="Attachment"
                      value={formik.values.attachment}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.attachment)}
                      helperText={formik.errors.attachment}
                    />
                  </Grid>

                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="fs"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Fs"
                      placeholder="Fs"
                      value={formik.values.fs}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.fs)}
                      helperText={formik.errors.fs}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel>Method</InputLabel>
                      <Select
                        size="small"
                        label="Method"
                        onChange={formik.handleChange}
                        name="method"
                      >
                        <MenuItem value="cash">Cash</MenuItem>
                        <MenuItem value="slip">Slip</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      flexGrow: 1,
                      padding: '0 !important',
                    }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        paddingLeft: '8px',
                      }}
                    >
                      <Typography sx={{ p: 0, mr: 1 }}>Slip Date</Typography>
                      <Grid item alignItems="flex-end">
                        <DateTimePicker
                          onChange={(e) => {
                            formik.setFieldValue('slip_date', e);
                          }}
                          value={formik.values.slip_date || new Date()}
                          disableClock
                          format="y-MM-dd"
                        />
                      </Grid>
                    </div>
                    {formik.touched.slip_date && formik.errors.slip_date && (
                      <FormHelperText error>
                        {formik.errors.slip_date}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="remark"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Remark(*optional)"
                      placeholder="Remark(*optional)"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.remark)}
                      helperText={formik.errors.remark}
                    />
                  </Grid>

                  <Grid item sx={{ flexGrow: 1 }}></Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditRegistrationForm;
