import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Cst } from '../../cst/cst';
import { Quarter } from '../../quarter/quarter';
import { Semester } from '../../semester/semester';
import { StudentForRcs } from '../reportCard';
import {
  getQuarterMark,
  getRcq,
  printMark,
} from '../services/reportCardService';

type SemesterReportCardTableProps = {
  students: StudentForRcs[];
  csts: Cst[];
  semester: Semester;
  quarters: Quarter[];
  quarterMarkMap: any;
  rcqMap: any;
};
const SemesterReportCardTable = ({
  students,
  csts,
  quarters,
  semester,
  quarterMarkMap,
  rcqMap,
}: SemesterReportCardTableProps) => {
  return (
    <>
      <Box sx={{ mt: 4 }}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 1000, maxWidth: 1600 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell sx={{ width: '230px' }}>Student</TableCell>
                <TableCell></TableCell>
                {csts.map((cst) => (
                  <TableCell key={cst.id}>{cst.subject.subject}</TableCell>
                ))}
                <TableCell>Total</TableCell>
                <TableCell>Average</TableCell>
                <TableCell>Rank</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student, i) => (
                <TableRow key={student.id} sx={{ py: 0 }}>
                  <TableCell size="small">{i + 1}</TableCell>
                  <TableCell>
                    <Grid>
                      {student.first_name} {student.father_name}
                    </Grid>
                    {!student.primary_phone && (
                      <Grid mt="4px">
                        <Chip label="No phone" color="warning" size="small" />
                      </Grid>
                    )}
                  </TableCell>
                  <TableCell sx={{ py: 0, width: '150px' }}>
                    <Table>
                      <TableBody>
                        {quarters.map((quarter, i) => (
                          <TableRow key={`quarter-${i}`}>
                            <TableCell sx={{ py: 1 }}>
                              Quarter {quarter.quarter}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell sx={{ py: 1 }}>
                            Semester {semester.semester}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>

                  {csts.map((cst) => (
                    <TableCell
                      key={`${student.id} ${cst.id}`}
                      sx={{ py: 0, px: 0 }}
                    >
                      <Table>
                        <TableBody>
                          {quarters.map((quarter, i) => (
                            <TableRow key={`quarter-mark-${i}`}>
                              <TableCell sx={{ py: 1 }}>
                                {printMark(
                                  getQuarterMark(
                                    quarterMarkMap,
                                    student.gradeStudents[0].id,
                                    cst.id,
                                    quarter.id
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell sx={{ py: 1 }}>
                              {printMark(student.cstScore[cst.id])}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  ))}

                  <TableCell sx={{ py: 0 }}>
                    <Table>
                      <TableBody>
                        {quarters.map((quarter, i) => (
                          <TableRow key={`total-${i}`}>
                            <TableCell sx={{ py: 1 }}>
                              {printMark(
                                getRcq(
                                  rcqMap,
                                  student.gradeStudents[0].id,
                                  quarter.id
                                )[0]
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell sx={{ py: 1 }}>
                            {printMark(student?.rcs?.total_score)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>

                  <TableCell sx={{ py: 0 }}>
                    <Table>
                      <TableBody>
                        {quarters.map((quarter, i) => (
                          <TableRow key={`total-${i}`}>
                            <TableCell sx={{ py: 1 }}>
                              {printMark(
                                getRcq(
                                  rcqMap,
                                  student.gradeStudents[0].id,
                                  quarter.id
                                )[1]
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell sx={{ py: 1 }}>
                            {printMark(student?.rcs?.average)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>

                  <TableCell sx={{ py: 0 }}>
                    <Table>
                      <TableBody>
                        {quarters.map((quarter, i) => (
                          <TableRow key={`total-${i}`}>
                            <TableCell sx={{ py: 1 }}>
                              {
                                getRcq(
                                  rcqMap,
                                  student.gradeStudents[0].id,
                                  quarter.id
                                )[2]
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell sx={{ py: 1 }}>
                            {student?.rcs?.rank || 'N/A'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default SemesterReportCardTable;
