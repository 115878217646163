import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "../../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../../core/utils/validation";
import useFetchApiData from "../../../../core/hooks/useFetchApiData";
import Loader from "../../../../core/ui/utility/Loader";
import { RegistrationEdit, Registration } from "../registration";
import EditRegistrationForm from "../components/EditRegistrationForm";
import ReturnButton from "../../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../../core/utils/utility";

const EditRegistrationContainer = () => {
  const { id } = useParams();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [registration, setRegistration] = useState<Registration | null>(null);

  const fetchRegistration = () =>
    fetchData(`finance/payment-new/registration-payments/${id}`, {
      onSuccess: (data: Registration) => {
        setRegistration(data);
      },
    });

  useEffect(() => {
    fetchRegistration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: RegistrationEdit,
    { setFieldError }: FormikHelpers<RegistrationEdit>
  ) => {
    const formData: any = parseFormQuery(values, ["slip_date"])

    await callApi({
      endpoint: `finance/payment-new/registration-payments/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        await fetchRegistration;
        toastMessage('Registration Edited');
      },
    });
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/payment/registration" />}>
      <Loader loading={loading || !registration}>
        <Grid sx={{ p: 2 }}>  
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Registration</Typography>
          </Grid>
          <EditRegistrationForm registration={registration!} onSubmit={handleSubmit} submitting={submitting} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditRegistrationContainer;
