import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import Layout from '../../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Loader from '../../../../../core/ui/utility/Loader';
import { QuarterEdit, Quarter } from '../quarter';
import EditQuarterForm from '../components/EditQuarterForm';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../../../core/utils/utility';
import GlobalContext from '../../../../../core/service/globalContext';

const EditQuarterContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [quarter, setQuarter] = useState<Quarter | null>(null);
  const { fetchGlobalData } = useContext(GlobalContext);

  const fetchQuarter = () =>
    fetchData(`academic/marklist/quarters/${id}`, {
      onSuccess: (data: Quarter) => {
        setQuarter(data);
      },
    });

  useEffect(() => {
    fetchQuarter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: QuarterEdit,
    { setFieldError }: FormikHelpers<QuarterEdit>
  ) => {
    let success = false;
    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `academic/marklist/quarters/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        // await fetchQuarter();
        toastMessage('Quarter Edited');
        success = true;
      },
    });

    if (success) {
      await fetchGlobalData({ enableLoading: false });
      navigate('/academic/quarter');
    }
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/quarter" />}>
      <Loader loading={loading || !quarter}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Quarter</Typography>
          </Grid>
          <EditQuarterForm
            quarter={quarter!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditQuarterContainer;
