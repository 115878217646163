import { PriorityHigh } from '@mui/icons-material';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import StudentPreview from '../../../../../core/ui/utility/StudentPreview';
import { Cst } from '../../cst/cst';
import { EvaluationMethod } from '../../evaluationMethod/evaluationMethod';
import { Quarter } from '../../quarter/quarter';
import { getMarkSum } from '../services/smlService';
import { StudentsForMarklist } from '../sml';
import MarkInlineForm from './MarkInlineForm';

type SmlStudentListProps = {
  cst: Cst;
  quarter: Quarter;
  evaluationMethods: Record<string, EvaluationMethod>;
  students: StudentsForMarklist[];
  fetchCstAndStudents: () => Promise<void>;
  updateScore: (
    studentIndex: number,
    evalMethodId: string,
    score: number
  ) => void;
};

const MarkDisplay = ({ score }: { score: number }) => (
  <>
    {score > 100 ? (
      <Tooltip title="Total score must not exceed 100">
        <Grid display="flex">
          <Typography color="error">{score}</Typography>
          <PriorityHigh color="error" />
        </Grid>
      </Tooltip>
    ) : (
      <Typography>{score}</Typography>
    )}
  </>
);

const SmlStudentList = ({
  cst,
  quarter,
  evaluationMethods,
  students,
  // fetchCstAndStudents,
  updateScore,
}: SmlStudentListProps) => {
  // const evalMethodsSorted = sortEvaluationMethods(
  //   Object.values(evaluationMethods)
  // );

  return (
    <>
      <Box sx={{}}>
        <Box
          sx={{
            border: '1px solid lightblue',
            borderColor: 'grey.400',
            borderLeft: '5px solid',
            borderLeftColor: '#1565C0',
            cursor: 'pointer',
            p: 2,
            maxWidth: 300,
            mb: 2,
          }}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Grid item display="flex" justifyContent="space-between">
            <Typography>Marklist for </Typography>
            <Typography variant="body2" fontWeight={700}>
              {cst.grade.name}
            </Typography>
          </Grid>

          <Grid item display="flex" justifyContent="space-between">
            <Typography>Subject </Typography>
            <Typography variant="body2" fontWeight={700}>
              {cst.subject.subject}
            </Typography>
          </Grid>

          <Grid item display="flex" justifyContent="space-between">
            <Typography>Quarter</Typography>
            <Typography variant="body2" fontWeight={700}>
              {quarter.quarter}
            </Typography>
          </Grid>
        </Box>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 500, maxWidth: 1000 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell>Student</TableCell>
                {Object.keys(evaluationMethods).map((key) => (
                  <TableCell key={evaluationMethods[key].id}>
                    {evaluationMethods[key].evaluationType.name} -
                    {evaluationMethods[key].evaluationType.weight}
                  </TableCell>
                ))}
                <TableCell>Total</TableCell>
                {/* <TableCell align="right">Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student, i) => (
                <TableRow key={student.id}>
                  <TableCell size="small">{i + 1}</TableCell>
                  <TableCell>
                    <StudentPreview student={student} />
                  </TableCell>
                  {Object.keys(evaluationMethods).map((key) => (
                    <TableCell
                      key={`${student.id}-${evaluationMethods[key].id}`}
                      sx={{ py: 0 }}
                    >
                      <MarkInlineForm
                        gradeStudentId={student.gradeStudentId}
                        evaluationMethodId={evaluationMethods[key].id}
                        score={student.score[evaluationMethods[key].id]}
                        smlId={
                          (student as any)?.smlMap[evaluationMethods[key].id]
                        }
                        updateMark={(score) => {
                          updateScore(i, evaluationMethods[key].id, score);
                        }}
                      />
                    </TableCell>
                  ))}
                  <TableCell>
                    <MarkDisplay
                      score={getMarkSum(Object.values(student.score))}
                    />
                  </TableCell>
                  {/* <TableCell align="right">
                    <CreateMarklistModal
                      gradeStudentId={student.gradeStudentId}
                      evaluationMethods={evalMethodsSorted}
                      studentName={`${student.first_name} ${student.father_name}`}
                      fetchCstAndStudents={fetchCstAndStudents}
                      studentMarks={student.score}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default SmlStudentList;
