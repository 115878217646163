/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetPaginated from '../../../../core/hooks/useGetPaginated';
import constants from '../../../../core/utils/constants';
import { RecurrentStudentPayment } from '../models/recurrentStudentPayment';

const useRecurrentPaymentSearch = () => {
  const { id } = useParams();
  const {
    data: recurrentPayments,
    fetchData,
    loading,
    pagination,
  } = useGetPaginated<RecurrentStudentPayment>(
    constants.keys.recurrentPaymentSearch
  );
  const [apiQuery, setApiQuery] = useState('');

  useEffect(() => {
    fetchData(
      `payment-latest/recurrent/student-payment/${id}/search`,
      apiQuery
    );
  }, [pagination.data.page, pagination.data.rowsPerPage, apiQuery]);

  const handleSearch = (filter: Record<string, any>) => {
    pagination.handlers.handleChangePage(null, 0);
    const parsed = Object.entries(filter)
      .filter(([_, val]) => !!val)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    // console.log('search', parsed);
    if (parsed !== apiQuery) {
      setApiQuery(parsed);
    }
  };

  return { recurrentPayments, loading, pagination, apiQuery, handleSearch };
};

export default useRecurrentPaymentSearch;
