/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { Clear, DeleteForever } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FixedPayment,
  FixedPending,
  StudentFixedPaymentCreate,
} from '../../fixedPayment';
import useFetchSingleData from '../../../../../core/hooks/useFetchSingleData';
import Loader from '../../../../../core/ui/utility/Loader';
import AddStudentFixedPaymentContainer from '../../container/AddStudentFixedPaymentContainer';
import DeletePendingFixedDialog from '../dialogs/DeletePendingFixedDialog';
import { StagePaymentContext } from '../../../../../core/state/StagePaymentContext';
import StageStudentFixedForm from '../forms/StageStudentFixedForm';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import StudentPreview from '../../../../../core/ui/utility/StudentPreview';

type FixedWithPending = FixedPayment & {
  fixedPendings: FixedPending[];
};

type StagePending = {
  student: string;
  grade: string;
  pending: FixedPending;
  amount: number;
  penalty: number;
};

const PendingFixedPayment = () => {
  const mounted = useRef(false);
  const [deletePending, setDeletePending] = useState<FixedPending | null>(null);
  const [addFixed, setAddFixed] = useState<FixedPending | null>(null);
  const [stageFixed, setStageFixed] = useState<StagePending | null>(null);
  const { id } = useParams();
  const { data, setData, loading, fetchData } =
    useFetchSingleData<FixedWithPending>();
  const [filter, setFilter] = useState('');
  const { payments, successfulPayments, addPayment } =
    useContext(StagePaymentContext);
  const paymentIds = payments.map(
    (i) => (i.payment as StudentFixedPaymentCreate).fixed_payment_pending_id
  );

  // Pagination stuff
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData(`payment-latest/fixed/with-pending/${id}`, {
      onSuccess: (data) => {},
    });

    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (filter !== '' && mounted.current === true) {
      setPage(0);
    }
  }, [filter]);

  const getPendingPayments = () =>
    (data?.fixedPendings || [])
      .filter(
        (item) =>
          !paymentIds.includes(item.id) && !successfulPayments.includes(item.id)
      )
      .sort((a, b) => a.student.first_name.localeCompare(b.student.first_name))
      .filter(
        ({ student, grade }) =>
          filter === '' ||
          (student.first_name + ' ' + student.father_name).match(
            new RegExp(`.*${filter}.*`, 'i')
          ) ||
          grade.name.match(new RegExp(`.*${filter}.*`, 'i'))
      );

  const removePending = (pendingId: string) => {
    setAddFixed(null);
    const fixedIndex = data?.fixedPendings.findIndex((i) => i.id === pendingId);

    if (fixedIndex !== undefined && fixedIndex !== -1) {
      const curPayment = _.cloneDeep(data);
      curPayment?.fixedPendings.splice(fixedIndex, 1);
      setData(curPayment);
    }
  };

  return (
    <>
      <Loader loading={loading || !data}>
        <Box>
          <Paper sx={{ p: 2, py: 4 }}>
            <Grid
              sx={{
                my: 1,
                py: 1,
                px: 2,
                bgcolor: 'white',
                filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                width: 'fit-content',
                borderLeft: '3px solid',
                borderLeftColor: 'primary.main',
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
                Pending student payments {data?.description}
              </Typography>

              <Typography fontWeight={600}>
                Remaining Payments {getPendingPayments().length}
              </Typography>
            </Grid>

            <Grid sx={{ my: 2 }}>
              <TextField
                size="small"
                placeholder="Filter by name or grade"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setFilter('')}>
                        <Clear fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <TableContainer
              sx={{ overflowX: 'auto', maxWidth: 800, mt: 2 }}
              component={Paper}
            >
              <Table
                sx={{ minWidth: 500 }}
                aria-label="custom pagination table"
                size="small"
              >
                <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                  <TableRow>
                    <TableCell>Student</TableCell>
                    <TableCell>Grade</TableCell>
                    <TableCell>Payment</TableCell>
                    <TableCell>Discount</TableCell>
                    <TableCell align="right" sx={{ width: 96 }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? getPendingPayments().slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : getPendingPayments()
                  ).map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell size="small" id={payment.id}>
                        <StudentPreview student={payment.student} />
                      </TableCell>
                      <TableCell size="small">{payment.grade.name}</TableCell>
                      <TableCell size="small">{data?.amount}</TableCell>
                      <TableCell size="small">
                        {payment.discount_amount}
                      </TableCell>
                      <TableCell align="right" sx={{ width: 96 }}>
                        <Grid display="flex">
                          <Button
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => setAddFixed(payment)}
                            disabled={payments.length > 0}
                          >
                            Add
                          </Button>
                          <Button
                            size="small"
                            onClick={() => {
                              setStageFixed({
                                pending: payment,
                                student: `${payment.student.first_name} 
                        ${payment.student.father_name}`,
                                grade: payment.grade.name,
                                amount: data?.amount || 0,
                                penalty: 0,
                              });
                            }}
                          >
                            Stage
                          </Button>

                          <Tooltip title="Delete Pending Payment">
                            <DeleteForever
                              color="warning"
                              sx={{ ml: 1, cursor: 'pointer' }}
                              onClick={() => setDeletePending(payment)}
                            />
                          </Tooltip>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        15,
                        30,
                        50,
                        { label: 'All', value: -1 },
                      ]}
                      colSpan={3}
                      count={getPendingPayments().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Loader>

      {data !== null && (
        <>
          <Modal
            open={data != null && addFixed !== null}
            onClose={() => setAddFixed(null)}
          >
            <>
              <AddStudentFixedPaymentContainer
                fixedPayment={data?.description || ''}
                fixedPaymentId={data.id}
                fixedPaymentPending={addFixed!}
                onCancel={() => setAddFixed(null)}
                onSuccess={removePending}
              />
            </>
          </Modal>
        </>
      )}

      {stageFixed !== null && (
        <Modal open={stageFixed !== null} onClose={() => setStageFixed(null)}>
          <>
            <StageStudentFixedForm
              fixedPaymentPending={stageFixed.pending}
              onCancel={() => setStageFixed(null)}
              fixedPaymentId={data!.id}
              stagePayment={(payment) => {
                addPayment({
                  payment,
                  type: 'fixed',
                  amount: stageFixed!.amount,
                  penalty: payment.penalty,
                  student: stageFixed.student,
                  grade: stageFixed.grade,
                });
                setStageFixed(null);
              }}
              fs={payments.length === 0 ? null : payments[0].payment.fs}
              attachment={
                payments.length === 0 ? null : payments[0].payment.attachment
              }
            />
          </>
        </Modal>
      )}

      <DeletePendingFixedDialog
        payment={deletePending}
        onSuccess={() => {
          setDeletePending(null);
          fetchData(`payment-latest/fixed/with-pending/${id}`);
        }}
        cancel={() => setDeletePending(null)}
      />
    </>
  );
};

export default PendingFixedPayment;
