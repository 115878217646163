/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { User } from '../../../user/user';

const useFetchNonTeachers = () => {
  const [users, setUsers] = useState<User[]>([]);

  const { fetchData, loading } = useFetchApiData();

  const fetchUsers = () => {
    fetchData('academic/teachers/non-teachers', {
      onSuccess: ({ data }) => {
        setUsers(data);
      },
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return { users, loading, fetchUsers };
};

export default useFetchNonTeachers;
