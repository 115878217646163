import Layout from '../../../../core/ui/layout/Layout';
import ChangeGradeContainer from '../containers/ChangeGradeContainer';

const ChangeGradePage = () => {
  return (
    <>
      <Layout>
        <ChangeGradeContainer />
      </Layout>
    </>
  );
};

export default ChangeGradePage;
