import { Box } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { modalStyle } from '../../../../core/ui/utility/ModalContainer';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { dataParserFactory } from '../../../../core/utils/utility';
import { parseValidationErrors } from '../../../../core/utils/validation';
import AddStudentRecurrentPaymentForm from '../components/forms/AddStudentRecurrentPaymentForm';
import { RecurrentPending, StudentRecurrentPaymentCreate } from '../recurrent';

const AddStudentRecurrentPaymentContainer = ({
  onSuccess,
  onCancel,
  recurrentPayment,
  recurrentPending,
  recurrentPaymentId,
}: {
  onSuccess: (id: string) => void;
  onCancel: () => void;
  recurrentPayment: string;
  recurrentPending: RecurrentPending;
  recurrentPaymentId: string;
}) => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: StudentRecurrentPaymentCreate,
    { setFieldError }: FormikHelpers<StudentRecurrentPaymentCreate>
  ) => {
    let success = false;

    const parsedData = dataParserFactory({ ...values })
      .clearEmptyFields()
      .parseDate(['slip_date']).data;

    await callApi({
      endpoint: 'payment-latest/recurrent/student-payment',
      data: parsedData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async () => {
        toastMessage('Recurrent Payment Created');
        success = true;
      },
    });

    if (success) {
      onSuccess(recurrentPending.id);
    }

    return success;
  };

  return (
    <Box
      sx={{
        ...modalStyle,
        p: 2,
        bgcolor: 'white',
        minWidth: 500,
        overflow: 'auto',
      }}
    >
      <AddStudentRecurrentPaymentForm
        onSubmit={handleSubmit}
        submitting={submitting}
        recurrentPayment={recurrentPayment}
        recurrentPending={recurrentPending}
        onCancel={onCancel}
        recurrentPaymentId={recurrentPaymentId}
      />
    </Box>
  );
};

export default AddStudentRecurrentPaymentContainer;
