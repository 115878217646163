import { DeleteForever } from '@mui/icons-material';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Grade } from '../grade';
import useDeleteHrtController from '../hooks/useDeleteHrtController';

type GradeHrtProps = {
  grade: Grade;
};

const GradeHrt = ({ grade }: GradeHrtProps) => {
  const { deleteHrt, loading } = useDeleteHrtController(
    grade.hrt!.id,
    grade.id
  );

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <Grid container>
          <Paper sx={{ display: 'flex', p: 2 }}>
            <Grid display="flex" flexDirection="column">
              <Typography sx={{ mb: 2 }} variant="h6">
                Home Room teacher
              </Typography>

              <Grid display="flex" alignItems="center">
                <Typography>
                  {grade.hrt!.user.first_name} {grade.hrt!.user.father_name}
                </Typography>

                <IconButton
                  disabled={loading}
                  onClick={deleteHrt}
                  sx={{ ml: 4 }}
                >
                  <DeleteForever color="warning" />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </>
  );
};

export default GradeHrt;
