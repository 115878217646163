/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useGetPaginated from '../../../../core/hooks/useGetPaginated';
import constants from '../../../../core/utils/constants';
import { FixedStudentPayment } from '../models/fixedStudentPayment';

const useFixedPaymentSearch = () => {
  const { id } = useParams();
  const {
    data: fixedPayments,
    fetchData,
    loading,
    pagination,
  } = useGetPaginated<FixedStudentPayment>(constants.keys.fixedPaymentSearch);
  const [apiQuery, setApiQuery] = useState('');

  useEffect(() => {
    fetchData(`payment-latest/fixed/student-payment/${id}/search`, apiQuery);
  }, [pagination.data.page, pagination.data.rowsPerPage, apiQuery]);

  const handleSearch = (filter: Record<string, any>) => {
    pagination.handlers.handleChangePage(null, 0);
    const parsed = Object.entries(filter)
      .filter(([_, val]) => !!val)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');

    // console.log('search', parsed);
    if (parsed !== apiQuery) {
      setApiQuery(parsed);
    }
  };

  return { fixedPayments, loading, pagination, apiQuery, handleSearch };
};

export default useFixedPaymentSearch;
