import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import Layout from '../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import Loader from '../../../../core/ui/utility/Loader';
import { AcademicYearEdit, AcademicYear } from '../academicYear';
import EditAcademicYearForm from '../components/EditAcademicYearForm';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import GlobalContext from '../../../../core/service/globalContext';

const EditAcademicYearContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [academicYear, setAcademicYear] = useState<AcademicYear | null>(null);
  const { fetchGlobalData } = useContext(GlobalContext);

  const fetchAcademicYear = () =>
    fetchData(`academic/academic-years/${id}`, {
      onSuccess: (data: AcademicYear) => {
        setAcademicYear(data);
      },
    });

  useEffect(() => {
    fetchAcademicYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: AcademicYearEdit,
    { setFieldError }: FormikHelpers<AcademicYearEdit>
  ) => {
    let success = false;

    await callApi({
      endpoint: `academic/academic-years/${id}`,
      method: 'patch',
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('AcademicYear Edited');
        success = true;
      },
    });

    if (success) {
      await fetchGlobalData({ enableLoading: false });
      navigate('/academic/academic-year', { replace: true });
    }
  };

  return (
    <Layout
      renderLeftToolbar={() => <ReturnButton to="/academic/academic-year" />}
    >
      <Loader loading={loading || !academicYear}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit AcademicYear</Typography>
          </Grid>
          <EditAcademicYearForm
            academicYear={academicYear!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditAcademicYearContainer;
