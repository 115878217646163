/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../core/service/globalContext';
import Loader from '../../../../core/ui/utility/Loader';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { Student } from '../../student/student';

const ChangeGradeForm = () => {
  const { years, grades, activeYear } = useContext(GlobalContext);
  const [fromYear, setFromYear] = useState(activeYear?.id || '');
  const [fromGrade, setFromGrade] = useState('');
  const [toYear, setToYear] = useState('');
  const [toGrade, setToGrade] = useState('');

  const [students, setStudents] = useState<Student[]>([]);
  const [studentCheckedMap, setStudentCheckedMap] = useState<
    Record<string, boolean>
  >({});
  const { fetchData: fetchStudents, loading: studentsLoading } =
    useFetchApiData();

  const { callApi, loading: sendingData } = useSendApiData();

  useEffect(() => {
    if (fromYear && fromGrade) {
      fetchStudents(
        `academic/grade-students/year-students/${fromGrade}/${fromYear}`,
        {
          onSuccess: (data: Student[]) => {
            setStudents(data);
            const checkMap: Record<string, boolean> = {};
            data.forEach((student) => {
              checkMap[student.id] = false;
            });

            setStudentCheckedMap(checkMap);
          },
        }
      );
    }
  }, [fromYear, fromGrade]);

  const flipAll = (flipTo: boolean) => {
    const curCheckMap = { ...studentCheckedMap };
    Object.keys(curCheckMap).forEach((key) => {
      curCheckMap[key] = flipTo;
    });

    setStudentCheckedMap(curCheckMap);
  };

  const checkAll = () => {
    const checkVals = Object.values(studentCheckedMap);
    let checkTo = false;

    if (checkVals.find((i) => i === false) !== undefined) {
      checkTo = true;
    }

    flipAll(checkTo);
  };

  const flipCheck = (studentId: string, flipTo: boolean) => {
    const curCheckMap = { ...studentCheckedMap };
    curCheckMap[studentId] = flipTo;
    setStudentCheckedMap(curCheckMap);
  };

  const changeGrade = async () => {
    const studentList: string[] = [];
    Object.entries(studentCheckedMap).forEach(([key, value]) => {
      if (value === true) {
        studentList.push(key);
      }
    });

    const data = {
      student_ids: studentList,
      grade_id: toGrade,
      year_id: toYear,
    };

    await callApi({
      endpoint: 'academic/grade-students/student/multiple/change-grade',
      data,
      onSuccess: () => {
        toastMessage('Changed grade successfully');
        flipAll(false);
        setToGrade('');
      },
      onError: toastError,
      onValidationError: (error) => toastError(Object.values(error).join('\n')),
    });
  };

  const changeDisabled = () =>
    !toGrade ||
    !toYear ||
    sendingData ||
    Object.values(studentCheckedMap).find((i) => i === true) === undefined;

  return (
    <>
      <Box sx={{ p: 4 }}>
        <Typography variant="h5">Change Grade</Typography>
        <Grid container maxWidth="sm" sx={{ mt: 2 }} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h6" sx={{ mb: 1 }} fontWeight="600">
              From
            </Typography>
            <Grid item xs={12} display="flex" flexDirection="row">
              <FormControl fullWidth sx={{ mr: 2 }}>
                <InputLabel>From Year</InputLabel>
                <Select
                  fullWidth
                  value={fromYear}
                  label="From Year"
                  size="small"
                  onChange={(e) => setFromYear(e.target.value)}
                >
                  {years.map((year) => (
                    <MenuItem value={year.id} key={year.id}>
                      {year.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>From Grade</InputLabel>
                <Select
                  fullWidth
                  value={fromGrade}
                  onChange={(e) => setFromGrade(e.target.value)}
                  label="From Grade"
                  size="small"
                >
                  {grades.map((grade) => (
                    <MenuItem value={grade.id} key={grade.id}>
                      {grade.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Typography variant="h6" sx={{ mt: 2, mb: 1 }} fontWeight="600">
              To
            </Typography>
            <Grid item xs={12} display="flex" flexDirection="row">
              <FormControl fullWidth sx={{ mr: 2 }}>
                <InputLabel>To Year</InputLabel>
                <Select
                  fullWidth
                  value={toYear}
                  label="To Year"
                  size="small"
                  onChange={(e) => setToYear(e.target.value)}
                >
                  {years.map((year) => (
                    <MenuItem value={year.id} key={year.id}>
                      {year.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel>To Grade</InputLabel>
                <Select
                  fullWidth
                  value={toGrade}
                  onChange={(e) => setToGrade(e.target.value)}
                  label="To Grade"
                  size="small"
                >
                  {grades.map((grade) => (
                    <MenuItem value={grade.id} key={grade.id}>
                      {grade.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                size="small"
                variant="contained"
                sx={{ ml: 2 }}
                onClick={changeGrade}
                disabled={changeDisabled()}
              >
                Change
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6">Students</Typography>

                <Loader loading={studentsLoading}>
                  {students.length ? (
                    <Grid item display="flex" flexDirection="column">
                      <Grid
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        sx={{ mb: 1 }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          onClick={checkAll}
                        >
                          Check all
                        </Button>
                      </Grid>

                      {students.map((student) => (
                        <Grid
                          key={student.id}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <Checkbox
                            checked={studentCheckedMap[student.id] || false}
                            onChange={(e) =>
                              flipCheck(student.id, e.target.checked)
                            }
                            sx={{ mr: 2 }}
                          />
                          <Typography>
                            {student.first_name} {student.father_name}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Loader>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChangeGradeForm;
