import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import { toastError } from '../../../../../core/utils/ui/alert';
import { StudentPaymentReport } from '../types/student-payment-report';

const useGetStudentPayment = () => {
  const [studentId, setStudentId] = useState('');
  const [report, setReport] = useState<StudentPaymentReport | null>(null);

  const { fetchData, loading } = useFetchApiData();

  useEffect(() => {
    if (studentId) {
      fetchData(`payment-latest/reports/student-payments/${studentId}`, {
        onSuccess: (data: StudentPaymentReport) => {
          data.recurrent.sort((a, b) => {
            const result = a.recurrentPayment.description.localeCompare(
              b.recurrentPayment.description
            );

            return result === 0
              ? a.recurrentPaymentChild.order - b.recurrentPaymentChild.order
              : result;
          });
          data.recurrentPending.sort((a, b) => {
            const result = a.recurrentPayment.description.localeCompare(
              b.recurrentPayment.description
            );

            return result === 0
              ? a.recurrentPaymentChild.order - b.recurrentPaymentChild.order
              : result;
          });
          data.fixed.sort((a, b) =>
            (a.fixedPayment.description || '').localeCompare(
              b.fixedPayment.description || ''
            )
          );

          setReport(data);
        },
        onError: toastError,
      });
    }
  }, [studentId]);

  return { studentId, setStudentId, report, loading };
};

export default useGetStudentPayment;
