import { useNavigate } from 'react-router-dom';
import useDeleteApiData from '../../../../core/hooks/useDeleteApiData';
import { toastMessage } from '../../../../core/utils/ui/alert';

const useDeleteHrtController = (hrtId: string, gradeId: string) => {
  const navigate = useNavigate();
  const { callApi, loading } = useDeleteApiData();

  const deleteHrt = async () => {
    let success = true;
    await callApi({
      endpoint: `academic/home-room/hrts/${hrtId}`,
      onSuccess: () => {
        toastMessage('Home Room removed');
        success = true;
      },
    });

    if (success) {
      navigate('/');
      navigate(`/academic/grade/edit/${gradeId}`);
    }
  };

  return { loading, deleteHrt };
};

export default useDeleteHrtController;
