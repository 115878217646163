import ChangeGradeForm from '../components/ChangeGradeForm';

const ChangeGradeContainer = () => {
  return (
    <>
      <ChangeGradeForm />
    </>
  );
};

export default ChangeGradeContainer;
