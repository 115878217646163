import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { RegistrationCreate } from '../registration';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
  Paper,
  Box,
  Button,
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';
import { useEffect, useState } from 'react';
import Loader from '../../../../core/ui/utility/Loader';
import { Grade } from '../../../academic/grade/grade';

type RegistrationProps = {
  onSubmit: (
    value: RegistrationCreate,
    helpers: FormikHelpers<RegistrationCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  grades: Record<string, Grade>;
  students: Record<string, string>;
  studentsLoading: boolean;
  onGradeChange: (gradeId: string | null) => Promise<void>;
};

const CreateRegistrationForm = ({
  onSubmit,
  submitting,
  grades,
  students,
  studentsLoading,
  onGradeChange,
}: RegistrationProps) => {
  const [student, setStudent] = useState<string | ''>('');
  const [grade, setGrade] = useState<Grade | null>(null);
  const [method, setMethod] = useState<'cash' | 'slip'>('cash');

  const navigate = useNavigate();

  const handleSubmit = async (
    value: RegistrationCreate,
    helpers: FormikHelpers<RegistrationCreate>
  ) => {
    const success = await onSubmit(
      {
        ...value,
        student_id: student || '',
        fee: grade?.registration_fee || 0,
        slip_date: method === 'slip' ? value.slip_date : null,
      },
      helpers
    );
    if (success) {
      navigate('/', { replace: true });
      navigate('/payment/registration/create', { replace: true });
    }
  };

  useEffect(() => {
    formik.setFieldValue('student_id', student);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  const initialValues: RegistrationCreate = {
    fee: 0,
    attachment: 0,
    fs: 0,
    slip_date: new Date(),
    remark: '',
    student_id: '',
    cash: 0,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 700 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, mb: 2 }}>
                <Grid container sx={{}} spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel>Grade</InputLabel>
                      <Select
                        value={grade?.id || ''}
                        size="small"
                        label="Grade"
                        onChange={(e) => {
                          const curGrade = grades[e.target.value as string];
                          setGrade(curGrade);
                          setStudent('');
                          onGradeChange(e.target.value as string);
                        }}
                        fullWidth
                      >
                        {Object.keys(grades).map((item) => (
                          <MenuItem key={item} value={item}>
                            {grades[item].name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Students  */}
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 5 }}>
                    <FormControl
                      fullWidth
                      error={Boolean(
                        formik.touched.student_id && formik.errors.student_id
                      )}
                    >
                      <InputLabel>Student</InputLabel>
                      <Select
                        disabled={!grade}
                        value={formik.values.student_id}
                        size="small"
                        label="Student"
                        onChange={(e) => {
                          setStudent(e.target.value);
                        }}
                        name="student_id"
                      >
                        {Object.keys(students).map((item) => (
                          <MenuItem key={item} value={item}>
                            {students[item]}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(
                        formik.errors.student_id && formik.errors.student_id
                      ) && (
                        <FormHelperText>
                          {formik.errors.student_id && formik.errors.student_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>

              {/* Form  */}
              <Paper sx={{ p: 2, pb: 3 }}>
                <Loader loading={studentsLoading}>
                  <Grid container sx={{ mt: 2 }}>
                    <TextField
                      disabled
                      fullWidth
                      name="fee"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Fee"
                      placeholder="Fee"
                      value={grade?.registration_fee}
                      onChange={formik.handleChange}
                      error={Boolean(formik.touched.fee && formik.errors.fee)}
                      helperText={formik.touched.fee && formik.errors.fee}
                    />
                  </Grid>

                  <Grid container sx={{ mt: 2 }}>
                    <TextField
                      disabled={!student}
                      fullWidth
                      name="attachment"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Attachment"
                      placeholder="Attachment"
                      value={formik.values.attachment}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.attachment && formik.errors.attachment
                      )}
                      helperText={
                        formik.touched.attachment && formik.errors.attachment
                      }
                    />
                  </Grid>

                  <Grid container sx={{ mt: 2 }}>
                    <TextField
                      disabled={!student}
                      fullWidth
                      name="fs"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Fs"
                      placeholder="Fs"
                      value={formik.values.fs}
                      onChange={formik.handleChange}
                      error={Boolean(formik.touched.fs && formik.errors.fs)}
                      helperText={formik.touched.fs && formik.errors.fs}
                    />
                  </Grid>

                  <Grid container sx={{ mt: 2 }} spacing={1}>
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <FormControl fullWidth>
                        <InputLabel>Method</InputLabel>
                        <Select
                          disabled={!student}
                          value={method}
                          size="small"
                          label="Method"
                          onChange={(e) => {
                            setMethod(e.target.value! as 'cash' | 'slip');
                          }}
                          name="method"
                        >
                          <MenuItem value="cash">Cash</MenuItem>
                          <MenuItem value="slip">Slip</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {method === 'slip' ? (
                      <Grid
                        item
                        sx={{
                          flexGrow: 1,
                          padding: '0 !important',
                        }}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="center"
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            paddingLeft: '8px',
                          }}
                        >
                          <Typography sx={{ p: 0, mr: 1 }}>
                            Slip Date
                          </Typography>
                          <Grid item alignItems="flex-end">
                            <DateTimePicker
                              disabled={!student}
                              onChange={(e) => {
                                formik.setFieldValue('slip_date', e);
                              }}
                              value={formik.values.slip_date || new Date()}
                              disableClock
                              format="y-MM-dd"
                            />
                          </Grid>
                        </div>
                        {formik.touched.slip_date &&
                          formik.touched.slip_date &&
                          formik.errors.slip_date && (
                            <FormHelperText error>
                              {formik.touched.slip_date &&
                                formik.errors.slip_date}
                            </FormHelperText>
                          )}
                      </Grid>
                    ) : (
                      <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                        <TextField
                          disabled={!student}
                          fullWidth
                          name="cash"
                          size="small"
                          type="number"
                          variant="outlined"
                          label="Cash"
                          placeholder="Cash"
                          value={formik.values.cash}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.cash && formik.errors.cash
                          )}
                          helperText={formik.touched.cash && formik.errors.cash}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container sx={{ mt: 2 }}>
                    <TextField
                      disabled={!student}
                      fullWidth
                      name="remark"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Remark(*optional)"
                      placeholder="Remark(*optional)"
                      value={formik.values.remark}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.remark && formik.errors.remark
                      )}
                      helperText={formik.touched.remark && formik.errors.remark}
                    />
                  </Grid>

                  <Grid container sx={{ mt: 3 }}>
                    <Button
                      type="submit"
                      variant="outlined"
                      disabled={submitting || !student}
                      size="small"
                      fullWidth
                    >
                      {submitting ? 'Saving' : 'Save'}
                    </Button>
                  </Grid>
                </Loader>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateRegistrationForm;
