import SemesterReportCardContainer from '../containers/SemesterReportCardContainer';

const SemesterReportCardPage = () => {
  return (
    <>
      <SemesterReportCardContainer />
    </>
  );
};

export default SemesterReportCardPage;
