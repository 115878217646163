/* eslint-disable react-hooks/exhaustive-deps */
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useState } from 'react';
import { useSendApiData } from '../../../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../../../core/service/globalContext';
import { modalStyle } from '../../../../../../core/ui/utility/ModalContainer';
import {
  toastError,
  toastMessage,
} from '../../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../../core/utils/validation';
import { Cst, EvaluationMethodCreate } from '../../cst';

type EvaluationMethodFormProps = {
  cst: Cst;
  onSuccess: () => Promise<void>;
  insertedQuarters: string[];
};

const EvaluationMethodForm = ({
  cst,
  onSuccess,
  insertedQuarters,
}: EvaluationMethodFormProps) => {
  const [open, setOpen] = useState(false);

  const { callApi, loading: submitting } = useSendApiData();
  const { quarters: availableQuarters, evaluationTypes } =
    useContext(GlobalContext);

  const quarters = availableQuarters.filter(
    (q) => !insertedQuarters.includes(q.id)
  );

  const initialValues: EvaluationMethodCreate = {
    cst_id: cst.id,
    quarter_id: '',
    evaluation_type_ids: [],
  };

  const handleSubmit = async (
    values: EvaluationMethodCreate,
    { setFieldError }: FormikHelpers<EvaluationMethodCreate>
  ) => {
    await callApi({
      endpoint: `academic/marklist/evaluation-methods`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        setOpen(false);
        onSuccess();
        toastMessage('Evaluation Method added');
      },
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  if (!quarters.length) return <></>;

  return (
    <>
      <Box display="flex" flexDirection="row" sx={{ mr: 2 }}>
        <Button
          variant="text"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          size="small"
        >
          <ListItemText>Add Evaluation</ListItemText>
        </Button>

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...modalStyle, minWidth: 300 }}>
            <form
              onSubmit={(e) => {
                e.stopPropagation();
                formik.handleSubmit(e);
              }}
            >
              <Grid item xs={12}>
                <Typography variant="body1">Add Evaluation Method</Typography>
                <Grid container justifyContent="space-between">
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        name="subject"
                        size="small"
                        type="text"
                        variant="outlined"
                        value={cst.subject.subject}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justifyContent="space-between">
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1, mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel>Quarter</InputLabel>
                      <Select
                        size="small"
                        label="Quarter"
                        value={formik.values.quarter_id}
                        onChange={formik.handleChange}
                        name="quarter_id"
                      >
                        {quarters.map((quarter) => (
                          <MenuItem key={quarter.id} value={quarter.id}>
                            {quarter.quarter}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.touched.quarter_id &&
                        formik.errors.quarter_id && (
                          <FormHelperText error>
                            {formik.errors.quarter_id}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid
                      item
                      display="flex"
                      justifyContent="space-between"
                      flexDirection="row"
                    >
                      <Typography>Evaluation Types</Typography>

                      <AddCircleOutline
                        fontSize="small"
                        sx={{ ml: 1, alignSelf: 'center' }}
                        color="primary"
                        onClick={() => {
                          formik.setFieldValue('evaluation_type_ids', [
                            ...formik.values.evaluation_type_ids,
                            '',
                          ]);
                        }}
                      />
                    </Grid>

                    {(formik.values.evaluation_type_ids || []).map(
                      (evalTypeId, i) => (
                        <Grid
                          item
                          key={`evaluation-type-${i}`}
                          sx={{ mt: 1 }}
                          display="flex"
                          flexDirection="row"
                        >
                          <FormControl fullWidth>
                            <InputLabel>Evaluation Type</InputLabel>
                            <Select
                              size="small"
                              label="Evaluation Type"
                              value={evalTypeId}
                              onChange={formik.handleChange}
                              name={`evaluation_type_ids[${i}]`}
                            >
                              {evaluationTypes.map((evalType) => (
                                <MenuItem key={evalType.id} value={evalType.id}>
                                  {evalType.name} - {evalType.weight}
                                </MenuItem>
                              ))}
                            </Select>
                            {/* TODO: Handle validation  */}
                            {/* {formik.touched.evaluation_type_ids &&
                            (formik.touched.evaluation_type_ids as boolean[])?[i] &&
                            formik.errors.evaluation_type_ids?[i] && (
                              <FormHelperText error>
                                {formik.errors.evaluation_type_ids[i]}
                              </FormHelperText>
                            )} */}
                          </FormControl>

                          <RemoveCircleOutline
                            fontSize="small"
                            sx={{ mx: 1, alignSelf: 'center' }}
                            color="warning"
                            onClick={() => {
                              const curEvalTypes = [
                                ...formik.values.evaluation_type_ids,
                              ];
                              curEvalTypes.splice(i, 1);
                              formik.setFieldValue(
                                'evaluation_type_ids',
                                curEvalTypes
                              );
                            }}
                          />
                        </Grid>
                      )
                    )}
                    {formik.touched.evaluation_type_ids &&
                      formik.errors.evaluation_type_ids && (
                        <FormHelperText error>
                          {formik.errors.evaluation_type_ids}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item sx={{ flexGrow: 2 }}></Grid>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="outlined"
                    disabled={submitting}
                    size="small"
                    sx={{ mt: 2 }}
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default EvaluationMethodForm;
