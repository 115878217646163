import moment from 'moment';
import { Grid, Typography } from '@mui/material';
import { RecurrentChild } from '../recurrent';

const RecurrentChildPaymentSummary = ({
  payment,
}: {
  payment: RecurrentChild;
}) => {
  const SummaryItem = ({
    label,
    value,
  }: {
    label: string;
    value: string | number;
  }) => (
    <Grid
      display="flex"
      flexDirection="row"
      item
      xs={12}
      sx={{
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        pb: 1,
        borderBottom: '1px solid lightgrey',
        height: 'fit-content',
      }}
    >
      <Typography variant="h5" fontSize={16} fontWeight={700}>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Grid>
  );

  const penaltyStart = `${moment(payment.start_date, 'yyyy-MM-DD')
    .add(payment.no_penalty_days, 'days')
    .format('MMMM Do YY')} (${payment.no_penalty_days} days)`;

  return (
    <>
      <Grid
        sx={{
          p: 2,
          bgcolor: 'white',
          borderLeft: '3px solid',
          borderLeftColor: 'primary.main',
          filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
          flexShrink: 1,
          mt: 2,
          maxWidth: 700,
          height: 'fit-content',
        }}
        container
        display="flex"
        flexDirection="column"
      >
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant="h5" fontWeight={600}>
            Sub Payment Summary
          </Typography>
        </Grid>

        <Grid item xs={12} display="flex">
          <Grid
            item
            xs={6}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{ pr: 2, height: 'fit-content' }}
          >
            <SummaryItem label="Description" value={payment.description} />
            <SummaryItem label="Amount" value={payment.amount} />
            <SummaryItem
              label="Effective Date"
              value={`${payment.start_date}`}
            />
            <SummaryItem label="End Date" value={`${payment.end_date}`} />
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{ height: 'fit-content' }}
          >
            <SummaryItem
              label="Has Penalty"
              value={`${payment.has_penalty ? 'Yes' : 'No'}`}
            />
            {payment.has_penalty ? (
              <>
                <SummaryItem
                  label="Penalty Type"
                  value={`${payment.penalty_type?.toUpperCase()}`}
                />
                <SummaryItem
                  label="Penalty Amount"
                  value={`${payment.penalty_amount} ${
                    payment.penalty_type === 'percentage' ? '%' : 'Birr'
                  }`}
                />
                <SummaryItem label="Penalty Start" value={penaltyStart} />
                <SummaryItem
                  label="Penalty Frequency"
                  value={`${payment.penalty_frequency?.toUpperCase()}`}
                />
                {payment.penalty_frequency === 'recurrent' ? (
                  <SummaryItem
                    label="Penalty Apply Every "
                    value={`${payment.penalty_reapply_days} Days`}
                  />
                ) : (
                  <></>
                )}
                <SummaryItem
                  label="Penalty Max"
                  value={payment.max_penalty || '-'}
                />
                <SummaryItem
                  label="Penalty Stop After"
                  value={
                    payment.max_penalty_apply_days
                      ? moment(payment.start_date)
                          .add(
                            payment.max_penalty_apply_days +
                              payment.no_penalty_days,
                            'days'
                          )
                          .format('MMMM Do YYYY')
                      : '-'
                  }
                />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RecurrentChildPaymentSummary;
