import { FormikHelpers, useFormik } from 'formik';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import { FixedPayment, FixedPaymentEdit } from '../../fixedPayment';

type RegistrationProps = {
  onSubmit: (
    value: FixedPaymentEdit,
    helpers: FormikHelpers<FixedPaymentEdit>
  ) => Promise<boolean>;
  onCancel: () => void;
  submitting: boolean;
  payment: FixedPayment;
};

const EditFixedPaymentForm = ({
  onSubmit,
  onCancel,
  submitting,
  payment,
}: RegistrationProps) => {
  const handleSubmit = async (
    value: FixedPaymentEdit,
    helpers: FormikHelpers<FixedPaymentEdit>
  ) => {
    if (await onSubmit(value, helpers)) {
      formik.resetForm();
    }
  };

  const initialValues: FixedPaymentEdit = {
    effective_date: payment.effective_date,
    end_date: payment.end_date,
    amount: payment.amount,
    description: payment.description,
    has_penalty: payment.has_penalty ? true : false,
    no_penalty_days: payment.no_penalty_days,
    penalty_type: payment.penalty_type,
    penalty_amount: payment.penalty_amount,
    penalty_frequency: payment.penalty_frequency,
    penalty_reapply_days: payment.penalty_reapply_days,
    max_penalty: payment.max_penalty || '',
    max_penalty_apply_days: payment.max_penalty_apply_days || '',
    archived: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mx: 'auto', mb: 4 }}
          >
            Edit Payment
          </Typography>

          <Grid container sx={{ mt: 1 }} spacing={1}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="effective_date"
                size="small"
                type="date"
                variant="outlined"
                label="Start Date"
                placeholder=""
                value={formik.values.effective_date}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="end_date"
                size="small"
                type="date"
                variant="outlined"
                label="End Date"
                placeholder=""
                value={formik.values.end_date}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="amount"
                size="small"
                type="number"
                variant="outlined"
                label="Amount"
                placeholder=""
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.amount && formik.errors.amount)}
                helperText={formik.errors.amount}
              />
            </Grid>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="description"
                size="small"
                type="text"
                variant="outlined"
                label="Description"
                placeholder=""
                value={formik.values.description}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                helperText={formik.errors.description}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item>
              <FormControl error>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="has_penalty"
                      checked={formik.values.has_penalty}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Has penalty"
                />
                {Boolean(formik.errors.has_penalty) && (
                  <FormHelperText>{formik.errors.has_penalty}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {formik.values.has_penalty && (
            <Grid
              container
              sx={{ mt: 1, pt: 2, borderTop: '1px solid lightgrey' }}
            >
              <Grid container>
                <Grid item display="flex" flexDirection="column">
                  <TextField
                    fullWidth
                    name="no_penalty_days"
                    size="small"
                    type="number"
                    variant="outlined"
                    label="Apply penalty after"
                    value={formik.values.no_penalty_days}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.no_penalty_days &&
                        formik.errors.no_penalty_days
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="subtitle2">Days</Typography>
                        </InputAdornment>
                      ),
                    }}
                    helperText={formik.errors.no_penalty_days}
                  />
                  <Typography fontSize={12} color="green" sx={{ mt: '2px' }}>
                    *Penalty will apply {formik.values.no_penalty_days} days
                    after effective date
                  </Typography>
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }} spacing={1} display="flex">
                <Grid item flexGrow={1}>
                  <FormControl fullWidth sx={{ maxWidth: '270px' }}>
                    <InputLabel>Penalty Type</InputLabel>
                    <Select
                      value={formik.values.penalty_type}
                      size="small"
                      label="PenaltyType"
                      name="penalty_type"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value="fixed">Fixed</MenuItem>
                      <MenuItem value="percentage">Percentage</MenuItem>
                    </Select>
                    {formik.touched.penalty_type &&
                      formik.errors.penalty_type && (
                        <FormHelperText error>
                          {formik.errors.penalty_type}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item flexGrow={1}>
                  <TextField
                    fullWidth
                    name="penalty_amount"
                    size="small"
                    type="number"
                    variant="outlined"
                    label={
                      formik.values.penalty_type === 'fixed'
                        ? 'Penalty Fee'
                        : 'Penalty Percentage'
                    }
                    placeholder=""
                    value={formik.values.penalty_amount}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.penalty_amount &&
                        formik.errors.penalty_amount
                    )}
                    helperText={formik.errors.penalty_amount}
                  />
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }} spacing={1} display="flex">
                <Grid item flexGrow={1}>
                  <FormControl fullWidth sx={{ maxWidth: '270px' }}>
                    <InputLabel>Penalty Frequency</InputLabel>
                    <Select
                      value={formik.values.penalty_frequency}
                      size="small"
                      label="Penalty Frequency"
                      name="penalty_frequency"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value="onetime">One Time</MenuItem>
                      <MenuItem value="recurrent">Recurrent</MenuItem>
                    </Select>
                    {formik.touched.penalty_frequency &&
                      formik.errors.penalty_frequency && (
                        <FormHelperText error>
                          {formik.errors.penalty_frequency}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item flexGrow={1}>
                  {formik.values.penalty_frequency === 'recurrent' ? (
                    <TextField
                      fullWidth
                      name="penalty_reapply_days"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Re apply penalty every"
                      placeholder=""
                      value={formik.values.penalty_reapply_days}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.penalty_reapply_days &&
                          formik.errors.penalty_reapply_days
                      )}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Typography variant="subtitle2">Days</Typography>
                          </InputAdornment>
                        ),
                      }}
                      helperText={formik.errors.penalty_reapply_days}
                    />
                  ) : (
                    <Grid></Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container sx={{ mt: 2 }} spacing={1}>
                <Grid item flexGrow={1}>
                  <TextField
                    fullWidth
                    name="max_penalty"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Max penalty amount"
                    placeholder=""
                    value={formik.values.max_penalty}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.max_penalty && formik.errors.max_penalty
                    )}
                    helperText={formik.errors.max_penalty}
                  />
                </Grid>
                <Grid item flexGrow={1}>
                  <TextField
                    fullWidth
                    name="max_penalty_apply_days"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Max penalty days(penalty will stop applying after)"
                    placeholder=""
                    value={formik.values.max_penalty_apply_days}
                    onChange={formik.handleChange}
                    error={Boolean(
                      formik.touched.max_penalty_apply_days &&
                        formik.errors.max_penalty_apply_days
                    )}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="subtitle2">Days</Typography>
                        </InputAdornment>
                      ),
                    }}
                    helperText={formik.errors.max_penalty_apply_days}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item>
              <FormControl error>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="archived"
                      checked={formik.values.archived}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Archive"
                />
                {Boolean(formik.errors.archived) && (
                  <FormHelperText>{formik.errors.archived}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }} justifyContent="flex-end">
            <Button
              type="submit"
              color="success"
              variant="outlined"
              disabled={submitting}
              size="small"
              sx={{ mr: 1 }}
            >
              {submitting ? 'Saving' : 'Save'}
            </Button>

            <Button
              type="button"
              variant="outlined"
              onClick={onCancel}
              disabled={submitting}
              size="small"
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditFixedPaymentForm;
