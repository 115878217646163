import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { RegistrationCreate } from '../registration';
import CreateRegistrationForm from '../components/CreateRegistrationForm';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../../core/utils/utility';
import { useContext, useState } from 'react';
import GlobalContext from '../../../../core/service/globalContext';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { Student } from '../../../academic/student/student';
import { Grade } from '../../../academic/grade/grade';

const CreateRegistrationContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading: studentsLoading } = useFetchApiData();

  const [students, setStudents] = useState<Record<string, string>>({});

  const { grades } = useContext(GlobalContext);
  const gradeMap: Record<string, Grade> = {};
  grades.forEach((grade) => (gradeMap[grade.id] = grade));

  const handleSubmit = async (
    values: RegistrationCreate,
    { setFieldError }: FormikHelpers<RegistrationCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, ['slip_date']);

    await callApi({
      endpoint: 'finance/payment-new/registration-payments',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Registration Created');
        success = true;
      },
    });

    return success;
  };

  const handleGradeChange = async (gradeId: string | null) => {
    if (!gradeId) {
      setStudents({});
    } else {
      await fetchData(
        `finance/payment-new/registration-payments/non-registered/${gradeId}`,
        {
          onSuccess: ({ data }: { data: Student[] }) => {
            const studentMap: Record<string, string> = {};
            data.forEach(
              (s) => (studentMap[s.id] = `${s.first_name} ${s.father_name}`)
            );
            setStudents(studentMap);
          },
        }
      );
    }
  };

  return (
    <Layout
      renderLeftToolbar={() => <ReturnButton to="/payment/registration" />}
    >
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Registration</Typography>
        </Grid>
        <CreateRegistrationForm
          onSubmit={handleSubmit}
          submitting={submitting}
          grades={gradeMap}
          students={students}
          onGradeChange={handleGradeChange}
          studentsLoading={studentsLoading}
        />
      </Grid>
    </Layout>
  );
};

export default CreateRegistrationContainer;
