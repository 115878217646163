import { useMemo, useState } from 'react';
import { GradeSmsResponse } from '../models/grade-sms-response';
import { SelectChangeEvent } from '@mui/material';
import { useFormik } from 'formik';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';

export const useSendMessage = (grades: GradeSmsResponse[]) => {
  const [selectedGrades, setSelectedGrades] = useState<string[]>([]);

  const { callApi, loading: submitting } = useSendApiData();

  const handleChange = (event: SelectChangeEvent<typeof selectedGrades>) => {
    const {
      target: { value },
    } = event;
    setSelectedGrades(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const formik = useFormik({
    initialValues: {
      message: '',
      parent_resource_id: '',
      msg_type: '',
      msg_tags: '',
    },
    onSubmit: async (values) => {
      const tags = values.msg_tags.split(',').map((i) => i.trim());
      const students = grades
        .filter((i) => selectedGrades.includes(i.id))
        .map((i) => i.gradeStudents)
        .flat()
        .map((i) => ({ id: i.student.id, phone: i.student.primary_phone }));

      const data = students.map((i) => ({
        ...values,
        phone: i.phone,
        entity_id: `${i.id}-${values.parent_resource_id}`,
        msg_tags: tags,
      }));

      await callApi({
        endpoint: 'text-messages',
        data: { data },
        headers: {
          'Content-Type': 'application/json',
        },
        onValidationError: (err) =>
          toastError(
            Object.entries(err.errors)
              .map(([key, value]) => `${key}-${value}`)
              .join(' \n')
          ),
        onError: toastError,
        onSuccess: async (_) => {
          toastMessage('Messages Created');
          formik.resetForm();
        },
      });
    },
  });

  const btnDisabled = useMemo(
    () => !selectedGrades.length || !formik.values.message || submitting,
    [selectedGrades, formik.values.message, submitting]
  );

  return { selectedGrades, handleChange, formik, btnDisabled };
};
