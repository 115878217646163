import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { EvaluationMethod } from '../../evaluationMethod/evaluationMethod';
import { EvaluationType } from '../../evaluationType/evaluationType';
import { parseEvaluationForPreview } from '../services/cstService';
import EvaluationMethodMenu from './EvaluationMethodMenu';

type EvaluationMethodProps = {
  evaluationMethods: EvaluationMethod[];
  cstId: string;
};

const RenderEvaluation = ({
  quarter,
  cstId,
  gradeId,
  quarterId,
  evaluations,
}: {
  quarter: string;
  gradeId: string;
  cstId: string;
  quarterId: string;
  evaluations: EvaluationType[];
}) => (
  <>
    {evaluations.map((evaluation, i) => (
      <TableRow key={`eval-type-${i}`}>
        {i === 0 && (
          <TableCell sx={{ py: '8px' }} rowSpan={evaluations.length}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography>Quarter {quarter}</Typography>
              <EvaluationMethodMenu
                gradeId={gradeId}
                cstId={cstId}
                quarterId={quarterId}
              />
            </Box>
          </TableCell>
        )}
        <TableCell sx={{ py: '8px' }}>
          {evaluation.name} - {evaluation.weight}
        </TableCell>
      </TableRow>
    ))}
  </>
);

const EvaluationMethods = ({
  evaluationMethods,
  cstId,
}: EvaluationMethodProps) => {
  const parsedEvaluations = parseEvaluationForPreview(evaluationMethods);
  const { gradeId } = useParams();

  return (
    <>
      {evaluationMethods.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="custom pagination table" size="medium">
            <TableBody>
              {Object.keys(parsedEvaluations)
                .sort()
                .map((key, i) => (
                  <RenderEvaluation
                    key={`eval-method-${i}`}
                    quarter={key}
                    gradeId={gradeId as string}
                    cstId={cstId}
                    evaluations={parsedEvaluations[key].evaluations}
                    quarterId={parsedEvaluations[key].quarterId}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>N/A</Typography>
      )}
    </>
  );
};

export default EvaluationMethods;
