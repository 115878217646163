import { FormikHelpers, useFormik } from 'formik';
import { GradeCreate } from '../grade';
import {
  Grid,
  TextField,
  Paper,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { useContext } from 'react';
import GlobalContext from '../../../../core/service/globalContext';

type GradeProps = {
  onSubmit: (
    value: GradeCreate,
    helpers: FormikHelpers<GradeCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateGradeForm = ({ onSubmit, submitting }: GradeProps) => {
  const { fetchGlobalData } = useContext(GlobalContext);

  const handleSubmit = async (
    value: GradeCreate,
    helpers: FormikHelpers<GradeCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      fetchGlobalData();
    }
  };

  const initialValues: GradeCreate = {
    name: '',
    order: 0,
    monthly_fee: 0,
    registration_fee: 0,
    tutorial_fee: 0,
    summer_fee: 0,
    report_card_template: 'main',
    skill_template: 'main',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 500 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    name="name"
                    size="small"
                    type="text"
                    variant="outlined"
                    label="Name"
                    placeholder=""
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.name)}
                    helperText={formik.errors.name}
                  />
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    name="order"
                    size="small"
                    type="number"
                    variant="outlined"
                    label="Order"
                    placeholder=""
                    value={formik.values.order}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.order)}
                    helperText={formik.errors.order}
                  />
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    name="monthly_fee"
                    size="small"
                    type="number"
                    variant="outlined"
                    label="Monthly Fee"
                    placeholder=""
                    value={formik.values.monthly_fee}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.monthly_fee)}
                    helperText={formik.errors.monthly_fee}
                  />
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    name="registration_fee"
                    size="small"
                    type="number"
                    variant="outlined"
                    label="Registration Fee"
                    placeholder=""
                    value={formik.values.registration_fee}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.registration_fee)}
                    helperText={formik.errors.registration_fee}
                  />
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    name="tutorial_fee"
                    size="small"
                    type="number"
                    variant="outlined"
                    label="Tutorial Fee"
                    placeholder=""
                    value={formik.values.tutorial_fee}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.tutorial_fee)}
                    helperText={formik.errors.tutorial_fee}
                  />
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <TextField
                    fullWidth
                    name="summer_fee"
                    size="small"
                    type="number"
                    variant="outlined"
                    label="Summer Fee"
                    placeholder=""
                    value={formik.values.summer_fee}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.summer_fee)}
                    helperText={formik.errors.summer_fee}
                  />
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Report card template</InputLabel>
                    <Select
                      value={formik.values.report_card_template}
                      size="small"
                      label="Report card template"
                      name="report_card_template"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="main">Main</MenuItem>
                      <MenuItem value="nursery">Nursery</MenuItem>
                    </Select>
                    {formik.touched.report_card_template &&
                      formik.errors.report_card_template && (
                        <FormHelperText error>
                          {formik.errors.report_card_template}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Skill template</InputLabel>
                    <Select
                      value={formik.values.skill_template}
                      size="small"
                      label="Skill template"
                      name="skill_template"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="main">Main</MenuItem>
                      <MenuItem value="nursery">Nursery</MenuItem>
                    </Select>
                    {formik.touched.skill_template &&
                      formik.errors.skill_template && (
                        <FormHelperText error>
                          {formik.errors.skill_template}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={3}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                size="small"
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateGradeForm;
