import { Conduct } from '../models/conduct';

export const parseConducts = (conducts: Conduct[]) => {
  const conductMap: Record<string, Conduct> = {};
  conducts.forEach((conduct) => {
    conductMap[conduct.quarter_id] = conduct;
  });

  return conductMap;
};
