import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { Grade } from '../grade';

const useFetchGradeController = () => {
  const { id } = useParams();
  const [grade, setGrade] = useState<Grade | null>(null);
  const { fetchData, loading: gradeLoading } = useFetchApiData();

  const fetchGrade = () =>
    fetchData(`academic/grades/${id}`, {
      onSuccess: (data: Grade) => {
        setGrade(data);
      },
    });

  useEffect(() => {
    fetchGrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { grade, gradeLoading };
};

export default useFetchGradeController;
