import { NurserySkill, Skill } from '../models/skill';

export const parseSkills = (skills: Skill[]) => {
  const skillMap: Record<string, Skill> = {};
  skills.forEach((skill) => {
    skillMap[skill.quarter_id] = skill;
  });

  return skillMap;
};

export const parseNurserySkills = (skills: NurserySkill[]) => {
  const skillMap: Record<string, NurserySkill> = {};
  skills.forEach((skill) => {
    skillMap[skill.quarter_id] = skill;
  });

  return skillMap;
};
