const constants = {
  apiUrl: `${process.env.REACT_APP_API}/api`,
  baseurl: `${process.env.REACT_APP_API}`,
  authUrl: 'auth',
  keys: {
    user: 'user',
    fixedPaymentSearch: 'fixedPaymentSearch',
    recurrentPaymentSearch: 'recurrentPaymentSearch',
  },
  prefixes: {
    sorting: 'sorting',
    visibility: 'visibility',
    density: 'density',
    rowsPerPage: 'rows_per_page',
  },
};

export default constants;
