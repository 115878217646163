import Layout from '../../../../../core/ui/layout/Layout';
import CSTList from '../components/CSTGradeList';

const ListCSTPage = () => {
  return (
    <Layout>
      <CSTList />
    </Layout>
  );
};

export default ListCSTPage;
