import axios, { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import authService from '../../features/auth/service/authService';
import constants from '../utils/constants';
import { logError } from '../utils/logger';
import {
  ErrorHandler,
  SuccessHandler,
  ValidationError,
  ValidationHandler,
} from '../utils/types';

export type SendDataMethod = 'post' | 'patch' | 'put' | 'delete';

type CallApiArg = {
  endpoint: string;
  data: any;
  method?: SendDataMethod;
  headers?: Record<string, any>;
  onValidationError?: ValidationHandler;
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
};

export const useSendApiData = () => {
  const [loading, setLoading] = useState(false);

  const callApi = async ({
    endpoint,
    data,
    method = 'post',
    headers = {},
    onValidationError,
    onError,
    onSuccess,
  }: CallApiArg) => {
    setLoading(true);
    try {
      let response: AxiosResponse;

      const headerList = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${authService.getToken()}`,
        ...headers,
      };
      if (method === 'delete') {
        response = await axios.delete(`${constants.apiUrl}/${endpoint}`, {
          headers: headerList,
        });
      } else {
        response = await axios[method](
          `${constants.apiUrl}/${endpoint}`,
          data,
          {
            headers: headerList,
          }
        );
      }

      if (onSuccess) onSuccess(response.data, response.status);
    } catch (err) {
      const apiErr = err as AxiosError;
      // logError('Api Error', apiErr);
      logError('Res Data', apiErr.response?.data);

      if (
        apiErr.response &&
        apiErr.response?.status === 422 &&
        onValidationError
      ) {
        onValidationError!(apiErr.response!.data as unknown as ValidationError);
      } else {
        if (onError) {
          onError(
            apiErr.response?.data?.message ||
              apiErr.message ||
              'Unknown Error occurred'
          );
        }
      }
    }
    setLoading(false);
  };

  return { callApi, loading };
};
