import { Create } from '@mui/icons-material';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import GlobalContext from '../../../../../core/service/globalContext';

const CSTGradeList = () => {
  const { grades } = useContext(GlobalContext);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', maxWidth: 400 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell>Grade</TableCell>
                <TableCell align="right" sx={{ width: '96px' }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {grades.map((grade, i) => (
                <TableRow key={grade.id}>
                  <TableCell size="small">{i + 1}</TableCell>
                  <TableCell>{grade.name}</TableCell>
                  <TableCell align="right">
                    <Link to={`/academic/marklist/cst/${grade.id}`}>
                      <Create
                        fontSize="small"
                        sx={{ mr: 1, fontSize: '15px' }}
                      />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CSTGradeList;
