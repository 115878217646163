import AuthWrapper from '../../features/auth/containers/AuthWrapper';
import AuthContext, {
  useAuthContext,
} from '../../features/auth/service/authContext';
import GlobalContext, { useGlobalContext } from '../service/globalContext';
import RouteManager from './RouteManager';
import { useEffect } from 'react';
import Loader from '../ui/utility/Loader';
import { StagePaymentProvider } from '../state/StagePaymentContext';

const App = () => {
  const authState = useAuthContext();
  const globalState = useGlobalContext();

  useEffect(() => {
    if (authState.user) {
      globalState.fetchGlobalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user]);

  return (
    <>
      <AuthContext.Provider value={{ ...authState }}>
        <Loader loading={globalState.globalLoading}>
          <GlobalContext.Provider value={globalState}>
            <StagePaymentProvider>
              <AuthWrapper>
                <RouteManager />
              </AuthWrapper>
            </StagePaymentProvider>
          </GlobalContext.Provider>
        </Loader>
      </AuthContext.Provider>
    </>
  );
};

export default App;
