import { FormikHelpers } from 'formik';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { dataParserFactory } from '../../../../core/utils/utility';
import { parseValidationErrors } from '../../../../core/utils/validation';
import EditFixedPaymentForm from '../components/forms/EditFixedPaymentForm';
import { FixedPayment, FixedPaymentEdit } from '../fixedPayment';

const EditFixedPaymentContainer = ({
  onSuccess,
  onCancel,
  payment,
}: {
  onSuccess: (id: string) => void;
  onCancel: () => void;
  payment: FixedPayment;
}) => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: FixedPaymentEdit,
    { setFieldError }: FormikHelpers<FixedPaymentEdit>
  ) => {
    let success = false;
    let id = '';

    const parsedData = dataParserFactory({ ...values }).parseDate([
      'effective_date',
      'end_date',
    ]).data;

    await callApi({
      endpoint: `payment-latest/fixed/${payment.id}`,
      data: {
        ...parsedData,
        max_penalty:
          parsedData.max_penalty === '' ? null : parsedData.max_penalty,
        max_penalty_apply_days:
          parsedData.max_penalty_apply_days === ''
            ? null
            : parsedData.max_penalty_apply_days,
      },
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (data) => {
        toastMessage('Fixed Payment edited');
        success = true;
        id = data.id;
      },
    });

    if (success) {
      onSuccess(id);
    }

    return success;
  };

  return (
    <EditFixedPaymentForm
      payment={payment}
      onSubmit={handleSubmit}
      submitting={submitting}
      onCancel={onCancel}
    />
  );
};

export default EditFixedPaymentContainer;
