import {
  Grid,
  TextField,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Button,
} from '@mui/material';
import { getImageUrl } from '../../../../../core/utils/image';
import { useStudentProfileEditForm } from '../../hooks/useStudentProfileForm';
import { StudentProfile } from '../../models/studentProfile';
import { Student } from '../../student';

type StudentProfileProps = {
  student: Student;
  studentProfile: StudentProfile;
};

const EditStudentProfileForm = ({
  studentProfile,
  student,
}: StudentProfileProps) => {
  const { formik, submitting } = useStudentProfileEditForm(
    student,
    studentProfile
  );

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, width: '100%' }}>
        <form onSubmit={formik.handleSubmit}>
          <Paper sx={{ p: 2, width: '100%' }}>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name=""
                  disabled
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Student"
                  placeholder=""
                  value={student.first_name + ' ' + student.father_name}
                />
              </Grid>
              <Grid sx={{ flexGrow: 3 }}></Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="nationality"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Nationality"
                  placeholder=""
                  value={formik.values.nationality}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.nationality)}
                  helperText={formik.errors.nationality}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="student_phone_number"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Student Phone"
                  placeholder=""
                  value={formik.values.student_phone_number}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.student_phone_number)}
                  helperText={formik.errors.student_phone_number}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="house_number"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="House Number"
                  placeholder=""
                  value={formik.values.house_number}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.house_number)}
                  helperText={formik.errors.house_number}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="address_city"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Address City"
                  placeholder=""
                  value={formik.values.address_city}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.address_city)}
                  helperText={formik.errors.address_city}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="address_sub_city"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Address Sub City"
                  placeholder=""
                  value={formik.values.address_sub_city}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.address_sub_city)}
                  helperText={formik.errors.address_sub_city}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="address_wored"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Address Woreda"
                  placeholder=""
                  value={formik.values.address_wored}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.address_wored)}
                  helperText={formik.errors.address_wored}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="other_health_status"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Other health statsus"
                  placeholder=""
                  value={formik.values.other_health_status}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.other_health_status)}
                  helperText={formik.errors.other_health_status}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="previous_school_name"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Previous school name"
                  placeholder=""
                  value={formik.values.previous_school_name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.previous_school_name)}
                  helperText={formik.errors.previous_school_name}
                />
              </Grid>

              <Grid item sx={{ flexGrow: 1 }}></Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="previous_school_city"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Previouse school city"
                  placeholder=""
                  value={formik.values.previous_school_city}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.previous_school_city)}
                  helperText={formik.errors.previous_school_city}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="previous_school_sub_city"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Other health sub city"
                  placeholder=""
                  value={formik.values.previous_school_sub_city}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.previous_school_sub_city)}
                  helperText={formik.errors.previous_school_sub_city}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="previous_school_woreda"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Previous school woreda"
                  placeholder=""
                  value={formik.values.previous_school_woreda}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.previous_school_woreda)}
                  helperText={formik.errors.previous_school_woreda}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="school_leave_other"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="School leave other"
                  placeholder=""
                  value={formik.values.school_leave_other}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.school_leave_other)}
                  helperText={formik.errors.school_leave_other}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="entry_class"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Entry class"
                  placeholder=""
                  value={formik.values.entry_class}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.entry_class)}
                  helperText={formik.errors.entry_class}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="parent_name"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Parent name"
                  placeholder=""
                  value={formik.values.parent_name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.parent_name)}
                  helperText={formik.errors.parent_name}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="occupation"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Parent occupation"
                  placeholder=""
                  value={formik.values.occupation}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.occupation)}
                  helperText={formik.errors.occupation}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="work_place"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Parent work place"
                  placeholder=""
                  value={formik.values.work_place}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.work_place)}
                  helperText={formik.errors.work_place}
                />
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="parent_phone_number"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Parent phone"
                  placeholder=""
                  value={formik.values.parent_phone_number}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.parent_phone_number)}
                  helperText={formik.errors.parent_phone_number}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="student_living_with"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Student living with"
                  placeholder=""
                  value={formik.values.student_living_with}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.student_living_with)}
                  helperText={formik.errors.student_living_with}
                />
              </Grid>

              <Grid
                item
                alignSelf="flex-start"
                sx={{ flexGrow: 1 }}
                display="flex"
                alignItems="center"
              >
                <Grid item sx={{ mr: 2 }}>
                  <InputLabel>Parent photo</InputLabel>
                </Grid>
                <Grid item flexGrow={1} display="flex" flexDirection="column">
                  <Grid item>
                    {studentProfile.parent_photo && (
                      <img
                        src={getImageUrl(studentProfile.parent_photo)}
                        alt=" "
                        style={{
                          width: '250px',
                          height: 'fit-content',
                          objectFit: 'cover',
                          objectPosition: 'center',
                        }}
                      />
                    )}
                  </Grid>
                  <input
                    type="file"
                    onChange={({ target: { files } }) => {
                      formik.setFieldValue(
                        'parent_photo',
                        files && files.length ? files[0] : null
                      );
                    }}
                    name="parent_photo"
                  />
                  {formik.touched.parent_photo &&
                    formik.errors.parent_photo && (
                      <FormHelperText error>
                        {formik.errors.parent_photo}
                      </FormHelperText>
                    )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  name="emergencies"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Emergencies"
                  placeholder=""
                  value={formik.values.emergencies}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.emergencies)}
                  helperText={formik.errors.emergencies}
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item sx={{ flexGrow: 2 }}></Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <FormControl
                  sx={{ minWidth: 120 }}
                  error={Boolean(formik.errors.health_status)}
                  fullWidth
                >
                  <InputLabel>Health status</InputLabel>
                  <Select
                    value={formik.values.health_status}
                    size="small"
                    label="Health status"
                    onChange={formik.handleChange}
                    name="health_status"
                  >
                    <MenuItem value="FULLY_HEALTHY">Fully Healthy</MenuItem>
                    <MenuItem value="PARAPLEGIC">Paraplegic</MenuItem>
                    <MenuItem value="OTHER">Other</MenuItem>
                  </Select>
                  {Boolean(formik.errors.health_status) && (
                    <FormHelperText>
                      {formik.errors.health_status}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <FormControl
                  sx={{ minWidth: 120 }}
                  error={Boolean(formik.errors.previous_school_leave_reason)}
                  fullWidth
                >
                  <InputLabel>Previous school leave reason</InputLabel>
                  <Select
                    value={formik.values.previous_school_leave_reason}
                    size="small"
                    label="Previous school leave reason"
                    onChange={formik.handleChange}
                    name="previous_school_leave_reason"
                  >
                    <MenuItem value="TRANSFER">Transfer</MenuItem>
                    <MenuItem value="DISCIPLINE_ISSUE">
                      Discipline issue
                    </MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                  {Boolean(formik.errors.previous_school_leave_reason) && (
                    <FormHelperText>
                      {formik.errors.previous_school_leave_reason}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              sx={{ mt: 2 }}
            >
              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <FormControl
                  sx={{ minWidth: 120 }}
                  error={Boolean(formik.errors.father_condition)}
                  fullWidth
                >
                  <InputLabel>Father condition</InputLabel>
                  <Select
                    value={formik.values.father_condition}
                    size="small"
                    label="Father condition"
                    onChange={formik.handleChange}
                    name="father_condition"
                  >
                    <MenuItem value="ALIVE">Alive</MenuItem>
                    <MenuItem value="DEAD">Dead</MenuItem>
                    <MenuItem value="ALIVE_BUT_DOES_NOT_LIVE_TOGETHER">
                      Alive but does not live together
                    </MenuItem>
                  </Select>
                  {Boolean(formik.errors.father_condition) && (
                    <FormHelperText>
                      {formik.errors.father_condition}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                <FormControl
                  sx={{ minWidth: 120 }}
                  error={Boolean(formik.errors.mother_condition)}
                  fullWidth
                >
                  <InputLabel>Mother condition</InputLabel>
                  <Select
                    value={formik.values.mother_condition}
                    size="small"
                    label="Mother condition"
                    onChange={formik.handleChange}
                    name="mother_condition"
                  >
                    <MenuItem value="ALIVE">Alive</MenuItem>
                    <MenuItem value="DEAD">Dead</MenuItem>
                    <MenuItem value="ALIVE_BUT_DOES_NOT_LIVE_TOGETHER">
                      Alive but does not live together
                    </MenuItem>
                  </Select>
                  {Boolean(formik.errors.mother_condition) && (
                    <FormHelperText>
                      {formik.errors.mother_condition}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                size="small"
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Paper>
        </form>
      </Box>
    </>
  );
};

export default EditStudentProfileForm;
