import { Grid, MenuItem, Select } from '@mui/material';
import { PuffLoader } from 'react-spinners';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import Loader from '../../../../../core/ui/utility/Loader';
import { toastError } from '../../../../../core/utils/ui/alert';

type SkillInlineProps = {
  gradeStudentId: string;
  quarterId: string;
  field: string;
  value: string;
  endpoint?: string;
  fetchSkill: () => void;
};

const SkillInlineForm = ({
  gradeStudentId,
  quarterId,
  field,
  value,
  fetchSkill,
  endpoint = `academic/students/skills`,
}: SkillInlineProps) => {
  const { callApi, loading } = useSendApiData();

  const updateSkill = (skill: string) => {
    callApi({
      endpoint,
      data: {
        grade_student_id: gradeStudentId,
        quarter_id: quarterId,
        [field]: skill,
      },
      onSuccess: () => {
        fetchSkill();
      },
      onError: toastError,
      onValidationError: (err) => {
        toastError(`Invalid data ${Object.values(err.errors).join('<br/>')}`);
      },
    });
  };

  return (
    <>
      <Loader
        loading={loading}
        loaderComponent={
          <Grid display="flex" alignItems="center" justifyContent="center">
            <PuffLoader color="#276BCE" size={20} />
          </Grid>
        }
      >
        <Select
          value={value}
          size="small"
          label=""
          onChange={(e) => {
            updateSkill(e.target.value);
          }}
          sx={{
            width: '60px',
            height: '30px',
            fontSize: '13px',
            input: {
              px: 1,
              textAlign: 'center',
              height: '30px',
              fontSize: '13px',
            },
          }}
        >
          <MenuItem dense value=""></MenuItem>
          <MenuItem dense value="E">
            E
          </MenuItem>
          <MenuItem dense value="G">
            G
          </MenuItem>
          <MenuItem dense value="N">
            N
          </MenuItem>
          <MenuItem dense value="P">
            P
          </MenuItem>
          <MenuItem dense value="R">
            R
          </MenuItem>
        </Select>
      </Loader>
    </>
  );
};

export default SkillInlineForm;
