import Layout from '../../../../core/ui/layout/Layout';
import StageContainer from '../../components/StageContainer';
import PendingRecurrentPaymentList from '../components/lists/PendingRecurrentPaymentList';

const AddPendingRecurrentPage = () => {
  return (
    <>
      <Layout>
        <StageContainer>
          <PendingRecurrentPaymentList />
        </StageContainer>
      </Layout>
    </>
  );
};

export default AddPendingRecurrentPage;
