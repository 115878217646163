/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import Layout from '../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { RoleMap, UserCreate } from '../user';
import CreateUserForm from '../components/CreateUserForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../core/utils/utility';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Loader from '../../../core/ui/utility/Loader';
import { useEffect, useState } from 'react';

const CreateUserContainer = () => {
  const [roleMap, setRoleMap] = useState<RoleMap[]>([]);

  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();

  useEffect(() => {
    fetchData('users/get-roles', {
      onSuccess: ({ data: apiData }) => {
        setRoleMap(apiData);
      },
    });
  }, []);

  const handleSubmit = async (
    values: UserCreate,
    { setFieldError }: FormikHelpers<UserCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: 'users',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('User Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/user" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add User</Typography>
        </Grid>
        <Loader loading={loading}>
          <CreateUserForm
            roles={roleMap}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Loader>
      </Grid>
    </Layout>
  );
};

export default CreateUserContainer;
