import { FormikHelpers, useFormik } from 'formik';
import { useSendApiData } from '../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../core/utils/validation';
import { User, UserEdit } from '../user';

const useEditUserController = (user: User) => {
  const { callApi, loading: submitting } = useSendApiData();

  const initialValues: UserEdit = {
    first_name: user.first_name,
    father_name: user.father_name,
    email: user.email,
    password: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (
      values: UserEdit,
      { setFieldError, resetForm }: FormikHelpers<UserEdit>
    ) => {
      await callApi({
        endpoint: `users/${user.id}`,
        method: 'patch',
        data: values,
        headers: {
          'Content-Type': 'application/json',
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
        onSuccess: async (_) => {
          toastMessage('User Edited');
          formik.setFieldValue('password', '');
        },
      });
    },
  });

  return { formik, submitting };
};

export default useEditUserController;
