import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../features/auth/containers/PrivateRoute';
import LoginPage from '../../features/auth/pages/LoginPage';
import ProfilePage from '../../features/auth/pages/ProfilePage';
import HomePage from '../../features/HomePage';
import ListRegistrationPage from '../../features/payment/registration/pages/ListRegistrationPage';
import CreateRegistrationPage from '../../features/payment/registration/pages/CreateRegistrationPage';
import EditRegistrationPage from '../../features/payment/registration/pages/EditRegistrationPage';
import ListEvaluationTypePage from '../../features/academic/marklist/evaluationType/pages/ListEvaluationTypePage';
import CreateEvaluationTypePage from '../../features/academic/marklist/evaluationType/pages/CreateEvaluationTypePage';
import EditEvaluationTypePage from '../../features/academic/marklist/evaluationType/pages/EditEvaluationTypePage';
import ListCSTPage from '../../features/academic/marklist/cst/pages/ListCSTPage';
import CstPage from '../../features/academic/marklist/cst/pages/CstPage';
import CreateSmlPage from '../../features/academic/marklist/sml/pages/CreateSmlPage';
import ReportCardPage from '../../features/academic/marklist/reportCard/pages/ReportCardPage';
import QuarterReportCardPage from '../../features/academic/marklist/reportCard/pages/QuarterReportCardPage';
import ListAcademicYearPage from '../../features/academic/academicYear/pages/ListAcademicYearPage';
import CreateAcademicYearPage from '../../features/academic/academicYear/pages/CreateAcademicYearPage';
import EditAcademicYearPage from '../../features/academic/academicYear/pages/EditAcademicYearPage';
import ListSemesterPage from '../../features/academic/marklist/semester/pages/ListSemesterPage';
import CreateSemesterPage from '../../features/academic/marklist/semester/pages/CreateSemesterPage';
import EditSemesterPage from '../../features/academic/marklist/semester/pages/EditSemesterPage';
import ListQuarterPage from '../../features/academic/marklist/quarter/pages/ListQuarterPage';
import CreateQuarterPage from '../../features/academic/marklist/quarter/pages/CreateQuarterPage';
import EditQuarterPage from '../../features/academic/marklist/quarter/pages/EditQuarterPage';
import ListSubjectPage from '../../features/academic/marklist/subject/pages/ListSubjectPage';
import CreateSubjectPage from '../../features/academic/marklist/subject/pages/CreateSubjectPage';
import EditSubjectPage from '../../features/academic/marklist/subject/pages/EditSubjectPage';
import ListGradePage from '../../features/academic/grade/pages/ListGradePage';
import CreateGradePage from '../../features/academic/grade/pages/CreateGradePage';
import EditGradePage from '../../features/academic/grade/pages/EditGradePage';
import ListUserPage from '../../features/user/pages/ListUserPage';
import CreateUserPage from '../../features/user/pages/CreateUserPage';
import EditUserPage from '../../features/user/pages/EditUserPage';
import ListTeacherPage from '../../features/academic/teacher/pages/ListTeacherPage';
import CreateTeacherPage from '../../features/academic/teacher/pages/CreateTeacherPage';
import NotFoundPage from '../ui/pages/NotFoundPage';
import ListStudentPage from '../../features/academic/student/pages/ListStudentPage';
import CreateStudentPage from '../../features/academic/student/pages/CreateStudentPage';
import EditStudentPage from '../../features/academic/student/pages/EditStudentPage';
import SemesterReportCardPage from '../../features/academic/marklist/reportCard/pages/SemesterReportCardPage';
import YearReportCardPage from '../../features/academic/marklist/reportCard/pages/YearReportCardPage';
import StudentDetailPage from '../../features/academic/student/pages/StudentDetailPage';
import RosterReportPage from '../../features/academic/marklist/reportCard/pages/RosterReportPage';
import CreateFixedPaymentPage from '../../features/paymentLatest/fixed/pages/CreateFixedPaymentPage';
import ChangeGradePage from '../../features/academic/grade/pages/ChangeGradePage';
import ListFixedPaymentPage from '../../features/paymentLatest/fixed/pages/ListFixedPaymentPage';
import AddFixedPaymentPage from '../../features/paymentLatest/fixed/pages/AddFixedPaymentPage';
import CreateRecurrentPage from '../../features/paymentLatest/recurrent/pages/CreateRecurrentPage';
import RecurrentListPage from '../../features/paymentLatest/recurrent/pages/RecurrentListPage';
import AddPendingRecurrentPage from '../../features/paymentLatest/recurrent/pages/AddPendingRecurrentPage';
import ListArchiveFixedPaymentPage from '../../features/paymentLatest/fixed/pages/ListArchiveFixedPaymentPage';
import RecurrentArchivePage from '../../features/paymentLatest/recurrent/pages/RecurrentArchivePage';
import IdCardPage from '../../features/academic/idCard/IdCardPage';
import StudentAttendancePage from '../../features/academic/homeRoom/pages/StudentAttendancePage';
import AdminCreateStudentAttendancePage from '../../features/academic/student/pages/AdminCreateStudentAttendancePage';
import ReportPage from '../../features/paymentLatest/report/pages/ReportPage';
import GradeStudentPage from '../../features/academic/gradeStudent/pages/GradeStudentPage';
import SearchFixedPaymentPage from '../../features/paymentLatest/fixed/pages/SearchFixedPaymentPage';
import SearchRecurrentPaymentPage from '../../features/paymentLatest/recurrent/pages/SearchRecurrentPaymentPage';
import SetActivenessPage from '../../features/academic/gradeStudent/pages/SetActivenessPage';
import HomeRoomStudentsPage from '../../features/academic/homeRoom/pages/HomeRoomStudentsPage';
import StudentPaymentPage from '../../features/paymentLatest/student/pages/StudentPaymentPage';
import AttachmentRangePage from '../../features/paymentLatest/student/pages/AttachmentRangePage';
import TextMessagePage from '../../features/textMessages/pages/TextMessagePage';

const RouteManager = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/sms"
          element={
            <PrivateRoute>
              <TextMessagePage />
            </PrivateRoute>
          }
        />

        {/* Payment Routes  */}
        {/* Registration Routes  */}
        <Route
          path="/payment/registration"
          element={
            <PrivateRoute>
              <ListRegistrationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/registration/create"
          element={
            <PrivateRoute>
              <CreateRegistrationPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/registration/edit/:id"
          element={
            <PrivateRoute>
              <EditRegistrationPage />
            </PrivateRoute>
          }
        />

        {/* Academic Routes  */}
        {/* Academic year routes  */}
        <Route
          path="/academic/academic-year"
          element={
            <PrivateRoute permissions={['view-academic-year']}>
              <ListAcademicYearPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/academic-year/create"
          element={
            <PrivateRoute permissions={['add-academic-year']}>
              <CreateAcademicYearPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/academic-year/edit/:id"
          element={
            <PrivateRoute permissions={['edit-academic-year']}>
              <EditAcademicYearPage />
            </PrivateRoute>
          }
        />
        {/* Grade Routes */}
        <Route
          path="/academic/grade"
          element={
            <PrivateRoute permissions={['view-grade']}>
              <ListGradePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/grade/create"
          element={
            <PrivateRoute permissions={['add-grade']}>
              <CreateGradePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/grade/edit/:id"
          element={
            <PrivateRoute permissions={['edit-grade']}>
              <EditGradePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/change-grade"
          element={
            <PrivateRoute permissions={['view-grade']}>
              <ChangeGradePage />
            </PrivateRoute>
          }
        />

        {/* Student Routes  */}
        <Route
          path="/academic/student"
          element={
            <PrivateRoute>
              <ListStudentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/student/create"
          element={
            <PrivateRoute>
              <CreateStudentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/student/view/:id"
          element={
            <PrivateRoute>
              <StudentDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/student/edit/:id"
          element={
            <PrivateRoute>
              <EditStudentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/attendance-student"
          element={
            <PrivateRoute>
              <AdminCreateStudentAttendancePage />
            </PrivateRoute>
          }
        />

        {/* Grade Student Routes  */}
        <Route
          path="/academic/grade-students"
          element={
            <PrivateRoute permissions={['view-grade']}>
              <GradeStudentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/grade-students/activeness"
          element={
            <PrivateRoute permissions={['view-grade']}>
              <SetActivenessPage />
            </PrivateRoute>
          }
        />

        {/* Teacher Routes  */}
        <Route
          path="/academic/teacher"
          element={
            <PrivateRoute permissions={['view-teacher']}>
              <ListTeacherPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/teacher/create"
          element={
            <PrivateRoute permissions={['add-teacher']}>
              <CreateTeacherPage />
            </PrivateRoute>
          }
        />

        {/* Marklist ROutes  */}
        {/* Subject Routes  */}
        <Route
          path="/academic/subject"
          element={
            <PrivateRoute permissions={['view-subject']}>
              <ListSubjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/subject/create"
          element={
            <PrivateRoute permissions={['add-subject']}>
              <CreateSubjectPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/subject/edit/:id"
          element={
            <PrivateRoute permissions={['edit-subject']}>
              <EditSubjectPage />
            </PrivateRoute>
          }
        />
        {/* Cst Routes  */}
        <Route
          path="/academic/marklist/cst"
          element={
            <PrivateRoute permissions={['view-cst']}>
              <ListCSTPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/marklist/cst/:gradeId"
          element={
            <PrivateRoute permissions={[]}>
              <CstPage />
            </PrivateRoute>
          }
        />
        {/* Eval Type Routes  */}
        <Route
          path="/academic/evaluation-type"
          element={
            <PrivateRoute permissions={['view-evaluation-type']}>
              <ListEvaluationTypePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/evaluation-type/create"
          element={
            <PrivateRoute permissions={['add-evaluation-type']}>
              <CreateEvaluationTypePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/evaluation-type/edit/:id"
          element={
            <PrivateRoute permissions={['edit-evaluation-type']}>
              <EditEvaluationTypePage />
            </PrivateRoute>
          }
        />
        {/* Report Routes  */}
        <Route
          path="/academic/report-roster"
          element={
            <PrivateRoute>
              <RosterReportPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/roster"
          element={
            <PrivateRoute permissions={['add-rcq']}>
              <ReportCardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/roster/grade/:gradeId/quarter/:quarterId"
          element={
            <PrivateRoute permissions={['view-rcq']}>
              <QuarterReportCardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/roster/grade/:gradeId/semester/:semesterId"
          element={
            <PrivateRoute permissions={['view-rcs']}>
              <SemesterReportCardPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/roster/grade/:gradeId/year"
          element={
            <PrivateRoute permissions={['view-rcy']}>
              <YearReportCardPage />
            </PrivateRoute>
          }
        />
        {/* Sml Routes  */}
        <Route
          path="/academic/marklist/:gradeId/cst/:cstId/quarter/:quarterId"
          element={
            <PrivateRoute>
              <CreateSmlPage />
            </PrivateRoute>
          }
        />
        {/* Quarter Routes  */}
        <Route
          path="/academic/quarter"
          element={
            <PrivateRoute permissions={['view-quarter']}>
              <ListQuarterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/quarter/create"
          element={
            <PrivateRoute permissions={['add-quarter']}>
              <CreateQuarterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/quarter/edit/:id"
          element={
            <PrivateRoute permissions={['edit-quarter']}>
              <EditQuarterPage />
            </PrivateRoute>
          }
        />
        {/* Semester Routes  */}
        <Route
          path="/academic/semester"
          element={
            <PrivateRoute permissions={['view-semester']}>
              <ListSemesterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/semester/create"
          element={
            <PrivateRoute permissions={['add-semester']}>
              <CreateSemesterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/semester/edit/:id"
          element={
            <PrivateRoute permissions={['edit-semester']}>
              <EditSemesterPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/academic/id-card"
          element={
            <PrivateRoute permissions={['view-academic-year']}>
              <IdCardPage />
            </PrivateRoute>
          }
        />

        {/* User Routes  */}
        <Route
          path="/user"
          element={
            <PrivateRoute permissions={['view-user']}>
              <ListUserPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/create"
          element={
            <PrivateRoute permissions={['add-user']}>
              <CreateUserPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/user/edit/:id"
          element={
            <PrivateRoute permissions={['edit-user']}>
              <EditUserPage />
            </PrivateRoute>
          }
        />

        {/* Payment Latest  */}
        <Route
          path="/payment/student"
          element={
            <PrivateRoute permissions={[]}>
              <StudentPaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/attachment"
          element={
            <PrivateRoute permissions={[]}>
              <AttachmentRangePage />
            </PrivateRoute>
          }
        />

        {/* Fixed  */}
        <Route
          path="/payment/fixed/create"
          element={
            <PrivateRoute permissions={[]}>
              <CreateFixedPaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/fixed/add"
          element={
            <PrivateRoute permissions={[]}>
              <ListFixedPaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/fixed/add/:id"
          element={
            <PrivateRoute permissions={[]}>
              <AddFixedPaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/fixed/view/:id"
          element={
            <PrivateRoute permissions={[]}>
              <SearchFixedPaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/fixed/archive"
          element={
            <PrivateRoute permissions={[]}>
              <ListArchiveFixedPaymentPage />
            </PrivateRoute>
          }
        />

        {/* Recurrent  */}
        <Route
          path="/payment/recurrent/create"
          element={
            <PrivateRoute permissions={[]}>
              <CreateRecurrentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/recurrent/add"
          element={
            <PrivateRoute permissions={[]}>
              <RecurrentListPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/recurrent/archive"
          element={
            <PrivateRoute permissions={[]}>
              <RecurrentArchivePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/recurrent/add/:id"
          element={
            <PrivateRoute permissions={[]}>
              <AddPendingRecurrentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/recurrent/view/:id"
          element={
            <PrivateRoute permissions={[]}>
              <SearchRecurrentPaymentPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/payment/report"
          element={
            <PrivateRoute permissions={[]}>
              <ReportPage />
            </PrivateRoute>
          }
        />

        {/* HOme ROom Routes  */}
        <Route
          path="/home-room-attendance"
          element={
            <PrivateRoute permissions={[]}>
              <StudentAttendancePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/home-room-students"
          element={
            <PrivateRoute permissions={[]}>
              <HomeRoomStudentsPage />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteManager;
