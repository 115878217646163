/* eslint-disable react-hooks/exhaustive-deps */
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import GlobalContext from '../../../core/service/globalContext';
import Loader from '../../../core/ui/utility/Loader';
import { Grade } from '../../academic/grade/grade';
import { Student } from '../../academic/student/student';
import StudentSelectionForm from '../components/StudentSelectionForm';

type ContainerProps = {
  onSubmit: () => any;
  submitting: boolean;
  onSetStudentData: (d: { student_id: string; grade_id: string }[]) => void;
};

const StudentSelectContainer = ({
  onSubmit,
  submitting,
  onSetStudentData,
}: ContainerProps) => {
  const { years } = useContext(GlobalContext);
  const [year, setYear] = useState('');
  const [gradeStudents, setGradeStudents] = useState<
    (Grade & { students: Student[] })[]
  >([]);
  const { fetchData, loading } = useFetchApiData();

  useEffect(() => {
    if (year) {
      fetchData(`academic/grade-students/grade-with-students/${year}`, {
        onSuccess: (data) => {
          setGradeStudents(data);
        },
      });
    }
  }, [year]);

  return (
    <>
      <Grid
        container
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ mx: 'auto' }}
      >
        <Grid sx={{ width: '300px', mb: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Select Year to get students from</InputLabel>
            <Select
              fullWidth
              value={year}
              size="small"
              label="Select Year to get students from"
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((year) => (
                <MenuItem value={year.id} key={year.id}>
                  {year.year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Loader loading={loading}>
          {gradeStudents.length ? (
            <StudentSelectionForm
              onSubmit={onSubmit}
              submitting={submitting}
              gradeStudents={gradeStudents}
              onSetStudentData={onSetStudentData}
            />
          ) : (
            <></>
          )}
        </Loader>
      </Grid>
    </>
  );
};

export default StudentSelectContainer;
