import { createContext, useState } from 'react';
import { User } from '../auth';
import constants from '../../../core/utils/constants';
import authService from './authService';

interface ILoginContext {
  user: User | null;
  authLoading: boolean;
  saveUser?: (user: User) => void;
  checkAuth?: () => Promise<void>;
  logoutUser?: () => Promise<void>;
}

const AuthContext = createContext<ILoginContext>({
  user: null,
  authLoading: false,
});

export const useAuthContext = () => {
  const [user, setUser] = useState<User | null>(null);
  const [authLoading, setAuthLoading] = useState(true);

  const checkAuth = async () => {
    setAuthLoading(true);
    const user = authService.getUser();
    if (user && user.token) {
      const apiUser = await authService.checkUserApi(user.token);
      if (apiUser) {
        saveUser(apiUser);
      }
    }
    setAuthLoading(false);
  };

  const saveUser = (user: User) => {
    user.permissions = JSON.parse(user.permissions as unknown as string);
    localStorage.setItem(constants.keys.user, JSON.stringify(user));
    setUser(user);
  };

  const logoutUser = async () => {
    localStorage.removeItem(constants.keys.user);
    setUser(null);
    authService.logoutUserApi(user?.token || '');
  };

  return { user, saveUser, authLoading, checkAuth, logoutUser };
};

export default AuthContext;
