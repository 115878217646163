import { Grid, Typography } from "@mui/material";
import { FormikHelpers } from "formik";
import Layout from "../../../../../core/ui/layout/Layout";
import { useSendApiData } from "../../../../../core/hooks/useSendApiData";
import { toastError, toastMessage } from "../../../../../core/utils/ui/alert";
import { parseValidationErrors } from "../../../../../core/utils/validation";
import { SemesterCreate } from "../semester";
import CreateSemesterForm from "../components/CreateSemesterForm";
import ReturnButton from "../../../../../core/ui/utility/ReturnButton";
import { parseFormQuery } from "../../../../../core/utils/utility";

const CreateSemesterContainer = () => {
  const { callApi, loading: submitting } = useSendApiData();
  

  const handleSubmit = async (
    values: SemesterCreate,
    { setFieldError }: FormikHelpers<SemesterCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, [])

    await callApi({
      endpoint: 'academic/marklist/semesters',
      data: formData,
      headers: {
        'Content-Type': 'application/json'
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Semester Created');
        success = true;
      },
    });

    return success;
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/semester" />}>
      
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Semester</Typography>
        </Grid>
        <CreateSemesterForm  onSubmit={handleSubmit} submitting={submitting} />
      </Grid>
      
    </Layout>
  );
};

export default CreateSemesterContainer;
