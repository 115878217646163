/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddCircle,
  DeleteForever,
  Edit,
  PersonAddAlt,
  Visibility,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import DateModified from '../../../../../core/ui/utility/DateModified';
import Loader from '../../../../../core/ui/utility/Loader';
import { modalStyle } from '../../../../../core/ui/utility/ModalContainer';
import { Recurrent, RecurrentChild } from '../../recurrent';
import DeleteRecurrentDialog from '../dialogs/DeleteRecurrentDialog';
import AddStudentRecurrentPendingForm from '../forms/AddStudentRecurrentPendingForm';
import EditRecurrentPaymentForm from '../forms/EditRecurrentPaymentForm';

const RecurrentPaymentList = () => {
  const { data, loading, fetchData } = useFetchApiData<Recurrent>();
  const [editPayment, setEditPayment] = useState<Recurrent | null>(null);
  const [deletePayment, setDeletePayment] = useState<Recurrent | null>(null);
  const [studentAddPending, setStudentAddPending] = useState<
    RecurrentChild[] | null
  >(null);

  useEffect(() => {
    fetchData('payment-latest/recurrent/active');
  }, []);

  return (
    <>
      <Box sx={{ p: 2, maxWidth: 1000 }}>
        <Grid display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" sx={{ mb: 2 }}>
            Recurrent Payment List
          </Typography>

          <Button component={Link} to="/payment/recurrent/archive" size="small">
            View Archived
          </Button>
        </Grid>

        <Loader loading={loading}>
          <TableContainer component={Paper} sx={{ overflowX: 'auto', mt: 2 }}>
            <Table aria-label="custom pagination table" size="small">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Payment Count</TableCell>
                  <TableCell>Effective Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Modified</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell size="small">{payment.description}</TableCell>
                    <TableCell size="small">
                      {payment.recurrentChildren.length}
                    </TableCell>
                    <TableCell size="small">{payment.effective_date}</TableCell>
                    <TableCell size="small">{payment.end_date}</TableCell>
                    <TableCell component="th" scope="row" sx={{ py: '4px' }}>
                      <DateModified
                        createdAt={payment.created_at}
                        updatedAt={payment.updated_at}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <Grid
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Tooltip title="Search Recurrent Payment">
                          <Link to={`/payment/recurrent/view/${payment.id}`}>
                            <Visibility
                              sx={{ mr: 2, mt: 1 }}
                              fontSize="small"
                              color="info"
                            />
                          </Link>
                        </Tooltip>

                        <Tooltip title="Add Recurrent Payment">
                          <Link to={`/payment/recurrent/add/${payment.id}`}>
                            <AddCircle
                              sx={{ mr: 1, mt: 1 }}
                              fontSize="small"
                              color="success"
                            />
                          </Link>
                        </Tooltip>

                        <Tooltip title="Edit Recurrent Payment">
                          <Edit
                            color="primary"
                            fontSize="small"
                            sx={{ cursor: 'pointer', mr: 1 }}
                            onClick={() => setEditPayment(payment)}
                          />
                        </Tooltip>

                        <Tooltip title="Delete Recurrent Payment">
                          <DeleteForever
                            fontSize="small"
                            color="error"
                            sx={{ cursor: 'pointer', mr: 1 }}
                            onClick={() => setDeletePayment(payment)}
                          />
                        </Tooltip>

                        <Tooltip title="Add Student to Recurrent Payment">
                          <PersonAddAlt
                            fontSize="small"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                              setStudentAddPending(payment.recurrentChildren)
                            }
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </Box>

      {editPayment !== null && (
        <Modal open={editPayment !== null} onClose={() => setEditPayment(null)}>
          <Box sx={{ ...modalStyle, width: 400, p: 2 }}>
            <EditRecurrentPaymentForm
              payment={editPayment!}
              onSuccess={() => {
                setEditPayment(null);
                fetchData('payment-latest/recurrent/active');
              }}
              onCancel={() => setEditPayment(null)}
            />
          </Box>
        </Modal>
      )}

      <DeleteRecurrentDialog
        payment={deletePayment}
        onSuccess={() => {
          setDeletePayment(null);
          fetchData('payment-latest/recurrent/active');
        }}
        cancel={() => setDeletePayment(null)}
      />

      {studentAddPending !== null && (
        <Modal
          open={studentAddPending !== null}
          onClose={() => setStudentAddPending(null)}
        >
          <Box
            sx={{
              ...modalStyle,
              width: 400,
              p: 2,
              maxHeight: 600,
              overflow: 'auto',
            }}
          >
            <AddStudentRecurrentPendingForm
              recurrentChildren={studentAddPending!}
              onSuccess={() => setStudentAddPending(null)}
              onCancel={() => setStudentAddPending(null)}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default RecurrentPaymentList;
