import Layout from '../../../../core/ui/layout/Layout';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import RecurrentArchivedList from '../components/lists/RecurrentArchivedList';

const RecurrentArchivePage = () => {
  return (
    <>
      <Layout
        renderLeftToolbar={() => <ReturnButton to="/payment/recurrent/add" />}
      >
        <RecurrentArchivedList />
      </Layout>
    </>
  );
};

export default RecurrentArchivePage;
