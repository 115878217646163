import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { StagePaymentContext } from '../../../core/state/StagePaymentContext';
import StudentSummary from './StudentSummary';

const StageSummary = () => {
  const [visible, setVisible] = useState(true);

  const [openClearDialog, setOpenClearDialog] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const { payments, removePayment, clearStage, formSubmitting, submitForm } =
    useContext(StagePaymentContext);

  const getAmountTotal = () =>
    payments.map((payment) => payment.amount).reduce((a, b) => a + b, 0);

  const getPenaltyTotal = () =>
    payments.map((payment) => payment.penalty).reduce((a, b) => a + b, 0);

  const total = getAmountTotal() + getPenaltyTotal();

  return (
    <>
      {payments.length ? (
        <Grid
          sx={{
            position: 'fixed',
            top: '58px',
            right: '8px',
            display: 'flex',
            flexDirection: 'column',
            minWidth: '300px',
            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
            bgcolor: 'white',
            py: 1,
            px: 1,
            zIndex: 10,
          }}
        >
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="h5" fontWeight={600}>
              Staging
            </Typography>

            {visible ? (
              <Grid
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
                sx={{ mt: '4px' }}
              >
                <Typography fontWeight={600} fontSize={12}>
                  Attachment: {payments[0].payment.attachment}
                </Typography>
                <Typography fontWeight={600} fontSize={12}>
                  FS: {payments[0].payment.fs}
                </Typography>
                <Typography fontWeight={600} fontSize={12}>
                  Payments: {payments.length}
                </Typography>
                <Typography fontWeight={600} fontSize={12}>
                  Amount Total: {getAmountTotal()}
                </Typography>
                <Typography fontWeight={600} fontSize={12}>
                  Penalty Total: {getPenaltyTotal()}
                </Typography>
                <Typography fontWeight={600} fontSize={12}>
                  Total Fee: {total}
                </Typography>
              </Grid>
            ) : (
              <IconButton onClick={() => setVisible(true)}>
                <Visibility fontSize="small" />
              </IconButton>
            )}
          </Grid>

          {visible && (
            <>
              <Grid sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '350px',
                    overflowY: 'auto',
                    py: 2,
                  }}
                >
                  {payments.map((payment, i) => (
                    <StudentSummary
                      stageInstance={payment}
                      formSubmitting={formSubmitting}
                      key={`stage-summary-${i}`}
                      removeItem={removePayment}
                    />
                  ))}
                </Grid>

                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    py: 0,
                  }}
                >
                  <IconButton onClick={() => setVisible(false)}>
                    <VisibilityOff fontSize="small" />
                  </IconButton>

                  <Grid>
                    <Button
                      color="success"
                      variant="outlined"
                      sx={{ mr: 1 }}
                      size="small"
                      onClick={() => setOpenSubmitDialog(true)}
                    >
                      Finish
                    </Button>
                    <Button
                      color="warning"
                      variant="outlined"
                      size="small"
                      onClick={() => setOpenClearDialog(true)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <></>
      )}

      <Dialog
        open={openClearDialog}
        onClose={() => setOpenClearDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to cancel staged payments?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              clearStage();
              setOpenClearDialog(false);
            }}
            variant="outlined"
            color="success"
            disabled={formSubmitting}
          >
            Agree
          </Button>
          <Button
            onClick={() => setOpenClearDialog(false)}
            variant="outlined"
            color="info"
            disabled={formSubmitting}
          >
            Disagree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSubmitDialog}
        onClose={() => setOpenSubmitDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to submit staged payments?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              submitForm();
              setOpenSubmitDialog(false);
            }}
            variant="outlined"
            color="success"
          >
            Agree
          </Button>
          <Button
            onClick={() => setOpenSubmitDialog(false)}
            variant="outlined"
            color="info"
          >
            Go Back
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StageSummary;
