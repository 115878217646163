import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Loader from '../../../../../core/ui/utility/Loader';
import { EvaluationTypeEdit, EvaluationType } from '../evaluationType';
import EditEvaluationTypeForm from '../components/EditEvaluationTypeForm';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../../../core/utils/utility';

const EditEvaluationTypeContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [evaluationType, setEvaluationType] = useState<EvaluationType | null>(
    null
  );

  const fetchEvaluationType = () =>
    fetchData(`academic/marklist/evaluation-types/${id}`, {
      onSuccess: (data: EvaluationType) => {
        setEvaluationType(data);
      },
    });

  useEffect(() => {
    fetchEvaluationType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: EvaluationTypeEdit,
    { setFieldError }: FormikHelpers<EvaluationTypeEdit>
  ) => {
    let success = false;
    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `academic/marklist/evaluation-types/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        success = true;
        toastMessage('EvaluationType Edited');
      },
    });

    if (success) {
      navigate('/academic/evaluation-type', { replace: true });
    }
  };

  return (
    <Layout
      renderLeftToolbar={() => <ReturnButton to="/academic/evaluation-type" />}
    >
      <Loader loading={loading || !evaluationType}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit EvaluationType</Typography>
          </Grid>
          <EditEvaluationTypeForm
            evaluationType={evaluationType!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditEvaluationTypeContainer;
