import React, { useState } from 'react';
import useFetchApiData from '../hooks/useFetchApiData';
import { Grade } from '../../features/academic/grade/grade';
import { EvaluationType } from '../../features/academic/marklist/evaluationType/evaluationType';
import { AcademicYear } from '../../features/academic/academicYear/academicYear';
import { Semester } from '../../features/academic/marklist/semester/semester';
import { Quarter } from '../../features/academic/marklist/quarter/quarter';
import { logger } from '../utils/logger';

type FetchOptions = { enableLoading?: boolean };

type IGlobalContext = {
  grades: Grade[];
  activeYear: AcademicYear | null;
  years: AcademicYear[];
  evaluationTypes: EvaluationType[];
  quarters: Quarter[];
  semesters: Semester[];
  version: string;
  globalLoading: boolean;
  fetchGlobalData: (options?: FetchOptions) => Promise<void>;
};

const GlobalContext = React.createContext<IGlobalContext>({
  grades: [],
  activeYear: null,
  years: [],
  evaluationTypes: [],
  quarters: [],
  semesters: [],
  version: '0',
  globalLoading: false,
  fetchGlobalData: async () => {},
});

export const useGlobalContext = (): IGlobalContext => {
  const [grades, setGrades] = useState<Grade[]>([]);
  const [years, setYears] = useState<AcademicYear[]>([]);
  const [evaluationTypes, setEvaluationTypes] = useState<EvaluationType[]>([]);
  const [quarters, setQuarters] = useState<Quarter[]>([]);
  const [semesters, setSemesters] = useState<Semester[]>([]);
  const [version, setVersion] = useState('');

  const [activeYear, setActiveYear] = useState<AcademicYear | null>(null);
  const [globalLoading, setGlobalLoading] = useState(false);

  const { fetchData } = useFetchApiData();

  const fetchGlobalData = async ({
    enableLoading = true,
  }: FetchOptions = {}) => {
    logger('fetch global');
    if (enableLoading) setGlobalLoading(true);
    await fetchData('global', {
      onSuccess: (data) => {
        setGrades(data.grades);
        setActiveYear(data.activeYear);
        setYears(data.years);
        setEvaluationTypes(data.evaluationTypes);
        setQuarters(data.quarters);
        setSemesters(data.semesters);
        setVersion(data.version);
        // logger('global success');
      },
    });
    if (enableLoading) setGlobalLoading(false);
    // logger('global done');
  };

  return {
    years,
    activeYear,
    grades,
    evaluationTypes,
    quarters,
    semesters,
    version,
    globalLoading,
    fetchGlobalData,
  };
};

export default GlobalContext;
