import { FormikHelpers, useFormik } from 'formik';
import { QuarterCreate } from '../quarter';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
} from '@mui/material';
import { useContext } from 'react';
import GlobalContext from '../../../../../core/service/globalContext';

type QuarterProps = {
  onSubmit: (
    value: QuarterCreate,
    helpers: FormikHelpers<QuarterCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateQuarterForm = ({ onSubmit, submitting }: QuarterProps) => {
  const { semesters, fetchGlobalData } = useContext(GlobalContext);

  const handleSubmit = async (
    value: QuarterCreate,
    helpers: FormikHelpers<QuarterCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      fetchGlobalData();
    }
  };

  const initialValues: QuarterCreate = { quarter: 0, semester_id: '' };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 300 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="quarter"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Quarter"
                      placeholder=""
                      value={formik.values.quarter}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.quarter)}
                      helperText={formik.errors.quarter}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.semester_id)}
                      fullWidth
                    >
                      <InputLabel>Semester</InputLabel>
                      <Select
                        value={formik.values.semester_id}
                        size="small"
                        label="Semester"
                        onChange={formik.handleChange}
                        name="semester_id"
                      >
                        {semesters.map((semester) => (
                          <MenuItem key={semester.id} value={semester.id}>
                            {semester.semester}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(formik.errors.semester_id) && (
                        <FormHelperText>
                          {formik.errors.semester_id}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateQuarterForm;
