import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Layout from '../../../../core/ui/layout/Layout';
import Loader from '../../../../core/ui/utility/Loader';
import useGradeStudentSelector from '../../gradeStudent/hooks/useGradeStudentSelector';
import StudentAttendanceForm from '../../homeRoom/components/StudentAttendanceForm';

const AdminCreateStudentAttendancePage = () => {
  const { grades, grade, setGrade, studentsLoading, students } =
    useGradeStudentSelector();

  return (
    <>
      <Layout>
        <Box sx={{ p: 2, mbb: 4 }}>
          <Grid container>
            <Grid item>
              <FormControl
                sx={{ minWidth: 200, width: 'fit-content' }}
                fullWidth
              >
                <InputLabel>Grade</InputLabel>
                <Select
                  fullWidth
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  label="From Grade"
                  size="small"
                >
                  {grades.map((grade) => (
                    <MenuItem value={grade.id} key={grade.id}>
                      {grade.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {grade && (
            <Loader loading={studentsLoading}>
              <StudentAttendanceForm
                grade={grades.find((i) => i.id === grade)!}
                students={students}
              />
            </Loader>
          )}
        </Box>
      </Layout>
    </>
  );
};

export default AdminCreateStudentAttendancePage;
