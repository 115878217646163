/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../../core/service/globalContext';
import Loader from '../../../../../core/ui/utility/Loader';
import { LoadingFit } from '../../../../../core/ui/utility/Loading';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import { Grade } from '../../../../academic/grade/grade';
import { Student } from '../../../../academic/student/student';
import { RecurrentAddStudentCreate, RecurrentChild } from '../../recurrent';

type AddStudentPendingProps = {
  recurrentChildren: RecurrentChild[];
  onSuccess: () => void;
  onCancel: () => void;
};

const AddStudentRecurrentPendingForm = ({
  recurrentChildren,
  onSuccess,
  onCancel,
}: AddStudentPendingProps) => {
  const { years } = useContext(GlobalContext);
  const [year, setYear] = useState('');
  const [students, setStudents] = useState<Student[]>([]);
  const [gradeStudents, setGradeStudents] = useState<
    (Grade & { students: Student[] })[]
  >([]);
  const { fetchData, loading } = useFetchApiData();
  const { loading: submitting, callApi } = useSendApiData();

  const initialValues: RecurrentAddStudentCreate = {
    student_id: '',
    grade_id: '',
    payments: [],
  };

  const formik = useFormik<RecurrentAddStudentCreate>({
    initialValues,
    onSubmit: async (
      value,
      { setFieldError }: FormikHelpers<RecurrentAddStudentCreate>
    ) => {
      let success = false;
      await callApi({
        endpoint: 'payment-latest/recurrent/pending/add-student',
        data: value,
        onSuccess: () => {
          toastMessage('Student assigned to payment');
          success = true;
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
      });

      if (success) onSuccess();
    },
  });

  useEffect(() => {
    if (year) {
      fetchData(`academic/grade-students/grade-with-students/${year}`, {
        onSuccess: (data) => {
          setGradeStudents(data);
        },
      });
    }
  }, [year]);

  useEffect(() => {
    if (formik.values.grade_id) {
      formik.setFieldValue('student_id', '');
      const grade = gradeStudents.find((i) => i.id === formik.values.grade_id);
      setStudents(grade?.students || []);
    }
  }, [formik.values.grade_id]);

  const hasError = (index: number) =>
    !!formik.errors.payments && !!formik.errors.payments[index];

  const getError = (index: number) => {
    if (formik.errors.payments && formik.errors.payments![index]) {
      return formik.errors.payments![index] as any;
    }

    return '';
  };

  const getDescription = (recurrentId: string) => {
    return recurrentChildren.find((i) => i.id === recurrentId)!.description;
  };

  return (
    <>
      <Grid sx={{}}>
        <FormControl fullWidth>
          <InputLabel>Select Year to get students from</InputLabel>
          <Select
            fullWidth
            value={year}
            size="small"
            label="Select Year to get students from"
            onChange={(e) => setYear(e.target.value)}
          >
            {years
              .sort((a, b) => b.year - a.year)
              .map((year) => (
                <MenuItem value={year.id} key={year.id}>
                  {year.year}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <Loader loading={loading} loaderComponent={<LoadingFit />}>
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={8}>
            <Grid container spacing={1} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Grade</InputLabel>
                <Select
                  value={formik.values.grade_id}
                  size="small"
                  label="Grade"
                  name="grade_id"
                  onChange={formik.handleChange}
                  fullWidth
                >
                  {gradeStudents.map((grade) => (
                    <MenuItem key={grade.id} value={grade.id}>
                      {grade.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.grade_id && formik.errors.grade_id && (
                  <FormHelperText error>
                    {formik.errors.grade_id}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid container spacing={1} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Student</InputLabel>
                <Select
                  value={formik.values.student_id}
                  size="small"
                  label="Student"
                  name="student_id"
                  onChange={formik.handleChange}
                  fullWidth
                >
                  {students
                    .sort((a, b) => a.first_name.localeCompare(b.first_name))
                    .map((student) => (
                      <MenuItem key={student.id} value={student.id}>
                        {student.first_name} {student.father_name}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.student_id && formik.errors.student_id && (
                  <FormHelperText error>
                    {formik.errors.student_id}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {formik.values.payments.map((payment, i) => (
              <Grid container key={`active-${payment}`}>
                <Grid item>
                  <FormControl error>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`payments[${i}]`}
                          checked={true}
                          size="small"
                          onClick={(e) => {
                            const curPayments = [...formik.values.payments];

                            const index = curPayments.findIndex(
                              (i) => i === payment
                            );

                            if (index !== -1) {
                              curPayments.splice(index, 1);
                              formik.setFieldValue('payments', curPayments);
                            }
                          }}
                        />
                      }
                      label={getDescription(payment)}
                    />

                    {hasError(i) && (
                      <FormHelperText>{getError(i)}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            ))}

            <Divider sx={{ my: 1 }} />

            {recurrentChildren
              .filter((child) => !formik.values.payments.includes(child.id))
              .map((recurrent, i) => (
                <Grid container key={recurrent.id}>
                  <Grid item>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`payments[${i}]`}
                            checked={false}
                            size="small"
                            onClick={(e) => {
                              const curPayments = [...formik.values.payments];

                              formik.setFieldValue('payments', [
                                ...curPayments,
                                recurrent.id,
                              ]);
                            }}
                          />
                        }
                        label={recurrent.description}
                      />

                      {hasError(formik.values.payments.length + i) && (
                        <FormHelperText>
                          {getError(formik.values.payments.length + i)}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              ))}

            <Grid container sx={{ mt: 3 }} justifyContent="flex-end">
              <Button
                type="submit"
                color="success"
                variant="outlined"
                disabled={loading || submitting}
                size="small"
                sx={{ mr: 2 }}
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>

              <Button
                type="button"
                variant="outlined"
                onClick={onCancel}
                disabled={loading || submitting}
                size="small"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Loader>
    </>
  );
};

export default AddStudentRecurrentPendingForm;
