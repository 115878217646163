import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Rcq } from '../../marklist/reportCard/models/rcq';
import { Rcs } from '../../marklist/reportCard/models/rcs';
import { Rcy } from '../../marklist/reportCard/models/rcy';
import { printMark } from '../../marklist/reportCard/services/reportCardService';
import {
  sortRcqCsts,
  sortRcsCsts,
  sortRcyCsts,
} from '../services/studentService';

export const StudentQuarterReport = ({ rcqs }: { rcqs: Rcq[] }) => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Quarter Rosters
      </Typography>
      {rcqs.length ? (
        rcqs.map((rcq) => (
          <Grid key={rcq.id} sx={{ mb: 2 }}>
            <TableContainer
              component={Paper}
              sx={{
                overflowX: 'auto',
                minWidth: 800,
                maxWidth: 1600,
              }}
            >
              <Table aria-label="custom pagination table" size="medium">
                <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                  <TableRow>
                    <TableCell size="small">Quarter</TableCell>
                    {sortRcqCsts(rcq.rcqCsts).map((rcqCst) => (
                      <TableCell key={rcqCst.id}>
                        {rcqCst.cst.subject.subject}
                      </TableCell>
                    ))}
                    <TableCell>Total</TableCell>
                    <TableCell>Average</TableCell>
                    <TableCell>Rank</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={rcq.id}>
                    <TableCell size="small">{rcq.quarter.quarter}</TableCell>
                    {sortRcqCsts(rcq.rcqCsts).map((cst) => (
                      <TableCell key={`quarter-mark-${cst.id}`}>
                        {printMark(cst.score)}
                      </TableCell>
                    ))}

                    <TableCell>{printMark(rcq.total_score)}</TableCell>
                    <TableCell>{printMark(rcq.average)}</TableCell>
                    <TableCell>{printMark(rcq.rank)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))
      ) : (
        <Typography>None</Typography>
      )}
    </>
  );
};

export const StudentSemesterReport = ({ rcss }: { rcss: Rcs[] }) => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
        Semester Rosters
      </Typography>
      {rcss.length ? (
        rcss.map((rcs) => (
          <Grid key={rcs.id} sx={{ mb: 2 }}>
            <TableContainer
              component={Paper}
              sx={{
                overflowX: 'auto',
                minWidth: 800,
                maxWidth: 1600,
              }}
            >
              <Table aria-label="custom pagination table" size="medium">
                <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                  <TableRow>
                    <TableCell size="small">Semester</TableCell>
                    {sortRcsCsts(rcs.rcsCsts).map((rcsCst) => (
                      <TableCell key={rcsCst.id}>
                        {rcsCst.cst.subject.subject}
                      </TableCell>
                    ))}
                    <TableCell>Total</TableCell>
                    <TableCell>Average</TableCell>
                    <TableCell>Rank</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={rcs.id}>
                    <TableCell size="small">{rcs.semester.semester}</TableCell>
                    {sortRcsCsts(rcs.rcsCsts).map((cst) => (
                      <TableCell key={`semester-mark-${cst.id}`}>
                        {printMark(cst.score)}
                      </TableCell>
                    ))}

                    <TableCell>{printMark(rcs.total_score)}</TableCell>
                    <TableCell>{printMark(rcs.average)}</TableCell>
                    <TableCell>{printMark(rcs.rank)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ))
      ) : (
        <Typography>None</Typography>
      )}
    </>
  );
};

export const StudentYearReport = ({ rcy }: { rcy: Rcy | null }) => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 2, mt: 4 }}>
        Year Roster
      </Typography>
      {rcy !== null ? (
        <Grid key={rcy.id} sx={{ mb: 2 }}>
          <TableContainer
            component={Paper}
            sx={{
              overflowX: 'auto',
              minWidth: 800,
              maxWidth: 1600,
            }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell size="small">Year</TableCell>
                  {sortRcyCsts(rcy.rcyCsts).map((rcyCst) => (
                    <TableCell key={rcyCst.id}>
                      {rcyCst.cst.subject.subject}
                    </TableCell>
                  ))}
                  <TableCell>Total</TableCell>
                  <TableCell>Average</TableCell>
                  <TableCell>Rank</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={rcy.id}>
                  <TableCell size="small">{rcy.academicYear.year}</TableCell>
                  {sortRcyCsts(rcy.rcyCsts).map((cst) => (
                    <TableCell key={`semester-mark-${cst.id}`}>
                      {printMark(cst.score)}
                    </TableCell>
                  ))}

                  <TableCell>{printMark(rcy.total_score)}</TableCell>
                  <TableCell>{printMark(rcy.average)}</TableCell>
                  <TableCell>{printMark(rcy.rank)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Typography>None</Typography>
      )}
    </>
  );
};
