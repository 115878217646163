import Layout from '../../../../core/ui/layout/Layout';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import StageContainer from '../../components/StageContainer';
import PendingFixedPayment from '../components/lists/PendingFixedPayment';

const AddFixedPaymentPage = () => {
  return (
    <>
      <Layout
        renderLeftToolbar={() => <ReturnButton to="/payment/fixed/add" />}
      >
        <StageContainer>
          <PendingFixedPayment />
        </StageContainer>
      </Layout>
    </>
  );
};

export default AddFixedPaymentPage;
