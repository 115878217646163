/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import Layout from '../../../../core/ui/layout/Layout';
import CreateTeacherForm from '../components/CreateTeacherForm';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import Loader from '../../../../core/ui/utility/Loader';
import useFetchNonTeachers from '../hooks/useFetchNonTeachers';

const CreateTeacherContainer = () => {
  const { users, loading, fetchUsers } = useFetchNonTeachers();

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/teacher" />}>
      <Grid sx={{ p: 2 }}>
        <Grid container sx={{ mb: 2, px: 1 }}>
          <Typography variant="h5">Add Teacher</Typography>
        </Grid>
        <Loader loading={loading}>
          <CreateTeacherForm users={users} fetchUsers={fetchUsers} />
        </Loader>
      </Grid>
    </Layout>
  );
};

export default CreateTeacherContainer;
