import { SortOp } from '../ui/table/tableTypes';

export const sortByText = <T>(
  data: T[],
  fieldGetter: (a: any) => string,
  op: SortOp
) => {
  const curData = [...data];
  const comparator = (a: any, b: any) =>
    op === 'asc'
      ? fieldGetter(a).toLowerCase().localeCompare(fieldGetter(b).toLowerCase())
      : fieldGetter(b)
          .toLowerCase()
          .localeCompare(fieldGetter(a).toLowerCase());
  curData.sort(comparator);

  return curData;
};

export const permissionChecker = {
  any: (arr: string[], target: string[]) => target.some((i) => arr.includes(i)),
  all: (arr: string[], target: string[]) =>
    target.every((i) => arr.includes(i)),
};

export const hasPermission = (
  userPermissions: string[],
  checkList: string[],
  mode: 'any' | 'all' = 'all'
) => permissionChecker[mode](userPermissions, checkList);
