import { FormikHelpers, useFormik } from 'formik';
import { SubjectEdit, Subject } from '../subject';
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Paper,
  Box,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

type SubjectProps = {
  onSubmit: (
    value: SubjectEdit,
    helpers: FormikHelpers<SubjectEdit>
  ) => Promise<void>;
  submitting: boolean;
  subject: Subject;
};

const EditSubjectForm = ({ onSubmit, submitting, subject }: SubjectProps) => {
  const handleSubmit = async (
    value: SubjectEdit,
    helpers: FormikHelpers<SubjectEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: SubjectEdit = {
    subject: subject.subject,
    code: subject.code,
    consider_for_rank: !!subject.consider_for_rank,
    order: subject.order || 0,
    show_on_report: !!subject.show_on_report,
    display_mode: subject.display_mode,
    report_card_template: subject.report_card_template,
    A: subject.display_rules.A,
    B: subject.display_rules.B,
    C: subject.display_rules.C,
    D: subject.display_rules.D,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const evalKeys = ['A', 'B', 'C', 'D'];

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 700 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 2 }}>
                    <TextField
                      fullWidth
                      name="subject"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Subject"
                      placeholder=""
                      value={formik.values.subject}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.subject)}
                      helperText={formik.errors.subject}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 2 }}>
                    <TextField
                      fullWidth
                      name="code"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Code"
                      placeholder=""
                      value={formik.values.code}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.code)}
                      helperText={formik.errors.code}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 2 }}>
                    <TextField
                      fullWidth
                      name="order"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Order"
                      placeholder=""
                      value={formik.values.order}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.order)}
                      helperText={formik.errors.order}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Display mode</InputLabel>
                    <Select
                      value={formik.values.display_mode}
                      size="small"
                      label="Display mode"
                      name="display_mode"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value="number">Number</MenuItem>
                      <MenuItem value="letter">Letter</MenuItem>
                    </Select>
                    {formik.touched.display_mode &&
                      formik.errors.display_mode && (
                        <FormHelperText error>
                          {formik.errors.display_mode}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                {formik.values.display_mode === 'letter' && (
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    sx={{ mt: '4px' }}
                  >
                    <Grid
                      item
                      alignSelf="flex-start"
                      sx={{ flexGrow: 2 }}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      {evalKeys.map((key) => (
                        <Grid
                          item
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          key={key}
                        >
                          <Typography variant="h6" sx={{ mr: 1 }}>
                            {key} {`>=`}
                          </Typography>

                          <TextField
                            size="small"
                            sx={{
                              width: '60px',
                              input: {
                                px: 1,
                                textAlign: 'center',
                              },
                            }}
                            name={key}
                            onChange={formik.handleChange}
                            value={formik.values[key as keyof SubjectEdit]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel>Report card template</InputLabel>
                    <Select
                      value={formik.values.report_card_template}
                      size="small"
                      label="Report card template"
                      name="report_card_template"
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="main">Main</MenuItem>
                      <MenuItem value="nursery">Nursery</MenuItem>
                    </Select>
                    {formik.touched.report_card_template &&
                      formik.errors.report_card_template && (
                        <FormHelperText error>
                          {formik.errors.report_card_template}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="show_on_report"
                            checked={formik.values.show_on_report}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Show on report"
                      />
                      {Boolean(formik.errors.show_on_report) && (
                        <FormHelperText>
                          {formik.errors.show_on_report}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 1 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl error>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="consider_for_rank"
                            checked={formik.values.consider_for_rank}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Consider for rank"
                      />
                      {Boolean(formik.errors.consider_for_rank) && (
                        <FormHelperText>
                          {formik.errors.consider_for_rank}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditSubjectForm;
