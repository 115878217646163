import { useState } from 'react';
import tableServices from '../tableServices';
import { SortOp } from '../tableTypes';

const useSorting = (modelToken: string) => {
  const savedSort = tableServices.getSorting(modelToken);
  const [sortField, setSortField] = useState(savedSort.sortField);
  const [sortOp, setSortOp] = useState<SortOp>(savedSort.sortOp);
  const [includeSort, setIncludeSort] = useState(savedSort.includeSort);

  const handleSort = (field: string) => {
    setIncludeSort(true);
    let orderOp: SortOp = 'asc';

    if (sortField === field) {
      orderOp = sortOp === 'asc' ? 'desc' : 'asc';
    }

    setSortField(field);
    setSortOp(orderOp);
    tableServices.saveSorting(modelToken, field, orderOp, true);
  };

  const handleCustomSort = (field: string, cb: (op: SortOp) => void) => {
    setIncludeSort(false);
    let orderOp: SortOp = 'asc';

    if (sortField === field) {
      orderOp = sortOp === 'asc' ? 'desc' : 'asc';
    }

    setSortField(field);
    setSortOp(orderOp);
    tableServices.saveSorting(modelToken, field, orderOp, false);

    cb(orderOp);
  };

  return {
    sortField,
    sortOp,
    includeSort,
    handleSort,
    handleCustomSort,
  };
};

export default useSorting;
