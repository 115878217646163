import { Student } from '../../features/academic/student/student';
import { User as AuthUser } from '../../features/auth/auth';
import { User } from '../../features/user/user';

export const printUser = (user: User | AuthUser) =>
  `${user.first_name} ${user.father_name}`;

export const getStudentName = ({
  first_name,
  father_name,
  grand_father_name,
}: Student) => `${first_name} ${father_name || ''} ${grand_father_name || ''}`;
