/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { toastError } from '../../../../core/utils/ui/alert';
import Loader from '../../../../core/ui/utility/Loader';
import { Student } from '../student';
import {
  StudentQuarterReport,
  StudentSemesterReport,
  StudentYearReport,
} from '../components/StudentReportCards';
import { sortRcqs, sortRcss } from '../services/studentService';
import SkillComponent from '../components/skill/SkillComponent';
import { PictureAsPdf } from '@mui/icons-material';
import NurserySkillComponent from '../components/skill/NurserySkillComponent';
import ConductComponent from '../components/conduct/ConductComponent';
import constants from '../../../../core/utils/constants';

const StudentDetailContainer = () => {
  const { id } = useParams();
  const [student, setStudent] = useState<Student | null>(null);
  const { fetchData: fetchStudent, loading: studentLoading } =
    useFetchApiData();

  useEffect(() => {
    fetchStudent(`academic/students/${id}`, {
      onSuccess: (data) => {
        setStudent(data);
      },
      onError: toastError,
    });
  }, []);

  return (
    <>
      <Grid container sx={{ p: 2 }} display="flex" flexDirection="column">
        <Loader loading={studentLoading}>
          {student !== null && (
            <>
              <Box
                sx={{
                  border: '1px solid lightblue',
                  borderColor: 'grey.200',
                  borderLeft: '5px solid',
                  borderLeftColor: '#1565C0',
                  cursor: 'pointer',
                  p: 2,
                  maxWidth: 500,
                  mb: 2,
                  bgcolor: 'white',
                  boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.06)',
                }}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Grid
                  item
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography sx={{ mr: 2 }}>Name </Typography>
                  <Typography variant="body2" fontWeight={700}>
                    {student?.first_name} {student?.father_name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography sx={{ mr: 2 }}>Grade </Typography>
                  <Typography variant="body2" fontWeight={700}>
                    {student.gradeStudents.length
                      ? student.gradeStudents[0].grade.name
                      : 'Not Assigned'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mt: 1 }}
                >
                  <Typography sx={{ mr: 2 }}>Actions </Typography>

                  {student.gradeStudents.length && (
                    <a
                      href={`${constants.apiUrl}/academic/marklist/report-card/generate-pdf/student/${student?.gradeStudents[0].id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <PictureAsPdf sx={{ mr: 1 }} color="primary" />
                        <Typography color="primary">Print Roster</Typography>
                      </span>
                    </a>
                  )}
                </Grid>
              </Box>

              <Box>
                {student.gradeStudents.length ? (
                  student.gradeStudents[0].grade.skill_template === 'main' ? (
                    <SkillComponent
                      studentId={id as string}
                      gsId={student.gradeStudents[0].id}
                    />
                  ) : (
                    <NurserySkillComponent
                      studentId={id as string}
                      gsId={student.gradeStudents[0].id}
                    />
                  )
                ) : (
                  <></>
                )}

                {student.gradeStudents.length ? (
                  <ConductComponent
                    studentId={id as string}
                    gsId={student.gradeStudents[0].id}
                  />
                ) : (
                  <></>
                )}

                <StudentQuarterReport rcqs={sortRcqs(student.rcqs || [])} />

                <StudentSemesterReport rcss={sortRcss(student.rcss || [])} />

                <StudentYearReport rcy={student.rcy || null} />
              </Box>
            </>
          )}
        </Loader>
      </Grid>
    </>
  );
};

export default StudentDetailContainer;
