import YearReportCardContainer from '../containers/YearReportCardContainer';

const YearReportCardPage = () => {
  return (
    <>
      <YearReportCardContainer />
    </>
  );
};

export default YearReportCardPage;
