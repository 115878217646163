import { useContext } from 'react';
import AuthContext from '../../../features/auth/service/authContext';
import { permissionChecker } from '../../utils/data';
import { ReactChildren } from '../../utils/types';

type ShowIfHasProps = {
  mode?: 'any' | 'all';
  permissions: string[];
} & ReactChildren;

const ShowIfHas = ({ mode = 'all', permissions, children }: ShowIfHasProps) => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {permissionChecker[mode](user!.permissions, permissions) ? (
        children
      ) : (
        <></>
      )}
    </>
  );
};

export default ShowIfHas;
