import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import Layout from '../../../../core/ui/layout/Layout';
import Loader from '../../../../core/ui/utility/Loader';
import { useCreateActiveness } from '../hooks/useActivenessController';

const SetActivenessPage = () => {
  const {
    loading,
    fixedPayments,
    formik,
    years,
    gradeSelection,
    setGradeSelection,
    submitting,
  } = useCreateActiveness();

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <Loader loading={loading}>
            <Grid item sx={{ mb: 2 }}>
              <Typography variant="h4">Set students Activeness Rule</Typography>
            </Grid>
            <Grid item sx={{ mb: 2 }}>
              <Paper
                sx={{
                  p: 2,
                  border: '1px solid blue',
                  width: 'fit-content',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" fontWeight="bold" sx={{ mr: 2 }}>
                  Note:
                </Typography>
                Change Students grade before performing this operation on
                selected year
              </Paper>
            </Grid>
            <Grid item display="flex" alignItems="flex-start">
              <Grid item display="flex" flexDirection="column" sx={{ mx: 2 }}>
                {gradeSelection.map((selection, i) => (
                  <FormControl id={selection.grade.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selection.selected}
                          onChange={(e) => {
                            const curSelections = [...gradeSelection];
                            curSelections[i].selected = e.target.checked;
                            setGradeSelection(curSelections);
                          }}
                        />
                      }
                      label={`${selection.grade.name} `}
                    />
                  </FormControl>
                ))}
                {formik.touched.grade_ids && formik.errors.grade_ids && (
                  <FormHelperText error>
                    {JSON.stringify(formik.errors.grade_ids)}
                  </FormHelperText>
                )}
              </Grid>

              <FormControl fullWidth sx={{ mr: 2, maxWidth: 200 }}>
                <InputLabel>Year</InputLabel>
                <Select
                  fullWidth
                  disabled
                  label="Year"
                  size="small"
                  name="academic_year_id"
                  value={formik.values.academic_year_id}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.academic_year_id)}
                >
                  {years.map((year) => (
                    <MenuItem value={year.id} key={year.id}>
                      {year.year}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.academic_year_id &&
                  formik.errors.academic_year_id && (
                    <FormHelperText error>
                      {formik.errors.academic_year_id}
                    </FormHelperText>
                  )}
              </FormControl>

              <FormControl fullWidth sx={{ mr: 2, maxWidth: 200 }}>
                <InputLabel>Fixed Payment</InputLabel>
                <Select
                  fullWidth
                  label="Fixed Payment"
                  size="small"
                  name="fixed_payment_id"
                  value={formik.values.fixed_payment_id}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.fixed_payment_id)}
                >
                  {fixedPayments.map((payment) => (
                    <MenuItem value={payment.id} key={payment.id}>
                      {payment.description}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fixed_payment_id &&
                  formik.errors.fixed_payment_id && (
                    <FormHelperText error>
                      {formik.errors.fixed_payment_id}
                    </FormHelperText>
                  )}
              </FormControl>

              <Button
                variant="contained"
                size="small"
                disabled={submitting}
                onClick={formik.submitForm}
              >
                Save
              </Button>
            </Grid>
          </Loader>
        </Box>
      </Layout>
    </>
  );
};

export default SetActivenessPage;
