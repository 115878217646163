import { useState } from 'react';
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  ResponseType,
} from 'axios';
import constants from '../utils/constants';
import { ErrorHandler, SuccessHandler } from '../utils/types';
import authService from '../../features/auth/service/authService';
import { logError } from '../utils/logger';

type CallApiArg = {
  headers?: AxiosRequestHeaders;
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
  method?: 'get' | 'post';
  body?: Record<string, any>;
  responseType?: ResponseType;
  dataAccessor?: (res: any) => any;
};

const useFetchApiData = <T = any>() => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T[]>([]);

  const fetchData = async (
    endpointUrl: string,
    {
      headers = {},
      onSuccess,
      onError,
      method = 'get',
      body = {},
      responseType = 'json',
      dataAccessor = (res: any) => res.data,
    }: CallApiArg = {}
  ) => {
    const token = `Bearer ${authService.getToken()}`;

    setLoading(true);
    try {
      let res: AxiosResponse<any, any>;

      if (method === 'get') {
        res = await axios[method](`${constants.apiUrl}/${endpointUrl}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: token,
            ...headers,
          },
          responseType,
        });
      } else {
        res = await axios[method](`${constants.apiUrl}/${endpointUrl}`, body, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: token,
            ...headers,
          },
          responseType,
        });
      }

      setData(dataAccessor(res));
      if (onSuccess) onSuccess(dataAccessor(res), res.status);
    } catch (e) {
      const apiErr = e as AxiosError;
      logError('Api Error', apiErr);
      logError('Res Data', apiErr?.response?.data);

      if (onError) {
        onError(
          apiErr.response?.data?.message ||
            apiErr.message ||
            'Unknown Error occurred'
        );
      }
    }
    setLoading(false);
  };

  return { fetchData, loading, data, setData };
};

export default useFetchApiData;
