/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import GlobalContext from '../../../../core/service/globalContext';
import { Student } from '../../student/student';

const useStudentSelector = () => {
  const { grades, activeYear, years } = useContext(GlobalContext);
  const [printFormat, setPrintFormat] = useState<'nursery' | 'main'>('main');

  const [year, setYear] = useState(activeYear?.id || '');
  const [grade, setGrade] = useState('');
  const [students, setStudents] = useState<
    { student: Student; selected: boolean }[]
  >([]);
  const { fetchData: fetchStudents, loading } = useFetchApiData();

  const getSelectedGrade = () => grades.find((i) => i.id === grade)!;

  const getSelectedYear = () => years.find((i) => i.id === year)!;

  useEffect(() => {
    if (year && grade) {
      fetchStudents(`academic/grade-students/year-students/${grade}/${year}`, {
        onSuccess: (data: Student[]) => {
          setStudents(data.map((student) => ({ student, selected: true })));
        },
      });
    }
  }, [year, grade]);

  return {
    year,
    setYear,
    grade,
    setGrade,
    students,
    setStudents,
    grades,
    years,
    loading,
    printFormat,
    setPrintFormat,
    getSelectedGrade,
    getSelectedYear,
  };
};

export default useStudentSelector;
