import { Edit, Visibility } from '@mui/icons-material';
import { Box, Grid, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import Layout from '../../../../core/ui/layout/Layout';
import TableContainer from '../../../../core/ui/table/TableContainer';
import { TableHeader } from '../../../../core/ui/table/tableTypes';
import { Paginated } from '../../../../core/utils/types';
import { parseQuery } from '../../../../core/utils/utility';
import { Student } from '../student';

const tableHeaders: TableHeader[] = [
  { field: 'first_name', label: 'First Name', align: 'left' },
  { field: 'father_name', label: 'Father Name', align: 'left' },
  { field: 'grand_father_name', label: 'G/Father Name', align: 'left' },
  { field: 'gender', label: 'Gender', align: 'left' },
  { field: 'id_number', label: 'ID Number', align: 'left' },
  { field: 'status', label: 'Status', align: 'left' },
];
const StudentList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const { fetchData } = useFetchApiData();

  const handleFetchData = async (query: any, cb: (c: number) => void) => {
    await fetchData(`academic/students/search${parseQuery(query)}`, {
      method: 'post',
      body: {
        // orderBies: parseOrdering(query),
        orderBies: [{ field: 'first_name', op: 'asc' }],
      },
      onSuccess: ({ total, data: apiData }: Paginated<Student>) => {
        setData(apiData);
        cb(total);
      },
    });
  };

  const renderActions = (student: Student) => {
    return (
      <>
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Tooltip title="Edit Conduct and skills">
            <Link to={`/academic/student/view/${student.id}`}>
              <Visibility fontSize="small" sx={{ mr: 1, fontSize: '15px' }} />
            </Link>
          </Tooltip>

          <Tooltip title="Edit Student">
            <Link to={`/academic/student/edit/${student.id}`}>
              <Edit fontSize="small" sx={{ mr: 1, fontSize: '15px' }} />
            </Link>
          </Tooltip>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2, maxWidth: 1400 }}>
          <TableContainer
            modelToken="student"
            tableHeaders={tableHeaders}
            data={data}
            modelLabel="Students"
            actions={{
              onFetchData: handleFetchData,
            }}
            routes={{
              edit: '/academic/student/edit',
              create: '/academic/student/create',
              delete: '/student',
            }}
            settings={{
              canCreate: true,
              canEdit: true,
              canDelete: false,
              canViewItem: false,
            }}
            renderActions={renderActions}
          />
        </Box>
      </Layout>
    </>
  );
};

export default StudentList;
