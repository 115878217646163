import React, { useState } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { FormikErrors, FormikTouched } from 'formik';
import DateTimePicker from 'react-datetime-picker';
import { PenaltyCreate, RecurrentCreate } from '../../recurrent';
import moment from 'moment';
import CreateRecurrentPenaltyForm from './CreateRecurrentPenaltyForm';

type RecurrentProps = {
  formik: {
    values: RecurrentCreate;
    errors: FormikErrors<RecurrentCreate>;
    touched: FormikTouched<RecurrentCreate>;
    setFieldValue: (f: string, v: any) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
  };
  onGeneratePayment: (paymentCount: number) => void;
  onFinalize: () => void;
  penalty: PenaltyCreate;
  setPenalty: (p: PenaltyCreate) => void;
  submitting: boolean;
  childrenGenerated: boolean;
  cancelPayment: () => void;
};

const CreateRecurrentForm = ({
  formik,
  onGeneratePayment,
  onFinalize,
  penalty,
  setPenalty,
  submitting,
  childrenGenerated,
  cancelPayment,
}: RecurrentProps) => {
  const [paymentCount, setPaymentCount] = useState(0);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 600 }}>
        <Paper sx={{ p: 4, mb: 2 }}>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mx: 'auto', mb: 2 }}
          >
            Create Recurrent Payment
          </Typography>

          <Grid container sx={{ mt: 1 }} spacing={1}>
            <Grid
              item
              sx={{
                flexGrow: 1,
                padding: '0 !important',
                pr: 2,
              }}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  paddingLeft: '8px',
                  width: '100%',
                }}
              >
                <Typography sx={{ p: 0, mr: 1 }}>Effective Date</Typography>
                <Grid item alignItems="flex-end">
                  <DateTimePicker
                    onChange={(e) => {
                      formik.setFieldValue('effective_date', e);
                    }}
                    value={formik.values.effective_date || new Date()}
                    disableClock
                    format="y-MM-dd"
                    clearIcon={null}
                  />
                </Grid>
              </div>
              {formik.touched.effective_date &&
                formik.errors.effective_date && (
                  <FormHelperText error>
                    {formik.errors.effective_date}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 1 }} spacing={1}>
            <Grid
              item
              sx={{
                flexGrow: 1,
                padding: '0 !important',
                pl: 2,
              }}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-end',
                  justifyContent: 'space-between',
                  paddingLeft: '8px',
                  width: '100%',
                }}
              >
                <Typography sx={{ p: 0, mr: 1 }}>End Date</Typography>
                <Grid item alignItems="flex-end">
                  <DateTimePicker
                    onChange={(e) => {
                      formik.setFieldValue('end_date', e);
                    }}
                    value={formik.values.end_date || new Date()}
                    disableClock
                    format="y-MM-dd"
                    clearIcon={null}
                  />
                </Grid>
              </div>
              {formik.touched.end_date && formik.errors.end_date && (
                <FormHelperText error>{formik.errors.end_date}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="description"
                size="small"
                type="text"
                variant="outlined"
                label="Description"
                placeholder=""
                value={formik.values.description}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                helperText={formik.errors.description}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="amount"
                size="small"
                type="number"
                variant="outlined"
                label="Amount"
                placeholder=""
                value={formik.values.amount}
                onChange={formik.handleChange}
                error={Boolean(formik.touched.amount && formik.errors.amount)}
                helperText={formik.errors.amount}
              />
            </Grid>
          </Grid>

          {childrenGenerated ? (
            <Grid
              container
              spacing={1}
              sx={{ mt: 2 }}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                onClick={() => cancelPayment()}
                variant="contained"
                disabled={submitting}
              >
                Cancel
              </Button>

              <Button
                onClick={onFinalize}
                variant="contained"
                disabled={submitting}
                color="success"
              >
                Finalize Payment
              </Button>
            </Grid>
          ) : (
            <>
              <CreateRecurrentPenaltyForm
                penalty={penalty}
                setPenalty={setPenalty}
              />

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item flexGrow={1}>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    value={paymentCount}
                    variant="outlined"
                    label="Payment Count(*at least 2)"
                    inputProps={{ min: 0 }}
                    placeholder=""
                    onChange={(e) => setPaymentCount(+e.target.value)}
                    helperText="Total number of payments between effective and end date"
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={
                      paymentCount <= 1 ||
                      moment(formik.values.end_date).diff(
                        moment(formik.values.effective_date),
                        'days'
                      ) <= 0
                    }
                    variant="contained"
                    onClick={() => onGeneratePayment(paymentCount)}
                  >
                    Generate
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default CreateRecurrentForm;
