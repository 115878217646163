/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from '@mui/icons-material';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import Layout from '../../../../core/ui/layout/Layout';
import ShowIfHas from '../../../../core/ui/permission/ShowIfHas';
import SummaryBlock from '../../../../core/ui/table/components/SummaryBlock';
import DateModified from '../../../../core/ui/utility/DateModified';
import { hasPermission } from '../../../../core/utils/data';
import { toastError } from '../../../../core/utils/ui/alert';
import AuthContext from '../../../auth/service/authContext';
import { Grade } from '../grade';

const EvaluationTypeList = () => {
  const [data, setData] = useState<Grade[]>([]);
  const { user } = useContext(AuthContext);

  const { fetchData } = useFetchApiData();

  useEffect(() => {
    fetchData(`academic/grades`, {
      onSuccess: ({ data: apiData }) => {
        setData(apiData);
      },
      onError: toastError,
    });
  }, []);

  return (
    <Layout>
      <>
        <Box sx={{ p: 2 }}>
          <SummaryBlock
            buttonLabel="New Grade"
            modelLabel="Grades"
            itemCount={data.length}
            addRoute="/academic/grade/create"
            showCreate={hasPermission(user!.permissions, ['add-grade'])}
          />

          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 1500, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="small">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Grade</TableCell>
                  <TableCell>Order</TableCell>
                  <TableCell>Home Room</TableCell>
                  <TableCell>Month Fee</TableCell>
                  <TableCell>Registration Fee</TableCell>
                  <TableCell>Summer Fee</TableCell>
                  <TableCell>Tutorial Fee</TableCell>
                  <TableCell>Report template</TableCell>
                  <TableCell>Skill template</TableCell>
                  <TableCell>Modified</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((grade) => (
                  <TableRow key={grade.id}>
                    <TableCell size="small">{grade.name}</TableCell>
                    <TableCell size="small">{grade.order}</TableCell>
                    <TableCell size="small">
                      {grade.hrt?.user?.first_name}{' '}
                      {grade.hrt?.user?.father_name}
                    </TableCell>
                    <TableCell size="small">{grade.monthly_fee}</TableCell>
                    <TableCell size="small">{grade.registration_fee}</TableCell>
                    <TableCell size="small">{grade.summer_fee}</TableCell>
                    <TableCell size="small">{grade.tutorial_fee}</TableCell>
                    <TableCell size="small">
                      {grade.report_card_template}
                    </TableCell>
                    <TableCell size="small">{grade.skill_template}</TableCell>
                    <TableCell component="th" scope="row" sx={{ py: '4px' }}>
                      <DateModified
                        createdAt={grade.created_at}
                        updatedAt={grade.updated_at}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <ShowIfHas permissions={['edit-grade']}>
                        <Link to={`/academic/grade/edit/${grade.id}`}>
                          <Edit
                            fontSize="small"
                            sx={{ mr: 1, fontSize: '15px' }}
                          />
                        </Link>
                      </ShowIfHas>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Layout>
  );
};

export default EvaluationTypeList;
