import useTablePagination from './hooks/useTablePagination';
import GenericTable from './components/GenericTable';
import {
  RouteSettings,
  TableActions,
  TableHeader,
  TableSettingsProps,
} from './tableTypes';
import DeleteAction from '../utility/DeleteAction';
import GenericTableFooter from './components/GenericTableFooter';
import FilterBlock from './components/filters/FilterBlock';
import useSorting from './hooks/useSorting';
import useFieldVisibility from './hooks/useFieldVisibility';
import useChecklist from './hooks/useChecklist';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Model } from '../../../core/utils/types';
import { Edit, Visibility } from '@mui/icons-material';
import SummaryBlock from './components/SummaryBlock';
import { Box, Grid } from '@mui/material';
import useDensity from './hooks/useDensity';
import TableSettings from './components/TableSettings';

type TableContainerProps = {
  modelToken: string;
  data: Record<string, any>[];
  tableHeaders: TableHeader[];
  modelLabel: string;
  actions: TableActions;
  routes: RouteSettings;
  settings: TableSettingsProps;
  searchBody?: Record<string, any>;
  renderActions?: (item: any) => any;
};

const TableContainer = ({
  modelToken,
  tableHeaders,
  data,
  modelLabel,
  actions: { onFetchData, onDelete, renderFilter },
  routes,
  settings,
  searchBody = {},
  renderActions,
}: TableContainerProps) => {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<Record<string, any>>({});

  const { dense, toggleDensity } = useDensity(modelToken);
  const {
    data: { page, rowsPerPage, total },
    handlers: { handleChangePage, handleChangeRowsPerPage, setTotal },
  } = useTablePagination(modelToken);
  const { sortField, sortOp, handleSort } = useSorting(modelToken);
  const { fieldVisible, handleToggleFieldVisibility } = useFieldVisibility(
    modelToken,
    tableHeaders
  );
  const { checklist, toggleChecklist, resetChecklist } = useChecklist(data);

  const fetchData = async () => {
    await onFetchData(
      {
        page: page + 1,
        rowsPerPage,
        sortField,
        sortOp,
        filter,
        searchBody,
      },
      (t) => {
        setTotal(t);
      }
    );

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortField, sortOp, filter]);

  const filteredHeaders = tableHeaders.filter(
    (header) => fieldVisible[header.field]
  );

  const handleDelete = async (item: Model) => {
    if (await onDelete!(item.id)) {
      await fetchData();
    }
  };

  const handleMultiDelete = async () => {
    let success = false;
    const promises: any[] = [];

    // console.log(Object.keys(checklist));

    Object.keys(checklist).forEach((key) => {
      promises.push(
        onDelete!(key, false).then((deleted) => {
          success = success || deleted;
        })
      );
    });

    await Promise.all(promises);

    resetChecklist();

    if (success) await fetchData();
  };

  return (
    <>
      <GenericTable
        data={data}
        tableHeaders={filteredHeaders}
        checklist={checklist}
        toggleChecklist={toggleChecklist}
        size={dense ? 'small' : 'medium'}
        settings={settings}
        onMultiDelete={handleMultiDelete}
        sorting={{
          orderBy: sortField,
          orderOp: sortOp,
          onSort: handleSort,
        }}
        loading={loading}
        renderSummary={() => (
          <SummaryBlock
            modelLabel={modelLabel}
            addRoute={routes.create!}
            buttonLabel={`+ New ${modelLabel}`}
            itemCount={total}
            showCreate={settings.canCreate || false}
          />
        )}
        renderFilterBlock={
          renderFilter
            ? renderFilter
            : () => (
                <FilterBlock
                  modelName={modelLabel}
                  onFilterApply={(filter) => {
                    setFilter(filter);
                  }}
                />
              )
        }
        renderTableSettings={() => (
          <Box
            sx={{ mb: '4px', mt: 2 }}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <TableSettings
                tableHeaders={tableHeaders}
                fieldVisible={fieldVisible}
                toggleFieldVisibility={handleToggleFieldVisibility}
                tableDense={dense}
                toggleTableDensity={toggleDensity}
              />
            </Grid>
          </Box>
        )}
        renderFooter={() => (
          <GenericTableFooter
            pagination={{
              page,
              rowsPerPage,
              total,
              handleChangePage,
              handleChangeRowsPerPage,
            }}
          />
        )}
        renderActions={
          renderActions
            ? renderActions
            : (item: Model) => (
                <>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    {settings.canViewItem && (
                      <Link to={`${routes.view}/${item.id}`}>
                        <Visibility
                          fontSize="small"
                          sx={{ mr: 1, fontSize: '15px' }}
                        />
                      </Link>
                    )}
                    {settings.canEdit && (
                      <Link to={`${routes.edit}/${item.id}`}>
                        <Edit
                          fontSize="small"
                          sx={{ mr: 1, fontSize: '15px' }}
                        />
                      </Link>
                    )}
                    {settings.canDelete && (
                      <DeleteAction
                        message="Are you sure you want to remove entry?"
                        onDelete={async (e) => {
                          handleDelete(item);
                        }}
                      />
                    )}
                  </Grid>
                </>
              )
        }
      />
    </>
  );
};

export default TableContainer;
