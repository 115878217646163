import { useState } from 'react';
import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import constants from '../utils/constants';
import { ErrorHandler, SuccessHandler } from '../utils/types';
import authService from '../../features/auth/service/authService';

type CallApiArg = {
  headers?: AxiosRequestHeaders;
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
};

const useGetApiData = <T>(initData: T) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<T>(initData);

  const fetchData = async (
    endpointUrl: string,
    { headers = {}, onSuccess, onError }: CallApiArg = {}
  ) => {
    setLoading(true);
    try {
      const res = await axios.get(`${constants.apiUrl}/${endpointUrl}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${authService.getToken()}`,
          ...headers,
        },
      });

      setData(res.data);

      if (onSuccess) onSuccess(res.data, res.status);
    } catch (e) {
      const apiErr = e as AxiosError;
      if (onError) {
        onError(
          apiErr.response?.data?.message ||
            apiErr.message ||
            'Unknown Error occurred'
        );
      }
    }
    setLoading(false);
  };

  return { fetchData, data, loading };
};

export default useGetApiData;
