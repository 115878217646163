import { Warning } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { FixedPending } from '../../fixedPayment';

type DeleteProps = {
  payment: FixedPending | null;
  cancel: () => void;
  onSuccess: () => void;
};

const DeletePendingFixedDialog = ({
  payment,
  cancel,
  onSuccess,
}: DeleteProps) => {
  const { callApi, loading } = useSendApiData();
  const [disabled, setDisabled] = useState(true);

  const handleSubmit = async () => {
    let success = false;
    await callApi({
      endpoint: `payment-latest/fixed/pending/${payment!.id}`,
      data: {
        archived: false,
      },
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      onError: toastError,
      onSuccess: async () => {
        toastMessage('Fixed Payment removed');
        success = true;
      },
    });

    if (success) {
      onSuccess();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  }, []);

  return (
    <>
      <Dialog
        open={payment !== null}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Do you want to remove pending payment?'}
        </DialogTitle>
        <DialogContent>
          <Grid
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Warning fontSize="medium" color="warning" sx={{ mr: 2 }} />
            <Typography variant="h6" color="crimson">
              This action is permanent and can not be undone
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            autoFocus
            color="error"
            disabled={loading || disabled}
          >
            Delete Forever
          </Button>
          <Button disabled={loading} onClick={cancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeletePendingFixedDialog;
