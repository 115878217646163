import { Close } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import { StagePaymentInstance } from '../../../core/state/stage.types';
import { Spinner } from '../../../core/ui/utility/Loading';
import { StudentFixedPaymentCreate } from '../fixed/fixedPayment';
import { StudentRecurrentPaymentCreate } from '../recurrent/recurrent';

const StudentSummary = ({
  stageInstance,
  removeItem,
  formSubmitting,
}: {
  stageInstance: StagePaymentInstance;
  removeItem: (i: string) => void;
  formSubmitting: boolean;
}) => (
  <Grid sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        pl: 1,
        bgcolor: 'white',
        filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
        borderLeft: '3px solid',
        borderLeftColor: 'primary.main',
        position: 'relative',
        border: stageInstance.error ? '1px solid red' : '',
      }}
    >
      <Grid sx={{ display: 'flex', flexDirection: 'column', py: 1 }}>
        <Typography fontWeight={600} fontSize={12}>
          Student: {stageInstance.student}
        </Typography>
        <Typography fontWeight={600} fontSize={12}>
          {stageInstance.grade}
        </Typography>
        <Typography fontWeight={600} fontSize={12}>
          Amount: {stageInstance.amount}
        </Typography>
        <Typography fontWeight={600} fontSize={12}>
          Penalty: {stageInstance.penalty}
        </Typography>
      </Grid>

      <Grid
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Grid
          sx={{
            padding: '1px 10px',
            bgcolor: '#1565C0',
            borderBottomLeftRadius: '12px',
            top: '0',
            right: '0',
            color: 'white',
          }}
        >
          <Typography textAlign="center" fontSize={11}>
            {stageInstance.type === 'fixed' ? 'Fixed' : 'Recurrent'}
          </Typography>
        </Grid>

        {stageInstance.submitting ? (
          <Spinner />
        ) : (
          <IconButton
            size="small"
            sx={{ mt: 1, mr: 2 }}
            onClick={() => {
              const id =
                stageInstance.type === 'fixed'
                  ? (stageInstance.payment as StudentFixedPaymentCreate)
                      .fixed_payment_pending_id
                  : (stageInstance.payment as StudentRecurrentPaymentCreate)
                      .recurrent_payment_pending_id;

              removeItem(id);
            }}
            disabled={formSubmitting}
          >
            <Close color="warning" />
          </IconButton>
        )}
      </Grid>
    </Grid>

    {stageInstance.error && (
      <>
        <Typography color="error" sx={{ mb: 1 }} fontSize={12}>
          {stageInstance.error}
        </Typography>
      </>
    )}
  </Grid>
);

export default StudentSummary;
