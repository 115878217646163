import { FilterList, FilterListOff, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useContext, useState } from 'react';
import GlobalContext from '../../../../core/service/globalContext';
import Layout from '../../../../core/ui/layout/Layout';
import CustomPaginationAction from '../../../../core/ui/shared/CustomPaginationAction';
import StudentPreview from '../../../../core/ui/utility/StudentPreview';
import useFixedPaymentSearch from '../hooks/useFixedPaymentSearch';
import useGetFixedPayment from '../hooks/useGetFixedPayment';

const searchFields = [
  { key: 'student', label: 'Student', type: 'text' },
  { key: 'fs', label: 'Fs', type: 'text' },
  { key: 'attachment', label: 'Attachment', type: 'text' },
  { key: 'start_date', label: 'Start Date', type: 'date' },
  { key: 'end_date', label: 'End Date', type: 'date' },
];

const filterInit: Record<string, any> = {
  grade_id: '',
};
searchFields.forEach((search) => (filterInit[search.key] = ''));

const SearchFixedPaymentPage = () => {
  const { grades } = useContext(GlobalContext);
  const { fixedPayments, pagination, loading, handleSearch } =
    useFixedPaymentSearch();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<Record<string, any>>(filterInit);
  const { fixedPayment } = useGetFixedPayment();

  const handleFilterChange = (key: string, value: any) => {
    setFilters({
      ...filters,
      [key]: value,
    });
  };

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            Fixed Payments
          </Typography>
          <Typography variant="h6">{fixedPayment?.description}</Typography>

          <Paper sx={{ mt: 2, p: 1 }}>
            <Grid item display="flex" justifyContent="space-between">
              <Typography sx={{ mb: 1 }} variant="h6">
                Filters
              </Typography>

              <Grid item display="flex">
                {!showFilters && (
                  <IconButton onClick={() => setShowFilters(true)}>
                    <FilterList />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            {showFilters && (
              <Grid display="flex" flexDirection="column">
                <Grid container display="flex" flexWrap="wrap" spacing={2}>
                  {searchFields.map((field, i) => (
                    <Grid item key={`search-${i}`}>
                      <TextField
                        sx={{ maxWidth: '150px' }}
                        size="small"
                        type={field.type}
                        variant="standard"
                        label={field.label}
                        placeholder=""
                        value={filters[field.key]}
                        onChange={(e) =>
                          handleFilterChange(field.key, e.target.value)
                        }
                      />
                    </Grid>
                  ))}
                  <Grid item>
                    <FormControl sx={{ width: '150px' }}>
                      <InputLabel>Grade</InputLabel>
                      <Select
                        fullWidth
                        value={filters.grade_id}
                        onChange={(e) =>
                          handleFilterChange('grade_id', e.target.value)
                        }
                        label="Grade"
                        size="small"
                        variant="standard"
                      >
                        <MenuItem value="">All</MenuItem>
                        {grades.map((grade) => (
                          <MenuItem value={grade.id} key={grade.id}>
                            {grade.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid display="flex" alignSelf="flex-end" sx={{ mt: 1 }}>
                  <Button
                    disabled={loading}
                    variant="text"
                    size="small"
                    startIcon={<Search />}
                    color="primary"
                    sx={{ mr: 2 }}
                    onClick={() => handleSearch(filters)}
                  >
                    Search
                  </Button>
                  <Button
                    disabled={loading}
                    variant="text"
                    size="small"
                    startIcon={<FilterListOff />}
                    color="secondary"
                    onClick={() => {
                      setFilters(filterInit);
                      handleSearch({});
                      setShowFilters(false);
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            )}
          </Paper>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table
              size="small"
              sx={{ minWidth: 650 }}
              aria-label="contacts table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Student</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Penalty</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Attachment</TableCell>
                  <TableCell>Fs</TableCell>
                  <TableCell>Slip Date</TableCell>
                  <TableCell align="right">Added On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fixedPayments.map((fixed) => (
                  <TableRow key={fixed.id}>
                    <TableCell>
                      <StudentPreview student={fixed.student} />
                    </TableCell>
                    <TableCell>{fixed.grade.name}</TableCell>
                    <TableCell>{fixed.amount}</TableCell>
                    <TableCell>{fixed.penalty}</TableCell>
                    <TableCell>{fixed.total}</TableCell>
                    <TableCell>{fixed.attachment}</TableCell>
                    <TableCell>{fixed.fs}</TableCell>
                    <TableCell>{fixed.slip_date || ''}</TableCell>
                    <TableCell align="right">
                      {moment(fixed.created_at).format('MMM Do YY')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TableFooter>
                <TableRow>
                  <TableCell colSpan={2}>
                    Found {pagination.data.total} results
                  </TableCell>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500]}
                    labelRowsPerPage="Per Page"
                    labelDisplayedRows={() => <></>}
                    count={pagination.data.total}
                    rowsPerPage={pagination.data.rowsPerPage}
                    page={pagination.data.page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={pagination.handlers.handleChangePage}
                    onRowsPerPageChange={
                      pagination.handlers.handleChangeRowsPerPage
                    }
                    ActionsComponent={CustomPaginationAction as React.FC<any>}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </Layout>
    </>
  );
};

export default SearchFixedPaymentPage;
