import { Box, Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from '../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import Loader from '../../../../core/ui/utility/Loader';
import { StudentEdit, Student } from '../student';
import EditStudentForm from '../components/EditStudentForm';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../../core/utils/utility';
import ChangeGradeForm from '../components/ChangeGradeForm';
import CreateStudentProfileForm from '../components/profile/CreateStudentProfileForm';
import EditStudentProfileForm from '../components/profile/EditStudentProfileForm';

const EditStudentContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [student, setStudent] = useState<Student | null>(null);

  const fetchStudent = () =>
    fetchData(`academic/students/${id}`, {
      onSuccess: (data: Student) => {
        setStudent(data);
      },
    });

  useEffect(() => {
    fetchStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: StudentEdit,
    { setFieldError }: FormikHelpers<StudentEdit>
  ) => {
    let success = false;
    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `academic/students/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        success = true;
        toastMessage('Student Edited');
      },
    });

    if (success) {
      navigate('/academic/student', { replace: true });
    }
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/student" />}>
      <Loader loading={loading || !student}>
        <>
          <Box sx={{ p: 2 }}>
            <Grid container display="flex">
              <Grid item sx={{ minWidth: 400, mr: 2 }}>
                <Grid container sx={{ mb: 1, px: 1 }}>
                  <Typography variant="h4">Edit Student</Typography>
                </Grid>
                <EditStudentForm
                  student={student!}
                  onSubmit={handleSubmit}
                  submitting={submitting}
                />
              </Grid>

              <Grid item>
                <Grid container sx={{ mb: 1, px: 1, minWidth: 300 }}>
                  <Typography variant="h5">Change Grade</Typography>
                </Grid>

                <ChangeGradeForm
                  grade={student?.gradeStudents[0]?.grade}
                  studentId={student?.id!}
                />
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2, maxWidth: 1200 }}>
              <Grid container sx={{ mb: 2, px: 1 }}>
                <Typography variant="h4">
                  {!student?.profile ? 'Add Profile' : 'Edit Profile'}
                </Typography>
              </Grid>

              {student && (
                <>
                  {student.profile ? (
                    <EditStudentProfileForm
                      student={student}
                      studentProfile={student.profile!}
                    />
                  ) : (
                    <CreateStudentProfileForm student={student} />
                  )}
                </>
              )}
            </Grid>
          </Box>
        </>
      </Loader>
    </Layout>
  );
};

export default EditStudentContainer;
