import { FormikHelpers, useFormik } from 'formik';
import { ChangeGrade } from '../student';
import {
  Grid,
  Paper,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import { useContext } from 'react';
import GlobalContext from '../../../../core/service/globalContext';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { Grade } from '../../grade/grade';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';

type ChangeGradeProps = {
  grade?: Grade;
  studentId: string;
};

const ChangeGradeForm = ({ grade, studentId }: ChangeGradeProps) => {
  const { callApi, loading: submitting } = useSendApiData();
  const { grades } = useContext(GlobalContext);

  const handleSubmit = async (
    value: ChangeGrade,
    { setFieldError }: FormikHelpers<ChangeGrade>
  ) => {
    await callApi({
      endpoint: 'academic/grade-students/student/change-grade',
      data: {
        ...value,
        student_id: studentId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Grade changed');
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      grade_id: grade?.id || '',
    },
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 400 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <FormControl fullWidth>
                  <InputLabel>Current Grade</InputLabel>
                  <Select
                    value={formik.values.grade_id}
                    size="small"
                    label="Grade"
                    name="grade_id"
                    onChange={formik.handleChange}
                    fullWidth
                  >
                    <MenuItem value=""></MenuItem>
                    {grades.map((grade) => (
                      <MenuItem key={grade.id} value={grade.id}>
                        {grade.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.grade_id && formik.errors.grade_id && (
                    <FormHelperText error>
                      {formik.errors.grade_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid container sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                  fullWidth
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default ChangeGradeForm;
