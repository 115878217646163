import { useContext } from 'react';
import Loader from '../../../../core/ui/utility/Loader';
import AuthContext from '../../../auth/service/authContext';
import StudentAttendanceForm from '../components/StudentAttendanceForm';
import useFetchHomeRoomStudents from '../hooks/useFetchHomeRoomStudents';

const CreateStudentAttendanceContainer = () => {
  const { students, loading } = useFetchHomeRoomStudents();
  const { user } = useContext(AuthContext);

  return (
    <>
      <Loader loading={loading}>
        <StudentAttendanceForm students={students} grade={user!.hrt!.grade} />
      </Loader>
    </>
  );
};

export default CreateStudentAttendanceContainer;
