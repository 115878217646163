/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';

const useGetPendingPenalty = (
  slipDate: Date | undefined,
  recurrentPaymentId: string
) => {
  const { fetchData, loading, data: penalty } = useFetchApiData();

  useEffect(() => {
    if (!slipDate) {
      fetchData(
        `payment-latest/recurrent/child/penalty/${recurrentPaymentId}`,
        {
          method: 'post',
          dataAccessor: (res) => res.data.data,
        }
      );
    } else {
      fetchData(
        `payment-latest/recurrent/child/penalty/${recurrentPaymentId}`,
        {
          method: 'post',
          dataAccessor: (res) => res.data.data,
          body: {
            slipDate: moment(slipDate).format('YYYY-MM-DD'),
          },
        }
      );
    }
  }, [slipDate]);

  return { penalty, loading };
};

export default useGetPendingPenalty;
