import { Box } from '@mui/material';
import Layout from '../../../../core/ui/layout/Layout';
import GradeStudentList from '../components/GradeStudentList';

const GradeStudentPage = () => {
  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <GradeStudentList />
        </Box>
      </Layout>
    </>
  );
};

export default GradeStudentPage;
