import Layout from '../../../../core/ui/layout/Layout';
import EditGradeForm from '../components/EditGradeForm';
import ReturnButton from '../../../../core/ui/utility/ReturnButton';
import Loader from '../../../../core/ui/utility/Loader';
import { Box, Grid, Typography } from '@mui/material';
import useFetchGradeController from '../hooks/useFetchGradeController';
import EditHrtForm from '../components/EditHrtForm';
import useFetchNonHrts from '../hooks/useFetchNonHrts';
import GradeHrt from '../components/GradeHrt';

const EditGradeContainer = () => {
  const { grade, gradeLoading } = useFetchGradeController();
  const { users, usersLoading } = useFetchNonHrts();

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/grade" />}>
      <Loader loading={gradeLoading || !grade}>
        <Box sx={{ p: 2 }}>
          <Grid>
            <Grid container sx={{ mb: 1, px: 1 }}>
              <Typography variant="h5">Edit Grade</Typography>
            </Grid>
            <EditGradeForm grade={grade!} />
          </Grid>

          <Loader loading={usersLoading}>
            {grade?.hrt ? (
              <GradeHrt grade={grade} />
            ) : (
              <EditHrtForm grade={grade!} users={users} />
            )}
          </Loader>
        </Box>
      </Loader>
    </Layout>
  );
};

export default EditGradeContainer;
