import { FormikHelpers, useFormik } from 'formik';
import { AcademicYearEdit, AcademicYear } from '../academicYear';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type AcademicYearProps = {
  onSubmit: (
    value: AcademicYearEdit,
    helpers: FormikHelpers<AcademicYearEdit>
  ) => Promise<void>;
  submitting: boolean;
  academicYear: AcademicYear;
};

const EditAcademicYearForm = ({
  onSubmit,
  submitting,
  academicYear,
}: AcademicYearProps) => {
  const handleSubmit = async (
    value: AcademicYearEdit,
    helpers: FormikHelpers<AcademicYearEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: AcademicYearEdit = {
    year: academicYear.year,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 300 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="year"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Year"
                      placeholder=""
                      value={formik.values.year}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.year)}
                      helperText={formik.errors.year}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditAcademicYearForm;
