/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import Layout from '../../../../core/ui/layout/Layout';
import { TableHeader } from '../../../../core/ui/table/tableTypes';
import { Model, Paginated } from '../../../../core/utils/types';
import { parseOrdering, parseQuery } from '../../../../core/utils/utility';
import { Registration } from '../registration';
import RegisterFilter from './RegisterFilter';
import GenericTable from '../../../../core/ui/table/components/GenericTable';
import useDensity from '../../../../core/ui/table/hooks/useDensity';
import useTablePagination from '../../../../core/ui/table/hooks/useTablePagination';
import useSorting from '../../../../core/ui/table/hooks/useSorting';
import useFieldVisibility from '../../../../core/ui/table/hooks/useFieldVisibility';
import useChecklist from '../../../../core/ui/table/hooks/useChecklist';
import SummaryBlock from '../../../../core/ui/table/components/SummaryBlock';
import TableSettings from '../../../../core/ui/table/components/TableSettings';
import GenericTableFooter from '../../../../core/ui/table/components/GenericTableFooter';
import { Link } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import DeleteAction from '../../../../core/ui/utility/DeleteAction';
import { sortByText } from '../../../../core/utils/data';
import { useDeleteData } from '../../../../core/hooks/useDeleteData';

const tableHeaders: TableHeader[] = [
  {
    field: 'name',
    label: 'Name',
    align: 'left',
    renderItem: (reg: Registration) =>
      `${reg.student.first_name} ${reg.student.father_name}`,
  },
  { field: 'fee', label: 'fee', align: 'left' },
  { field: 'attachment', label: 'attachment', align: 'left' },
  { field: 'fs', label: 'fs', align: 'left' },
  { field: 'method', label: 'method', align: 'left' },
  { field: 'slip_date', label: 'slip_date', align: 'left' },
  { field: 'remark', label: 'remark', align: 'left' },
];

const modelToken = 'registration';
const modelLabel = 'Registration';
const defaultSearchBody = {
  withs: {
    student: {},
  },
};

const RegistrationList = () => {
  const [data, setData] = useState<Record<string, any>[]>([]);
  const [searchBody, setSearchBody] =
    useState<Record<string, any>>(defaultSearchBody);
  const [loading, setLoading] = useState(false);

  // Table Pagination and density
  const { dense, toggleDensity } = useDensity(modelToken);
  const {
    data: { page, rowsPerPage, total },
    handlers: { handleChangePage, handleChangeRowsPerPage, setTotal },
  } = useTablePagination(modelToken);
  const { sortField, sortOp, handleSort, handleCustomSort, includeSort } =
    useSorting(modelToken);

  // Field visibility
  const { fieldVisible, handleToggleFieldVisibility } = useFieldVisibility(
    modelToken,
    tableHeaders
  );
  const filteredHeaders = tableHeaders.filter(
    (header) => fieldVisible[header.field]
  );
  tableHeaders[0].onSort = (field: string) =>
    handleCustomSort(field, (op) => {
      setData(sortByText(data, (a: Registration) => a.student.first_name, op));
    });

  // Deletion items
  const { checklist, toggleChecklist, resetChecklist } = useChecklist(data);
  const { handleDelete, handleMultiDelete } = useDeleteData();

  // Data Fetch api
  const { fetchData: fetchApiData } = useFetchApiData();

  const fetchData = async () => {
    setLoading(true);
    const query = {
      page: page + 1,
      rowsPerPage,
      sortField,
      sortOp,
      includeSort,
    };
    // console.log('body', {
    //   ...searchBody,
    //   orderBies: parseOrdering(query),
    // });

    await fetchApiData(
      `finance/payment-new/registration-payments/search${parseQuery(query)}`,
      {
        method: 'post',
        onSuccess: ({ total, data: apiData }: Paginated<Registration>) => {
          // console.log(apiData);
          setData(apiData);
          setTotal(total);
        },
        body: {
          ...searchBody,
          orderBies: parseOrdering(query),
        },
      }
    );

    setLoading(false);
  };

  useEffect(() => {
    // console.log('fetch');
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, searchBody, includeSort]);

  // TODO: Optimize
  useEffect(() => {
    if (includeSort) {
      // console.log('refetch');
      fetchData();
    }
  }, [sortField, sortOp, includeSort]);

  return (
    <>
      <Layout>
        <Box sx={{ p: 2 }}>
          <GenericTable
            data={data}
            tableHeaders={filteredHeaders}
            checklist={checklist}
            toggleChecklist={toggleChecklist}
            size={dense ? 'small' : 'medium'}
            settings={{
              canCreate: true,
              canEdit: true,
              canDelete: true,
            }}
            onMultiDelete={() =>
              handleMultiDelete({
                baseEndpoint: `finance/payment-new/registration-payments`,
                checklist,
                resetChecklist,
                onSuccess: fetchData,
              })
            }
            sorting={{
              orderBy: sortField,
              orderOp: sortOp,
              onSort: handleSort,
            }}
            loading={loading}
            renderSummary={() => (
              <SummaryBlock
                modelLabel={modelLabel}
                addRoute={'/payment/registration/create'}
                buttonLabel={`+ New ${modelLabel}`}
                itemCount={total}
                showCreate={true}
              />
            )}
            renderFilterBlock={() => (
              <RegisterFilter
                onClear={() => setSearchBody(defaultSearchBody)}
                onApply={(searchFilters: any) =>
                  setSearchBody({ ...defaultSearchBody, ...searchFilters })
                }
              />
            )}
            renderTableSettings={() => (
              <Box
                sx={{ mb: '4px', mt: 2 }}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item>
                  <TableSettings
                    tableHeaders={tableHeaders}
                    fieldVisible={fieldVisible}
                    toggleFieldVisibility={handleToggleFieldVisibility}
                    tableDense={dense}
                    toggleTableDensity={toggleDensity}
                  />
                </Grid>
              </Box>
            )}
            renderFooter={() => (
              <GenericTableFooter
                pagination={{
                  page,
                  rowsPerPage,
                  total,
                  handleChangePage,
                  handleChangeRowsPerPage,
                }}
              />
            )}
            renderActions={(item: Model) => (
              <>
                <Grid container justifyContent="flex-end" alignItems="flex-end">
                  <Link to={`payment/registration/edit/${item.id}`}>
                    <Edit fontSize="small" sx={{ mr: 1, fontSize: '15px' }} />
                  </Link>

                  <DeleteAction
                    message="Are you sure you want to remove entry?"
                    onDelete={async (e) => {
                      handleDelete({
                        endpoint: `finance/payment-new/registration-payments/${item.id}`,
                        onSuccess: fetchData,
                      });
                    }}
                  />
                </Grid>
              </>
            )}
          />
        </Box>
      </Layout>
    </>
  );
};

export default RegistrationList;
