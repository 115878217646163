import { Box } from '@mui/material';
import { StudentPaymentReport } from '../lib/types/student-payment-report';
import StudentRecurrent from './StudentRecurrent';
import StudentRecurrentPendingTable from './StudentRecurrentPendingTable';
import StudentFixedReportTable from './StudentFixedReportTable';
import StudentFixedPendingTable from './StudentFixedPendingTable';

const StudentPaymentReportTable = ({
  report,
}: {
  report: StudentPaymentReport;
}) => {
  return (
    <>
      <Box>
        <StudentRecurrent recurrents={report.recurrent} />
        <StudentRecurrentPendingTable pendings={report.recurrentPending} />
        <StudentFixedReportTable fixed={report.fixed} />
        <StudentFixedPendingTable pendings={report.fixedPending} />
      </Box>
    </>
  );
};

export default StudentPaymentReportTable;
