import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseFormQuery } from '../../../../core/utils/utility';
import { parseValidationErrors } from '../../../../core/utils/validation';
import {
  StudentProfile,
  StudentProfileCreate,
  StudentProfileEdit,
} from '../models/studentProfile';
import { Student } from '../student';

const useStudentProfileCreateForm = (student: Student) => {
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: StudentProfileCreate,
    { setFieldError }: FormikHelpers<StudentProfileCreate>
  ) => {
    let success = false;

    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: 'academic/student-profiles',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Student Profile Created');
        success = true;
      },
    });

    if (success) {
      navigate('/', { replace: true });
      navigate(`/academic/student/edit/${student.id}`, { replace: true });
    }
  };

  const initialValues: StudentProfileCreate = {
    nationality: '',
    student_phone_number: '',
    house_number: '',
    address_city: '',
    address_sub_city: '',
    address_wored: '',
    other_health_status: '',
    previous_school_name: '',
    previous_school_city: '',
    previous_school_sub_city: '',
    previous_school_woreda: '',
    school_leave_other: '',
    entry_class: '',
    parent_name: '',
    occupation: '',
    work_place: '',
    parent_phone_number: '',
    student_living_with: '',
    parent_photo: null,
    emergencies: '',
    health_status: '',
    previous_school_leave_reason: '',
    father_condition: '',
    mother_condition: '',
    student_id: student.id,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  return { formik, submitting };
};

const useStudentProfileEditForm = (
  student: Student,
  studentProfile: StudentProfile
) => {
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: StudentProfileEdit,
    { setFieldError }: FormikHelpers<StudentProfileEdit>
  ) => {
    let success = false;
    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `academic/student-profiles/${studentProfile.id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        success = true;
        toastMessage('Student Profile Edited');
      },
    });

    if (success) {
      navigate('/', { replace: true });
      navigate(`/academic/student/edit/${student.id}`, { replace: true });
    }
  };

  const initialValues: StudentProfileEdit = {
    nationality: studentProfile.nationality || '',
    student_phone_number: studentProfile.student_phone_number || '',
    house_number: studentProfile.house_number || '',
    address_city: studentProfile.address_city || '',
    address_sub_city: studentProfile.address_sub_city || '',
    address_wored: studentProfile.address_wored || '',
    other_health_status: studentProfile.other_health_status || '',
    previous_school_name: studentProfile.previous_school_name || '',
    previous_school_city: studentProfile.previous_school_city || '',
    previous_school_sub_city: studentProfile.previous_school_sub_city || '',
    previous_school_woreda: studentProfile.previous_school_woreda || '',
    school_leave_other: studentProfile.school_leave_other || '',
    entry_class: studentProfile.entry_class || '',
    parent_name: studentProfile.parent_name || '',
    occupation: studentProfile.occupation || '',
    work_place: studentProfile.work_place || '',
    parent_phone_number: studentProfile.parent_phone_number || '',
    student_living_with: studentProfile.student_living_with || '',
    parent_photo: null,
    emergencies: studentProfile.emergencies || '',
    health_status: studentProfile.health_status || '',
    previous_school_leave_reason:
      studentProfile.previous_school_leave_reason || '',
    father_condition: studentProfile.father_condition || '',
    mother_condition: studentProfile.mother_condition || '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  return { formik, submitting };
};

export { useStudentProfileCreateForm, useStudentProfileEditForm };
