import React from 'react';
import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Cst } from '../../cst/cst';
import { Semester } from '../../semester/semester';
import { StudentForRcy } from '../reportCard';
import {
  getQuarterMark,
  getRcq,
  getRcs,
  getSemesterMark,
  printMark,
} from '../services/reportCardService';

type QuarterReportCardTableProps = {
  students: StudentForRcy[];
  csts: Cst[];
  semesters: Semester[];
  semesterMarkMap: any;
  quarterMarkMap: any;
  rcsMap: any;
  rcqMap: any;
};
const QuarterReportCardTable = ({
  students,
  csts,
  semesters,
  semesterMarkMap,
  quarterMarkMap,
  rcsMap,
  rcqMap,
}: QuarterReportCardTableProps) => {
  // const year2014 = '76c05aa8-161a-40eb-9539-be5bc0c2b70b';
  // const year2015 = '9c454250-c05c-4c78-a344-687c91b97fc6';
  // const grades2014 = [
  //   'ae2047c8-6b5e-4af2-9fa7-90efbfcb9e34',
  //   '5a515bed-c4ff-4f58-9821-33e7c58b72fb',
  //   'ce7e829d-f3d8-4f9f-a023-b42cb5ce983b',
  //   'd665efd8-7df4-4d1d-ad1d-9c3689137f7f',
  //   '97dc7fcd-652f-4ca0-9dc2-1481c479a887',
  //   '715ba2d5-3d9b-4a19-a568-12da31d79fb1',
  //   '92a8819a-bd1f-4a8e-9c60-664a1f984c2f',
  //   'ac932c83-b841-46db-b857-5f431fb7ec13',
  // ];
  // const grades2015 = [
  //   'ae2047c8-6b5e-4af2-9fa7-90efbfcb9e34',
  //   '5a515bed-c4ff-4f58-9821-33e7c58b72fb',
  //   'ce7e829d-f3d8-4f9f-a023-b42cb5ce983b',
  //   'd665efd8-7df4-4d1d-ad1d-9c3689137f7f',
  //   '97dc7fcd-652f-4ca0-9dc2-1481c479a887',
  //   '715ba2d5-3d9b-4a19-a568-12da31d79fb1',
  //   '92a8819a-bd1f-4a8e-9c60-664a1f984c2f',
  //   'ac932c83-b841-46db-b857-5f431fb7ec13',
  // ];
  // const subs2014 = [
  //   'a5104836-076e-4869-817a-99daeb1b0deb',
  //   '30c7f2c2-0e89-4f3f-a9b9-fd6febed9c78',
  //   '6f03ead3-b9a8-4da3-8b65-e657c630ba35',
  //   'fff1a12d-4e4c-49de-ba3c-a5605490446d',
  //   '69c2e9c0-26c6-4e9c-9d4e-077e81914928',
  //   '1add6830-0cda-48ec-89ef-c97be55ec7b8',
  //   '87464e11-a65a-4b3e-b2b8-d4da96e1b291',
  //   '7764c877-3f54-49c2-b419-1a6bfaf2497a',
  //   '5e18a767-8c0b-4461-9e79-407473bab59c',
  // ];
  // const subs2015 = [
  //   'a5104836-076e-4869-817a-99daeb1b0deb',
  //   '30c7f2c2-0e89-4f3f-a9b9-fd6febed9c78',
  //   '6f03ead3-b9a8-4da3-8b65-e657c630ba35',
  //   'fff1a12d-4e4c-49de-ba3c-a5605490446d',
  //   '69c2e9c0-26c6-4e9c-9d4e-077e81914928',
  //   '87464e11-a65a-4b3e-b2b8-d4da96e1b291',
  // ];
  let cstsFiltered = csts;
  // if (
  //   csts.length &&
  //   csts[0].academic_year_id === year2015 &&
  //   grades2015.includes(csts[0].grade_id)
  // ) {
  //   cstsFiltered = csts.filter((i) => !subs2015.includes(i.subject_id));
  // }
  // if (
  //   csts.length &&
  //   csts[0].academic_year_id === year2014 &&
  //   grades2014.includes(csts[0].grade_id)
  // ) {
  //   cstsFiltered = csts.filter((i) => !subs2014.includes(i.subject_id));
  // }

  return (
    <>
      <Box sx={{ mt: 4 }}>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 1000 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell>Student</TableCell>
                <TableCell></TableCell>
                {cstsFiltered.map((cst) => (
                  <TableCell key={cst.id}>{cst.subject.subject}</TableCell>
                ))}
                <TableCell>Total</TableCell>
                <TableCell>Average</TableCell>
                <TableCell>Rank</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {students.map((student, i) => (
                <TableRow key={student.id}>
                  <TableCell size="small">{i + 1}</TableCell>
                  <TableCell>
                    <Grid>
                      {student.first_name} {student.father_name}
                    </Grid>
                    {!student.primary_phone && (
                      <Grid mt="4px">
                        <Chip label="No phone" color="warning" size="small" />
                      </Grid>
                    )}
                  </TableCell>
                  <TableCell sx={{ py: 0, width: '150px' }}>
                    <Table>
                      <TableBody>
                        {semesters.map((semester, j) => (
                          <React.Fragment key={`semester-${j}`}>
                            {semester.quarters.map((quarter, i) => (
                              <TableRow key={`quarter-${i}`}>
                                <TableCell sx={{ py: 1 }}>
                                  Quarter {quarter.quarter}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell sx={{ py: 1 }}>
                                Semester {semester.semester}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell> </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}
                        <TableRow>
                          <TableCell sx={{ py: 1 }}>Year</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>

                  {cstsFiltered.map((cst) => (
                    <TableCell
                      key={`${student.id} ${cst.id}`}
                      sx={{ py: 0, px: 0 }}
                    >
                      <Table>
                        <TableBody>
                          {semesters.map((semester, j) => (
                            <React.Fragment key={`semester-mark-${j}`}>
                              {semester.quarters.map((quarter, i) => (
                                <TableRow key={`quarter-mark-${i}`}>
                                  <TableCell sx={{ py: 1 }}>
                                    {printMark(
                                      getQuarterMark(
                                        quarterMarkMap,
                                        student.gradeStudents[0].id,
                                        cst.id,
                                        quarter.id
                                      )
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell sx={{ py: 1 }}>
                                  {printMark(
                                    getSemesterMark(
                                      semesterMarkMap,
                                      student.gradeStudents[0].id,
                                      cst.id,
                                      semester.id
                                    )
                                  )}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell> </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                          <TableRow>
                            <TableCell
                              key={`${student.id} ${cst.id}`}
                              sx={{ py: 1 }}
                            >
                              {printMark(student.cstScore[cst.id])}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  ))}

                  <TableCell sx={{ py: 0 }}>
                    <Table>
                      <TableBody>
                        {semesters.map((semester, j) => (
                          <React.Fragment key={`total-mark-${j}`}>
                            {semester.quarters.map((quarter, i) => (
                              <TableRow key={`total-${i}`}>
                                <TableCell sx={{ py: 1 }}>
                                  {printMark(
                                    getRcq(
                                      rcqMap,
                                      student.gradeStudents[0].id,
                                      quarter.id
                                    )[0]
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell sx={{ py: 1 }}>
                                {printMark(
                                  getRcs(
                                    rcsMap,
                                    student.gradeStudents[0].id,
                                    semester.id
                                  )[0]
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell> </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}

                        <TableRow>
                          <TableCell sx={{ py: 1 }}>
                            {printMark(student?.rcy?.total_score)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>

                  <TableCell sx={{ py: 0 }}>
                    <Table>
                      <TableBody>
                        {semesters.map((semester, j) => (
                          <React.Fragment key={`total-mark-${j}`}>
                            {semester.quarters.map((quarter, i) => (
                              <TableRow key={`total-${i}`}>
                                <TableCell sx={{ py: 1 }}>
                                  {printMark(
                                    getRcq(
                                      rcqMap,
                                      student.gradeStudents[0].id,
                                      quarter.id
                                    )[1]
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell sx={{ py: 1 }}>
                                {printMark(
                                  getRcs(
                                    rcsMap,
                                    student.gradeStudents[0].id,
                                    semester.id
                                  )[1]
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell> </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}

                        <TableRow>
                          <TableCell sx={{ py: 1 }}>
                            {printMark(student?.rcy?.average)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>

                  <TableCell sx={{ py: 0 }}>
                    <Table>
                      <TableBody>
                        {semesters.map((semester, j) => (
                          <React.Fragment key={`total-mark-${j}`}>
                            {semester.quarters.map((quarter, i) => (
                              <TableRow key={`total-${i}`}>
                                <TableCell sx={{ py: 1 }}>
                                  {
                                    getRcq(
                                      rcqMap,
                                      student.gradeStudents[0].id,
                                      quarter.id
                                    )[2]
                                  }
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell sx={{ py: 1 }}>
                                {
                                  getRcs(
                                    rcsMap,
                                    student.gradeStudents[0].id,
                                    semester.id
                                  )[2]
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell> </TableCell>
                            </TableRow>
                          </React.Fragment>
                        ))}

                        <TableRow>
                          <TableCell sx={{ py: 1 }}>
                            {student?.rcy?.rank || 'N/A'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default QuarterReportCardTable;
