import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { CreateHrt } from '../models/hrt';

const useEditHrtForm = (gradeId: string) => {
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();

  const initialValues: CreateHrt = {
    grade_id: gradeId,
    user_id: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }: FormikHelpers<CreateHrt>) => {
      let success = false;
      await callApi({
        endpoint: 'academic/home-room/hrts',
        data: values,
        onSuccess: () => {
          toastMessage('Home Room assigned');
          success = true;
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
      });

      if (success) {
        navigate('/');
        navigate(`/academic/grade/edit/${gradeId}`);
      }
    },
  });

  return { formik, submitting };
};

export default useEditHrtForm;
