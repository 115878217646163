import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { PenaltyCreate } from '../../recurrent';

type PenaltyProps = {
  penalty: PenaltyCreate;
  setPenalty: (p: PenaltyCreate) => void;
};

const CreateRecurrentPenaltyForm = ({ penalty, setPenalty }: PenaltyProps) => {
  return (
    <>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item flexGrow={1}>
          <FormControl error>
            <FormControlLabel
              control={
                <Checkbox
                  checked={penalty.has_penalty}
                  onChange={(e) =>
                    setPenalty({
                      ...penalty,
                      has_penalty: e.target.checked,
                    })
                  }
                />
              }
              label="Has penalty"
            />
          </FormControl>
        </Grid>
      </Grid>

      {penalty.has_penalty && (
        <Grid container sx={{ mt: 1, pt: 2, borderTop: '1px solid lightgrey' }}>
          <Grid container>
            <Grid item display="flex" flexDirection="column">
              <TextField
                fullWidth
                size="small"
                type="number"
                variant="outlined"
                label="Apply penalty after"
                value={penalty.no_penalty_days}
                onChange={(e) =>
                  setPenalty({
                    ...penalty,
                    no_penalty_days: +e.target.value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="subtitle2">Days</Typography>
                    </InputAdornment>
                  ),
                }}
              />
              <Typography fontSize={12} color="green" sx={{ mt: '2px' }}>
                *Penalty will apply {penalty.no_penalty_days} days after
                effective date
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }} spacing={1} display="flex">
            <Grid item flexGrow={1}>
              <FormControl fullWidth sx={{ maxWidth: '270px' }}>
                <InputLabel>Penalty Type</InputLabel>
                <Select
                  value={penalty.penalty_type}
                  size="small"
                  label="PenaltyType"
                  onChange={(e) =>
                    setPenalty({
                      ...penalty,
                      penalty_type: e.target.value,
                    })
                  }
                  fullWidth
                >
                  <MenuItem value="fixed">Fixed</MenuItem>
                  <MenuItem value="percentage">Percentage</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                size="small"
                type="number"
                variant="outlined"
                label={
                  penalty.penalty_type === 'fixed'
                    ? 'Penalty Fee'
                    : 'Penalty Percentage'
                }
                placeholder=""
                value={penalty.penalty_amount}
                onChange={(e) =>
                  setPenalty({
                    ...penalty,
                    penalty_amount: +e.target.value,
                  })
                }
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }} spacing={1} display="flex">
            <Grid item flexGrow={1}>
              <FormControl fullWidth sx={{ maxWidth: '270px' }}>
                <InputLabel>Penalty Frequency</InputLabel>
                <Select
                  value={penalty.penalty_frequency}
                  size="small"
                  label="Penalty Frequency"
                  onChange={(e) =>
                    setPenalty({
                      ...penalty,
                      penalty_frequency: e.target.value,
                    })
                  }
                  fullWidth
                >
                  <MenuItem value="onetime">One Time</MenuItem>
                  <MenuItem value="recurrent">Recurrent</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item flexGrow={1}>
              {penalty.penalty_frequency === 'recurrent' ? (
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  variant="outlined"
                  label="Re apply penalty every"
                  placeholder=""
                  value={penalty.penalty_reapply_days}
                  onChange={(e) =>
                    setPenalty({
                      ...penalty,
                      penalty_reapply_days: +e.target.value,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="subtitle2">Days</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <Grid></Grid>
              )}
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }} spacing={1}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Max penalty amount"
                placeholder=""
                value={penalty.max_penalty}
                onChange={(e) =>
                  setPenalty({
                    ...penalty,
                    max_penalty: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                size="small"
                type="text"
                variant="outlined"
                label="Max penalty days(penalty will stop applying after)"
                placeholder=""
                value={penalty.max_penalty_apply_days}
                onChange={(e) =>
                  setPenalty({
                    ...penalty,
                    max_penalty_apply_days: e.target.value,
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="subtitle2">Days</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CreateRecurrentPenaltyForm;
