import { Box } from '@mui/material';
import { Cst } from '../../cst';
import EvaluationMethodForm from './EvaluationMethodForm';
import ShowIfHas from '../../../../../../core/ui/permission/ShowIfHas';
import CSTEditForm from './CstEditForm';
import DeleteAction from '../../../../../../core/ui/utility/DeleteAction';
import { useDeleteData } from '../../../../../../core/hooks/useDeleteData';
import { useNavigate, useParams } from 'react-router-dom';

type CSTMenuProps = {
  cst: Cst;
  fetchCsts: () => Promise<void>;
  insertedQuarters: string[];
};

const CSTMenu = ({ cst, fetchCsts, insertedQuarters }: CSTMenuProps) => {
  const navigate = useNavigate();
  const { gradeId } = useParams();
  const { handleDelete } = useDeleteData();
  // TODO: Wrap with menu
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ShowIfHas permissions={['add-evaluation-method']}>
          <EvaluationMethodForm
            cst={cst}
            onSuccess={async () => {
              fetchCsts();
            }}
            insertedQuarters={insertedQuarters}
          />
        </ShowIfHas>
        <ShowIfHas permissions={['edit-cst']}>
          <CSTEditForm cst={cst} onSuccess={fetchCsts} />
        </ShowIfHas>
        <ShowIfHas permissions={['remove-cst']}>
          <DeleteAction
            message="This action will remove associated evaluation methods, marklist and report cards"
            onDelete={async (e) => {
              handleDelete({
                endpoint: `academic/marklist/csts/${cst.id}`,
                successMsg: 'Class subject removed',
                onSuccess: () => {
                  navigate('/', { replace: true });
                  navigate(`/academic/marklist/cst/${gradeId}`, {
                    replace: true,
                  });
                },
              });
            }}
          />
        </ShowIfHas>
      </Box>
    </>
  );
};

export default CSTMenu;
