import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { User } from '../../../user/user';
import { Grade } from '../grade';
import useEditHrtForm from '../hooks/useEditHrtForm';

type EditHrtProps = {
  grade: Grade;
  users: User[];
};

const EditHrtForm = ({ grade, users }: EditHrtProps) => {
  const { formik, submitting } = useEditHrtForm(grade.id);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 400, mt: 4 }}>
        <form onSubmit={formik.handleSubmit}>
          <Paper sx={{ p: 2, pb: 3 }}>
            <Grid item sx={{ mt: 2 }}>
              <Typography sx={{ mb: 2 }} variant="h6">
                Home Room teacher
              </Typography>

              <FormControl fullWidth>
                <InputLabel>Teacher</InputLabel>
                <Select
                  value={formik.values.user_id}
                  size="small"
                  label="Teacher"
                  name="user_id"
                  onChange={formik.handleChange}
                  fullWidth
                >
                  <MenuItem value=""></MenuItem>
                  {users
                    .sort((a, b) => a.first_name.localeCompare(b.first_name))
                    .map((user) => (
                      <MenuItem value={user.id} key={user.id}>
                        {user.first_name} {user.father_name}
                      </MenuItem>
                    ))}
                </Select>
                {formik.touched.user_id && formik.errors.user_id && (
                  <FormHelperText error>{formik.errors.user_id}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                size="small"
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Paper>
        </form>
      </Box>
    </>
  );
};

export default EditHrtForm;
