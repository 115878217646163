import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import Layout from '../../../core/ui/layout/Layout';
import { useLoadGradesSms } from '../lib/hooks/useLoadGradesSms';
import Loading from '../../../core/ui/utility/Loading';
import { useSendMessage } from '../lib/hooks/useSendMessage';

const TextMessagePage = () => {
  const { grades, loading } = useLoadGradesSms();
  const { selectedGrades, handleChange, formik, btnDisabled } =
    useSendMessage(grades);

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, maxWidth: 500 }} p={2}>
        {/* Grades  */}
        <Grid container display="flex" flexDirection={'column'}>
          <Typography variant="h4">Global Text Messages</Typography>

          <Grid item mt={2}>
            <FormControl sx={{ width: 300 }}>
              <InputLabel id="demo-multiple-name-label">Grades</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={selectedGrades}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                size="small"
              >
                {grades.map((grade) => (
                  <MenuItem key={grade.id} value={grade.id}>
                    <span>
                      {grade.name} ({grade.gradeStudents.length} students)
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <form onSubmit={formik.handleSubmit}>
            <Paper sx={{ p: 2, mt: 2 }}>
              <Grid container spacing={1} sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  name="message"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Message"
                  required
                  multiline
                  minRows={4}
                  placeholder=""
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.message)}
                  helperText={formik.errors.message}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  name="parent_resource_id"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Id (unique id to group messages together)"
                  placeholder="Eg. 2016-christmas-holiday"
                  value={formik.values.parent_resource_id}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.parent_resource_id)}
                  helperText={formik.errors.parent_resource_id}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  name="msg_type"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Message type(optional)"
                  placeholder="Eg. holiday"
                  value={formik.values.msg_type}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.msg_type)}
                  helperText={formik.errors.msg_type}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mb: 3 }}>
                <TextField
                  fullWidth
                  name="msg_tags"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Message tags comma separated (optional)"
                  placeholder="holiday,2023,christmas"
                  value={formik.values.msg_tags}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.msg_tags)}
                  helperText={formik.errors.msg_tags}
                />
              </Grid>

              <Grid item xs={3}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={btnDisabled}
                  size="small"
                >
                  Generate
                </Button>
              </Grid>
            </Paper>
          </form>
        </Grid>
      </Box>
    </Layout>
  );
};

export default TextMessagePage;
