/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Loader from '../../../../core/ui/utility/Loader';
import usePaymentReportController from '../hooks/usePaymentReportController';

const PaymentReport = () => {
  const { mode, setMode, report, loading, getReport, resetReport } =
    usePaymentReportController();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    resetReport();
  }, [startDate, endDate]);

  const LabelItem = ({ label, stat }: { label: string; stat: number }) => (
    <Grid display="flex" justifyContent="space-between">
      <Typography fontWeight="600" variant="body1" fontSize="17px">
        {label}
      </Typography>
      <Typography fontWeight="600" variant="body1" fontSize="17px">
        {stat}
      </Typography>
    </Grid>
  );

  return (
    <>
      <Paper sx={{ py: 4, px: 2 }}>
        <Grid container display="flex" flexDirection="row">
          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <InputLabel>Mode</InputLabel>
            <Select
              value={mode}
              size="small"
              label="Mode"
              onChange={(e) => setMode(e.target.value as any)}
              fullWidth
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="range">Date Range</MenuItem>
            </Select>
          </FormControl>

          {mode === 'range' && (
            <Grid item display="flex" sx={{ ml: 4 }}>
              <Grid item sx={{ maxWidth: 200, mr: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  type="date"
                  variant="outlined"
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Grid>
              <Grid item sx={{ maxWidth: 200, mr: 2 }}>
                <TextField
                  fullWidth
                  size="small"
                  type="date"
                  variant="outlined"
                  label="End Date"
                  placeholder=""
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={startDate === '' || endDate === '' || loading}
                  variant="contained"
                  onClick={() => getReport(startDate, endDate)}
                >
                  Get
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container sx={{ mt: 3 }} display="flex" flexDirection="column">
          <Typography variant="h5" sx={{ mb: 1 }}>
            Reports
          </Typography>

          <Loader loading={loading}>
            {report !== null && (
              <Grid container display="flex" flexDirection="column">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  sx={{
                    p: 2,
                    bgcolor: 'white',
                    border: '3px solid',
                    borderColor: '#45a5a5',
                    filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                    flexShrink: 1,
                    maxWidth: 700,
                    height: 'fit-content',
                    px: 2,
                    mb: 3,
                    minWidth: '200px',
                  }}
                >
                  <Typography
                    fontWeight="600"
                    fontSize="22px"
                    sx={{ mb: 1 }}
                    textAlign="center"
                  >
                    Overall Total
                  </Typography>

                  <LabelItem
                    label="Count"
                    stat={report.fixed.count + report.recurrent.count}
                  />
                  <LabelItem
                    label="Penalty"
                    stat={
                      report.fixed.penaltyTotal + report.recurrent.penaltyTotal
                    }
                  />
                  <LabelItem
                    label="Fee"
                    stat={
                      report.fixed.amountTotal + report.recurrent.amountTotal
                    }
                  />
                  <Grid sx={{ mt: 1 }}></Grid>
                  <LabelItem
                    label="Total"
                    stat={
                      report.fixed.penaltyTotal +
                      report.fixed.amountTotal +
                      report.recurrent.penaltyTotal +
                      report.recurrent.amountTotal
                    }
                  />
                </Grid>

                <Paper
                  sx={{
                    p: 2,
                    bgcolor: 'white',
                    borderLeft: '3px solid',
                    borderLeftColor: '#5ee465',
                    filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                    flexShrink: 1,
                    maxWidth: 700,
                    height: 'fit-content',
                    px: 2,
                    mb: 3,
                    minWidth: '200px',
                  }}
                >
                  <Grid container display="flex" flexDirection="column">
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      Fixed Reports
                    </Typography>

                    <Grid item display="flex" flexWrap="wrap" sx={{ mb: 1 }}>
                      {report.fixed.payments.map((fixedReport) => (
                        <Grid
                          key={fixedReport.fixed.id}
                          item
                          display="flex"
                          flexDirection="column"
                          sx={{
                            p: 2,
                            bgcolor: 'white',
                            borderLeft: '3px solid',
                            borderLeftColor: 'primary.main',
                            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                            flexShrink: 1,
                            maxWidth: 700,
                            height: 'fit-content',
                            px: 2,
                            minWidth: '200px',
                            mr: 3,
                            mb: 2,
                          }}
                        >
                          <Typography
                            fontWeight="600"
                            fontSize="18px"
                            sx={{ mb: 1 }}
                          >
                            {fixedReport.fixed.description}
                          </Typography>

                          <LabelItem label="Count" stat={fixedReport.count} />
                          <LabelItem
                            label="Penalty"
                            stat={fixedReport.penaltyTotal}
                          />
                          <LabelItem
                            label="Fee"
                            stat={fixedReport.amountTotal}
                          />
                          <Grid sx={{ mt: 1 }}></Grid>
                          <LabelItem
                            label="Total"
                            stat={
                              fixedReport.penaltyTotal + fixedReport.amountTotal
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    display="flex"
                    flexDirection="column"
                    sx={{
                      p: 2,
                      bgcolor: 'white',
                      border: '3px solid',
                      borderColor: '#45a5a5',
                      filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                      flexShrink: 1,
                      maxWidth: 700,
                      height: 'fit-content',
                      px: 2,
                      minWidth: '200px',
                      mr: 3,
                      mb: 2,
                    }}
                  >
                    <Typography
                      fontWeight="600"
                      fontSize="22px"
                      sx={{ mb: 1 }}
                      textAlign="center"
                    >
                      Fixed Total
                    </Typography>

                    <LabelItem label="Count" stat={report.fixed.count} />
                    <LabelItem
                      label="Penalty"
                      stat={report.fixed.penaltyTotal}
                    />
                    <LabelItem label="Fee" stat={report.fixed.amountTotal} />
                    <Grid sx={{ mt: 1 }}></Grid>
                    <LabelItem
                      label="Total"
                      stat={
                        report.fixed.penaltyTotal + report.fixed.amountTotal
                      }
                    />
                  </Grid>
                </Paper>

                <Paper
                  sx={{
                    p: 2,
                    bgcolor: 'white',
                    borderLeft: '3px solid',
                    borderLeftColor: '#5ee465',
                    filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                    flexShrink: 1,
                    height: 'fit-content',
                    px: 2,
                    minWidth: '200px',
                  }}
                >
                  <Grid container display="flex" flexDirection="column">
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      Recurrent Reports
                    </Typography>

                    {report.recurrent.payments.map((recPayment) => (
                      <Paper sx={{ mb: 2, p: 2 }} key={recPayment.recurrent.id}>
                        <Grid container display="flex" flexDirection="column">
                          <Typography variant="h6" sx={{ mb: 1 }}>
                            {recPayment.recurrent.description}
                          </Typography>

                          <Grid
                            item
                            display="flex"
                            flexWrap="wrap"
                            sx={{ mb: 1 }}
                          >
                            {recPayment.recurrentChildren.map(
                              (recChildReport) => (
                                <Grid
                                  key={recChildReport.payment.id}
                                  item
                                  display="flex"
                                  flexDirection="column"
                                  sx={{
                                    p: 2,
                                    bgcolor: 'white',
                                    borderLeft: '3px solid',
                                    borderLeftColor: 'primary.main',
                                    filter:
                                      'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                                    flexShrink: 1,
                                    maxWidth: 700,
                                    height: 'fit-content',
                                    px: 2,
                                    minWidth: '200px',
                                    mr: 3,
                                    mb: 2,
                                  }}
                                >
                                  <Typography
                                    fontWeight="600"
                                    fontSize="18px"
                                    sx={{ mb: 1 }}
                                  >
                                    {recChildReport.payment.description}
                                  </Typography>

                                  <LabelItem
                                    label="Count"
                                    stat={recChildReport.count}
                                  />
                                  <LabelItem
                                    label="Penalty"
                                    stat={recChildReport.penaltyTotal}
                                  />
                                  <LabelItem
                                    label="Fee"
                                    stat={recChildReport.amountTotal}
                                  />
                                  <Grid sx={{ mt: 1 }}></Grid>
                                  <LabelItem
                                    label="Total"
                                    stat={
                                      recChildReport.penaltyTotal +
                                      recChildReport.amountTotal
                                    }
                                  />
                                </Grid>
                              )
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          display="flex"
                          flexDirection="column"
                          sx={{
                            p: 2,
                            bgcolor: 'white',
                            borderLeft: '3px solid',
                            borderLeftColor: 'primary.main',
                            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                            flexShrink: 1,
                            maxWidth: 400,
                            height: 'fit-content',
                            px: 2,
                            minWidth: '200px',
                            mr: 3,
                            mb: 2,
                          }}
                        >
                          <Typography
                            fontWeight="600"
                            fontSize="18px"
                            sx={{ mb: 1 }}
                          >
                            {recPayment.recurrent.description} Total
                          </Typography>

                          <LabelItem label="Count" stat={recPayment.count} />
                          <LabelItem
                            label="Penalty"
                            stat={recPayment.penaltyTotal}
                          />
                          <LabelItem
                            label="Fee"
                            stat={recPayment.amountTotal}
                          />
                          <Grid sx={{ mt: 1 }}></Grid>
                          <LabelItem
                            label="Total"
                            stat={
                              recPayment.penaltyTotal + recPayment.amountTotal
                            }
                          />
                        </Grid>
                      </Paper>
                    ))}

                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      sx={{
                        p: 2,
                        bgcolor: 'white',
                        border: '3px solid',
                        borderColor: '#45a5a5',
                        filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                        flexShrink: 1,
                        maxWidth: 700,
                        height: 'fit-content',
                        px: 2,
                        minWidth: '200px',
                        mr: 3,
                      }}
                    >
                      <Typography
                        fontWeight="600"
                        fontSize="22px"
                        sx={{ mb: 1 }}
                        textAlign="center"
                      >
                        Recurrent Total
                      </Typography>

                      <LabelItem label="Count" stat={report.recurrent.count} />
                      <LabelItem
                        label="Penalty"
                        stat={report.recurrent.penaltyTotal}
                      />
                      <LabelItem
                        label="Fee"
                        stat={report.recurrent.amountTotal}
                      />
                      <Grid sx={{ mt: 1 }}></Grid>
                      <LabelItem
                        label="Total"
                        stat={
                          report.recurrent.penaltyTotal +
                          report.recurrent.amountTotal
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Loader>
        </Grid>
      </Paper>
    </>
  );
};

export default PaymentReport;
