/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Paper, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import Loader from '../../../../core/ui/utility/Loader';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import StudentSelectContainer from '../../containers/StudentSelectContainer';
import FixedPaymentSummary from '../components/FixedPaymentSummary';
import { FixedPaymentCreate } from '../fixedPayment';

type FixedPayload = {
  fixed_payment_id: string;
  students: {
    student_id: string;
    grade_id: string;
  }[];
};

const CreateFixedPaymentPendingContainer = ({
  fixedPaymentId,
  onSuccess,
}: {
  fixedPaymentId: string;
  onSuccess: () => void;
}) => {
  const [fixedPayment, setFixedPayment] = useState<FixedPaymentCreate | null>(
    null
  );
  const { fetchData, loading: fixedPaymentLoading } = useFetchApiData();
  const { callApi, loading: submitting } = useSendApiData();
  const initialValues: FixedPayload = {
    fixed_payment_id: fixedPaymentId,
    students: [],
  };

  const formik = useFormik<FixedPayload>({
    initialValues,
    onSubmit: async (
      value: FixedPayload,
      { setFieldError }: FormikHelpers<FixedPayload>
    ) => {
      let success = false;

      await callApi({
        endpoint: 'payment-latest/fixed/pending',
        data: value,
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
        onSuccess: () => {
          toastMessage('Pending payment created successfully');
          success = true;
        },
      });

      if (success) {
        onSuccess();
      }
    },
  });

  useEffect(() => {
    fetchData(`payment-latest/fixed/${fixedPaymentId}`, {
      onSuccess: (data) => setFixedPayment(data),
      onError: toastError,
    });
  }, []);

  return (
    <Loader loading={fixedPaymentLoading || !fixedPayment}>
      <Grid container sx={{ p: 2, mx: 'auto', mt: 4 }} display="flex">
        <Grid item sm={6}>
          <FixedPaymentSummary fixedPayment={fixedPayment!} />
        </Grid>

        <Grid
          item
          sm={6}
          display="flex"
          flexDirection="column"
          alignItems="center"
          component={Paper}
          sx={{ p: 4 }}
        >
          <StudentSelectContainer
            onSubmit={() => formik.submitForm()}
            submitting={submitting}
            onSetStudentData={(
              data: { student_id: string; grade_id: string }[]
            ) => {
              formik.setFieldValue('students', data);
            }}
          />

          <Grid>
            {Boolean(
              formik.errors.fixed_payment_id && formik.touched.fixed_payment_id
            ) && (
              <Typography color="error">
                Fixed payment {formik.errors.fixed_payment_id}
              </Typography>
            )}

            {Boolean(formik.errors.students && formik.touched.students) && (
              <Typography color="error">
                Students {JSON.stringify(formik.errors.students)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default CreateFixedPaymentPendingContainer;
