import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import StageSummary from './StageSummary';

const StageContainer = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Grid
        sx={{
          width: '100%',
          minHeight: '50vh',
          position: 'relative',
        }}
      >
        <StageSummary />
        {children}
      </Grid>
    </>
  );
};

export default StageContainer;
