/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import GlobalContext from '../../../../core/service/globalContext';
import { toastError } from '../../../../core/utils/ui/alert';
import { GradeStudent } from '../gradeStudent';

const useFetchGradeStudents = (initYear?: string) => {
  const { fetchData, loading } = useFetchApiData();
  const { years, grades } = useContext(GlobalContext);
  const [year, setYear] = useState(initYear || '');
  const [grade, setGrade] = useState('');
  const [activeFilter, setActiveFilter] = useState(false);
  const [gradeStudents, setGradeStudents] = useState<GradeStudent[]>([]);

  const filteredGradeStudents = useMemo(
    () => gradeStudents.filter((i) => !activeFilter || i.active),
    [gradeStudents, activeFilter]
  );

  useEffect(() => {
    if (year) {
      if (grade === '') {
        fetchData(`academic/grade-students/year-grade-students/all/${year}`, {
          onSuccess: (data) => {
            setGradeStudents(data);
          },
          onError: toastError,
        });
      } else {
        fetchData(
          `academic/grade-students/year-grade-students/${year}/${grade}`,
          {
            onSuccess: (data) => {
              setGradeStudents(data);
            },
            onError: toastError,
          }
        );
      }
    }
  }, [year, grade]);

  return {
    years,
    grades,
    year,
    setYear,
    grade,
    setGrade,
    loading,
    gradeStudents: filteredGradeStudents,
    activeFilter,
    setActiveFilter,
  };
};

export default useFetchGradeStudents;
