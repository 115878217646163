import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DateModified from '../../../../core/ui/utility/DateModified';
import Loader from '../../../../core/ui/utility/Loader';
import StudentPreview from '../../../../core/ui/utility/StudentPreview';
import useFetchGradeStudents from '../hooks/useFetchGradeStudents';

const GradeStudentList = () => {
  const navigate = useNavigate();
  const {
    grades,
    years,
    grade,
    setGrade,
    year,
    setYear,
    gradeStudents,
    loading,
    activeFilter,
    setActiveFilter,
  } = useFetchGradeStudents();

  return (
    <>
      <Paper sx={{ px: 2, py: 4 }}>
        <Grid item sx={{ mb: 2 }}>
          <Button
            onClick={() => navigate('/academic/grade-students/activeness')}
          >
            Set Activeness Rule
          </Button>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="row">
          <FormControl fullWidth sx={{ mr: 2, maxWidth: 200 }}>
            <InputLabel>Year</InputLabel>
            <Select
              fullWidth
              value={year}
              label="Year"
              size="small"
              onChange={(e) => setYear(e.target.value)}
            >
              {years.map((year) => (
                <MenuItem value={year.id} key={year.id}>
                  {year.year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ maxWidth: 200, mr: 4 }}>
            <InputLabel>Grade</InputLabel>
            <Select
              fullWidth
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              label="Grade"
              size="small"
            >
              <MenuItem value="">All</MenuItem>
              {grades.map((grade) => (
                <MenuItem value={grade.id} key={grade.id}>
                  {grade.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ maxWidth: 200 }}>
            <Grid display="flex" flexDirection="row" alignItems="center">
              <Checkbox
                color="primary"
                size="small"
                checked={activeFilter}
                onChange={(e) => setActiveFilter(e.target.checked)}
              />
              <Typography>Active Only</Typography>
            </Grid>
          </FormControl>
        </Grid>

        <Loader loading={loading}>
          <Grid>
            <TableContainer
              component={Paper}
              sx={{ overflowX: 'auto', maxWidth: 1300, mt: 2 }}
            >
              <Table aria-label="custom pagination table" size="small">
                <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Student</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Grade</TableCell>
                    <TableCell>Id Number</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Modified</TableCell>
                    {/* <TableCell align="right" sx={{ width: 48 }}></TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gradeStudents.map((gs, i) => (
                    <TableRow key={gs.id}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell size="small">
                        <StudentPreview student={gs.student} />
                      </TableCell>
                      <TableCell>{gs.active ? 'Yes' : 'No'}</TableCell>
                      <TableCell size="small">{gs.student.gender}</TableCell>
                      <TableCell size="small">
                        {gs.student.date_of_birth
                          ? moment()
                              .diff(moment(gs.student.date_of_birth), 'years')
                              .toString()
                          : ''}
                      </TableCell>
                      <TableCell size="small">{gs.grade.name}</TableCell>
                      <TableCell size="small">{gs.student.id_number}</TableCell>
                      <TableCell size="small">
                        {gs.student.primary_phone}
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{ py: '4px' }}>
                        <DateModified
                          createdAt={gs.created_at}
                          updatedAt={gs.updated_at}
                        />
                      </TableCell>
                      {/* <TableCell align="right" sx={{ width: 48 }}></TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Loader>
      </Paper>
    </>
  );
};

export default GradeStudentList;
