import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import { Recurrent, RecurrentEdit } from '../../recurrent';

type EditRecurrentProps = {
  payment: Recurrent;
  onSuccess: () => void;
  onCancel: () => void;
};

const EditRecurrentPaymentForm = ({
  payment,
  onSuccess,
  onCancel,
}: EditRecurrentProps) => {
  const { callApi, loading: submitting } = useSendApiData();

  const initialValues: RecurrentEdit = {
    effective_date: payment.effective_date,
    end_date: payment.end_date,
    description: payment.description,
    archived: false,
  };

  const formik = useFormik<RecurrentEdit>({
    initialValues,
    onSubmit: async (
      value,
      { setFieldError }: FormikHelpers<RecurrentEdit>
    ) => {
      let success = false;
      await callApi({
        endpoint: `payment-latest/recurrent/${payment.id}`,
        data: value,
        method: 'patch',
        onSuccess: () => {
          toastMessage('Recurrent payment edited');
          success = true;
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
      });

      if (success) onSuccess();
    },
  });

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ mx: 'auto', mb: 4 }}
          >
            Edit Payment
          </Typography>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="description"
                size="small"
                type="text"
                variant="outlined"
                label="Description"
                placeholder=""
                value={formik.values.description}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                helperText={formik.errors.description}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="effective_date"
                size="small"
                type="date"
                variant="outlined"
                label="Effective Date"
                placeholder=""
                value={formik.values.effective_date}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.effective_date && formik.errors.effective_date
                )}
                helperText={formik.errors.effective_date}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item flexGrow={1}>
              <TextField
                fullWidth
                name="end_date"
                size="small"
                type="date"
                variant="outlined"
                label="End Date"
                placeholder=""
                value={formik.values.end_date}
                onChange={formik.handleChange}
                error={Boolean(
                  formik.touched.end_date && formik.errors.end_date
                )}
                helperText={formik.errors.end_date}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item>
              <FormControl error>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="archived"
                      checked={formik.values.archived}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Archive"
                />
                {Boolean(formik.errors.archived) && (
                  <FormHelperText>{formik.errors.archived}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }} justifyContent="flex-end">
            <Button
              type="submit"
              color="success"
              variant="outlined"
              disabled={submitting}
              size="small"
              sx={{ mr: 1 }}
            >
              {submitting ? 'Saving' : 'Save'}
            </Button>

            <Button
              type="button"
              variant="outlined"
              onClick={onCancel}
              disabled={submitting}
              size="small"
            >
              Cancel
            </Button>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditRecurrentPaymentForm;
