import { Box, Button, Modal } from '@mui/material';
import { ReactChildren } from '../../utils/types';

type ModalContainerProps = {
  open: boolean;
  setOpen: (x: boolean) => void;
  buttonLabel?: string;
  width?: number;
} & ReactChildren;

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '0',
  p: 2,
};

const ModalContainer = ({
  buttonLabel,
  children,
  open,
  setOpen,
  width = 400,
}: ModalContainerProps) => {
  return (
    <>
      <Box>
        {buttonLabel && (
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            size="small"
            sx={{ textTransform: 'none' }}
          >
            {buttonLabel}
          </Button>
        )}

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...modalStyle, width }}>{children}</Box>
        </Modal>
      </Box>
    </>
  );
};

export default ModalContainer;
