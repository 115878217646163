import { Edit } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Layout from '../../../../core/ui/layout/Layout';
import Loader from '../../../../core/ui/utility/Loader';
import useFetchHomeRoomStudents from '../hooks/useFetchHomeRoomStudents';

const HomeRoomStudentsPage = () => {
  const { students, loading } = useFetchHomeRoomStudents();

  return (
    <>
      <Layout>
        <Loader loading={loading}>
          <Box sx={{ p: 2 }}>
            <Table
              aria-label="custom pagination table"
              size="small"
              sx={{ maxWidth: '600px' }}
            >
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Student</TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell size="small">
                      {student.first_name} {student.father_name || ''}{' '}
                      {student.grand_father_name || ''}
                    </TableCell>
                    <TableCell size="small">{student.id_number}</TableCell>
                    <TableCell>
                      <Link to={`/academic/student/view/${student.id}`}>
                        <Edit fontSize="small" color="primary" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Loader>
      </Layout>
    </>
  );
};

export default HomeRoomStudentsPage;
