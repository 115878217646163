import { AttachmentPayment } from '../types/attachment-payment.type';
import {
  StudentFixedReport,
  StudentRecurrentReport,
} from '../types/student-payment-report';

const AttachmentService = {
  parseAttachment: (payments: AttachmentPayment[]) => {
    const recurrents = payments
      .filter((i) => i.type === 'recurrent')
      .map((i: any) => i.payment as StudentRecurrentReport);
    const fixeds = payments
      .filter((i) => i.type === 'fixed')
      .map((i: any) => i.payment as StudentFixedReport);

    const recurrentTotal = recurrents.reduce((acc, cur) => acc + cur.total, 0);
    const fixedTotal = fixeds.reduce((acc, cur) => acc + cur.total, 0);
    const empties = payments.filter((i) => i.type === 'empty');

    return {
      fixed: { count: fixeds.length, total: fixedTotal },
      recurrent: { count: recurrents.length, total: recurrentTotal },
      empty: { count: empties.length },
    };
  },
};

export default AttachmentService;
