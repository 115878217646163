import { Grid, MenuItem, Select } from '@mui/material';
import { PuffLoader } from 'react-spinners';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import Loader from '../../../../../core/ui/utility/Loader';
import { toastError } from '../../../../../core/utils/ui/alert';

type ConductInlineProps = {
  gradeStudentId: string;
  quarterId: string;
  value: string;
  endpoint?: string;
  fetchConduct: () => void;
};

const ConductInlineForm = ({
  gradeStudentId,
  quarterId,
  value,
  fetchConduct,
}: ConductInlineProps) => {
  const { callApi, loading } = useSendApiData();

  const updateConduct = (conduct: string) => {
    callApi({
      endpoint: 'academic/students/conducts',
      data: {
        grade_student_id: gradeStudentId,
        quarter_id: quarterId,
        conduct,
      },
      onSuccess: () => {
        fetchConduct();
      },
      onError: toastError,
      onValidationError: (err) => {
        toastError(`Invalid data ${Object.values(err.errors).join('<br/>')}`);
      },
    });
  };

  return (
    <>
      <Loader
        loading={loading}
        loaderComponent={
          <Grid display="flex" alignItems="center" justifyContent="center">
            <PuffLoader color="#276BCE" size={20} />
          </Grid>
        }
      >
        <Select
          value={value}
          size="small"
          label=""
          onChange={(e) => {
            updateConduct(e.target.value);
          }}
          sx={{
            width: '60px',
            height: '30px',
            fontSize: '13px',
            input: {
              px: 1,
              textAlign: 'center',
              height: '30px',
              fontSize: '13px',
            },
          }}
        >
          <MenuItem dense value=""></MenuItem>
          <MenuItem dense value="A">
            A
          </MenuItem>
          <MenuItem dense value="B">
            B
          </MenuItem>
          <MenuItem dense value="C">
            C
          </MenuItem>
          <MenuItem dense value="D">
            D
          </MenuItem>
        </Select>
      </Loader>
    </>
  );
};

export default ConductInlineForm;
