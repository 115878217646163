import Layout from '../../../../core/ui/layout/Layout';
import StudentDetailContainer from '../containers/StudentDetailContainer';

const StudentDetailPage = () => {
  return (
    <>
      <Layout>
        <StudentDetailContainer />
      </Layout>
    </>
  );
};

export default StudentDetailPage;
