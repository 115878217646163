/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from '@mui/icons-material';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Layout from '../../../../../core/ui/layout/Layout';
import ShowIfHas from '../../../../../core/ui/permission/ShowIfHas';
import SummaryBlock from '../../../../../core/ui/table/components/SummaryBlock';
import { hasPermission } from '../../../../../core/utils/data';
import { toastError } from '../../../../../core/utils/ui/alert';
import AuthContext from '../../../../auth/service/authContext';
import { Quarter } from '../quarter';

const EvaluationTypeList = () => {
  const [data, setData] = useState<Quarter[]>([]);
  const { user } = useContext(AuthContext);

  const { fetchData } = useFetchApiData();

  useEffect(() => {
    fetchData(`academic/marklist/quarters/search`, {
      onSuccess: ({ data: apiData }) => {
        setData(apiData);
      },
      method: 'post',
      body: {
        orderBies: [{ field: 'quarter', op: 'asc' }],
        withs: { semester: {} },
      },
      onError: toastError,
    });
  }, []);

  return (
    <Layout>
      <>
        <Box sx={{ p: 2 }}>
          <SummaryBlock
            buttonLabel="New Quarter"
            modelLabel="Quarters"
            itemCount={data.length}
            addRoute="/academic/quarter/create"
            showCreate={hasPermission(user!.permissions, ['add-quarter'])}
          />

          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 450, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Quarter</TableCell>
                  <TableCell>Semester</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((quarter) => (
                  <TableRow key={quarter.id}>
                    <TableCell size="small">{quarter.quarter}</TableCell>
                    <TableCell size="small">
                      {quarter.semester.semester}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {DateTime.fromISO(quarter.created_at).toLocaleString(
                        DateTime.DATE_MED
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <ShowIfHas permissions={['edit-quarter']}>
                        <Link to={`/academic/quarter/edit/${quarter.id}`}>
                          <Edit
                            fontSize="small"
                            sx={{ mr: 1, fontSize: '15px' }}
                          />
                        </Link>
                      </ShowIfHas>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Layout>
  );
};

export default EvaluationTypeList;
