/* eslint-disable react-hooks/exhaustive-deps */
import { FormikHelpers, useFormik } from 'formik';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Typography,
  Box,
  Button,
} from '@mui/material';
import DateTimePicker from 'react-datetime-picker';
import { useEffect, useState } from 'react';
import {
  RecurrentPending,
  StudentRecurrentPaymentCreate,
} from '../../recurrent';
import { modalStyle } from '../../../../../core/ui/utility/ModalContainer';
import useGetPendingPenalty from '../../hooks/useGetPendingPenalty';
import { LoadingFit } from '../../../../../core/ui/utility/Loading';

type StudentRecurrentPaymentProps = {
  recurrentPending: RecurrentPending;
  recurrentDescription: string;
  fs: string | null;
  attachment: string | null;
  onCancel: () => void;
  stagePayment: (p: StudentRecurrentPaymentCreate) => void;
  recurrentPaymentId: string;
};

const StageStudentRecurrentPaymentForm = ({
  recurrentPending,
  recurrentDescription,
  fs,
  attachment,
  onCancel,
  stagePayment,
  recurrentPaymentId,
}: StudentRecurrentPaymentProps) => {
  const [method, setMethod] = useState<'cash' | 'slip'>('cash');

  const initialValues: StudentRecurrentPaymentCreate = {
    recurrent_payment_pending_id: recurrentPending.id,
    attachment: attachment || '',
    fs: fs || '',
    slip_date: undefined,
    remark: '',
    cash: '',
    penalty: 0,
  };

  const handleSubmit = (
    values: StudentRecurrentPaymentCreate,
    _: FormikHelpers<StudentRecurrentPaymentCreate>
  ) => {
    stagePayment({
      ...values,
      slip_date: method === 'slip' ? values.slip_date : undefined,
      penalty: penalty as unknown as number,
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const { penalty, loading } = useGetPendingPenalty(
    formik.values.slip_date,
    recurrentPaymentId
  );

  useEffect(() => {
    if (method === 'cash') {
      formik.setFieldValue('slip_date', undefined);
    }
  }, [method]);

  return (
    <>
      <Box
        sx={{
          ...modalStyle,
          p: 2,
          bgcolor: 'white',
          minWidth: 500,
          overflow: 'auto',
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                sx={{
                  my: 1,
                  py: 1,
                  px: 2,
                  bgcolor: 'white',
                  filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                  borderLeft: '3px solid',
                  borderLeftColor: 'primary.main',
                }}
              >
                <Typography fontWeight={600} sx={{ mb: 1 }}>
                  Student {recurrentPending.student.first_name}{' '}
                  {recurrentPending.student.father_name}
                </Typography>

                <Typography fontWeight={600} sx={{ mb: 1 }}>
                  {recurrentPending.grade.name}
                </Typography>

                {loading ? (
                  <LoadingFit />
                ) : (
                  <Typography fontWeight={600}>Penalty {penalty}</Typography>
                )}
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  disabled
                  name="recurrent_payment_pending_id"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Description"
                  placeholder=""
                  value={recurrentDescription}
                  error={Boolean(
                    formik.touched.recurrent_payment_pending_id &&
                      formik.errors.recurrent_payment_pending_id
                  )}
                  helperText={
                    formik.touched.recurrent_payment_pending_id &&
                    formik.errors.recurrent_payment_pending_id
                  }
                />
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="attachment"
                  disabled={attachment !== null}
                  size="small"
                  type="number"
                  variant="outlined"
                  label="Attachment"
                  placeholder="Attachment"
                  value={formik.values.attachment}
                  onChange={formik.handleChange}
                  error={Boolean(
                    formik.touched.attachment && formik.errors.attachment
                  )}
                  helperText={
                    formik.touched.attachment && formik.errors.attachment
                  }
                />
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="fs"
                  disabled={fs !== null}
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Fs"
                  placeholder="Fs"
                  value={formik.values.fs}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.fs && formik.errors.fs)}
                  helperText={formik.touched.fs && formik.errors.fs}
                />
              </Grid>

              <Grid container sx={{ mt: 2 }} spacing={1}>
                <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel>Method</InputLabel>
                    <Select
                      value={method}
                      size="small"
                      label="Method"
                      onChange={(e) => {
                        setMethod(e.target.value! as 'cash' | 'slip');
                      }}
                      name="method"
                    >
                      <MenuItem value="cash">Cash</MenuItem>
                      <MenuItem value="slip">Slip</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {method === 'slip' ? (
                  <Grid
                    item
                    sx={{
                      flexGrow: 1,
                      padding: '0 !important',
                    }}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        paddingLeft: '8px',
                      }}
                    >
                      <Typography sx={{ p: 0, mr: 1 }}>Slip Date</Typography>
                      <Grid item alignItems="flex-end">
                        <DateTimePicker
                          onChange={(e) => {
                            formik.setFieldValue('slip_date', e || undefined);
                          }}
                          value={formik.values.slip_date}
                          disableClock
                          format="y-MM-dd"
                        />
                      </Grid>
                    </div>
                    {formik.touched.slip_date &&
                      formik.touched.slip_date &&
                      formik.errors.slip_date && (
                        <FormHelperText error>
                          {formik.touched.slip_date && formik.errors.slip_date}
                        </FormHelperText>
                      )}
                  </Grid>
                ) : (
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="cash"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Cash"
                      placeholder="Cash"
                      value={formik.values.cash}
                      onChange={formik.handleChange}
                      error={Boolean(formik.touched.cash && formik.errors.cash)}
                      helperText={formik.touched.cash && formik.errors.cash}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid container sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="remark"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Remark(*optional)"
                  placeholder="Remark(*optional)"
                  value={formik.values.remark}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.remark && formik.errors.remark)}
                  helperText={formik.touched.remark && formik.errors.remark}
                />
              </Grid>

              <Grid
                container
                sx={{ mt: 3 }}
                justifyContent="flex-end"
                display="flex"
              >
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={formik.values.fs.length !== 8 || attachment === ''}
                  size="small"
                  sx={{ mr: 2 }}
                >
                  Save
                </Button>

                <Button
                  type="button"
                  onClick={onCancel}
                  variant="outlined"
                  size="small"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default StageStudentRecurrentPaymentForm;
