/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useEffect } from 'react';
import Layout from '../../../../core/ui/layout/Layout';
import SummaryBlock from '../../../../core/ui/table/components/SummaryBlock';
import { hasPermission } from '../../../../core/utils/data';
import AuthContext from '../../../auth/service/authContext';
import useTeacherHook from '../hooks/useTeacherHook';
import { sortTeachers } from '../services/teacherService';

const EvaluationTypeList = () => {
  const { teachers, fetchTeachers } = useTeacherHook();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    fetchTeachers();
  }, []);

  return (
    <Layout>
      <>
        <Box sx={{ p: 2 }}>
          <SummaryBlock
            buttonLabel="New Teacher"
            modelLabel="Teachers"
            itemCount={teachers.length}
            addRoute="/academic/teacher/create"
            showCreate={hasPermission(user!.permissions, ['add-teacher'])}
          />

          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 750, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="small">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Home Room</TableCell>
                  <TableCell>Date Created</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortTeachers(teachers).map((teacher, i) => (
                  <TableRow key={teacher.id}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>
                      {teacher.user.first_name} {teacher.user.father_name}
                    </TableCell>
                    <TableCell>{teacher.user.hrt?.grade.name}</TableCell>
                    <TableCell component="th" scope="row">
                      {DateTime.fromISO(teacher.created_at).toLocaleString(
                        DateTime.DATE_MED
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Layout>
  );
};

export default EvaluationTypeList;
