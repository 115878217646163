import { Grid, Typography } from '@mui/material';
import { Recurrent } from '../recurrent';

const RecurrentPaymentSummary = ({
  recurrentPayment,
}: {
  recurrentPayment: Recurrent;
}) => {
  const SummaryItem = ({
    label,
    value,
  }: {
    label: string;
    value: string | number;
  }) => (
    <Grid
      display="flex"
      flexDirection="row"
      item
      xs={12}
      sx={{
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        mb: 1,
        pb: 1,
        borderBottom: '1px solid lightgrey',
        height: 'fit-content',
      }}
    >
      <Typography variant="h5" fontSize={16} fontWeight={700}>
        {label}
      </Typography>
      <Typography>{value}</Typography>
    </Grid>
  );

  return (
    <>
      <Grid
        sx={{
          p: 2,
          bgcolor: 'white',
          borderLeft: '3px solid',
          borderLeftColor: 'primary.main',
          filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
          flexShrink: 1,
          mb: 4,
          maxWidth: 400,
        }}
        container
        display="flex"
        flexDirection="column"
      >
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Typography variant="h5" fontWeight={600}>
            Recurrent Payment Summary
          </Typography>
        </Grid>

        <Grid item xs={12} display="flex">
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{ pr: 2, height: 'fit-content' }}
          >
            <SummaryItem
              label="Description"
              value={recurrentPayment.description}
            />
            <SummaryItem
              label="Total Payments"
              value={recurrentPayment.recurrentChildren.length}
            />
            <SummaryItem
              label="Effective Date"
              value={`${recurrentPayment.effective_date}`}
            />
            <SummaryItem
              label="End Date"
              value={`${recurrentPayment.end_date}`}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default RecurrentPaymentSummary;
