import { Box, Button, TextField, Typography } from '@mui/material';
import Layout from '../../../../core/ui/layout/Layout';
import useLoadAttachmentPayments from '../lib/hooks/useLoadAttachmentPayments';
import { LoadingFit } from '../../../../core/ui/utility/Loading';
import AttachmentReportTable from '../components/AttachmentReportTable';
import AttachmentSummary from '../components/AttachmentSummary';
import { useMemo } from 'react';

const AttachmentRangePage = () => {
  const { start, setStart, end, setEnd, payments, getPayments, loading } =
    useLoadAttachmentPayments();
  console.log('range');

  const paymentBox = useMemo(
    () => (
      <Box>
        <AttachmentSummary payments={payments} />
        <AttachmentReportTable payments={payments} />
      </Box>
    ),
    [payments]
  );

  return (
    <>
      <Layout>
        <Box p={2}>
          <Typography mb={1} variant="h5">
            Attachment Report
          </Typography>

          <Box alignItems="center" display="flex">
            <TextField
              label="Start"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              sx={{ width: 200, mr: 2 }}
              size="small"
            />
            <TextField
              label="End"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              sx={{ width: 200, mr: 4 }}
              size="small"
            />

            <Button
              size="small"
              variant="contained"
              disabled={!start || !end}
              onClick={getPayments}
            >
              Get
            </Button>
          </Box>

          {loading ? <LoadingFit /> : <>{paymentBox}</>}

          <Box height={40} />
        </Box>
      </Layout>
    </>
  );
};

export default AttachmentRangePage;
