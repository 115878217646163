/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../../core/service/globalContext';
import { toastError } from '../../../../../core/utils/ui/alert';
import { NurserySkill } from '../../models/skill';
import { parseNurserySkills } from '../../services/skillServices';
import SkillInlineForm from './SkillInlineForm';

type NurserySkillProps = {
  studentId: string;
  gsId: string;
};

const NurserySkillComponent = ({ studentId, gsId }: NurserySkillProps) => {
  const { quarters } = useContext(GlobalContext);
  const { fetchData } = useFetchApiData();
  const [skillMap, setNurserySkillMap] = useState<Record<string, NurserySkill>>(
    {}
  );
  const { callApi, loading } = useSendApiData();

  const fetchNurserySkills = () =>
    fetchData(`academic/students/nursery-skills/student/${studentId}`, {
      onSuccess: (data) => {
        setNurserySkillMap(parseNurserySkills(data?.nurserySkills || []));
      },
      onError: toastError,
    });

  useEffect(() => {
    fetchNurserySkills();
  }, []);

  const markAllE = async () => {
    for (let i = 0; i < quarters.length; i++) {
      await callApi({
        endpoint: 'academic/students/nursery-skills',
        data: {
          grade_student_id: gsId,
          quarter_id: quarters[i].id,
          acknowledges: 'E',
          greets: 'E',
          works_with_others: 'E',
          responds: 'E',
          accepts_responsibility: 'E',
          obeys_quickly: 'E',
          completes_work: 'E',
          listens_and_follows: 'E',
          work_independently: 'E',
          vocabulary_improvement: 'E',
        },
        onSuccess: () => {
          fetchNurserySkills();
        },
        onError: toastError,
      });
    }
  };

  return (
    <>
      <Grid display="flex" alignItems="baseline" sx={{ mb: 1 }}>
        <Typography variant="h5" sx={{ mr: 8 }}>
          Skill
        </Typography>

        <Button
          disabled={loading}
          onClick={markAllE}
          variant="contained"
          size="small"
        >
          All E
        </Button>
      </Grid>
      <Box sx={{ mb: 4 }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 600, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell size="small">Evaluation Areas</TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`eval-${quarter.id}`} size="small">
                      <Grid
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography sx={{ fontSize: 14 }}>Quarter</Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {quarter.quarter}
                        </Typography>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Acknowledges friends and teachers</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`ac-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="acknowledges"
                        value={skillMap[quarter.id]?.acknowledges || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Greets friends and teachers</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`gre-${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="greets"
                        value={skillMap[quarter.id]?.greets || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Works and plays with other children</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`wor${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="works_with_others"
                        value={skillMap[quarter.id]?.works_with_others || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>
                      Responds, gives suggestions and comments
                    </Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`res${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="responds"
                        value={skillMap[quarter.id]?.responds || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Accepts responsibility to action</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`acres${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="accepts_responsibility"
                        value={
                          skillMap[quarter.id]?.accepts_responsibility || ''
                        }
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Obeys quickly and cheerfully</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`ob${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="obeys_quickly"
                        value={skillMap[quarter.id]?.obeys_quickly || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Completes work carefully and neatly</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`comp${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="completes_work"
                        value={skillMap[quarter.id]?.completes_work || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Listens and follows direction</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`lis${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="listens_and_follows"
                        value={skillMap[quarter.id]?.listens_and_follows || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Works independently</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`wor-ind${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="work_independently"
                        value={skillMap[quarter.id]?.work_independently || ''}
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell size="small">
                    <Typography>Vocabulary improvement</Typography>
                  </TableCell>
                  {quarters.map((quarter) => (
                    <TableCell key={`voc${quarter.id}`} sx={{ py: 1 }}>
                      <SkillInlineForm
                        gradeStudentId={gsId}
                        quarterId={quarter.id}
                        field="vocabulary_improvement"
                        value={
                          skillMap[quarter.id]?.vocabulary_improvement || ''
                        }
                        fetchSkill={fetchNurserySkills}
                        endpoint="academic/students/nursery-skills"
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            sx={{
              px: 6,
              py: 4,
              ml: 4,
              border: '1px solid lightblue',
              borderColor: 'grey.200',
              borderTop: '5px solid',
              borderTopColor: '#1565C0',
              bgcolor: 'white',
              boxShadow: '0px 2px 14px rgba(0, 0, 0, 0.06)',
            }}
          >
            <Typography
              variant="h6"
              textAlign="center"
              sx={{ mb: 2 }}
              fontWeight={800}
            >
              Evaluation Code
            </Typography>

            <Grid display="flex">
              <Grid display="flex" flexDirection="column" sx={{ mr: 4 }}>
                <Typography variant="subtitle2" fontWeight="700">
                  E = Excellent
                </Typography>
                <Typography variant="subtitle2" fontWeight="700">
                  G = Good
                </Typography>
                <Typography variant="subtitle2" fontWeight="700">
                  N = Needs Improvement
                </Typography>
              </Grid>

              <Grid display="flex" flexDirection="column">
                <Typography variant="subtitle2" fontWeight="700">
                  P = Poor
                </Typography>
                <Typography variant="subtitle2" fontWeight="700">
                  R = At Risk
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NurserySkillComponent;
