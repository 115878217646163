import Layout from '../../../../core/ui/layout/Layout';
import FixedPaymentList from '../components/lists/FixedPaymentList';

const ListFixedPaymentPage = () => {
  return (
    <>
      <Layout>
        <FixedPaymentList />
      </Layout>
    </>
  );
};

export default ListFixedPaymentPage;
