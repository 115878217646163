/* eslint-disable react-hooks/exhaustive-deps */
import { Clear, DeleteForever, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import useFetchSingleData from '../../../../../core/hooks/useFetchSingleData';
import { StagePaymentContext } from '../../../../../core/state/StagePaymentContext';
import Loader from '../../../../../core/ui/utility/Loader';
import { modalStyle } from '../../../../../core/ui/utility/ModalContainer';
import StudentName from '../../../../../core/ui/utility/StudentPreview';
import AddStudentRecurrentPaymentContainer from '../../containers/AddStudentRecurrentContainer';
import {
  Recurrent,
  RecurrentChild,
  RecurrentPending,
  StudentRecurrentPaymentCreate,
} from '../../recurrent';
import DeleteRecurrentChildDialog from '../dialogs/DeleteRecurrentChildDialog';
import DeleteRecurrentPending from '../dialogs/DeleteRecurrentPending';
import EditRecurrentChildPaymentForm from '../forms/EditRecurrentChildPaymentForm';
import StageStudentRecurrentPaymentForm from '../forms/StageStudentRecurrentForm';
import RecurrentChildPaymentSummary from '../RecurrentChildPaymentSummary';

type StagePending = {
  student: string;
  grade: string;
  penalty: number;
  amount: number;
  pending: RecurrentPending;
  description: string;
};

const PendingRecurrentPaymentList = () => {
  const { id } = useParams();
  const mounted = useRef(false);
  const {
    loading: recurrentLoading,
    fetchData: fetchRecurrent,
    data: recurrentPayment,
  } = useFetchSingleData<Recurrent>();
  const {
    loading: pendingLoading,
    data: pendingPayments,
    fetchData: fetchPending,
    setData: setPendingPayment,
  } = useFetchApiData<RecurrentPending>();
  const [filter, setFilter] = useState('');
  const [addPending, setAddPending] = useState<RecurrentPending | null>(null);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [recurrentChild, setRecurrentChild] = useState<RecurrentChild | null>(
    null
  );
  const [editRecurrentChild, setEditRecurrentChild] =
    useState<RecurrentChild | null>(null);
  const [deleteRecurrentChild, setDeleteRecurrentChild] =
    useState<RecurrentChild | null>(null);
  const [deletePending, setDeletePending] = useState<RecurrentPending | null>(
    null
  );
  const [stageRecurrent, setStageRecurrent] = useState<StagePending | null>(
    null
  );
  const { payments, successfulPayments, addPayment } =
    useContext(StagePaymentContext);
  const paymentIds = payments.map(
    (i) =>
      (i.payment as StudentRecurrentPaymentCreate).recurrent_payment_pending_id
  );

  // Pagination stuff
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchRecurrent(`payment-latest/recurrent/${id}`);

    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (filter !== '' && mounted.current === true) {
      setPage(0);
    }
  }, [filter]);

  useEffect(() => {
    setPendingPayment([]);
    setRecurrentChild(null);
    if (selectedPayment) {
      fetchPending(
        `payment-latest/recurrent/pending/recurrent-child/${selectedPayment}`
      );

      const selectedChild = (recurrentPayment?.recurrentChildren || []).find(
        (i) => i.id === selectedPayment
      );

      if (selectedChild) {
        setRecurrentChild(selectedChild);
      }
    }
  }, [selectedPayment]);

  const getPendingPayments = () =>
    pendingPayments
      .filter(
        (item) =>
          !paymentIds.includes(item.id) && !successfulPayments.includes(item.id)
      )
      .sort((a, b) => a.student.first_name.localeCompare(b.student.first_name))
      .filter(
        ({ student, grade }) =>
          filter === '' ||
          (student.first_name + ' ' + student.father_name).match(
            new RegExp(`.*${filter}.*`, 'i')
          ) ||
          grade.name.match(new RegExp(`.*${filter}.*`, 'i'))
      );

  const removePending = (pendingId: string) => {
    setAddPending(null);
    const pendingIndex = pendingPayments.findIndex((i) => i.id === pendingId);

    if (pendingIndex !== undefined && pendingIndex !== -1) {
      const curPayment = [...pendingPayments];
      curPayment.splice(pendingIndex, 1);
      setPendingPayment(curPayment);
    }
  };

  const getRecurrentPayment = () => {
    const item = (recurrentPayment?.recurrentChildren || []).find(
      (i) => i.id === selectedPayment
    );

    return item ? `${item.order} ${item.description}` : '';
  };

  return (
    <>
      <Loader loading={recurrentLoading || !recurrentPayment}>
        <Grid display="flex" flexDirection="column" sx={{ p: 2 }}>
          {recurrentChild && (
            <RecurrentChildPaymentSummary payment={recurrentChild!} />
          )}
          <Paper sx={{ p: 2, mr: 2, mt: 2, pt: 2, pb: 4, maxWidth: 800 }}>
            <Grid
              sx={{
                my: 1,
                py: 1,
                px: 2,
                bgcolor: 'white',
                filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                width: 'fit-content',
                borderLeft: '3px solid',
                borderLeftColor: 'primary.main',
              }}
            >
              <Typography variant="h6" sx={{ mb: 1 }}>
                Pending Recurrent Student Payments -{' '}
                {recurrentPayment?.description}
              </Typography>

              <Typography fontWeight={600}>
                Total Payments {recurrentPayment?.recurrentChildren.length}
              </Typography>
            </Grid>

            <Grid
              item
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <FormControl sx={{ mr: 2, mt: 2, width: 300 }}>
                <InputLabel>Select sub payment</InputLabel>
                <Select
                  fullWidth
                  value={selectedPayment}
                  label="Select sub payment"
                  size="small"
                  onChange={(e) => setSelectedPayment(e.target.value)}
                >
                  {(recurrentPayment?.recurrentChildren || [])
                    .sort((a, b) => a.order - b.order)
                    .map((payment) => (
                      <MenuItem value={payment.id} key={payment.id}>
                        {payment.order} {payment.description}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              {recurrentChild && (
                <Grid
                  display="flex"
                  flexDirection="column"
                  sx={{
                    width: 500,
                    py: 1,
                    px: 2,
                    bgcolor: 'white',
                    filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
                    borderLeft: '3px solid',
                    borderLeftColor: 'primary.main',
                  }}
                >
                  <Grid
                    display="flex"
                    sx={{ width: '100%' }}
                    justifyContent="space-between"
                  >
                    <Typography sx={{ ml: 2, mb: 1 }} fontWeight={600}>
                      Payments Left
                    </Typography>

                    <Typography fontWeight={600}>
                      {getPendingPayments().length}
                    </Typography>
                  </Grid>

                  <Grid
                    display="flex"
                    sx={{ width: '100%' }}
                    justifyContent="space-between"
                  >
                    <Typography sx={{ ml: 2, mb: 1 }} fontWeight={600}>
                      Actions
                    </Typography>

                    <Grid display="flex" alignItems="center">
                      <Tooltip title="Edit Sub Payment">
                        <Edit
                          fontSize="small"
                          color="success"
                          sx={{ cursor: 'pointer', mr: 1 }}
                          onClick={() => setEditRecurrentChild(recurrentChild)}
                        />
                      </Tooltip>

                      <Tooltip title="Delete Sub Payment">
                        <DeleteForever
                          fontSize="small"
                          color="error"
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            setDeleteRecurrentChild(recurrentChild)
                          }
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Loader loading={pendingLoading}>
              <Grid sx={{ my: 2 }}>
                <TextField
                  size="small"
                  disabled={pendingPayments.length === 0}
                  placeholder="Filter by name or grade"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton size="small" onClick={() => setFilter('')}>
                          <Clear fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <TableContainer
                sx={{ overflowX: 'auto', mt: 2 }}
                component={Paper}
              >
                <Table aria-label="custom pagination table" size="small">
                  <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                    <TableRow>
                      <TableCell>Student</TableCell>
                      <TableCell>Grade</TableCell>
                      <TableCell>Payment</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell align="right" sx={{ width: 96 }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? getPendingPayments().slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : getPendingPayments()
                    ).map((payment) => (
                      <TableRow key={payment.id}>
                        <TableCell size="small">
                          <StudentName student={payment.student} />
                        </TableCell>
                        <TableCell size="small">{payment.grade.name}</TableCell>
                        <TableCell size="small">
                          {payment.recurrentPaymentChild.amount}
                        </TableCell>
                        <TableCell size="small">
                          {payment.discount_amount}
                        </TableCell>
                        <TableCell align="right" sx={{ width: 96 }}>
                          <Grid display="flex">
                            <Button
                              size="small"
                              sx={{ mr: 1 }}
                              onClick={() => setAddPending(payment)}
                              disabled={payments.length > 0}
                            >
                              Add
                            </Button>
                            <Button
                              size="small"
                              sx={{ mr: 1 }}
                              onClick={() => {
                                setStageRecurrent({
                                  student: `${payment.student.first_name} 
                        ${payment.student.father_name}`,
                                  grade: payment.grade.name,
                                  amount: recurrentChild!.amount,
                                  pending: payment,
                                  description: recurrentChild!.description,
                                  penalty: 0,
                                });
                              }}
                            >
                              Stage
                            </Button>

                            <DeleteForever
                              fontSize="small"
                              color="error"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => setDeletePending(payment)}
                            />
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>

                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          15,
                          30,
                          50,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={3}
                        count={getPendingPayments().length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Loader>
          </Paper>
        </Grid>
      </Loader>

      {selectedPayment !== null && (
        <>
          <Modal open={addPending !== null} onClose={() => setAddPending(null)}>
            <>
              <AddStudentRecurrentPaymentContainer
                recurrentPayment={getRecurrentPayment()}
                recurrentPending={addPending!}
                recurrentPaymentId={selectedPayment}
                onCancel={() => setAddPending(null)}
                onSuccess={removePending}
              />
            </>
          </Modal>
        </>
      )}

      {stageRecurrent !== null && (
        <Modal
          open={stageRecurrent !== null}
          onClose={() => setStageRecurrent(null)}
        >
          <>
            <StageStudentRecurrentPaymentForm
              recurrentPending={stageRecurrent.pending}
              recurrentDescription={stageRecurrent.description}
              recurrentPaymentId={selectedPayment}
              onCancel={() => setStageRecurrent(null)}
              stagePayment={(payment) => {
                addPayment({
                  payment,
                  type: 'recurrent',
                  amount: stageRecurrent.amount,
                  penalty: payment.penalty,
                  student: stageRecurrent.student,
                  grade: stageRecurrent.grade,
                });
                setStageRecurrent(null);
              }}
              fs={payments.length === 0 ? null : payments[0].payment.fs}
              attachment={
                payments.length === 0 ? null : payments[0].payment.attachment
              }
            />
          </>
        </Modal>
      )}

      {editRecurrentChild !== null && (
        <Modal
          open={editRecurrentChild !== null}
          onClose={() => setEditRecurrentChild(null)}
        >
          <Box sx={{ ...modalStyle, width: 700, p: 2 }}>
            <EditRecurrentChildPaymentForm
              payment={editRecurrentChild!}
              onSuccess={() => {
                fetchRecurrent(`payment-latest/recurrent/${id}`);
                setEditRecurrentChild(null);
                setSelectedPayment('');
              }}
              onCancel={() => setEditRecurrentChild(null)}
            />
          </Box>
        </Modal>
      )}

      <DeleteRecurrentPending
        payment={deletePending}
        cancel={() => setDeletePending(null)}
        onSuccess={() => {
          setDeletePending(null);
          removePending(deletePending!.id);
        }}
      />

      <DeleteRecurrentChildDialog
        payment={deleteRecurrentChild}
        onSuccess={() => {
          setDeleteRecurrentChild(null);
          fetchRecurrent(`payment-latest/recurrent/${id}`);
          setEditRecurrentChild(null);
          setSelectedPayment('');
        }}
        cancel={() => {
          setDeleteRecurrentChild(null);
        }}
      />
    </>
  );
};

export default PendingRecurrentPaymentList;
