/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import GlobalContext from '../../../../../core/service/globalContext';
import { toastError } from '../../../../../core/utils/ui/alert';
import { Conduct } from '../../models/conduct';
import { parseConducts } from '../../services/conductService';
import ConductInlineForm from './ConductInlineForm';

type ConductProps = {
  studentId: string;
  gsId: string;
};

const ConductComponent = ({ studentId, gsId }: ConductProps) => {
  const { quarters } = useContext(GlobalContext);
  const { fetchData } = useFetchApiData();
  const [conductMap, setConductMap] = useState<Record<string, Conduct>>({});

  const fetchConducts = () =>
    fetchData(`academic/students/conducts/student/${studentId}`, {
      onSuccess: (data) => {
        setConductMap(parseConducts(data?.conducts || []));
      },
      onError: toastError,
    });

  useEffect(() => {
    fetchConducts();
  }, []);

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Conduct
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 600, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  {quarters.map((quarter) => (
                    <TableCell key={`eval-${quarter.id}`} size="small">
                      <Grid
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography sx={{ fontSize: 14 }}>Quarter</Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {quarter.quarter}
                        </Typography>
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {quarters.map((quarter) => (
                    <TableCell key={`conduct-${quarter.id}`} sx={{ py: 1 }}>
                      <Grid
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ConductInlineForm
                          gradeStudentId={gsId}
                          quarterId={quarter.id}
                          value={conductMap[quarter.id]?.conduct || ''}
                          fetchConduct={fetchConducts}
                        />
                      </Grid>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </>
  );
};

export default ConductComponent;
