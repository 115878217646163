/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import Layout from '../../../core/ui/layout/Layout';
import Loader from '../../../core/ui/utility/Loader';
import EditUserForm from '../components/EditUserForm';
import ReturnButton from '../../../core/ui/utility/ReturnButton';
import useFetchUserController from '../hooks/useFetchUser';

const EditUserContainer = () => {
  const { user, loading } = useFetchUserController();

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/user" />}>
      <Loader loading={loading || !user}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit User</Typography>
          </Grid>
          <EditUserForm user={user!} />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditUserContainer;
