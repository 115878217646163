/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { Student } from '../../student/student';

const useFetchHomeRoomStudents = () => {
  const { fetchData, data: students, loading } = useFetchApiData<Student>();

  useEffect(() => {
    fetchData('academic/home-room/hrts/students');
  }, []);

  return { students, loading };
};

export default useFetchHomeRoomStudents;
