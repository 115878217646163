import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';
import { ReactChildren } from '../../../utils/types';

type DropperProps = {
  active?: boolean;
  icon?: React.ReactElement;
  label: string;
} & ReactChildren;

const Dropper = ({ children, active = false, icon, label }: DropperProps) => {
  const [open, setOpen] = useState(active);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem button dense sx={{}} onClick={handleClick}>
        {icon && <ListItemIcon sx={{ minWidth: '35px' }}>{icon}</ListItemIcon>}
        <ListItemText
          primary={label}
          primaryTypographyProps={{ fontWeight: '700' }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ ml: 3 }}>
        {children}
      </Collapse>
    </>
  );
};

export default Dropper;
