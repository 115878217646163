/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from '@mui/icons-material';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Layout from '../../../../../core/ui/layout/Layout';
import ShowIfHas from '../../../../../core/ui/permission/ShowIfHas';
import SummaryBlock from '../../../../../core/ui/table/components/SummaryBlock';
import { hasPermission } from '../../../../../core/utils/data';
import { toastError } from '../../../../../core/utils/ui/alert';
import AuthContext from '../../../../auth/service/authContext';
import { Subject } from '../subject';

const EvaluationTypeList = () => {
  const [data, setData] = useState<Subject[]>([]);
  const { user } = useContext(AuthContext);

  const { fetchData } = useFetchApiData();

  useEffect(() => {
    fetchData(`academic/marklist/subjects`, {
      onSuccess: ({ data: apiData }) => {
        setData(apiData);
      },
      onError: toastError,
    });
  }, []);

  return (
    <Layout>
      <>
        <Box sx={{ p: 2 }}>
          <SummaryBlock
            buttonLabel="New Subject"
            modelLabel="Subjects"
            itemCount={data.length}
            addRoute="/academic/subject/create"
            showCreate={hasPermission(user!.permissions, ['add-subject'])}
          />

          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 1200, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="small">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Ranked</TableCell>
                  <TableCell>Order</TableCell>
                  <TableCell>On Report Card</TableCell>
                  <TableCell>Format</TableCell>
                  <TableCell>Template</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .sort((a, b) => a.order - b.order)
                  .map((subject, i) => (
                    <TableRow key={subject.id}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell size="small">{subject.subject}</TableCell>
                      <TableCell size="small">{subject.code}</TableCell>
                      <TableCell size="small">
                        {subject.consider_for_rank ? (
                          <Typography color="green">Yes</Typography>
                        ) : (
                          <Typography>No</Typography>
                        )}
                      </TableCell>
                      <TableCell size="small">{subject.order}</TableCell>
                      <TableCell size="small">
                        {subject.show_on_report ? (
                          <Typography color="green">Yes</Typography>
                        ) : (
                          <Typography>No</Typography>
                        )}
                      </TableCell>
                      <TableCell size="small">{subject.display_mode}</TableCell>
                      <TableCell size="small">
                        {subject.report_card_template}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {DateTime.fromISO(subject.created_at).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </TableCell>
                      <TableCell align="right" sx={{ width: 48 }}>
                        <ShowIfHas permissions={['edit-subject']}>
                          <Link to={`/academic/subject/edit/${subject.id}`}>
                            <Edit
                              fontSize="small"
                              sx={{ mr: 1, fontSize: '15px' }}
                            />
                          </Link>
                        </ShowIfHas>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Layout>
  );
};

export default EvaluationTypeList;
