/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGetApiData from '../../../../core/hooks/useGetApiData';
import { FixedPayment } from '../fixedPayment';

const useGetFixedPayment = () => {
  const { id } = useParams();
  const { fetchData, data: fixedPayment } = useGetApiData<FixedPayment | null>(
    null
  );

  useEffect(() => {
    fetchData(`payment-latest/fixed/${id}`);
  }, []);

  return { fixedPayment };
};

export default useGetFixedPayment;
