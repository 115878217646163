import { Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../core/ui/layout/Layout';
import CreateRecurrentContainer from '../containers/CreateRecurrentContainer';
import CreateRecurrentPendingContainer from '../containers/CreateRecurrentPendingContainer';

const CreateRecurrentPage = () => {
  const navigate = useNavigate();
  const [recurrentPaymentId, setRecurrentPaymentId] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Create Recurrent Payment', 'Assign Students to payment'];

  useEffect(() => {
    if (recurrentPaymentId) {
      setActiveStep(1);
    }
  }, [recurrentPaymentId]);

  return (
    <>
      <Layout>
        <>
          <Grid
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={{ p: 2 }}
          >
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 ? (
              <CreateRecurrentContainer
                onSuccess={(id) => setRecurrentPaymentId(id)}
              />
            ) : (
              <CreateRecurrentPendingContainer
                recurrentPaymentId={recurrentPaymentId}
                onSuccess={() => {
                  navigate('/');
                  navigate('/payment/recurrent/create', { replace: true });
                }}
              />
            )}
          </Grid>
        </>
      </Layout>
    </>
  );
};

export default CreateRecurrentPage;
