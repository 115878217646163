/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from '@mui/icons-material';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../core/hooks/useFetchApiData';
import Layout from '../../../core/ui/layout/Layout';
import ShowIfHas from '../../../core/ui/permission/ShowIfHas';
import SummaryBlock from '../../../core/ui/table/components/SummaryBlock';
import { hasPermission } from '../../../core/utils/data';
import { toastError } from '../../../core/utils/ui/alert';
import AuthContext from '../../auth/service/authContext';
import UserService from '../services/user-service';
import { User } from '../user';

const EvaluationTypeList = () => {
  const [data, setData] = useState<User[]>([]);
  const { user } = useContext(AuthContext);

  const { fetchData } = useFetchApiData();

  useEffect(() => {
    fetchData(`users`, {
      onSuccess: ({ data: apiData }) => {
        setData(apiData);
      },
      onError: toastError,
    });
  }, []);

  return (
    <Layout>
      <>
        <Box sx={{ p: 2 }}>
          <SummaryBlock
            buttonLabel="New User"
            modelLabel="Users"
            itemCount={data.length}
            addRoute="/user/create"
            showCreate={hasPermission(user!.permissions, ['add-user'])}
          />

          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 650, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="medium">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {UserService.sort(data).map((user, i) => (
                  <TableRow key={user.id}>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell size="small">
                      {user.first_name} {user.father_name}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell component="th" scope="row">
                      {DateTime.fromISO(user.created_at).toLocaleString(
                        DateTime.DATE_MED
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <ShowIfHas permissions={['add-user']}>
                        <Link to={`/user/edit/${user.id}`}>
                          <Edit
                            fontSize="small"
                            sx={{ mr: 1, fontSize: '15px' }}
                          />
                        </Link>
                      </ShowIfHas>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Layout>
  );
};

export default EvaluationTypeList;
