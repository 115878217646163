/* eslint-disable react-hooks/exhaustive-deps */
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { StudentAttendance } from '../../student/models/student-attendance';
import { Student } from '../../student/student';
import { GradeAttendance } from '../models/attendance';

const useAttendanceController = (students: Student[], gradeId: string) => {
  const { callApi, loading } = useSendApiData();
  const { loading: attendanceLoading, fetchData: fetchAttendance } =
    useFetchApiData<StudentAttendance>();
  const [attData, setAttData] = useState<
    {
      student: Student;
      status: string;
      absentReason: string;
    }[]
  >(
    students.map((student, i) => ({
      student: student,
      status: 'Present',
      absentReason: '',
    }))
  );

  const initialValues: GradeAttendance = {
    date: new Date().toISOString().substring(0, 10),
    data: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (
      values,
      { setFieldError }: FormikHelpers<GradeAttendance>
    ) => {
      await callApi({
        endpoint: `academic/home-room/student-attendances/${gradeId}`,
        data: values,
        onSuccess: () => {
          toastMessage('Attendance added');
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
      });
    },
  });

  const fetchAttendanceData = async () => {
    let attendances: StudentAttendance[] = [];
    await fetchAttendance(
      `academic/home-room/student-attendances/${gradeId}/${formik.values.date}`,
      {
        onSuccess: (resData: StudentAttendance[]) => {
          attendances = resData;
        },
      }
    );

    if (attendances.length) {
      const attendanceIdMap: Record<string, StudentAttendance> = {};
      attendances.forEach((attendance) => {
        attendanceIdMap[attendance.student_id] = attendance;
      });
      const curData = [...attData];
      for (let i = 0; i < curData.length; i++) {
        const studentId = curData[i].student.id;
        if (attendanceIdMap[studentId]) {
          curData[i].status = attendanceIdMap[studentId].status || 'Present';
          curData[i].absentReason =
            attendanceIdMap[studentId].absent_reason || '';
        }
      }

      setAttData(curData);
    } else {
      setAttData(
        students.map((student, i) => ({
          student: student,
          status: 'Present',
          absentReason: '',
        }))
      );
    }
  };

  useEffect(() => {
    if (gradeId && formik.values.date) {
      fetchAttendanceData();
    }
  }, [gradeId, formik.values.date]);

  return { formik, loading, attData, setAttData, attendanceLoading };
};

export default useAttendanceController;
