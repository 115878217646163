import { Grid, Typography } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import Layout from '../../../../../core/ui/layout/Layout';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Loader from '../../../../../core/ui/utility/Loader';
import { SemesterEdit, Semester } from '../semester';
import EditSemesterForm from '../components/EditSemesterForm';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';
import { parseFormQuery } from '../../../../../core/utils/utility';
import GlobalContext from '../../../../../core/service/globalContext';

const EditSemesterContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { callApi, loading: submitting } = useSendApiData();
  const { fetchData, loading } = useFetchApiData();
  const [semester, setSemester] = useState<Semester | null>(null);
  const { fetchGlobalData } = useContext(GlobalContext);

  const fetchSemester = () =>
    fetchData(`academic/marklist/semesters/${id}`, {
      onSuccess: (data: Semester) => {
        setSemester(data);
      },
    });

  useEffect(() => {
    fetchSemester();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (
    values: SemesterEdit,
    { setFieldError }: FormikHelpers<SemesterEdit>
  ) => {
    let success = false;
    const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `academic/marklist/semesters/${id}`,
      method: 'patch',
      data: formData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        toastMessage('Semester Edited');
        success = true;
      },
    });

    if (success) {
      await fetchGlobalData({ enableLoading: false });
      await navigate('/academic/semester', { replace: true });
    }
  };

  return (
    <Layout renderLeftToolbar={() => <ReturnButton to="/academic/semester" />}>
      <Loader loading={loading || !semester}>
        <Grid sx={{ p: 2 }}>
          <Grid container sx={{ mb: 1, px: 1 }}>
            <Typography variant="h5">Edit Semester</Typography>
          </Grid>
          <EditSemesterForm
            semester={semester!}
            onSubmit={handleSubmit}
            submitting={submitting}
          />
        </Grid>
      </Loader>
    </Layout>
  );
};

export default EditSemesterContainer;
