import moment from 'moment';
import constants from '../../../../core/utils/constants';
import { Student } from '../../student/student';

type IDCardProps = {
  idType: 'main' | 'nursery';
  student: Student;
  grade: string;
  year: number;
};

const IDCard = ({ idType, student, grade, year }: IDCardProps) => {
  const gradientMain =
    'linear-gradient(0deg, rgba(195,92,34,1) 0%, rgba(242,255,0,1) 100%)';
  const footerMain = '#873020';

  const gradientNursery =
    'radial-gradient(circle, rgba(250,176,208,1) 0%, rgba(247,31,44,1) 100%);';
  const footerNursery = 'rgba(250,176,208,1) ';

  const UnderLineItem = ({
    label,
    value,
    borderColor = 'black',
    textColor = 'black',
  }: {
    label: string;
    value: string;
    borderColor?: string;
    textColor?: string;
  }) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}
    >
      <div style={{ marginRight: '5px', fontSize: '19.5px', color: textColor }}>
        {label}:
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          flex: 1,
        }}
      >
        <div
          style={{
            width: '100%',
            borderBottom: `1px solid ${borderColor}`,
            fontSize: '19.5px',
            whiteSpace: 'nowrap',
            zIndex: 10,
            textAlign: 'center',
            color: textColor,
            fontWeight: 'bold',
          }}
        >
          {value}
        </div>
        {/* <div style={{ height: '1px' }}></div> */}
      </div>
    </div>
  );

  return (
    <div
      style={{
        width: '50%',
        height: '100%',
        border: '1px solid black',
        margin: '5px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          height: '23%',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            width: '20%',
            height: '100%',
            display: 'flex',
            margin: '0 5px',
            justifyContent: 'flex-end',
          }}
        >
          <img
            style={{ height: '100%' }}
            src={`${constants.baseurl}/img?img_path=/imgs/logo-blue.jpg`}
            alt="logo"
          />
        </div>

        <div
          style={{
            width: '80%',
            height: '100%',
            border: `2px solid ${
              idType === 'main' ? footerMain : footerNursery
            }`,
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            background: idType === 'main' ? gradientMain : gradientNursery,
          }}
        >
          <div
            style={{
              width: '100%',
              margin: '0 auto',
              textAlign: 'center',
              fontSize: '40px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
          >
            Strivers Academy
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                flex: 1,
                fontWeight: '600',
                fontSize: '16px',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>Tel: 011-8829390</span>
            </div>
            <div
              style={{
                flex: 1,
                fontWeight: '600',
                fontSize: '16px',
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Mob: 0911685076
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: '58%',
          width: '100%',
          border: `2px solid ${idType === 'main' ? footerMain : footerNursery}`,
          padding: '5px',
          margin: '5px 0',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          background: idType === 'main' ? gradientMain : gradientNursery,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '65%',
          }}
        >
          <UnderLineItem
            label="Name"
            value={`${student.first_name} ${student.father_name}`}
          />
          <div style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>
              <UnderLineItem
                label="Age"
                value={
                  student.date_of_birth
                    ? moment()
                        .diff(moment(student.date_of_birth), 'years')
                        .toString()
                    : ''
                }
              />
            </div>
            <div style={{ width: '100%', marginLeft: '5px' }}>
              <UnderLineItem label="Sex" value={student.gender} />
            </div>
          </div>
          <UnderLineItem label="Grade" value={grade} />
          <UnderLineItem label="Date of Issue" value={`Meskerem`} />
          <UnderLineItem label="Date of Expiry" value={`Sene`} />
          <UnderLineItem label="Phone" value={student.primary_phone} />
        </div>
        <div
          style={{
            width: '35%',
            border: '3px solid black',
            height: '100%',
            marginLeft: '25px',
            background: 'white',
          }}
        >
          {student.img && (
            <img
              src={`${constants.baseurl}/img?img_path=/images/${student.img}`}
              alt=" "
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          )}
        </div>
      </div>

      <div
        style={{
          height: '15%',
          width: '100%',
          border: '1px solid grey',
          padding: '5px 10px',
          background: idType === 'main' ? footerMain : footerNursery,
        }}
      >
        <div style={{ width: '80%', color: 'white' }}>
          <UnderLineItem
            label="Student ID: "
            value={student.id_number}
            textColor={idType === 'main' ? 'white' : 'black'}
            borderColor="black"
          />
        </div>
      </div>
    </div>
  );
};

export default IDCard;
