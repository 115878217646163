import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { EvaluationTypeCreate } from '../evaluationType';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type EvaluationTypeProps = {
  onSubmit: (
    value: EvaluationTypeCreate,
    helpers: FormikHelpers<EvaluationTypeCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateEvaluationTypeForm = ({
  onSubmit,
  submitting,
}: EvaluationTypeProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: EvaluationTypeCreate,
    helpers: FormikHelpers<EvaluationTypeCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/academic/evaluation-type/create', { replace: true });
    }
  };

  const initialValues: EvaluationTypeCreate = { name: '', weight: 0 };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 350 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="name"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Name"
                      placeholder=""
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.name)}
                      helperText={formik.errors.name}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="weight"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Weight"
                      placeholder=""
                      value={formik.values.weight}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.weight)}
                      helperText={formik.errors.weight}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Grid container>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateEvaluationTypeForm;
