import Layout from '../../../../core/ui/layout/Layout';
import RecurrentPaymentList from '../components/lists/RecurrentPaymentList';

const RecurrentListPage = () => {
  return (
    <>
      <Layout>
        <RecurrentPaymentList />
      </Layout>
    </>
  );
};

export default RecurrentListPage;
