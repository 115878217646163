import {
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
} from '@mui/material';
import { User } from '../../../user/user';
import useCreateTeacherController from '../hooks/useCreateTeacherController';

type TeacherProps = {
  users: User[];
  fetchUsers: () => void;
};

const CreateTeacherForm = ({ users, fetchUsers }: TeacherProps) => {
  const { formik, submitting } = useCreateTeacherController(fetchUsers);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 500 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.user_id)}
                      fullWidth
                    >
                      <InputLabel>User</InputLabel>
                      <Select
                        value={formik.values.user_id}
                        size="small"
                        label="User"
                        onChange={formik.handleChange}
                        name="user_id"
                      >
                        {users.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            {user.first_name} {user.father_name}
                          </MenuItem>
                        ))}
                      </Select>
                      {Boolean(formik.errors.user_id) && (
                        <FormHelperText>{formik.errors.user_id}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={3}>
              {/* <Paper sx={{ p: 2 }}> */}
              <Grid container>
                <Button
                  type="submit"
                  variant="outlined"
                  disabled={submitting}
                  size="small"
                >
                  {submitting ? 'Assigning' : 'Assign'}
                </Button>
              </Grid>
              {/* </Paper> */}
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateTeacherForm;
