/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import Layout from '../../../../../core/ui/layout/Layout';
import Loader from '../../../../../core/ui/utility/Loader';
import ReturnButton from '../../../../../core/ui/utility/ReturnButton';
import { Cst } from '../../cst/cst';
import { EvaluationMethod } from '../../evaluationMethod/evaluationMethod';
import { Quarter } from '../../quarter/quarter';
import SmlStudentList from '../components/SmlStudentList';
import { parseStudentsForMarklist } from '../services/smlService';
import { StudentsForMarklist } from '../sml';
import _ from 'lodash';

const CreateSmlContainer = () => {
  const [loading, setLoading] = useState(true);
  const { gradeId, cstId, quarterId } = useParams();
  const { fetchData } = useFetchApiData();

  const [quarter, setQuarter] = useState<Quarter | null>(null);
  const [cst, setCst] = useState<Cst | null>(null);
  const [students, setStudents] = useState<StudentsForMarklist[]>([]);
  const [evaluationMethods, setEvaluationMethods] = useState<
    Record<string, EvaluationMethod>
  >({});

  const fetchCstAndStudents = async () => {
    setLoading(true);
    await fetchBaseData();
    setLoading(false);
  };

  const fetchBaseData = async () => {
    await fetchData(`academic/marklist/csts/${cstId}/quarter/${quarterId}`, {
      onSuccess: (data) => {
        const [evalMap, studentList] = parseStudentsForMarklist(
          data.students,
          data.cst
        );
        setQuarter(data.quarter);
        setCst(data.cst);
        setEvaluationMethods(evalMap);
        setStudents(studentList);
      },
    });

    console.log('fetched');
  };

  const updateScore = (
    studentIndex: number,
    evaluationId: string,
    score: number
  ) => {
    const newStudents = _.cloneDeep(students);
    newStudents[studentIndex].score[evaluationId] = score;

    setStudents(newStudents);
  };

  useEffect(() => {
    fetchCstAndStudents();
  }, []);

  return (
    <>
      <Layout
        renderLeftToolbar={() => (
          <ReturnButton
            to={`/academic/marklist/cst/${gradeId}`}
            text="Back to class subject"
          />
        )}
      >
        <Box sx={{ p: 2 }}>
          <Loader loading={loading || !cst}>
            <SmlStudentList
              cst={cst!}
              quarter={quarter!}
              students={students}
              evaluationMethods={evaluationMethods}
              fetchCstAndStudents={fetchBaseData}
              updateScore={updateScore}
            />
          </Loader>
        </Box>
      </Layout>
    </>
  );
};

export default CreateSmlContainer;
