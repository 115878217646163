/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddCircle,
  DeleteForever,
  Edit,
  PersonAddAlt,
  Visibility,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import DateModified from '../../../../../core/ui/utility/DateModified';
import Loader from '../../../../../core/ui/utility/Loader';
import { modalStyle } from '../../../../../core/ui/utility/ModalContainer';
import EditFixedPaymentContainer from '../../container/EditFixedPaymentContainer';
import { FixedPayment } from '../../fixedPayment';
import DeleteFixedDialog from '../dialogs/DeleteFixedDialog';
import AddStudentFixedComponent from '../forms/AddStudentFixedComponent';

const FixedPaymentList = () => {
  const [editPayment, setEditPayment] = useState<FixedPayment | null>(null);
  const [deletePayment, setDeletePayment] = useState<FixedPayment | null>(null);
  const [addStudentPayment, setAddStudentPayment] = useState<string | null>(
    null
  );
  const { data, loading, fetchData } = useFetchApiData<FixedPayment>();

  useEffect(() => {
    fetchData('payment-latest/fixed/active');
  }, []);

  return (
    <>
      <Box sx={{ p: 2, maxWidth: 1000 }}>
        <Grid display="flex" justifyContent="space-between">
          <Typography variant="h5" sx={{ mb: 2 }}>
            Fixed Payment List
          </Typography>
          <Button component={Link} to="/payment/fixed/archive" size="small">
            View Archived
          </Button>
        </Grid>

        <Loader loading={loading}>
          <TableContainer component={Paper} sx={{ overflowX: 'auto', mt: 2 }}>
            <Table aria-label="custom pagination table" size="small">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Effective Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Modified</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell size="small">{payment.description}</TableCell>
                    <TableCell size="small">{payment.amount}</TableCell>
                    <TableCell size="small">{payment.effective_date}</TableCell>
                    <TableCell size="small">{payment.end_date}</TableCell>
                    <TableCell component="th" scope="row" sx={{ py: '4px' }}>
                      <DateModified
                        createdAt={payment.created_at}
                        updatedAt={payment.updated_at}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <Grid
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Tooltip title="View Payments Payment">
                          <Link to={`/payment/fixed/view/${payment.id}`}>
                            <Visibility
                              sx={{ mt: 1, mr: 2 }}
                              fontSize="small"
                              color="info"
                            />
                          </Link>
                        </Tooltip>

                        <Tooltip title="Add New Payment">
                          <Link to={`/payment/fixed/add/${payment.id}`}>
                            <AddCircle
                              sx={{ mt: 1 }}
                              fontSize="small"
                              color="success"
                            />
                          </Link>
                        </Tooltip>

                        <Tooltip title="Edit Fixed Payment">
                          <Edit
                            fontSize="small"
                            color="info"
                            sx={{ ml: 1, cursor: 'pointer' }}
                            onClick={() => setEditPayment(payment)}
                          />
                        </Tooltip>

                        <Tooltip title="Delete Fixed Payment">
                          <DeleteForever
                            color="warning"
                            sx={{ ml: 1, cursor: 'pointer' }}
                            onClick={() => setDeletePayment(payment)}
                          />
                        </Tooltip>

                        <Tooltip title="Add student to Fixed Payment">
                          <PersonAddAlt
                            fontSize="small"
                            color="info"
                            sx={{ ml: 1, cursor: 'pointer' }}
                            onClick={() => setAddStudentPayment(payment.id)}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </Box>

      {editPayment !== null && (
        <Modal open={editPayment !== null} onClose={() => setEditPayment(null)}>
          <Box sx={{ ...modalStyle, width: 800, p: 2 }}>
            <EditFixedPaymentContainer
              onSuccess={() => {
                fetchData('payment-latest/fixed/active');
                setEditPayment(null);
              }}
              payment={editPayment!}
              onCancel={() => setEditPayment(null)}
            />
          </Box>
        </Modal>
      )}

      {addStudentPayment !== null && (
        <Modal
          open={addStudentPayment !== null}
          onClose={() => setAddStudentPayment(null)}
        >
          <Box sx={{ ...modalStyle, width: 400, p: 2 }}>
            <AddStudentFixedComponent
              fixedPaymentId={addStudentPayment!}
              onSuccess={() => setAddStudentPayment(null)}
              onCancel={() => setAddStudentPayment(null)}
            />
          </Box>
        </Modal>
      )}

      <DeleteFixedDialog
        payment={deletePayment}
        cancel={() => setDeletePayment(null)}
        onSuccess={() => {
          fetchData('payment-latest/fixed/active');
          setDeletePayment(null);
        }}
      />
    </>
  );
};

export default FixedPaymentList;
