import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import { useState } from 'react';
import authService from '../../features/auth/service/authService';
import constants from '../utils/constants';
import { ErrorHandler, SuccessHandler } from '../utils/types';

type CallSingleArg = {
  headers?: AxiosRequestHeaders;
  onError?: ErrorHandler;
  onSuccess?: SuccessHandler;
};

const useFetchSingleData = <T>() => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (
    endpointUrl: string,
    { headers, onError, onSuccess }: CallSingleArg = {}
  ) => {
    const token = `Bearer ${authService.getToken()}`;
    setLoading(true);

    try {
      const res = await axios.get(`${constants.apiUrl}/${endpointUrl}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: token,
          ...headers,
        },
      });

      setData(res.data);

      if (onSuccess) onSuccess(res.data);
    } catch (err) {
      if (onError) {
        onError((err as AxiosError).message);
      }
    }

    setLoading(false);
  };

  return { data, loading, fetchData, setData };
};

export default useFetchSingleData;
