import { FormikHelpers, useFormik } from 'formik';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../core/service/globalContext';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { Grade, GradeEdit } from '../grade';

const useEditGradeController = (grade: Grade) => {
  const { callApi, loading: submitting } = useSendApiData();
  const navigate = useNavigate();
  const { fetchGlobalData } = useContext(GlobalContext);

  const initialValues: GradeEdit = {
    name: grade.name,
    order: grade.order,
    monthly_fee: grade.monthly_fee,
    registration_fee: grade.registration_fee,
    tutorial_fee: grade.tutorial_fee,
    summer_fee: grade.summer_fee,
    report_card_template: grade.report_card_template || 'main',
    skill_template: grade.skill_template || 'main',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (
      values: GradeEdit,
      { setFieldError }: FormikHelpers<GradeEdit>
    ) => {
      let success = false;

      await callApi({
        endpoint: `academic/grades/${grade.id}`,
        method: 'patch',
        data: values,
        headers: {
          'Content-Type': 'application/json',
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
        onSuccess: async (_) => {
          success = true;
          toastMessage('Grade Edited');
        },
      });

      if (success) {
        await fetchGlobalData({ enableLoading: false });
        navigate('/academic/grade', { replace: true });
      }
    },
  });

  return { formik, submitting };
};

export default useEditGradeController;
