import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StudentRecurrentReport } from '../lib/types/student-payment-report';
import moment from 'moment';

const StudentRecurrent = ({
  recurrents,
}: {
  recurrents: StudentRecurrentReport[];
}) => {
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Recurrent Payments
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 1000, maxWidth: 1600 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell sx={{}}>Payment</TableCell>
                <TableCell sx={{}}>Payment child</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Penalty</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Attachment</TableCell>
                <TableCell>Fs</TableCell>
                <TableCell>Handled By</TableCell>
                <TableCell>Paid On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {recurrents.map((payment, i) => (
                <TableRow key={payment.id} sx={{ py: 0 }}>
                  <TableCell size="small">{i + 1}</TableCell>
                  <TableCell size="small">
                    {payment.recurrentPayment.description}
                  </TableCell>
                  <TableCell size="small">
                    {payment.recurrentPaymentChild.description}
                  </TableCell>
                  <TableCell size="small">{payment.amount}</TableCell>
                  <TableCell size="small">{payment.penalty}</TableCell>
                  <TableCell size="small">{payment.total}</TableCell>
                  <TableCell size="small">{payment.attachment}</TableCell>
                  <TableCell size="small">{payment.fs}</TableCell>
                  <TableCell size="small">
                    {payment.user.first_name} {payment.user.father_name}
                  </TableCell>
                  <TableCell size="small">
                    {moment(payment.created_at).format('MMM D YYYY')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default StudentRecurrent;
