/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../../core/service/globalContext';
import ShowIfHas from '../../../../../core/ui/permission/ShowIfHas';
import Loader from '../../../../../core/ui/utility/Loader';
import ModalContainer from '../../../../../core/ui/utility/ModalContainer';
import { printUser } from '../../../../../core/utils/text';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../core/utils/validation';
import { Grade } from '../../../grade/grade';
import useTeacherHook from '../../../teacher/hooks/useTeacherHook';
import { sortTeachers } from '../../../teacher/services/teacherService';
import useSubjectHook from '../../subject/hooks/useSubjectHook';
import { sortSubjects } from '../../subject/services/subjectService';
import { CstCreate } from '../cst';

type CSTHeaderProps = {
  grade: Grade;
  onSuccess: () => Promise<void>;
};

const CSTHeader = ({ grade, onSuccess }: CSTHeaderProps) => {
  const [open, setOpen] = useState(false);
  const { activeYear } = useContext(GlobalContext);

  const { callApi, loading: submitting } = useSendApiData();
  const { subjects, subjectsLoading, fetchSubjects } = useSubjectHook();
  const { teachers, teachersLoading, fetchTeachers } = useTeacherHook();

  const initialValues: CstCreate = {
    grade_id: grade.id,
    academic_year_id: activeYear!.id,
    subject_id: '',
    teacher_id: '',
    count: 0,
  };

  const handleSubmit = async (
    values: CstCreate,
    { setFieldError }: FormikHelpers<CstCreate>
  ) => {
    // const formData: any = parseFormQuery(values, []);

    await callApi({
      endpoint: `academic/marklist/csts`,
      data: values,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        formik.resetForm();
        setOpen(false);
        onSuccess();
        toastMessage('Class Subject added');
      },
    });
  };

  useEffect(() => {
    fetchSubjects();
    fetchTeachers();
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Box display="flex" flexDirection="row">
        <Loader loading={teachersLoading || subjectsLoading}>
          <Typography sx={{ flexGrow: 1 }} variant="h5">
            Class Subject Teacher for - {grade.name}
          </Typography>

          <ShowIfHas permissions={['add-cst']}>
            <ModalContainer buttonLabel="Add Cst" open={open} setOpen={setOpen}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                        <FormControl fullWidth>
                          <InputLabel>Grade</InputLabel>
                          <Select
                            size="small"
                            label="Grade"
                            value={grade.id}
                            name="grade_id"
                            disabled
                          >
                            <MenuItem value={grade.id} selected>
                              {grade.name}
                            </MenuItem>
                          </Select>
                          {formik.touched.grade_id &&
                            formik.errors.grade_id && (
                              <FormHelperText error>
                                {formik.errors.grade_id}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between">
                      <Grid
                        item
                        alignSelf="flex-start"
                        sx={{ flexGrow: 1, mt: 2 }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>Academic Year</InputLabel>
                          <Select
                            size="small"
                            label="Academic Year"
                            value={activeYear!.id}
                            name="academic_year_id"
                            disabled
                          >
                            <MenuItem value={activeYear!.id} selected>
                              {activeYear?.year}
                            </MenuItem>
                          </Select>
                          {formik.touched.academic_year_id &&
                            formik.errors.academic_year_id && (
                              <FormHelperText error>
                                {formik.errors.academic_year_id}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between">
                      <Grid
                        item
                        alignSelf="flex-start"
                        sx={{ flexGrow: 1, mt: 2 }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>Teacher</InputLabel>
                          <Select
                            size="small"
                            label="Teacher"
                            value={formik.values.teacher_id}
                            onChange={formik.handleChange}
                            name="teacher_id"
                          >
                            {sortTeachers(teachers).map((teacher) => (
                              <MenuItem key={teacher.id} value={teacher.id}>
                                {printUser(teacher.user)}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.teacher_id &&
                            formik.errors.teacher_id && (
                              <FormHelperText error>
                                {formik.errors.teacher_id}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container justifyContent="space-between">
                      <Grid
                        item
                        alignSelf="flex-start"
                        sx={{ flexGrow: 1, mt: 2 }}
                      >
                        <FormControl fullWidth>
                          <InputLabel>Subject</InputLabel>
                          <Select
                            size="small"
                            label="Subject"
                            value={formik.values.subject_id}
                            onChange={formik.handleChange}
                            name="subject_id"
                          >
                            {sortSubjects(subjects).map((subject) => (
                              <MenuItem key={subject.id} value={subject.id}>
                                {subject.subject}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.touched.subject_id &&
                            formik.errors.subject_id && (
                              <FormHelperText error>
                                {formik.errors.subject_id}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      justifyContent="space-between"
                      spacing={1}
                      sx={{ mt: 1 }}
                    >
                      <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                        <TextField
                          fullWidth
                          name="count"
                          size="small"
                          type="number"
                          variant="outlined"
                          label="Weekly count"
                          placeholder="Weekly count"
                          value={formik.values.count}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.count && Boolean(formik.errors.count)
                          }
                          helperText={
                            formik.touched.count && formik.errors.count
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={submitting}
                      size="small"
                      sx={{ mt: 1 }}
                    >
                      {submitting ? 'Saving' : 'Save'}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </ModalContainer>
          </ShowIfHas>
        </Loader>
      </Box>
    </>
  );
};

export default CSTHeader;
