import Layout from '../../../../core/ui/layout/Layout';
import CreateStudentAttendanceContainer from '../containers/CreateStudentAttendanceContainer';

const StudentAttendancePage = () => {
  return (
    <>
      <Layout>
        <CreateStudentAttendanceContainer />
      </Layout>
    </>
  );
};

export default StudentAttendancePage;
