import moment from 'moment';
import { DateTime } from 'luxon';
import constants from './constants';

export const parseQuery = ({
  page,
  rowsPerPage,
  sortField,
  sortOp,
  filter,
}: Record<string, any>) => {
  let parsed = '?';

  if (page && rowsPerPage) {
    parsed += `perPage=${rowsPerPage}&page=${page}&`;
  }
  if (sortField) {
    parsed += `sort_field=${sortField}&sort_op=${sortOp || 'asc'}&`;
  }
  if (filter) {
    parsed += `filter=${JSON.stringify(filter)}`;
  }

  if (parsed.endsWith('&')) {
    parsed = parsed.substring(0, parsed.length - 1);
  }

  return parsed;
};

export const parseOrdering = ({
  sortField,
  sortOp,
  includeSort,
}: Record<string, any>) => {
  const filters: Record<string, any>[] = [];
  if (includeSort && sortField) {
    filters.push({
      field: sortField,
      op: sortOp || 'asc',
    });
  }

  return filters;
};

export const richTextModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image'],
  ],
};

export const parseFormQuery = (values: any, dateFields: string[] = []) => {
  const formData: any = new FormData();

  dateFields.forEach((field) => {
    if (values[field]) {
      values[field] = values[field]
        .toISOString()
        .substring(0, 10) as unknown as Date;
    }
  });

  Object.entries(values).forEach(([key, val]) => {
    // console.log(key, val);
    if (val !== null) {
      formData.append(key, val as Blob);
    }
  });

  return formData;
};

class DataParser {
  constructor(protected _data: Record<string, any>) {}

  get data() {
    return this._data;
  }

  parseDate(fields: string[]) {
    fields.forEach((field) => {
      if (this._data[field]) {
        this._data[field] = moment(this._data[field] as Date).format(
          'yyyy-MM-DD'
        );
      }
    });

    return this;
  }

  clearEmptyFields() {
    Object.keys(this._data).forEach((field) => {
      const value = this._data[field];
      if (value === null || value === undefined || value === '') {
        delete this._data[field];
      }
    });

    return this;
  }
}

export const dataParserFactory = (data: Record<string, any>) =>
  new DataParser(data);

export const timeElapsed = (date: string) => {
  return DateTime.fromISO(date).toRelative();
};

export const getImgUrl = (path: string | null) =>
  `${constants.baseurl}/img?img_path=/images/${path || 'default.png'}`;

export const parseMoney = (x: number) =>
  x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
