import { Chip, Grid, TableCell } from '@mui/material';
import { Cst } from '../../cst/cst';
import { StudentForRcq } from '../reportCard';
import { printMark } from '../services/reportCardService';

type StudentQuarterProps = {
  student: StudentForRcq;
  csts: Cst[];
};

export const StudentQuarterReportRow = ({
  student,
  csts,
}: StudentQuarterProps) => {
  return (
    <>
      <TableCell>
        <Grid>
          {student.first_name} {student.father_name}
        </Grid>
        {!student.primary_phone && (
          <Grid mt="4px">
            <Chip label="No phone" color="warning" size="small" />
          </Grid>
        )}
      </TableCell>

      {csts.map((cst) => (
        <TableCell key={`${student.id} ${cst.id}`}>
          {printMark(student.cstScore[cst.id])}
        </TableCell>
      ))}

      <TableCell>{printMark(student.rcq?.total_score)}</TableCell>
      <TableCell>{printMark(student.rcq?.average)}</TableCell>
      <TableCell>{printMark(student.rcq?.rank)}</TableCell>
    </>
  );
};
