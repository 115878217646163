import { FormikHelpers, useFormik } from 'formik';
import { RoleMap, UserCreate } from '../user';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useState } from 'react';

type UserProps = {
  onSubmit: (
    value: UserCreate,
    helpers: FormikHelpers<UserCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  roles: RoleMap[];
};

const CreateUserForm = ({ onSubmit, submitting, roles }: UserProps) => {
  const [selectedRole, setSelectedRole] = useState(roles[0]?.label || '');

  const handleSubmit = async (
    value: UserCreate,
    helpers: FormikHelpers<UserCreate>
  ) => {
    const success = await onSubmit(
      { ...value, permissions: JSON.stringify(getSelectedPermission()) },
      helpers
    );
    if (success) {
      formik.resetForm();
    }
  };

  const initialValues: UserCreate = {
    first_name: '',
    father_name: '',
    email: '',
    password: '',
    permissions: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  const getSelectedPermission = (): string[] => {
    return roles.find((role) => role.label === selectedRole)?.permissions || [];
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <form onSubmit={formik.handleSubmit}>
          <Paper sx={{ p: 2, pb: 3, maxWidth: '400px' }}>
            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="first_name"
                size="small"
                type="text"
                variant="outlined"
                label="First Name"
                placeholder=""
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.first_name)}
                helperText={formik.errors.first_name}
              />
            </Grid>

            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="father_name"
                size="small"
                type="text"
                variant="outlined"
                label="Father Name"
                placeholder=""
                value={formik.values.father_name}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.father_name)}
                helperText={formik.errors.father_name}
              />
            </Grid>

            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="email"
                size="small"
                type="text"
                variant="outlined"
                label="Email"
                placeholder=""
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
              />
            </Grid>

            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <TextField
                fullWidth
                name="password"
                size="small"
                type="text"
                variant="outlined"
                label="Password"
                placeholder=""
                value={formik.values.password}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
              />
            </Grid>
            <Grid item alignSelf="flex-start" sx={{ mt: 2, flexGrow: 1 }}>
              <FormControl
                sx={{ minWidth: 120 }}
                error={Boolean(formik.errors.permissions)}
                fullWidth
              >
                <InputLabel>Role</InputLabel>
                <Select
                  value={selectedRole}
                  size="small"
                  label="Role"
                  onChange={(e) => {
                    setSelectedRole(e.target.value);
                  }}
                  name="permissions"
                >
                  {roles.map((role, i) => (
                    <MenuItem key={`role-${i}`} value={role.label}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(formik.errors.permissions) && (
                  <FormHelperText>{formik.errors.permissions}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item sx={{ mt: 2, flexGrow: 2 }}>
              <Box display="flex" flexDirection="column" sx={{ pl: 2 }}>
                <Typography variant="h6" fontWeight={500}>
                  Permissions
                </Typography>
                {getSelectedPermission().map((permission: string, i) => (
                  <Typography key={`permission-${i}`}>{permission}</Typography>
                ))}
              </Box>
            </Grid>

            <Grid item sx={{ mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={submitting}
                size="small"
              >
                {submitting ? 'Saving' : 'Save'}
              </Button>
            </Grid>
          </Paper>
        </form>
      </Box>
    </>
  );
};

export default CreateUserForm;
