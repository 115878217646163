import { Box, Grid, Typography } from '@mui/material';
import { AttachmentPayment } from '../lib/types/attachment-payment.type';
import AttachmentService from '../lib/services/attachment-service';
import { useMemo } from 'react';
import { parseMoney } from '../../../../core/utils/utility';

const AttachmentSummary = ({ payments }: { payments: AttachmentPayment[] }) => {
  const report = useMemo(
    () => AttachmentService.parseAttachment(payments),
    [payments]
  );

  return (
    <>
      <Box display="flex" mt={4}>
        <Grid
          item
          display="flex"
          flexDirection="column"
          sx={{
            p: 2,
            bgcolor: 'white',
            border: '1px solid',
            borderColor: '#45a5a5',
            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
            flexShrink: 1,
            maxWidth: 700,
            height: 'fit-content',
            mb: 3,
            minWidth: '200px',
            mr: 3,
          }}
        >
          <Typography
            fontWeight="500"
            fontSize="20px"
            sx={{ mb: 1 }}
            textAlign="center"
          >
            Recurrent Summary
          </Typography>

          <LabelItem label="Count" stat={`${report.recurrent.count}`} />
          <LabelItem label="Total" stat={parseMoney(report.recurrent.total)} />
        </Grid>

        <Grid
          item
          display="flex"
          flexDirection="column"
          sx={{
            p: 2,
            bgcolor: 'white',
            border: '1px solid',
            borderColor: '#313f41',
            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
            flexShrink: 1,
            maxWidth: 700,
            height: 'fit-content',
            mb: 3,
            minWidth: '200px',
            mr: 3,
          }}
        >
          <Typography
            fontWeight="500"
            fontSize="20px"
            sx={{ mb: 1 }}
            textAlign="center"
          >
            Fixed Summary
          </Typography>

          <LabelItem label="Count" stat={`${report.fixed.count}`} />
          <LabelItem label="Total" stat={parseMoney(report.fixed.total)} />
        </Grid>

        <Grid
          item
          display="flex"
          flexDirection="column"
          sx={{
            p: 2,
            bgcolor: 'white',
            border: '1px solid',
            borderColor: '#b4170c',
            filter: 'drop-shadow(0px 1px 3px rgba(0,0,0,0.12))',
            flexShrink: 1,
            maxWidth: 700,
            height: 'fit-content',
            mb: 3,
            minWidth: '200px',
          }}
        >
          <Typography
            fontWeight="500"
            fontSize="20px"
            sx={{ mb: 1 }}
            textAlign="center"
          >
            Empty Summary
          </Typography>

          <LabelItem label="Count" stat={`${report.empty.count}`} />
        </Grid>
      </Box>
    </>
  );
};

export default AttachmentSummary;

const LabelItem = ({ label, stat }: { label: string; stat: string }) => (
  <Grid display="flex" justifyContent="space-between">
    <Typography fontWeight="400" variant="body2">
      {label}
    </Typography>
    <Typography fontWeight="400" variant="body2">
      {stat}
    </Typography>
  </Grid>
);
