import { Grid } from '@mui/material';
import { FormikHelpers } from 'formik';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { dataParserFactory } from '../../../../core/utils/utility';
import { parseValidationErrors } from '../../../../core/utils/validation';
import CreateFixedPaymentForm from '../components/forms/CreateFixedPaymentForm';
import { FixedPaymentCreate } from '../fixedPayment';

const CreateFixedPaymentContainer = ({
  onSuccess,
}: {
  onSuccess: (id: string) => void;
}) => {
  const { callApi, loading: submitting } = useSendApiData();

  const handleSubmit = async (
    values: FixedPaymentCreate,
    { setFieldError }: FormikHelpers<FixedPaymentCreate>
  ) => {
    let success = false;
    let id = '';

    const parsedData = dataParserFactory({ ...values }).parseDate([
      'effective_date',
      'end_date',
    ]).data;

    await callApi({
      endpoint: 'payment-latest/fixed',
      data: parsedData,
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (data) => {
        toastMessage('Fixed Payment Created');
        success = true;
        id = data.id;
      },
    });

    if (success) {
      onSuccess(id);
    }

    return success;
  };

  return (
    <Grid
      sx={{
        p: 2,
        mt: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CreateFixedPaymentForm onSubmit={handleSubmit} submitting={submitting} />
    </Grid>
  );
};

export default CreateFixedPaymentContainer;
