/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Paper, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import useFetchSingleData from '../../../../core/hooks/useFetchSingleData';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import Loader from '../../../../core/ui/utility/Loader';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import StudentSelectContainer from '../../containers/StudentSelectContainer';
import RecurrentPaymentSummary from '../components/RecurrentPaymentSummary';
import { Recurrent, RecurrentPendingCreate } from '../recurrent';

type RecurrentPendingProps = {
  recurrentPaymentId: string;
  onSuccess: () => void;
};

const CreateRecurrentPendingContainer = ({
  recurrentPaymentId,
  onSuccess,
}: RecurrentPendingProps) => {
  const [students, setStudents] = useState<
    { student_id: string; grade_id: string }[]
  >([]);
  const {
    data: recurrentPayment,
    loading: recurrentPaymentLoading,
    fetchData,
  } = useFetchSingleData<Recurrent>();
  const { callApi, loading: submitting } = useSendApiData();

  const initialValues: RecurrentPendingCreate = {
    payments: [],
  };

  const formik = useFormik<RecurrentPendingCreate>({
    initialValues,
    onSubmit: async (
      value: RecurrentPendingCreate,
      { setFieldError }: FormikHelpers<RecurrentPendingCreate>
    ) => {
      let success = false;

      const data = (recurrentPayment?.recurrentChildren || []).map((i) => ({
        recurrent_payment_child_id: i.id,
        students,
      }));

      await callApi({
        endpoint: 'payment-latest/recurrent/pending',
        data: {
          payments: data,
        },
        onValidationError: (err) => {
          toastError('invalid data, try again');
          parseValidationErrors(err, setFieldError);
        },
        onError: toastError,
        onSuccess: () => {
          toastMessage('Pending payment created successfully');
          success = true;
        },
      });

      if (success) {
        onSuccess();
      }
    },
  });

  useEffect(() => {
    fetchData(`payment-latest/recurrent/${recurrentPaymentId}`);
  }, []);

  return (
    <>
      <Loader loading={recurrentPaymentLoading || !recurrentPayment}>
        <Grid
          container
          sx={{ p: 2, mx: 'auto', mt: 4, maxWidth: 1200 }}
          display="flex"
        >
          <Grid item sm={6}>
            <RecurrentPaymentSummary recurrentPayment={recurrentPayment!} />
          </Grid>

          <Grid
            item
            sm={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            component={Paper}
            sx={{ p: 4 }}
          >
            <StudentSelectContainer
              onSubmit={() => formik.submitForm()}
              submitting={submitting}
              onSetStudentData={(
                data: { student_id: string; grade_id: string }[]
              ) => {
                setStudents(data);
              }}
            />

            <Grid>
              {Boolean(formik.touched.payments && formik.errors.payments) && (
                <Typography color="error">
                  Fixed payment {JSON.stringify(formik.errors.payments)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Loader>
    </>
  );
};

export default CreateRecurrentPendingContainer;
