/* eslint-disable react-hooks/exhaustive-deps */
import { Restore, ViewList } from '@mui/icons-material';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFetchApiData from '../../../../../core/hooks/useFetchApiData';
import DateModified from '../../../../../core/ui/utility/DateModified';
import Loader from '../../../../../core/ui/utility/Loader';
import { FixedPayment } from '../../fixedPayment';
import RestoreFixedDialog from '../dialogs/RestoreFixedDialog';

const FixedArchivedPaymentList = () => {
  const { data, loading, fetchData } = useFetchApiData<FixedPayment>();
  const [payment, setPayment] = useState<FixedPayment | null>(null);

  useEffect(() => {
    fetchData('payment-latest/fixed/archived', {
      dataAccessor: (res) => res.data.data,
    });
  }, []);

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Fixed Payment Archive List
        </Typography>

        <Loader loading={loading}>
          <TableContainer
            component={Paper}
            sx={{ overflowX: 'auto', maxWidth: 1000, mt: 2 }}
          >
            <Table aria-label="custom pagination table" size="small">
              <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Effective Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Modified</TableCell>
                  <TableCell align="right" sx={{ width: 48 }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((payment) => (
                  <TableRow key={payment.id}>
                    <TableCell size="small">{payment.description}</TableCell>
                    <TableCell size="small">{payment.amount}</TableCell>
                    <TableCell size="small">{payment.effective_date}</TableCell>
                    <TableCell size="small">{payment.end_date}</TableCell>
                    <TableCell component="th" scope="row" sx={{ py: '4px' }}>
                      <DateModified
                        createdAt={payment.created_at}
                        updatedAt={payment.updated_at}
                      />
                    </TableCell>
                    <TableCell align="right" sx={{ width: 48 }}>
                      <Grid display="flex" flexDirection="row">
                        <Link to={`/payment/fixed/archived/${payment.id}`}>
                          <ViewList fontSize="small" />
                        </Link>

                        <Tooltip title="Restore Payment">
                          <Restore
                            fontSize="small"
                            color="success"
                            onClick={() => setPayment(payment)}
                            sx={{ ml: 1, cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Loader>
      </Box>

      <RestoreFixedDialog
        payment={payment}
        onSuccess={() => {
          fetchData('payment-latest/fixed/archived', {
            dataAccessor: (res) => res.data.data,
          });
          setPayment(null);
        }}
        cancel={() => setPayment(null)}
      />
    </>
  );
};

export default FixedArchivedPaymentList;
