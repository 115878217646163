import { sortByText } from '../../../../../core/utils/data';
import { Student } from '../../../student/student';
import { Cst } from '../../cst/cst';
import { EvaluationMethod } from '../../evaluationMethod/evaluationMethod';
import { StudentsForMarklist } from '../sml';

export const parseStudentsForMarklist = (
  students: Student[],
  cst: Cst
): [Record<string, EvaluationMethod>, StudentsForMarklist[]] => {
  const evaluationMethodMap: Record<string, EvaluationMethod> = {};
  sortByText(
    cst.evaluationMethods,
    (e: EvaluationMethod) => e.evaluationType.name,
    'asc'
  ).forEach((item) => {
    evaluationMethodMap[item.id] = item;
  });

  const studentScoreMap: Record<string, Record<string, number>> = {};
  const studentSmlMap: Record<string, Record<string, string>> = {};

  cst.evaluationMethods.forEach((method) => {
    method.smls.forEach((sml) => {
      if (studentScoreMap[sml.grade_student_id] === undefined) {
        studentScoreMap[sml.grade_student_id] = {};
        studentSmlMap[sml.grade_student_id] = {};
      }
      studentScoreMap[sml.grade_student_id][sml.evaluation_method_id] =
        sml.score;

      studentSmlMap[sml.grade_student_id][sml.evaluation_method_id] = sml.id;
    });
  });

  const studentList = students.map((student) => ({
    ...student,
    gradeStudentId: student.gradeStudents[0].id,
    evaluationMethod: evaluationMethodMap,
    score: studentScoreMap[student.gradeStudents[0].id] || {},
    smlMap: studentSmlMap[student.gradeStudents[0].id] || {},
  }));

  return [evaluationMethodMap, studentList];
  // return [{}, []];
};

export const getMarkSum = (scores: number[]) =>
  scores.reduce((a, b) => a + b, 0);
