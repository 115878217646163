import { Box, MenuItem, Select, Typography } from '@mui/material';
import Layout from '../../../../core/ui/layout/Layout';
import useFetchGradeStudents from '../../../academic/gradeStudent/hooks/useFetchGradeStudents';
import { useContext } from 'react';
import GlobalContext from '../../../../core/service/globalContext';
import Loading, { LoadingFit } from '../../../../core/ui/utility/Loading';
import useGetStudentPayment from '../lib/hooks/useGetStudentPayment';
import StudentPaymentReportTable from '../components/StudentPaymentReportTable';

const StudentPaymentPage = () => {
  const { activeYear } = useContext(GlobalContext);

  const { gradeStudents, loading } = useFetchGradeStudents(activeYear!.id);
  const {
    studentId,
    setStudentId,
    loading: reportLoading,
    report,
  } = useGetStudentPayment();

  if (loading) {
    return <Loading />;
  }

  return (
    <Layout>
      <Box p={2}>
        <Typography mb={1} variant="h5">
          View Student Payments
        </Typography>

        <Box>
          <Select
            value={studentId}
            size="small"
            label="Student"
            onChange={(e) => setStudentId(e.target.value)}
            sx={{ width: 300 }}
          >
            <MenuItem value=""></MenuItem>
            {gradeStudents
              .sort((a, b) =>
                a.student.first_name.localeCompare(b.student.first_name)
              )
              .map((gs) => (
                <MenuItem value={gs.student_id} key={gs.id}>
                  {gs.student.first_name} {gs.student.father_name} (
                  {gs.grade.name})
                </MenuItem>
              ))}
          </Select>
        </Box>

        {reportLoading ? (
          <LoadingFit />
        ) : (
          report !== null && <StudentPaymentReportTable report={report} />
        )}

        <Box height={40} />
      </Box>
    </Layout>
  );
};

export default StudentPaymentPage;
