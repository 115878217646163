import { Print } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { ReactElement, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Student } from '../../student/student';
import IDCard from './IDCard';

type IDListingProps = {
  idType: 'main' | 'nursery';
  students: Student[];
  grade: string;
  year: number;
};

const IDListing = ({ students, grade, idType, year }: IDListingProps) => {
  // console.log(students);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
    documentTitle: 'Id card',
    onAfterPrint: () => console.log('Print success'),
  });

  const IdPlaceholder = () => (
    <div
      style={{
        width: '50%',
        height: '100%',
        margin: '5px',
        padding: '5px',
      }}
    ></div>
  );

  const parseIds = (): ReactElement[] => {
    const elements: ReactElement[] = [];

    for (let i = 0; i < students.length; i += 8) {
      const curElements: ReactElement[] = [];
      Array(4)
        .fill(null)
        .forEach((_, j) => {
          const curIndex = 2 * j + i;
          // console.log('index', !!students[curIndex], !!students[curIndex + 1]);

          curElements.push(
            <div
              style={{
                width: '100%',
                height: '25%',
                margin: '5px auto',
                display: 'flex',
              }}
              key={`key-${i}-${j}`}
            >
              {students[curIndex] ? (
                <IDCard
                  student={students[curIndex]}
                  grade={grade}
                  idType={idType}
                  year={year}
                />
              ) : (
                <IdPlaceholder />
              )}
              {students[curIndex + 1] ? (
                <IDCard
                  student={students[curIndex + 1]}
                  grade={grade}
                  idType={idType}
                  year={year}
                />
              ) : (
                <IdPlaceholder />
              )}
            </div>
          );
        });

      elements.push(
        <div
          style={{
            width: '100%',
            height: '100vh',
            padding: '20px 30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
          key={`parent-container${i}`}
        >
          {curElements}
        </div>
      );
    }

    return elements;
  };

  return (
    <>
      <Grid item>
        <Button
          onClick={() => {
            handlePrint();
          }}
          startIcon={<Print />}
          variant="outlined"
          sx={{ ml: 2, my: 2 }}
        >
          Print ids
        </Button>
      </Grid>
      <div ref={componentRef} style={{}}>
        {parseIds()}
      </div>
    </>
  );
};

export default IDListing;
