import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { StudentRecurrentPending } from '../lib/types/student-payment-report';
import moment from 'moment';

const StudentRecurrentPendingTable = ({
  pendings,
}: {
  pendings: StudentRecurrentPending[];
}) => {
  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          Pending Recurrent Payments
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ overflowX: 'auto', minWidth: 1000, maxWidth: 1600 }}
        >
          <Table aria-label="custom pagination table" size="small">
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell size="small" sx={{ width: '20px' }}>
                  #
                </TableCell>
                <TableCell sx={{}}>Payment</TableCell>
                <TableCell sx={{}}>Payment child</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Due</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendings.map((payment, i) => (
                <TableRow key={payment.id} sx={{ py: 0 }}>
                  <TableCell size="small">{i + 1}</TableCell>
                  <TableCell size="small">
                    {payment?.recurrentPayment.description}
                  </TableCell>
                  <TableCell size="small">
                    {payment?.recurrentPaymentChild.description}
                  </TableCell>
                  <TableCell size="small">
                    {payment?.recurrentPaymentChild.amount}
                  </TableCell>
                  <TableCell size="small">
                    {moment(payment.recurrentPaymentChild.end_date).format(
                      'MMM D YYYY'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default StudentRecurrentPendingTable;
