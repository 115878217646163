/* eslint-disable react-hooks/exhaustive-deps */
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { useSendApiData } from '../../../../core/hooks/useSendApiData';
import GlobalContext from '../../../../core/service/globalContext';
import { toastError, toastMessage } from '../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../core/utils/validation';
import { FixedPayment } from '../../../paymentLatest/fixed/fixedPayment';
import { Grade } from '../../grade/grade';
import { CreateGsActiveness } from '../gradeStudent';

export const useCreateActiveness = () => {
  const { callApi, loading: submitting } = useSendApiData();
  const { grades, years, activeYear } = useContext(GlobalContext);
  const [gradeSelection, setGradeSelection] = useState<
    {
      grade: Grade;
      selected: boolean;
    }[]
  >(grades.map((grade) => ({ grade, selected: false })));

  const initialValues: CreateGsActiveness = {
    academic_year_id: activeYear?.id || '',
    grade_ids: [],
    fixed_payment_id: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (
      values,
      { setFieldError }: FormikHelpers<CreateGsActiveness>
    ) => {
      await callApi({
        endpoint: 'academic/grade-students/activeness',
        data: values,
        onSuccess: () => {
          formik.resetForm();
          setGradeSelection(
            grades.map((grade) => ({ grade, selected: false }))
          );
          toastMessage('Activeness rule assigned');
        },
        onValidationError: (err) => parseValidationErrors(err, setFieldError),
        onError: toastError,
      });
    },
  });

  const {
    data: fixedPayments,
    loading,
    fetchData,
  } = useFetchApiData<FixedPayment>();

  useEffect(() => {
    fetchData('payment-latest/fixed/active');
  }, []);

  useEffect(() => {
    const gradeIds = gradeSelection
      .filter((i) => i.selected)
      .map((i) => i.grade.id);
    formik.setFieldValue('grade_ids', gradeIds);
  }, [gradeSelection]);

  return {
    grades,
    years,
    activeYear,
    fixedPayments,
    loading,
    gradeSelection,
    setGradeSelection,
    formik,
    submitting,
  };
};
