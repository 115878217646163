import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { StudentCreate } from '../student';
import {
  Grid,
  TextField,
  Paper,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from '@mui/material';
import { useContext } from 'react';
import GlobalContext from '../../../../core/service/globalContext';

type StudentProps = {
  onSubmit: (
    value: StudentCreate,
    helpers: FormikHelpers<StudentCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateStudentForm = ({ onSubmit, submitting }: StudentProps) => {
  const navigate = useNavigate();
  const { grades } = useContext(GlobalContext);

  const handleSubmit = async (
    value: StudentCreate,
    helpers: FormikHelpers<StudentCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/academic/student/create', { replace: true });
    }
  };

  const initialValues: StudentCreate = {
    first_name: '',
    father_name: '',
    grand_father_name: '',
    gender: '',
    id_number: '',
    grade_id: '',
    img: null,
    primary_phone: '',
    date_of_birth: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 400 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid item xs={8}>
            <Paper sx={{ p: 2, pb: 3 }}>
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="first_name"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="First Name"
                  placeholder=""
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.first_name)}
                  helperText={formik.errors.first_name}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="father_name"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Father Name"
                  placeholder=""
                  value={formik.values.father_name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.father_name)}
                  helperText={formik.errors.father_name}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="grand_father_name"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="G/Father Name"
                  placeholder=""
                  value={formik.values.grand_father_name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.grand_father_name)}
                  helperText={formik.errors.grand_father_name}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="date_of_birth"
                  size="small"
                  type="date"
                  variant="outlined"
                  label="Date"
                  placeholder=""
                  value={formik.values.date_of_birth}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.date_of_birth)}
                  helperText={formik.errors.date_of_birth}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  name="primary_phone"
                  size="small"
                  type="text"
                  variant="outlined"
                  label="Phone"
                  placeholder=""
                  value={formik.values.primary_phone}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.primary_phone)}
                  helperText={formik.errors.primary_phone}
                />
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    value={formik.values.gender}
                    size="small"
                    label="Gender"
                    name="gender"
                    onChange={formik.handleChange}
                    fullWidth
                  >
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Male">Male</MenuItem>
                  </Select>
                  {formik.touched.gender && formik.errors.gender && (
                    <FormHelperText error>
                      {formik.errors.gender}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>Grade</InputLabel>
                  <Select
                    value={formik.values.grade_id}
                    size="small"
                    label="Grade"
                    name="grade_id"
                    onChange={formik.handleChange}
                    fullWidth
                  >
                    {grades.map((grade) => (
                      <MenuItem key={grade.id} value={grade.id}>
                        {grade.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.grade_id && formik.errors.grade_id && (
                    <FormHelperText error>
                      {formik.errors.grade_id}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid container spacing={1} sx={{ mt: 2 }}>
                <InputLabel sx={{ mr: 1 }}>Image</InputLabel>
                <input
                  id="btn-upload"
                  name="btn-upload"
                  type="file"
                  itemType="image/*"
                  onChange={(e) =>
                    formik.setFieldValue(
                      'img',
                      e.target.files ? e.target.files[0] : null
                    )
                  }
                />
                {formik.touched.img && formik.errors.img && (
                  <FormHelperText error>{formik.errors.img}</FormHelperText>
                )}
              </Grid>

              <Grid container sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={submitting}
                  size="small"
                  fullWidth
                >
                  {submitting ? 'Saving' : 'Save'}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateStudentForm;
