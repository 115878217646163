import { useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import { Teacher } from '../teacher';

const useTeacherHook = () => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const { fetchData, loading: teachersLoading } = useFetchApiData();

  const fetchTeachers = async () => {
    await fetchData('academic/teachers', {
      onSuccess: ({ data }) => {
        setTeachers(data);
      },
    });
  };

  return { teachers, teachersLoading, fetchTeachers };
};

export default useTeacherHook;
