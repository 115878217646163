/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import useFetchApiData from '../../../../core/hooks/useFetchApiData';
import GlobalContext from '../../../../core/service/globalContext';
import { Student } from '../../student/student';

const useGradeStudentSelector = () => {
  const { years, grades, activeYear } = useContext(GlobalContext);
  const [year, setYear] = useState(activeYear?.id || '');
  const [grade, setGrade] = useState('');

  const {
    fetchData: fetchStudents,
    data: students,
    loading: studentsLoading,
  } = useFetchApiData<Student>();

  useEffect(() => {
    // console.log(`academic/grade-students/year-students/${grade}/${year}`);
    if (year && grade) {
      fetchStudents(`academic/grade-students/year-students/${grade}/${year}`);
    }
  }, [year, grade]);

  return {
    students,
    studentsLoading,
    years,
    grades,
    grade,
    year,
    setGrade,
    setYear,
  };
};

export default useGradeStudentSelector;
