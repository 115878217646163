import { Info, Warning } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSendApiData } from '../../../../../core/hooks/useSendApiData';
import { toastError, toastMessage } from '../../../../../core/utils/ui/alert';
import { RecurrentChild } from '../../recurrent';

type DeleteProps = {
  payment: RecurrentChild | null;
  cancel: () => void;
  onSuccess: () => void;
};

const DeleteRecurrentChildDialog = ({
  payment,
  cancel,
  onSuccess,
}: DeleteProps) => {
  const [text, setText] = useState('');
  const { callApi, loading } = useSendApiData();

  const handleSubmit = async () => {
    let success = false;
    await callApi({
      endpoint: `payment-latest/recurrent/child/${payment!.id}`,
      data: {
        archived: false,
      },
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      onError: toastError,
      onSuccess: async () => {
        toastMessage('Fixed Payment removed');
        success = true;
      },
    });

    if (success) {
      onSuccess();
      setText('');
    }
  };

  return (
    <>
      <Dialog
        open={payment !== null}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Do you want to remove recurrent sub payment?'}
        </DialogTitle>
        <DialogContent>
          <Grid
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Warning fontSize="medium" color="warning" sx={{ mr: 2 }} />
            <Typography variant="h6" color="crimson">
              This action is permanent and can not be undone
            </Typography>
          </Grid>

          <DialogContentText>
            This will remove the following associated data with this recurrent
            payment
          </DialogContentText>

          <List>
            <ListItem>
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText primary="Pending payments" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText primary="Student payments" />
            </ListItem>
          </List>

          <FormControl fullWidth sx={{ mt: 1 }}>
            <TextField
              value={text}
              onChange={(e) => setText(e.target.value)}
              label={`Type ${payment?.description} to proceed`}
              size="small"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            autoFocus
            color="error"
            disabled={loading || text !== payment?.description}
          >
            Delete Forever
          </Button>
          <Button disabled={loading} onClick={cancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteRecurrentChildDialog;
