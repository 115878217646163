import { sortByText } from '../../../../../core/utils/data';
import { EvaluationMethod } from '../../evaluationMethod/evaluationMethod';
import { EvaluationType } from '../../evaluationType/evaluationType';
import { Cst } from '../cst';

export const parseEvaluationForPreview = (evaluations: EvaluationMethod[]) => {
  const parsed: Record<
    string,
    { id: string; quarterId: string; evaluations: EvaluationType[] }
  > = {};

  evaluations.forEach(({ id, quarter, evaluationType }) => {
    if (parsed[quarter.quarter] === undefined) {
      parsed[quarter.quarter] = {
        id,
        quarterId: quarter.id,
        evaluations: [],
      };
    }
    parsed[quarter.quarter].evaluations.push(evaluationType);
  });

  return parsed;
};

export const sortCsts = (csts: Cst[]) =>
  sortByText(csts, (cst: Cst) => cst.subject.subject, 'asc');
