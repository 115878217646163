import { TableCell, TableRow } from '@mui/material';
import { ReactChildren } from '../../utils/types';
import Loading from './Loading';

type TableLoaderProps = {
  loading: boolean;
  span: number;
} & ReactChildren;

const TableLoader = ({ loading, span, children }: TableLoaderProps) => {
  return (
    <>
      {loading ? (
        <TableRow>
          <TableCell colSpan={span}>
            <Loading />
          </TableCell>
        </TableRow>
      ) : (
        children
      )}
    </>
  );
};

export default TableLoader;
