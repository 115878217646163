/* eslint-disable react-hooks/exhaustive-deps */
import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useSendApiData } from '../../../../../../core/hooks/useSendApiData';
import Loader from '../../../../../../core/ui/utility/Loader';
import ModalContainer from '../../../../../../core/ui/utility/ModalContainer';
import { printUser } from '../../../../../../core/utils/text';
import {
  toastError,
  toastMessage,
} from '../../../../../../core/utils/ui/alert';
import { parseValidationErrors } from '../../../../../../core/utils/validation';
import useTeacherHook from '../../../../teacher/hooks/useTeacherHook';
import { sortTeachers } from '../../../../teacher/services/teacherService';
import { Cst, CstEdit } from '../../cst';

type CSTEditFormProps = {
  cst: Cst;
  onSuccess: () => Promise<void>;
};

const CSTEditForm = ({ cst, onSuccess }: CSTEditFormProps) => {
  const [open, setOpen] = useState(false);

  const { callApi, loading: submitting } = useSendApiData();
  const { teachers, teachersLoading, fetchTeachers } = useTeacherHook();

  const initialValues: CstEdit = {
    teacher_id: cst.teacher_id,
    count: cst.count,
  };

  const handleSubmit = async (
    values: CstEdit,
    { setFieldError }: FormikHelpers<CstEdit>
  ) => {
    await callApi({
      endpoint: `academic/marklist/csts/${cst.id}`,
      data: values,
      method: 'patch',
      headers: {
        'Content-Type': 'application/json',
      },
      onValidationError: (err) => parseValidationErrors(err, setFieldError),
      onError: toastError,
      onSuccess: async (_) => {
        // setOpen(false);
        onSuccess();
        toastMessage('Evaluation Method edited');
      },
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    fetchTeachers();
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="row" sx={{ mr: 2 }}>
        <IconButton onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>

        <Loader loading={teachersLoading}>
          <ModalContainer open={open} setOpen={setOpen}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container justifyContent="space-between">
                    <Grid
                      item
                      alignSelf="flex-start"
                      sx={{ flexGrow: 1, mt: 2 }}
                    >
                      <FormControl fullWidth>
                        <InputLabel>Teacher</InputLabel>
                        <Select
                          size="small"
                          label="Teacher"
                          value={formik.values.teacher_id}
                          onChange={formik.handleChange}
                          name="teacher_id"
                        >
                          {sortTeachers(teachers).map((teacher) => (
                            <MenuItem key={teacher.id} value={teacher.id}>
                              {printUser(teacher.user)}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.teacher_id &&
                          formik.errors.teacher_id && (
                            <FormHelperText error>
                              {formik.errors.teacher_id}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        name="count"
                        size="small"
                        type="number"
                        variant="outlined"
                        label="Weekly count"
                        placeholder="Weekly count"
                        value={formik.values.count}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.count && Boolean(formik.errors.count)
                        }
                        helperText={formik.touched.count && formik.errors.count}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                    sx={{ mt: 1 }}
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </ModalContainer>
        </Loader>
      </Box>
    </>
  );
};

export default CSTEditForm;
