import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Modal,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { modalStyle } from '../../../../../../core/ui/utility/ModalContainer';
import { getStudentName } from '../../../../../../core/utils/text';
import {
  QuarterRcqCst,
  QuarterReport,
  SemesterRcsCst,
  SemesterReport,
  YearRcyCst,
  YearReport,
  ReportPeriod,
  ReportRoster,
} from '../../models/report';

type GradeStatProps = {
  grade: string;
  range: { min: number; max: number };
  showReportMeta: boolean;
  subjects: { id: string; subject: string }[];
  period: ReportPeriod;
  gradeReport: ReportRoster;
};

const GradeStat = ({
  grade,
  range,
  showReportMeta,
  subjects,
  period,
  gradeReport,
}: GradeStatProps) => {
  const [modalMeta, setModalMeta] = useState<{
    grade: string;
    subject: string;
    csts: (
      | QuarterRcqCst
      | QuarterReport
      | SemesterRcsCst
      | SemesterReport
      | YearRcyCst
      | YearReport
    )[];
    type:
      | 'quarter-rcq'
      | 'quarter-report'
      | 'semester-rcs'
      | 'semester-report'
      | 'year-rcy'
      | 'year-report';
  } | null>(null);

  const renderQuarterData = () => {
    const rows = subjects.map(({ id: subjectId, subject }, i) => {
      const csts = gradeReport.quarterRcqCsts[period.id]
        .filter((i) => i.cst.subject_id === subjectId)
        .filter((i) => i.score >= range.min && i.score <= range.max);

      const cstsMale = csts.filter(
        (i) => i.rcq.gradeStudent.student.gender === 'Male'
      );

      return (
        <TableRow key={`${period.id}-${subjectId}`}>
          <TableCell>{i + 1}</TableCell>
          <TableCell>{grade}</TableCell>
          <TableCell>{subject}</TableCell>
          <TableCell align="center">{cstsMale.length}</TableCell>
          <TableCell align="center">{csts.length - cstsMale.length}</TableCell>
          <TableCell align="center">{csts.length}</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setModalMeta({
                  grade,
                  subject,
                  csts: csts,
                  type: 'quarter-rcq',
                });
              }}
            >
              Show Students
            </Button>
          </TableCell>
        </TableRow>
      );
    });

    if (showReportMeta) {
      const rcqs = gradeReport.quarterReports[period.id].filter(
        (i) => i.average >= range.min && i.average <= range.max
      );

      const rcqMale = rcqs.filter(
        (i) => i.gradeStudent.student.gender === 'Male'
      );

      rows.push(
        <TableRow key={`${period.id}-${period.label}`}>
          <TableCell>{subjects.length + 1}</TableCell>
          <TableCell>{grade}</TableCell>
          <TableCell>{period.label} Average</TableCell>
          <TableCell align="center">{rcqMale.length}</TableCell>
          <TableCell align="center">{rcqs.length - rcqMale.length}</TableCell>
          <TableCell align="center">{rcqs.length}</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setModalMeta({
                  grade,
                  subject: `${period.label} Average`,
                  csts: rcqs,
                  type: 'quarter-report',
                });
              }}
            >
              Show Students
            </Button>
          </TableCell>
        </TableRow>
      );
    }

    return rows;
  };

  const renderSemesterData = () => {
    const rows = subjects.map(({ id: subjectId, subject }, i) => {
      const csts = gradeReport.semesterRcsCsts[period.id]
        .filter((i) => i.cst.subject_id === subjectId)
        .filter((i) => i.score >= range.min && i.score <= range.max);

      const cstsMale = csts.filter(
        (i) => i.rcs.gradeStudent.student.gender === 'Male'
      );

      return (
        <TableRow key={`${period.id}-${subjectId}`}>
          <TableCell>{i + 1}</TableCell>
          <TableCell>{grade}</TableCell>
          <TableCell>{subject}</TableCell>
          <TableCell align="center">{cstsMale.length}</TableCell>
          <TableCell align="center">{csts.length - cstsMale.length}</TableCell>
          <TableCell align="center">{csts.length}</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setModalMeta({
                  grade,
                  subject,
                  csts: csts,
                  type: 'semester-rcs',
                });
              }}
            >
              Show Students
            </Button>
          </TableCell>
        </TableRow>
      );
    });

    if (showReportMeta) {
      const rcss = gradeReport.semesterReports[period.id].filter(
        (i) => i.average >= range.min && i.average <= range.max
      );

      const rcsMale = rcss.filter(
        (i) => i.gradeStudent.student.gender === 'Male'
      );

      rows.push(
        <TableRow key={`${period.id}-${period.label}`}>
          <TableCell>{subjects.length + 1}</TableCell>
          <TableCell>{grade}</TableCell>
          <TableCell>{period.label} Average</TableCell>
          <TableCell align="center">{rcsMale.length}</TableCell>
          <TableCell align="center">{rcss.length - rcsMale.length}</TableCell>
          <TableCell align="center">{rcss.length}</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setModalMeta({
                  grade,
                  subject: `${period.label} Average`,
                  csts: rcss,
                  type: 'semester-report',
                });
              }}
            >
              Show Students
            </Button>
          </TableCell>
        </TableRow>
      );
    }

    return rows;
  };

  const renderYearData = () => {
    const rows = subjects.map(({ id: subjectId, subject }, i) => {
      const csts = gradeReport.yearRcyCsts
        .filter((i) => i.cst.subject_id === subjectId)
        .filter((i) => i.score >= range.min && i.score <= range.max);

      const cstsMale = csts.filter(
        (i) => i.rcy.gradeStudent.student.gender === 'Male'
      );
      // console.log({ report: gradeReport.yearRcyCsts, csts });

      return (
        <TableRow key={`year-${subjectId}`}>
          <TableCell>{i + 1}</TableCell>
          <TableCell>{grade}</TableCell>
          <TableCell>{subject}</TableCell>
          <TableCell align="center">{cstsMale.length}</TableCell>
          <TableCell align="center">{csts.length - cstsMale.length}</TableCell>
          <TableCell align="center">{csts.length}</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setModalMeta({
                  grade,
                  subject,
                  csts: csts,
                  type: 'year-rcy',
                });
              }}
            >
              Show Students
            </Button>
          </TableCell>
        </TableRow>
      );
    });

    if (showReportMeta) {
      const rcys = gradeReport.yearReports.filter(
        (i) => i.average >= range.min && i.average <= range.max
      );

      const rcsMale = rcys.filter(
        (i) => i.gradeStudent.student.gender === 'Male'
      );

      rows.push(
        <TableRow key={`year-${period.label}`}>
          <TableCell>{subjects.length + 1}</TableCell>
          <TableCell>{grade}</TableCell>
          <TableCell>{period.label} Average</TableCell>
          <TableCell align="center">{rcsMale.length}</TableCell>
          <TableCell align="center">{rcys.length - rcsMale.length}</TableCell>
          <TableCell align="center">{rcys.length}</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="contained"
              onClick={() => {
                setModalMeta({
                  grade,
                  subject: `${period.label} Average`,
                  csts: rcys,
                  type: 'year-report',
                });
              }}
            >
              Show Students
            </Button>
          </TableCell>
        </TableRow>
      );
    }

    return rows;
  };

  const parseScore = (score: number) =>
    Math.floor(score) === score ? score : score.toFixed(2);

  const parseCsts: Record<
    | 'quarter-rcq'
    | 'quarter-report'
    | 'semester-rcs'
    | 'semester-report'
    | 'year-rcy'
    | 'year-report',
    (arg: any) => any
  > = {
    'quarter-rcq': (csts: QuarterRcqCst[]) =>
      csts
        .sort((a, b) =>
          a.rcq.gradeStudent.student.first_name.localeCompare(
            b.rcq.gradeStudent.student.first_name
          )
        )
        .map((cst, i) => (
          <TableRow key={`quarter-rcq-${i}`}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>
              {getStudentName(cst.rcq.gradeStudent.student)}
            </TableCell>
            <TableCell>{cst.rcq.gradeStudent.student.gender}</TableCell>
            <TableCell>{parseScore(cst.score)}</TableCell>
          </TableRow>
        )),
    'quarter-report': (rcqs: QuarterReport[]) =>
      rcqs
        .sort((a, b) =>
          a.gradeStudent.student.first_name.localeCompare(
            b.gradeStudent.student.first_name
          )
        )
        .map((rcq, i) => (
          <TableRow key={`quarter-rcq-${i}`}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>{getStudentName(rcq.gradeStudent.student)}</TableCell>
            <TableCell>{rcq.gradeStudent.student.gender}</TableCell>
            <TableCell>{parseScore(rcq.average)}</TableCell>
          </TableRow>
        )),
    'semester-rcs': (csts: SemesterRcsCst[]) =>
      csts
        .sort((a, b) =>
          a.rcs.gradeStudent.student.first_name.localeCompare(
            b.rcs.gradeStudent.student.first_name
          )
        )
        .map((cst, i) => (
          <TableRow key={`quarter-rcs-${i}`}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>
              {getStudentName(cst.rcs.gradeStudent.student)}
            </TableCell>
            <TableCell>{cst.rcs.gradeStudent.student.gender}</TableCell>
            <TableCell>{parseScore(cst.score)}</TableCell>
          </TableRow>
        )),
    'semester-report': (rcss: SemesterReport[]) =>
      rcss
        .sort((a, b) =>
          a.gradeStudent.student.first_name.localeCompare(
            b.gradeStudent.student.first_name
          )
        )
        .map((rcs, i) => (
          <TableRow key={`quarter-rcs-${i}`}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>{getStudentName(rcs.gradeStudent.student)}</TableCell>
            <TableCell>{rcs.gradeStudent.student.gender}</TableCell>
            <TableCell>{parseScore(rcs.average)}</TableCell>
          </TableRow>
        )),
    'year-rcy': (csts: YearRcyCst[]) =>
      csts
        .sort((a, b) =>
          a.rcy.gradeStudent.student.first_name.localeCompare(
            b.rcy.gradeStudent.student.first_name
          )
        )
        .map((cst, i) => (
          <TableRow key={`quarter-rcy-${i}`}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>
              {getStudentName(cst.rcy.gradeStudent.student)}
            </TableCell>
            <TableCell>{cst.rcy.gradeStudent.student.gender}</TableCell>
            <TableCell>{parseScore(cst.score)}</TableCell>
          </TableRow>
        )),
    'year-report': (rcys: SemesterReport[]) =>
      rcys
        .sort((a, b) =>
          a.gradeStudent.student.first_name.localeCompare(
            b.gradeStudent.student.first_name
          )
        )
        .map((rcy, i) => (
          <TableRow key={`quarter-rcy-${i}`}>
            <TableCell>{i + 1}</TableCell>
            <TableCell>{getStudentName(rcy.gradeStudent.student)}</TableCell>
            <TableCell>{rcy.gradeStudent.student.gender}</TableCell>
            <TableCell>{parseScore(rcy.average)}</TableCell>
          </TableRow>
        )),
  };

  return (
    <>
      <Grid display="flex" flexDirection="row">
        <Grid>
          <Table
            aria-label="custom pagination table"
            size="small"
            sx={{ flexShrink: 1 }}
          >
            <TableHead sx={{ background: 'rgb(243,243,242)' }}>
              <TableRow>
                <TableCell rowSpan={2} sx={{ minWidth: 80 }}>
                  No.
                </TableCell>
                <TableCell rowSpan={2} sx={{ minWidth: 150 }}>
                  Grade
                </TableCell>
                <TableCell rowSpan={2} sx={{ minWidth: 180 }}>
                  Subject
                </TableCell>
                <TableCell align="center" colSpan={3} sx={{ minWidth: 300 }}>
                  Student count
                </TableCell>
                <TableCell align="right" rowSpan={2} sx={{ minWidth: 150 }}>
                  Actions
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Male</TableCell>
                <TableCell align="center">Female</TableCell>
                <TableCell align="center">Both</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {period.type === 'quarter' && renderQuarterData()}
              {period.type === 'semester' && renderSemesterData()}
              {period.type === 'year' && renderYearData()}
            </TableBody>
          </Table>
        </Grid>

        <Modal
          open={modalMeta !== null}
          onClose={() => setModalMeta(null)}
          aria-labelledby="modal-preview-title"
          aria-describedby="modal-preview-description"
        >
          <Box
            sx={{
              ...modalStyle,
              p: 4,
              minWidth: 800,
              maxHeight: 800,
              overflow: 'auto',
            }}
          >
            <Grid display="flex" flexDirection="column" sx={{ py: 2 }}>
              <Typography variant="h5">{modalMeta?.grade}</Typography>
              <Typography sx={{ mt: 1, mb: 2, fontWeight: 'bold' }}>
                {modalMeta?.subject}
              </Typography>

              <Table
                aria-label="custom pagination table"
                size="small"
                sx={{ flexShrink: 1 }}
              >
                <TableHead sx={{ background: 'rgb(243,243,242)' }}>
                  <TableRow>
                    <TableCell rowSpan={2} sx={{ minWidth: 20 }}>
                      No
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ minWidth: 150 }}>
                      Student
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ minWidth: 80 }}>
                      Gender
                    </TableCell>
                    <TableCell rowSpan={2} sx={{ minWidth: 40 }}>
                      Score
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modalMeta && parseCsts[modalMeta!.type](modalMeta!.csts)}
                </TableBody>
              </Table>
            </Grid>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default GradeStat;
